import React, { useCallback, useEffect, useRef, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";

import WeekHeader from "./WeekHeader";
import Month from "./Month";
import { defaultUtils as utils } from "./dateUtils";
import CalendarToolbar from "./CalendarToolbar";
import CalendarButtons from "./CalendarButtons";
import DateDisplay from "./DateDisplay";
import { TABLET_BP } from "../../../../const/users";

const useStyles = makeStyles(theme => ({
    root: {
        flex: "1",
        display: "flex",
        maxHeight: "100%",
        overflow: "hidden",
    },
    rootMobile: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
    },
    selectorContainer: {
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    calendarContainer: {
        display: "flex",
        maxWidth: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: `0 ${theme.spacing(1)}px`,
        color: "#9D9FA2"
    },
    calendarButtons: {
        padding: "12px 24px 24px",
        marginTop: 'auto'
    },
    suggestedDates: {
        color: "#2A2728",
        fontSize: '14px',
        fontWeight: '500'
    },
    suggestedDatesTitle: {
        color: "#9D9FA2",
        fontSize: '12px',
        fontWeight: '500'
    }
}));

const Calendar = ({
    initialDate,
    maxDate,
    minDate,
    selectedDates,
    suggestedDates,
    suggestedEndTime,
    onSelect,
    onCancel,
    onOk,
    readOnly,
    onRemoveAtIndex,
    cancelButtonText,
    submitButtonText,
    selectedDatesTitle,
    isOfferTime,
    invalid
}) => {
    const calendar = useRef(null);
    const classes = useStyles();
    const matches = useMediaQuery(TABLET_BP);

    const [displayDate, setDisplayDate] = useState(() =>
        utils.getFirstDayOfMonth(initialDate || new Date())
    );

    const handleMonthChange = useCallback(
        months => {
            setDisplayDate(displayDate => utils.addMonths(displayDate, months));
        },
        [setDisplayDate]
    );

    useEffect(
        () => {
            setDisplayDate(utils.getFirstDayOfMonth(initialDate || new Date()));
        },
        [initialDate]
    );

    maxDate = maxDate || utils.addYears(new Date(), 100);
    minDate = minDate || utils.addYears(new Date(), -100);

    const toolbarInteractions = {
        prevMonth: utils.monthDiff(displayDate, minDate) > 0,
        nextMonth: utils.monthDiff(displayDate, maxDate) < 0
    };

    return (
        <div className={!matches ? classes.root : classes.rootMobile}>
            <div className={classes.selectorContainer}>
                <div className={classes.calendarContainer}>
                    <CalendarToolbar
                        displayDate={displayDate}
                        onMonthChange={handleMonthChange}
                        prevMonth={toolbarInteractions.prevMonth}
                        nextMonth={toolbarInteractions.nextMonth}
                    />
                    <WeekHeader />
                    <Month
                        displayDate={displayDate}
                        key={displayDate.toDateString()}
                        selectedDates={selectedDates}
                        suggestedDates={suggestedDates}
                        minDate={minDate}
                        maxDate={maxDate}
                        onSelect={onSelect}
                        readOnly={readOnly}
                        ref={calendar}
                    />
                </div>
            </div>
            <DateDisplay
                selectedDatesTitle={selectedDatesTitle}
                selectedDates={selectedDates}
                suggestedDates={suggestedDates}
                readOnly={readOnly}
                isOfferTime={isOfferTime}
                suggestedEndTime={suggestedEndTime}
                classes={classes}
                onRemoveAtIndex={onRemoveAtIndex}>
                <div className={classes.calendarButtons}>
                    <CalendarButtons
                        readOnly={readOnly}
                        onCancel={onCancel}
                        onOk={onOk}
                        cancelButtonText={cancelButtonText}
                        submitButtonText={submitButtonText}
                        invalid={invalid}
                    />
                </div>
            </DateDisplay>
        </div>
    );
};

export default Calendar;
