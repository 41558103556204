/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import _ from "lodash";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import BasePopup from '../BasePopup';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import Button from "../../components/Button";
import FormControl, { FORM_CONTROL_ELEMENT } from "../../components/FormControl";
import formConfig from './form-config';
import Label from "../../components/Label";
import Input from "../../components/Input";
import Flex from "../../components/Flex";
import ErrorMessage from "../../components/ErrorMessage";
import Caption from "../../components/Caption";
import CheckboxesControl from "../../components/CheckboxesControl";
import BranchesService from '../../services/BranchesService';
import Validator from "../../validators";
import { closePopup, getCompanyTypes, getServiceCenterById } from "../../redux/actions";
import formInternalization from "../../utils/form-internalization";

import styles from './index.module.scss'

class EditBranchServicePopup extends BasePopup {
    imageId: number;

    constructor(props: any) {
        super(props);
        let { getCompanyTypes, service } = props;
        let { branchName, businessTypes, companyName, image = {} } = service;
        let { id } = image || {};
        this.i18n = props.i18n;
        this.title = this.i18n.popups.summary_information;
        this.imageId = id;

        props.change('branchName', branchName);
        props.change('companyName', companyName);
        props.change('types', businessTypes.map(({ id }: any) => id));
        getCompanyTypes();
    }

    public onSubmit(model: any) {
        let props: any = this.props;
        const { serviceCenterId } = props.initialData;

        delete model.companyName;

        if (this.imageId) {
            model.imageId = this.imageId;
        }

        new BranchesService().editBranchInfo(serviceCenterId, model)
            .then(() => {
                props.getBranchServiceInfo(serviceCenterId);
                this.close();
            });
    }

    public renderContent() {
        let props: any = this.props;
        let { handleSubmit, invalid, $values } = props;
        let { companyTypes } = props;

        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <Flex className={styles.serviceName}>
                            <FormControl {...formConfig.carServiceName}
                                className={styles.control}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} disabled />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <FormControl {...formConfig.carServiceBranch}
                                className={styles.control}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                        </Flex>

                        <Caption
                            className={styles.Caption}
                            size={"16"}>
                            {this.i18n.popups.company_service_type}:
                        </Caption>

                        <Flex className={styles.checkBoxes}>
                            <CheckboxesControl
                                name={'types'}
                                collection={companyTypes.collection}
                                validate={[
                                    Validator.arrayNotEmpty(),
                                ]}
                            />
                        </Flex>
                    </form>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button
                        fontSize={16}
                        className={styles.btnCancel}
                        color={"secondary"}
                        onClick={this.close}
                        variant={"outlined"}>
                        {this.i18n.common.cancel}
                    </Button>

                    <Button
                        fontSize={16}
                        className={styles.btnSave}
                        disabled={invalid}
                        color={"secondary"}
                        onClick={() => this.onSubmit({ ...$values })}
                        variant={"text"}>
                        {this.i18n.common.save}
                    </Button>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let { companyTypes, branchServiceCenterInfo, service } = state;
    let { values = {} } = state.form[form] || {};
    return { companyTypes, service, branchServiceCenterInfo, $values: values, i18n: state.i18n }
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getBranchServiceInfo: getServiceCenterById(dispatch),
    getCompanyTypes: getCompanyTypes(dispatch)
});

const Popup: any = EditBranchServicePopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        companyName: '',
        branchName: '',
        types: []
    }
})(
    Popup
);

export default connect(mapStateToProps, mapDispatchToProps)(
    Form
);
