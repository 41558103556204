import React from "react";

import {
    IconCarService,
    IconCarWash,
    IconCheckUp,
    IconTireWorks,
    IconTowTruck,
    IconTuning,
} from "./../components/IconBooking";

export const BUSINESS_TYPES_MAP = new Map(
    [
        [1, (<IconCarService />)],
        [2, (<IconCheckUp />)],
        [3, (<IconCarWash />)],
        [5, (<IconTireWorks />)],
        [6, (<IconTowTruck />)],
        [7, (<IconTuning />)],
    ]
);

export enum BusinessTypesEnum {
    CarService = 1,
    CarCheckUp = 2,
    CarWash = 3,
    WashDetailing = 4, // not uset atm
    TireWorks = 5,
    TowTruck = 6,
    Tuning = 7
}

export enum BusinessTypesTranslationsEnum {
    car_service = 1,
    car_check_up = 2,
    car_wash = 3,
    tire_works = 5,
    tow_truck = 6,
    tuning = 7
}

export const CompanyTypesEnum: any = {
    1: "car_service",
    2: "car_check_up",
    3: "car_wash",
    5: "tire_works",
    6: "tow_truck",
    7: "tuning"
};
