import * as React from "react";
import { connect } from "react-redux";

import { closePopup } from "../../redux/actions";
import Icon from "../../components/Icon";
import Flex from "../../components/Flex";
import Caption from "../../components/Caption";
import Title from "../../components/Title";
import Button from "../../components/Button";

import styles from "./index.module.scss";
import StyledDialogContent from "../../components/Dialog/DialogContent";

interface IProps {
    closePopup: () => any;
    initialData: any;
}

class SuccessPopup extends React.Component<IProps> {
    i18n: any;

    constructor(props: any) {
        super(props);
        this.i18n = props.i18n;
    }

    public close = () => {
        let props: any = this.props;

        let { initialData = {} } = this.props;
        let { redirect } = initialData;
        props.closePopup(props.uuid);
        if (redirect) { redirect() };
    };

    render() {
        let { initialData = {} } = this.props;
        const { title, text, heading } = initialData;
        return (
            <StyledDialogContent >
                <div className={styles.popup}>

                    <Flex className={styles.icon}>
                        <Icon icon={'success'} />
                    </Flex>
                    <Title className={styles.title}>
                        {heading || this.i18n.popups.success}
                    </Title>
                    <Caption size={'16'}
                        className={styles.title}>
                        {title}
                    </Caption>

                    <Button large={true}
                        small={true}
                        fontSize={16}
                        className={styles.btn}
                        color='alter'
                        onClick={this.close}
                        variant={"text"}>
                        {text}
                    </Button>
                </div>
            </StyledDialogContent>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch)
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPopup);
