import styles from './helpers.module.scss'

const Classes = {
    spaceBetween: styles.spaceBetween,
    textCenter: styles.textAlignCenter,
    primaryColor: styles.primaryColor

};

export default Classes;
