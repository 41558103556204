/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { makeStyles, useMediaQuery } from '@material-ui/core';

import { setLanguage } from '../../redux/actions/i18n';
import StoreService from '../../services/StorageService';
import Divider from '../Divider';
import Icon from '../Icon';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../const/languages';
import { KEY_LANGUAGE } from '../../const/local-storage';
import { TABLET_BP } from '../../const/users';

import IconEE from '../../images/icons/estonian.svg'
import IconRUS from '../../images/icons/ic-flag-rassian.svg';
import IconENG from '../../images/icons/ic-flag-england.svg';

import styles from './index.module.scss';
import { joinClassNames } from '../../helpers/className';

const LanguageToggle = ({ setLanguage, color, removeIcons, selectedShortName, isShortMenu, contentStyles }: any) => {
    const anchorRef = React.useRef(null);
    const [languageToggle, setLanguageToggle] = useState(false);
    const sessionStorage = new StoreService();
    const [lang, setLang] = useState(sessionStorage.get(KEY_LANGUAGE, DEFAULT_LANGUAGE));
    const matches = useMediaQuery(TABLET_BP);
    const width = matches ? '100%' : '193px';
    const isSecondary = color === 'secondary';
    const contentClassName = joinClassNames(styles.content, contentStyles)

    const flags = {
        [LANGUAGES.EE.shortName]: IconEE,
        [LANGUAGES.RU.shortName]: IconRUS,
        [LANGUAGES.EN.shortName]: IconENG
    }

    const menuStyles = makeStyles({
        paper: {
            padding: '0 8px',
            borderRadius: '0px',
            backgroundColor: '#3D3B3B',
            marginTop: '30px',
            width: isShortMenu ? '100px' : width
        },
        list: {
            padding: '0px',
        }
    });

    const menuStylesSecondary = makeStyles({
        paper: {
            padding: '0 8px',
            borderRadius: '0px',
            backgroundColor: 'white',
            boxShadow: 'none',
            border: '1px solid #C7C8CA',
            width: isShortMenu ? '100px' : width,
            marginTop: '30px'
        },
        list: {
            padding: '0px',
            width: isShortMenu ? '100px' : '100%'
        }
    });

    const selectedItemStyles = makeStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: 'white',
            padding: '10px 8px'
        }
    });

    const selectedItemStylesSecondary = makeStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: '#2A2728',
            padding: '10px 8px'
        }
    })

    const itemStyles = makeStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            color: 'rgba(255,255,255,0.7)',
            padding: '10px 8px'
        }
    });

    const itemStylesSecondary = makeStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            color: '#2A2728',
            padding: '10px 8px'
        }
    });

    const languageToggleOpen = () => {
        setLanguageToggle(true);
    };

    const languageToggleClose = () => {
        setLanguageToggle(false);
    };

    const changeLang = (lang: any) => {
        setLang(lang);
        setLanguageToggle(false);
    }

    useEffect(() => {
        sessionStorage.set(KEY_LANGUAGE, lang);
        setLanguage(lang.shortName);
    }, [lang]);

    const languages = Object
        .values(LANGUAGES)
        .filter(language => language.shortName !== lang.shortName);

    return (
        <>
            <button
                className={isSecondary ? styles.btnSecondary : styles.btn}
                ref={anchorRef}
                onClick={languageToggleOpen}>
                <div className={contentClassName}>
                    {matches
                        ? <>
                            {!removeIcons && <img src={flags[lang.shortName]} className={styles.icon} alt='flag' />}
                            {selectedShortName ? lang.shortName.toUpperCase() : lang.fullName}
                        </>
                        : <>
                            {lang.shortName.toUpperCase()}
                        </>
                    }
                    <Icon icon='langArrow' />
                </div>
            </button>
            <Menu
                disableAutoFocusItem
                classes={isSecondary ? menuStylesSecondary() : menuStyles()}
                anchorEl={anchorRef.current}
                open={languageToggle}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

                onClose={languageToggleClose}
            >
                <MenuItem onClick={() => changeLang(lang)} classes={isSecondary ? selectedItemStylesSecondary() : selectedItemStyles()}>
                    {!removeIcons && <img src={flags[lang.shortName]} alt='flag'
                        className={isSecondary ? styles.iconSecondary : styles.icon} />}
                    {lang.fullName}
                    <Icon icon='langCheckmark' className={styles.checkMark} />
                </MenuItem>
                <Divider className={isSecondary ? styles.dividerSecondary : styles.divider} />
                {
                    Object.values(languages).map((lang: any, index: number) =>
                        <MenuItem key={index} onClick={() => changeLang(lang)} classes={isSecondary ? itemStylesSecondary() : itemStyles()}>
                            {!removeIcons && <img src={flags[lang.shortName]} alt='flag'
                                className={isSecondary ? styles.iconSecondary : styles.icon} />}
                            {lang.fullName}
                        </MenuItem>
                    )
                }
            </Menu>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLanguage: setLanguage(dispatch)
});

export default connect(null, mapDispatchToProps)(LanguageToggle);
