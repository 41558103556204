import { PRICE_FORMAT } from "../const/price-formats";

export function formatPrice(rate: number, priceFormat: string) {
    switch (priceFormat) {
        case PRICE_FORMAT.FIXED:
            return `${rate} €`;

        case PRICE_FORMAT.PER_HOUR:
            return `${rate} €/H`;

        case PRICE_FORMAT.MIN:
            return `min ${rate} €`

        default:
            return `${rate}`
    }
}
