import { Dispatch } from "redux";
import { CLOSE_MENU, OPEN_MENU } from "../constants";

export const openMenu = (dispatch: Dispatch) => () => {
    dispatch({ type: OPEN_MENU })
};

export const closeMenu = (dispatch: Dispatch) => () => {
    dispatch({ type: CLOSE_MENU })
};
