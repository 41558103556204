import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as sw2 from './sw2';
import { Provider } from "react-redux";
import store from './redux/store'
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

const isWWW = window.location.hostname.match('www.caru.ee');
const destination =
  "https://caru.ee" +
  window.location.pathname +
  window.location.search +
  window.location.hash;

if (isWWW) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
      window.location.href = destination;
    });
  } else {
    window.location.href = destination;
  }
} else {
  sw2.register()
};

new Worker(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`);
