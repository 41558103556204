import React from 'react'
import { connect } from "react-redux";
import uuid from "uuid/v4";

import BasePopup from "../BasePopup";
import { closePopup } from "../../redux/actions";
import StyledDialogContent from '../../components/Dialog/DialogContent';
import Title from "../../components/Title";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import { formatMilleage } from '../../pipes/mileage';
import { FUEL_TYPES, GEARBOX } from '../../const/validation';
import { VEHICLE_FIELDS } from "../../const/vehicle-fields";

import styles from './index.module.scss'

const Desc = ({ children }: any) => (<Text className={styles.Desc}>{children}</Text>);

class CarInformationPopup extends BasePopup {
    props: any;
    id?: number;
    model: any;
    contractVehicle = {} as any;

    constructor(props: any) {
        super(props);
        this.props = props;
        let { initialData } = props;
        this.withDivider = false;

        this.contractVehicle = initialData;

        this.className = styles.Card;
        this.title =
            <div className={styles.title}>
                <div className={styles.model}>
                    {this.contractVehicle.model}
                </div>
                <span>
                    {this.contractVehicle.brand}
                </span>
            </div>;
    }

    renderContent() {
        const { i18n } = this.props;
        const { vehicle_fields = {} } = i18n;

        const fuelTypes = FUEL_TYPES.map(item => ({ id: item, name: i18n.fuels[item] }));
        const gearType = GEARBOX.map(item => ({ id: item, name: i18n.gears[item] }));

        VEHICLE_FIELDS.forEach((item: any) => {
            item.title = vehicle_fields[item.key];
        });

        return (
            <StyledDialogContent>
                <Flex className={styles.parameters}>
                    {
                        Object.keys(this.contractVehicle).map((value: any, index: number) => {
                            const field = this.contractVehicle;
                            const fieldMap = VEHICLE_FIELDS.filter((item: any) => item.key === value)[0];
                            let displayedValue = field[value];

                            if (value === 'fuelType') {
                                const val = fuelTypes && fuelTypes.find(item => item.id === displayedValue);
                                displayedValue = val && val.name;
                            }

                            if (value === 'gearbox') {
                                const val = gearType && gearType.find(item => item.id === displayedValue);
                                displayedValue = val && val.name;
                            }

                            return (
                                !!fieldMap && !!field[value] &&
                                <div key={uuid()} className={styles.parameter}>
                                    <Flex className={styles.parameterHeader}>
                                        {fieldMap.icon}
                                        <Title>
                                            {fieldMap && fieldMap.title}
                                        </Title>
                                    </Flex>
                                    <Desc>{value === 'mileage' ? formatMilleage(displayedValue) : displayedValue}</Desc>
                                </div>
                            )
                        })
                    }
                </Flex>
            </StyledDialogContent>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(CarInformationPopup);
