import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import Card from '../Card';
import Rating from '../Rating';
import CompanyLogo from '../CompanyLogo';
import Button from '../Button';
import ContractsService from '../../services/ContractsService';
import { closePopup, openPopup } from '../../redux/actions';
import { CONFIRM_POPUP_FULL_SCREEN, OFFER_DETAILS_POPUP } from '../../popups';
import { STATUS_OFFER } from '../../const/offer-status';
import { CONTRACT_STATUS } from '../../const/contract-status';
import { formatDistance } from '../../pipes/distance';
import { formatPrice } from '../../pipes/price';
import HighlightedMessage from '../HighlightedMessage';
import StartEndTimeRow from '../TimeRow/StartEndTimeRow';
import ETARow from '../ETARow';
import { MOBILE_BP } from '../../const/users';
import updateDataLayer from "../../utils/data-layer";
import { FULL_DATE_WITH_TIME } from "../../const/date-time-format";
import styles from './index.module.scss';
import { joinClassNames } from '../../helpers/className';
import { useHistory } from 'react-router-dom';
import StorageService from '../../services/StorageService';
import { KEY_USER_INFO, KEY_IS_ACTIVE_SEARCH } from '../../const/local-storage';
import NotVerifiedEmailPopup from '../../popups/NotVerifiedEmail';

const storeService = new StorageService();

interface ActionRow {
    labelText: string,
    buttonText: string,
    onClick: (values: Event) => void,
    openDetails: () => void,
    declineOffer: (event: Event) => void,
    sectionStyles: string,
    isDisabled: boolean,
    isFromPopup: boolean,
    declineText: string,
    waitingText: string,
    isWaiting: boolean,
    buttonClassName: string,
}

const ActionRow = ({
    labelText,
    buttonText,
    onClick,
    sectionStyles,
    isDisabled,
    openDetails,
    isFromPopup,
    declineOffer,
    declineText,
    waitingText,
    isWaiting,
    buttonClassName,
}: ActionRow) => (
    <section className={sectionStyles}>
        {isWaiting ?
            <span className={styles.statusText}>{waitingText}</span>
            :
            <>
                {
                    isFromPopup ?
                        <Button
                            onClick={declineOffer}
                            variant="outlined"
                            disabled={isDisabled}
                            color="danger">
                            {declineText}
                        </Button>
                        :
                        <span className={styles.moreDetails} onClick={openDetails}>{labelText}</span>
                }
                <Button
                    className={buttonClassName}
                    disabled={isDisabled}
                    onClick={onClick}
                    color="primary">
                    {buttonText}
                </Button>
            </>
        }
    </section >
);

const CardOffer = (props: any) => {
    const {
        service: {
            serviceCenterId,
            id,
            branchName,
            distance,
            rating,
            rate,
            priceFormat,
            offerStatus,
            contractStatus,
            start,
            end,
            image,
            declineReason,
            eta,
            createdAt,
            service,
            companyName,
        },
        refresh,
        submitWithRefresh,
        withoutShadow,
        openPopup,
        closePopup,
        isNotClickable,
        isFromPopup = false,
        history: popupHistory,
    } = props;

    const { i18n: { bookings = {}, common = {}, find_service = {} } } = props;
    const isMobile = useMediaQuery(MOBILE_BP);
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);

    const userInfo = storeService.get(KEY_USER_INFO, {});

    const timeRow = !!eta
        ? <ETARow eta={eta} />
        : <StartEndTimeRow start={start} end={end} />

    const anotherPicked = STATUS_OFFER.Accepted !== offerStatus
        && CONTRACT_STATUS.WaitingForConfirmation === contractStatus;

    const isRequested = STATUS_OFFER.Accepted === offerStatus &&
        CONTRACT_STATUS.WaitingForConfirmation === contractStatus;

    const isDeclined = (STATUS_OFFER.DeclinedByServiceOwner === offerStatus) || (STATUS_OFFER.DeclinedByCarOwner === offerStatus);
    const declinedByService = STATUS_OFFER.DeclinedByServiceOwner === offerStatus;
    const isDisabled = (anotherPicked && isDeclined) || anotherPicked;

    const redirectToReviews = () => {
        if (history) {
            history.push(`/user/dashboard/service-details/${serviceCenterId || service.serviceCenterId}?tab=3`)
            return;
        }
        refresh()
        popupHistory.push(`/user/dashboard/service-details/${serviceCenterId || service.serviceCenterId}?tab=3`)
    }

    const redirectToProfile = () => {
        if (history) {
            history.push(`/user/dashboard/service-details/${serviceCenterId || service.serviceCenterId}`)
            return
        }
        refresh()
        popupHistory.push(`/user/dashboard/service-details/${serviceCenterId || service.serviceCenterId}`)
    }

    const declineOffer = () => {
        new ContractsService()
            .declineOffer(id)
            .then(() => {
                updateDataLayer({
                    'eventCategory': 'booking',
                    'eventAction': 'decline',
                    'eventLabel': '7  step',
                    'event': 'autoEvent',
                });
                refresh()
            });
    };

    const handleOpenInfoPopup = () => {
        if (!userInfo.isEmailActivated) {
            setIsOpen(true);
        }
    }

    const acceptOffer = () => {
        new ContractsService()
            .acceptOffer(id)
            .then(() => {
                handleOpenInfoPopup();
                updateDataLayer({
                    'eventCategory': 'booking',
                    'eventAction': 'accept',
                    'eventLabel': '7  step',
                    'event': 'autoEvent',
                });
                submitWithRefresh ? submitWithRefresh() : refresh();
            });
    };

    const openDetails = () => {
        !isDeclined && !isNotClickable && openPopup(OFFER_DETAILS_POPUP, { id, refresh, handleOpenInfoPopup, history })
    }

    const openDeclineOffer = (e: Event) => {
        openPopup(CONFIRM_POPUP_FULL_SCREEN,
            {
                title: ' ',
                text: bookings.are_you_sure_you_want_to_cancel_this_invitation,
                cancel: common.No,
                action: common.Yes,
                color: 'danger',
                callback: declineOffer,
            });
    }

    const handleAccept = () => {
        openPopup(CONFIRM_POPUP_FULL_SCREEN,
            {
                title: ' ',
                text: bookings.are_you_sure_you_want_to_accept_this_invitation,
                action: common.accept,
                color: 'secondary',
                callback: acceptOffer,
            });
    }

    useEffect(() => {
        return () => {
            closePopup(OFFER_DETAILS_POPUP.key)
            storeService.set(KEY_IS_ACTIVE_SEARCH, true);
        }
    }, []);

    return (
        <Card large={true}
            withoutShadow={withoutShadow}
            className={joinClassNames(
                isFromPopup ? styles.popupCard : styles.card,
                isDisabled && styles.disabledOpacity,
                isDeclined && styles.noPadding,
            )}
        >
            {(!isFromPopup || (isFromPopup && !isMobile)) ?
                <section className={styles.wrapper}>
                    <section className={joinClassNames(styles.section, styles.mobileHeader)}>
                        <section className={joinClassNames(styles.section, styles.mobileHeader, styles.maxNameWidth)}>
                            <section className={styles.mobileLogo} onClick={redirectToProfile}>
                                <CompanyLogo
                                    className={styles.logo}
                                    image={image}
                                    isEditable={false}
                                />
                                {isMobile && !!distance && <div className={styles.distanceTag}>{formatDistance(distance)}</div>}
                            </section>
                            {!isMobile ?
                                <label className={styles.companyName} onClick={redirectToProfile}>{companyName}</label>
                                :
                                <section className={styles.mobileRating}>
                                    <Rating rate={Number(rating)} onClick={redirectToReviews} />
                                    <label className={styles.companyName} onClick={redirectToProfile}>{companyName}</label>
                                </section>
                            }
                            {!isMobile && !!distance && <div className={styles.distanceTag}>{formatDistance(distance)}</div>}
                        </section>
                        <div className={styles.price}>{formatPrice(rate, priceFormat)}</div>
                    </section>
                    <section className={joinClassNames(styles.wrapper, styles.paddingLeft)}>
                        {isFromPopup && <label className={joinClassNames(styles.branchName, styles.popupOffset)}>{branchName}</label>}
                        {!isMobile &&
                            <section className={joinClassNames(styles.section, styles.ratingRow, isFromPopup && styles.popupOffset)}>
                                <Rating rate={Number(rating)} onClick={redirectToReviews} />
                                {isFromPopup && <div className={styles.received}>
                                    <span>{bookings.offer_received}:</span>
                                    {moment(createdAt).local().format(FULL_DATE_WITH_TIME)}
                                </div>}
                            </section>
                        }
                        <section className={styles.section}>
                            <section className={joinClassNames(
                                styles.section,
                                isFromPopup && styles.popupOffset,
                                isFromPopup ? styles.popupDate : styles.timeRow,
                            )}>
                                {timeRow}
                            </section>
                            {!isDeclined && !isMobile &&
                                <ActionRow
                                    labelText={find_service.more_details}
                                    buttonText={common.accept}
                                    onClick={handleAccept}
                                    openDetails={openDetails}
                                    sectionStyles={joinClassNames(styles.section, styles.actionRow)}
                                    isDisabled={isDisabled}
                                    isFromPopup={isFromPopup}
                                    declineOffer={openDeclineOffer}
                                    declineText={bookings.decline}
                                    waitingText={bookings.waiting_for_confirmation_progress}
                                    isWaiting={isRequested}
                                    buttonClassName={styles.acceptButton}
                                />
                            }
                        </section>
                        {!isDeclined && isMobile &&
                            <ActionRow
                                labelText={find_service.more_details}
                                buttonText={common.accept}
                                onClick={handleAccept}
                                openDetails={openDetails}
                                sectionStyles={styles.section}
                                isDisabled={isDisabled}
                                isFromPopup={isFromPopup}
                                declineOffer={openDeclineOffer}
                                declineText={bookings.decline}
                                waitingText={bookings.waiting_for_confirmation_progress}
                                isWaiting={isRequested}
                                buttonClassName={styles.acceptButton}
                            />
                        }
                    </section>
                </section>
                :
                <section className={styles.mobileWrapper}>
                    <section className={styles.mobileHeading}>
                        <CompanyLogo
                            className={styles.logo}
                            image={image}
                            isEditable={false}
                        />
                        {!!distance && <div className={styles.distanceTagPopup}>{formatDistance(distance)}</div>}
                    </section>
                    <section className={joinClassNames(styles.mobileSection, styles.mobileRating)}>
                        <Rating rate={Number(rating)} onClick={redirectToReviews} />
                        <div className={styles.price}>{formatPrice(rate, priceFormat)}</div>
                    </section>
                    <label className={styles.companyNamePopup} onClick={redirectToProfile}>{companyName}</label>
                    <label className={joinClassNames(styles.branchNamePopup)}>{branchName}</label>
                    <section className={styles.dateTimePopup}>{timeRow}</section>
                    <section className={styles.receivedOfferPopup}>
                        <span>{bookings.offer_received}:</span>
                        {moment(createdAt).local().format(FULL_DATE_WITH_TIME)}
                    </section>
                    <ActionRow
                        labelText={find_service.more_details}
                        buttonText={common.accept}
                        onClick={handleAccept}
                        openDetails={openDetails}
                        isDisabled={isDisabled}
                        isFromPopup={isFromPopup}
                        declineOffer={openDeclineOffer}
                        declineText={bookings.decline}
                        waitingText={bookings.waiting_for_confirmation_progress}
                        isWaiting={isRequested}
                        sectionStyles={styles.sectionPopup}
                        buttonClassName={styles.acceptButtonPopup}
                    />
                </section>
            }
            {isDeclined && <HighlightedMessage
                className={styles.HighlightedMessage}
                icon="warning"
                text={declinedByService ? bookings.service_center_declined_your_request : bookings.CancelledByServiceOwner}
                link={declinedByService && bookings.view_the_reason}
                action={(e: Event) => {
                    e.stopPropagation();
                    openPopup(CONFIRM_POPUP_FULL_SCREEN,
                        {
                            title: bookings.reason_of_cancellation,
                            text: declineReason,
                            withoutCancel: true,
                            action: common.close,
                            callback: () => {
                            },
                        });
                }} />
            }
            <NotVerifiedEmailPopup
                isOpen={isOpen}
                toggle={() => setIsOpen((prevState: boolean) => !prevState)}
                isFromOffers
            />
        </Card>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openPopup: openPopup(dispatch),
    closePopup: closePopup(dispatch),
});

const mapStateToProps = (state: any) => {
    const { bookings, i18n } = state;
    return { bookings, i18n };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardOffer);
