import { AnyAction } from 'redux';

import {
    GET_ALL_SERVICES,
    GET_COMPANY_TYPES,
    GET_MY_SERVICES,

    GET_ON_MAP,
    GET_ON_MAP_FAIL,
    GET_ON_MAP_SUCCESS,

    NEW_REQUEST,
    GET_REQUESTS,
    GET_REQUESTS_FAIL,
    GET_REQUESTS_REQUEST,
    GET_REQUESTS_REQUEST_FAIL,
    GET_REQUESTS_REQUEST_SUCCESS,
    GET_REQUESTS_SUCCESS,

    GET_SERVICE_CENTER_INFO_BY_ID_FAIL,
    GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS,
    GET_SERVICE_CENTERS_SUCCESS,

    GET_SERVICE_REVIEWS_BY_ID_FAIL,
    GET_SERVICE_REVIEWS_BY_ID_SUCCESS,

    GET_SERVICES_BY_COMPANY_TYPES,

    GET_STATISTICS,
    GET_STATISTICS_FAIL,
    GET_STATISTICS_SUCCESS,

    SERVICE_CENTER_INFO_FAIL,
    SERVICE_CENTER_INFO_REQUEST,
    SERVICE_CENTER_INFO_SUCCESS,

    BADGE_SC_GET_REQUESTS_API,
    BADGES_SC_WS,
    REQUEST_CANCELLED,
    NEW_CONTRACT_FOR_CONFIRMATION,
    CONTRACT_CANCELLED,
    CONTRACT_STATUS_CHANGED,

    BADGE_REVIEWS_API_SUCCESS,
    BADGE_REVIEWS_WS,
    NO_OFFER_REQUEST_CANCELLED_SC,
    ACTIVATED_WS
} from '../constants';
import { PAGINATION_LIMIT } from "../../const/pagination";
import { CONTRACT_STATUS } from '../../const/contract-status';
import { KEY_USER_INFO } from '../../const/local-storage';
import StorageService from '../../services/StorageService';

export const companyTypes = (state = { collection: [] }, action: AnyAction) => {

    switch (action.type) {
        case GET_COMPANY_TYPES:
            return { ...state, collection: action.payload };
        default:
            return state;
    }
};

const myServiceCenterInfoData = {
    wait: false,
    branchName: '',
    companyName: '',
    descriptionTranslations: {},
    id: null,
    address: null,
    weekSchedule: null,
    contacts: [],
    branches: [],
    businessTypes: [],
    rating: 0,
    isActivated: new StorageService().get(KEY_USER_INFO)?.isActivated
};

export const myServiceCenterInfo = (state = myServiceCenterInfoData, action: AnyAction) => {

    switch (action.type) {
        case SERVICE_CENTER_INFO_REQUEST:
            return { ...state, wait: true };

        case SERVICE_CENTER_INFO_SUCCESS:
            return { ...state, wait: false, ...action.payload };

        case SERVICE_CENTER_INFO_FAIL:
            return { ...state, wait: false };

        case ACTIVATED_WS:
            return { ...state, isActivated : action?.payload.isActivated  };

        default:
            return state;
    }
};

export const service = (state = myServiceCenterInfoData, action: AnyAction) => {

    switch (action.type) {
        case GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS:
            return { ...state, wait: false, ...action.payload };

        case GET_SERVICE_CENTER_INFO_BY_ID_FAIL:
            return { ...state, wait: false };

        default:
            return state;
    }
};

export const services = (state = { collection: [], pagination: {}, wait: false }, action: AnyAction) => {

    switch (action.type) {
        case GET_SERVICES_BY_COMPANY_TYPES:
            return { ...state, collection: action.payload };

        case SERVICE_CENTER_INFO_REQUEST:
            return { ...state, wait: false };

        case GET_SERVICE_CENTERS_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case SERVICE_CENTER_INFO_FAIL:
            return { ...state, wait: false };

        default:
            return state;
    }
};

export const myServices = (state = { collection: [] }, action: AnyAction) => {

    switch (action.type) {
        case GET_MY_SERVICES:
            return { ...state, collection: action.payload };

        case GET_ALL_SERVICES:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        default:
            return state;
    }
};

const defaultRequestsState = {
    collection: [],
    pagination: {},
    wait: false,
    type: 'request'
};

export const requests = (state = defaultRequestsState, action: AnyAction) => {

    switch (action.type) {
        case GET_REQUESTS:
            return { ...state, collection: [], pagination: {}, type: action.payload.type };

        case GET_REQUESTS_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, type: action.payload.type, pagination: action.payload.pagination };

        case GET_REQUESTS_FAIL:
            return { ...state, collection: [], pagination: {} };

        case GET_REQUESTS_REQUEST:
            return { ...state, collection: [], pagination: {}, wait: true };

        case GET_REQUESTS_REQUEST_SUCCESS:
            return {
                ...state,
                wait: false,
                collection: action.payload.data,
                pagination: action.payload.pagination,
                type: defaultRequestsState.type
            };

        case GET_REQUESTS_REQUEST_FAIL:
            return { ...state, collection: [], pagination: {} };

        case NEW_REQUEST:
            return state.type === defaultRequestsState.type
                ? {
                    ...state,
                    collection: [action.payload].concat(state.collection.slice(0, PAGINATION_LIMIT - 2))
                }
                : state;

        case NEW_CONTRACT_FOR_CONFIRMATION:
            return state.type === defaultRequestsState.type
                ? { ...state, collection: state.collection.filter((request: any) => request.id !== action.payload.contractId) }
                : moveContractBetweenColumns(CONTRACT_STATUS.WaitingForConfirmation)(state, action);

        case CONTRACT_STATUS_CHANGED:
            return {
                ...state,
                collection: state.collection.map((booking: any) => booking.id === action.payload.id ? action.payload : booking)
            };

        case CONTRACT_CANCELLED:
            return moveContractBetweenColumns(CONTRACT_STATUS.Cancelled)(state, action);

        case NO_OFFER_REQUEST_CANCELLED_SC:
            return state.type === defaultRequestsState.type
                ? { ...state, collection: state.collection.filter((request: any) => request.id !== action.payload) }
                : { ...state };

        case REQUEST_CANCELLED:
            return state.type === CONTRACT_STATUS.Cancelled
                ? { ...state, collection: [action.payload].concat(state.collection.slice(0, PAGINATION_LIMIT - 2)) }
                : { ...state, collection: state.collection.filter((request: any) => request.id !== action.payload.contractId) };

        default:
            return state;
    }
};

export const moveContractBetweenColumns = (type?: string) => (state = defaultRequestsState, action: AnyAction) => {
    return state.type === type
        ? { ...state, collection: [action.payload].concat(state.collection.slice(0, PAGINATION_LIMIT - 2)) }
        : {
            ...state,
            collection: state.collection.filter((contract: any) => contract.id !== action.payload.id)
        };
};

export const statistics = (state = {}, action: AnyAction) => {

    switch (action.type) {
        case GET_STATISTICS:
            return { ...state };

        case GET_STATISTICS_SUCCESS:
            return { ...state, ...action.payload.data };

        case GET_STATISTICS_FAIL:
            return { ...state };

        default:
            return state;
    }
};

export const servicesOnMap = (state = { collection: [] }, action: AnyAction) => {

    switch (action.type) {
        case GET_ON_MAP:
            return { ...state };

        case GET_ON_MAP_SUCCESS:
            return { ...state, collection: action.payload.data };

        case GET_ON_MAP_FAIL:
            return { ...state };

        default:
            return state;
    }
};

export const reviews = (state = { collection: [], pagination: {} }, action: AnyAction) => {
    switch (action.type) {
        case GET_SERVICE_REVIEWS_BY_ID_SUCCESS:
            const { data, pagination } = action.payload;
            return { ...state, collection: data, pagination };

        case GET_SERVICE_REVIEWS_BY_ID_FAIL:
            return { ...state };

        default:
            return state;
    }
};

const BADGES = {
    newRequests: '',
    waitingForConfirmation: '',
    confirmed: '',
    cancelled: '',
};

export const serviceOwnerBadges = (state = BADGES, action: AnyAction) => {
    switch (action.type) {
        case BADGE_SC_GET_REQUESTS_API:
            return { ...state, ...action.payload.data }

        case BADGES_SC_WS:
            return { ...state, ...action.payload }

        default:
            return { ...state }
    }
};

const Reviews = {
    count: 0
}

export const serviceOwnerReviewsBadges = (state = Reviews, action: AnyAction) => {

    switch (action.type) {
        case BADGE_REVIEWS_API_SUCCESS:
            return { ...action.payload };

        case BADGE_REVIEWS_WS:
            return { count: action.payload }

        default:
            return { ...state }
    }
}
