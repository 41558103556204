import React from "react";
import {joinClassNames} from "../../helpers/className";
import styles from './index.module.scss'

interface Props {
    className: string;
    children?: any;
    is?: string
}

const ErrorMessage = ({className, children}: Props) => {
    return (
        <div className={joinClassNames(styles.message, className)}>
            {
                children
            }
        </div>
    );
};

ErrorMessage.defaultProps = {
    className: '',
};

export default ErrorMessage;
