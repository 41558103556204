import * as React from "react";
import { connect } from "react-redux";

import { closePopup } from "../../redux/actions";
import ContractsService from "../../services/ContractsService";
import BasePopup from "../BasePopup";
import Icon from "../../components/Icon";
import CardServiceWithDistance from "../../components/CardOffer";
import DottedLine from "../../components/DottedLine";
import CardTitle from "../../components/CardTitle";
import Pin from "../../components/Pin";
import Map from "../../components/Map";
import { IconType } from "../../types/icon";
import { joinClassNames } from "../../helpers/className";
import { formatPhone } from "../../pipes/phone";
import { DEFAULT_LOCATION } from "../LocationPopup";
import Caption from "../../components/Caption";

import styles from './index.module.scss'
import { capitalizeFirstLetter } from "../MuiDatePicker/components/Datepicker/utils";
import StoreService from "../../services/StorageService";

import {
    isIOS,
    isSafari,
  } from "react-device-detect";

const icons = [
    'carServiceIcon', 'checkUpIcon', 'carWashIcon', 'washDetailing', 'tireWorksIcon', 'towTruckIcon', 'tuningIcon',
]

class OfferDetailsPopup extends BasePopup {

    refresh: () => any;
    handleOpenInfoPopup: () => void;
    i18n: any;
    history: any;

    constructor(props: any) {
        super(props);

        let { initialData } = props;

        this.refresh = initialData.refresh;
        this.handleOpenInfoPopup = initialData.handleOpenInfoPopup;
        this.history = initialData.history;
        this.i18n = props.i18n;
        this.title = ''
        this.state = {
            service: null
        };

        new ContractsService()
            .getOfferInfoById(initialData.id)
            .then((data: any) => {
                this.setState({ service: data });
            });
    }

    public close = () => {
        let props: any = this.props;

        props.closePopup(props.uuid);
    };

    renderContent() {
        let { service } = this.state as any;
        const address = service && service.address;
        let rootService = service && service.service;
        const currentLocale = new StoreService().getCurrentLangKey();
        const serviceNameTranslated = (!!rootService && rootService.nameTranslations[`name${ capitalizeFirstLetter(currentLocale) }`]) || "";
        const serviceDescTranslated = (!!rootService && rootService.descriptionTranslations[`description${ capitalizeFirstLetter(currentLocale) }`]) || "";
        const icon = (service && service.service && service.service.businessTypeId)
        const coords = address && `${address.geoLocation.lat},${address?.geoLocation.lng}`
        const linkToMaps = (isSafari || isIOS) ? `http://maps.apple.com/?daddr=${coords}` : `geo:0,0?q=${coords}`;
        return (
            <div className={ styles.base }>
                { service && <div className={ styles.header }>
                    <div className={joinClassNames(styles.container, styles.offsetTop) }>
                        <CardServiceWithDistance
                            refresh={ () => {
                                this.refresh();
                                this.close();
                            } }
                            submitWithRefresh={() => {
                                this.refresh();
                                this.close();
                                this.handleOpenInfoPopup();
                            }}
                            withoutShadow={ true }
                            isNotClickable={ true }
                            withoutDescription={ true }
                            service={ service }
                            isFromPopup
                            history={this.history}
                        />
                        <div
                            className={ styles.close }
                            onClick={ this.close }>
                            <Icon icon="closeBlack"/> {this.i18n.common.close}
                        </div>
                    </div>
                </div> }

                <div className={ styles.scrollable }>

                    { rootService && rootService.defaultDescription &&
                    <div className={ joinClassNames(styles.container, styles.item) }>
                        <CardTitle icon={ icons[icon] as IconType }
                                   title={ serviceNameTranslated }
                                   className={ styles.CaptionTitle }
                                   color={ 'secondary' }
                        >
                        </CardTitle>
                        { rootService.defaultDescription &&
                        <div className={ joinClassNames(styles.contactRow, styles.mainText) }>
                            { serviceDescTranslated || rootService.defaultDescription }
                        </div> }
                    </div>
                    }

                    { address && (
                        <div className={ joinClassNames(styles.container, styles.item) }>
                            { <CardTitle icon={ 'location' }
                                         title={ this.i18n.popups.location }
                                         color='secondary'
                                         className={ styles.CaptionTitle }>
                                <Caption
                                    size={ "14" }
                                    className={ styles.Caption }>
                                    {address && <a href={`${linkToMaps}`}>{ address.title }</a>}
                                </Caption>
                            </CardTitle> }
                            <Map center={ address.geoLocation }
                                 height={ '400px' }
                                 defaultCenter={ DEFAULT_LOCATION }
                                 className={ styles.map }>
                                <Pin { ...address.geoLocation } />
                            </Map>
                        </div>
                    ) }

                    { service && service.contacts[0] &&
                    <div className={ joinClassNames(styles.container, styles.item, styles.contacts) }>
                        <CardTitle icon={ 'user' } title={ this.i18n.common.contacts } color='secondary'
                                   className={ styles.CaptionTitle }/>

                        { service.contacts.map(({ phone, name }: any, index: number) => (
                            <DottedLine key={ index } className={ styles.contactRow }>
                                <span>{ name }</span>
                                <span><a href={`tel:${phone}`}>{formatPhone(phone)}</a></span>
                            </DottedLine>
                        )) }
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch)
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsPopup);
