import { FormConfig } from "../types/formConfig";
import store from "../redux/store";

export default function formInternalization(formConfig: FormConfig) {
    Object.keys(formConfig).forEach((field: any) => {
        const { i18n = {} } = store.getState();
        const currentField = formConfig[field];

        if (i18n.forms) {
            currentField.label = i18n.forms[field].label;
            currentField.placeholder = i18n.forms[field].placeholder;
        }
    });
}
