import {Route as ReactRoute, matchPath } from 'react-router-dom'
import React from 'react'

export default class Route extends React.Component {
    static cache = [];

    static match(url, {path, exact = false, strict = false}) {

        return matchPath(url, {
            path,
            exact,
            strict
        });
    }

    static preloadRoute (url) {

        Route.cache.forEach(({path, component, ...config}) => {
            if (Route.match(url, {path}) && component && component.preload) {
                component.preload();
                config.loaded = true;
            }
        });
    }

    static enablePreload(schema) {
        // TODO check duplicate name
        Object.keys(schema).forEach(key => {
            let route = schema[key];

            Route.cache.push({
                _key: key,
                loaded: false,
                ...route
            });

        });
    }

    render() {
        return (<ReactRoute {...this.props} />);
    }
}
