import React, { useEffect } from "react";
import Loadable from 'react-loadable';

import store from "../../redux/store";
import { HIDE_LOADER, SHOW_LOADER } from "../../redux/constants";
import { AnyAction } from "redux";

const NavigationLoader = () => {
    useEffect(() => {
        store.dispatch({ type: SHOW_LOADER } as AnyAction);
        return () => {
            store.dispatch({ type: HIDE_LOADER } as AnyAction);
        }
    }, []);
    return <></>
};

const ChunkLoader = (loader: any) => {
    return Loadable({
        loader,
        loading: NavigationLoader,
        delay: 300
    });
};

export default ChunkLoader;
