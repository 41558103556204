import React from "react";
import styles from './index.module.scss'
import {joinClassNames} from "../../helpers/className";

interface Props {
    className: string;
}

const Divider = ({className}: Props) => {
    return (
        <div className={joinClassNames(styles.Divider, 'Divider', className)} />
    );
};

Divider.defaultProps = {
    className: ''
};

export default Divider;
