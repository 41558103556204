export const ROLE = {
    BUSINESS: 'ServiceOwner',
    USER: 'CarOwner'
};

export const LAPTOP_BP = '(max-width:1024px)';
export const MORE_THEN_LAPTOP_BP = '(min-width:1025px)';
export const TABLET_BP = '(max-width:1024px)';
export const MOBILE_BP = '(max-width:576px)';
export const LARGE_TABLET = '(max-width:950px)';
export const SMALL_LAPTOP_BP = '(max-width:1200px)';
