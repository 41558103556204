export const CALENDAR_THEME = {
    palette: {
        primary: {
            light: '#4D4D4F',
            main: '#4D4D4F',
            dark: '#4D4D4F',
            contrastText: '#4D4D4F',
        },
        marked: {
            main: '#FAEDB3'
        }
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
} as any;
