import React from 'react'
import {IPropsClassName} from "../../types/props";
import Radio from "../Radio";
import Label from "../Label";
import Flex from "../Flex";
import styles from './index.module.scss'
import {Field} from "redux-form";

interface IOption {
    label: string;
    value: any
}

interface IProps extends IPropsClassName {
    options: IOption[];
    label?: string;
    name: string;
    isValueInt?: boolean
}

const renderRadio = ({input, label }: any) => {
    return (
        <Radio
            label={label}
            key={label}
            {...input}
        />
    )
};

const toInt = (num: string|number) => +num;

const RadioGroupControl = ({options, name, label, className, isValueInt}: IProps) => (
    <div className={className}>
        <Label>{label}</Label>
        <Flex className={styles.Flex}>
            {options.map(({label, value}: IOption) =>
                <Field name={name}
                       key={value}
                       label={label}
                       format={isValueInt ? toInt : (val: any) => val}
                       component={renderRadio}
                       type="radio"
                       value={value} />
            )}
        </Flex>
    </div>
);

export default RadioGroupControl