export const KEY_USER_ID = 'userId';
export const KEY_SESSION = 'session';
export const KEY_ROLE = 'role';
export const KEY_SIGN_UP_STEPS = 'sign-up-steps';
export const KEY_NEW_BOOKING_STEPS = 'key-new-booking-steps';
export const KEY_LANGUAGE = 'language';
export const IS_COMPANY = 'is_company';
export const PRIVACY = 'privacy';
export const COOKIES_ACCEPTED = 'cookies-accepted';
export const KEY_BANNER_CLOSED = 'apple-pwa-banner-closed';
export const KEY_VERSION = 'bookmap-app-version'
export const KEY_USER_INFO = 'userInfo';
export const USERS_MOBILE = 'usersImobile';
export const KEY_REQUEST_ID = "requestId";
export const KEY_EMAIL_LINK = "restoreEmail";
export const KEY_POTENCIAL_COUNT = "potencialCount";
export const KEY_SELECTED_PERSONAL_INFO = 'selectedPersonalInfo';
export const KEY_SAVE_TO_VEHICLES = 'saveToMyVehicles';
export const KEY_REGISTRATION_VALUES = "registrationValues";
export const KEY_RESTORATION_EMAIL = "restorationEmail";
export const KEY_IS_ACTIVE_SEARCH = 'isActiveSearching';
export const KEY_IS_REGISTERED = "isRegistered";