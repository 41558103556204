import qs from 'qs';

import Http from "./HttpService";
import { PAGINATION_LIMIT, MAX_LIMIT } from "../const/pagination";

class ServiceCentersService extends Http {

    public getServiceCenters(model: any) {
        return this.get('/service-centers', {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        });
    }

    public getServiceCenterById(id: number) {
        return this.get(`/service-centers/${id}`);
    }

    public getTypeOfCompanies() {
        return this.get('/service-centers/business-types');
    }

    public getMyServiceCenterInfo() {
        return this.get('/service-owners/me/service-center');
    }

    public updateMyServiceCenterInfo(model: any) {
        return this.put('/service-owners/me/service-center', model)
    }

    public getServices({ types }: any) {
        return this.get(`service-centers/services-info?BusinessTypes=${types}&Limit=25`);
    }

    public getAllServices() {
        return this.get(`service-centers/business-types`);
    }

    public addService(models: any[]) {
        // @ts-ignore
        return this.put('/service-owners/me/service-center/services', models)
    }

    public getMyServices(model: any) {
        return this.get(`/service-centers/services`, {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }

    public changeContacts(collection: any[]) {
        return this.put('/service-owners/me/service-center/contacts', collection)
    }

    public sendBranchInvite(model: any) {
        return this.post('/service-owners/me/service-center/invites', model)
    }

    public changeAddress(model: any) {
        return this.put('/service-owners/me/service-center/address', model)
    }

    public changeSchedule(model: any) {
        return this.put('/service-owners/me/service-center/schedule', model)
    }

    public checkBranch(v: string) {
        return this.get(`/service-centers/branch?Name=${v}`)
    }

    public checkCompany(v: string) {
        return this.get(`/service-centers/company?Name=${v}`)
    }

    public getContracts(type: string, offset: number, businessTypes: number[], search: string) {
        let options = '';

        if (businessTypes.length > 0) {
            businessTypes.forEach((id: number) => {
                options += `&BusinessTypes=${id}`;
            });
        }

        return this.get(`/service-owners/me/service-center/contracts?Option=${type}&Offset=${offset}&Limit=${PAGINATION_LIMIT}&Search=${search}${options}`);
    }

    public getRequests(offset: number, businessTypes: number[], search: string) {
        let options = '';

        if (businessTypes.length > 0) {
            businessTypes.forEach((id: number) => {
                options += `&BusinessTypes=${id}`;
            });
        }

        return this.get(`/service-owners/me/service-center/requests?Offset=${offset}&Limit=${PAGINATION_LIMIT}&Search=${search}${options}`);
    }

    public getContacts(id: number) {
        return this.get(`/service-centers/${id}/contacts`).then(({ data }: any) => data);
    }

    public getStatistics(scope: any) {
        return this.get(`/service-owners/me/service-center/statistics?scope=${scope}`)
    }

    public getServiceCentersOnMap(model: any) {
        return this.get('/service-centers/map', {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }

    public getServiceReviewsById(id: number, offset = 0) {
        return this.get(`/service-centers/${id}/reviews?Offset=${offset}&Limit=${PAGINATION_LIMIT}`);
    }

    public getBadgeRequests() {
        return this.get('/service-owners/me/service-center/badge');
    }

    public getReviewsBadge() {
        return this.get('/service-owners/me/service-center/review-badge')
    }

    public getMyBrands() {
        return this.get('/service-owners/me/service-center/car-brand')
    }

    public getBrandsById(id: number) {
        return this.get(`/service-centers/${id}/car-brand`);
    }

    public addNewBrand(brand: { id: number, name: string }) {
        return this.post('/service-owners/me/service-center/car-brand', {
            ...brand
        });
    }

    public deleteCarBrand(id: number) {
        return this.delete(`/service-owners/me/service-center/car-brand/${id}`)
    }

    public async searchService(text: string) {
        return await this.get('/service-centers/services-info', { params: { Search: text, limit: MAX_LIMIT } })
    }
}

export default ServiceCentersService;
