import React, { useState, useEffect } from "react";
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core";
import { connect } from "react-redux";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import { COLOR } from "../../const/colors";
import { CALENDAR_THEME } from "./theme";
import Icon from "../Icon";
import ToasterService from "../../services/ToasterService";
import { joinClassNames } from "../../helpers/className";
import { DAY_PATTERN } from "../../const/date-time-format";
import StoreService from "../../services/StorageService";
import { KEY_LANGUAGE } from "../../const/local-storage";
import { DEFAULT_LANGUAGE } from "../../const/languages";

// @ts-ignore
import MultipleDatesPicker from "../../popups/MuiDatePicker";

import styles from './index.module.scss';

const momentFormatDate = (date: any, locale: string) => moment(date).locale(locale).format(DAY_PATTERN);

const MAX_DATE = moment().add(5, 'M').toDate();

const getISOStartDate = (date: any) => (moment(date).startOf('day').toISOString(false));

const getISOEndDate = (date: any) => {
    const endOfDay = moment(date).endOf('day').toISOString(false)

    if (endOfDay.includes('999Z'))
        return moment(date).endOf('day').add(1, 'ms').toISOString(false);
    return endOfDay;
};

const formatDatesToISOString = (dates: any[]) => (
    dates.map(date => ({
        startDate: getISOStartDate(date),
        endDate: getISOEndDate(date)
    }))
)

interface Props {
    color?: 'primary' | 'secondary';
    dates: any[];
    onChange: (val: any) => void;
    maxCount: number;
    className?: string;
    minDate?: any;
    onCancel?: () => void;
    isOfferTime?: boolean;
    offerTimeSubmit?: (data: any) => void;
    suggestedDates?: any[];
    suggestedEndTime?: any;
    i18n?: any;
    invalid?: boolean;
}

interface Dates {
    startDate: string, 
    endDate: string,
}

const MultiDatePicker = ({
    color,
    dates,
    onChange,
    maxCount,
    minDate,
    suggestedEndTime,
    suggestedDates,
    className,
    onCancel,
    isOfferTime,
    offerTimeSubmit,
    i18n,
    invalid
}: Props) => {

    const colorClassName = color === COLOR.SECONDARY ? styles.secondary : styles.primary;
    const [open, setOpen] = useState(false);
    const [translatedDates, setTranslatedDates] = useState<any[]>([]);
    const chipClasses = joinClassNames(styles.Chips, colorClassName);
    const wrapperClasses = joinClassNames(styles.DatePicker, className);

    useEffect(() => {
        isOfferTime && setOpen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const locale = new StoreService().get(KEY_LANGUAGE, DEFAULT_LANGUAGE).origin;
        const translated = dates.map(date => {
            let value;
            (typeof (date) === 'string')
                ? value = momentFormatDate(date, locale)
                : value = momentFormatDate(date.startDate, locale);
            return value;
        });
        setTranslatedDates(translated);
    }, [i18n, dates]);

    if (color !== COLOR.SECONDARY) {
        for (let key of Object.keys(CALENDAR_THEME.palette.primary)) {
            CALENDAR_THEME.palette.primary[key] = '#FFDC37';
        }
    }

    let [maxDate, setMaxDate] = useState(MAX_DATE);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [minDateInner, setMinDateInner] = useState(moment().toDate());

    const calendarTheme = createMuiTheme(CALENDAR_THEME);

    return (
        <div onClick={() => setOpen(true)}>
            <div className={wrapperClasses}>
                {translatedDates && translatedDates.length === 0 && <div className={styles.placeHolder}>{i18n.common.date}</div>}
                {translatedDates && translatedDates.map((val, index) =>
                    <div key={index} className={chipClasses}>
                        {val}
                    </div>)
                }
                <Icon className={styles.DatePickerIcon} icon="calendar" />
                <MuiThemeProvider theme={calendarTheme}>
                    <div onClick={(e: any) => { e.stopPropagation() }} >
                        <MultipleDatesPicker
                            invalid={invalid}
                            selectedDates={dates.map((date: Dates) => new Date(date.startDate))}
                            open={open}
                            minDate={minDateInner}
                            maxDate={maxDate}
                            isOfferTime={isOfferTime}
                            maxCount={maxCount}
                            suggestedDates={suggestedDates}
                            suggestedEndTime={suggestedEndTime}
                            onChange={(e: any) => {
                                setMaxDate(moment(e[0]).add(1, 'M').toDate());
                                if ((e.length > maxCount) && maxCount !== 1) {
                                    ToasterService.error(`Max count of dates is ${maxCount}`);
                                    return;
                                }
                                if (e.length === 0) {
                                    setMaxDate(MAX_DATE);
                                    return;
                                }
                            }}
                            onCancel={() => { !!onCancel ? onCancel() : setOpen(false); }}
                            onSubmit={(dates: any) => {
                                let currentDates = formatDatesToISOString(dates);

                                if (dates.length > maxCount) {
                                    return;
                                }

                                if (moment(minDate).diff(dates[0], 'days') > 0) {
                                    ToasterService.error(`End time cannot be earlier then start time`);
                                    return;
                                }

                                if (currentDates.length === 0) {
                                    return;
                                }

                                onChange(currentDates);

                                if (!!isOfferTime && !!onCancel && offerTimeSubmit) {
                                    offerTimeSubmit(currentDates);
                                    onCancel();
                                } else {
                                    setOpen(false);
                                }
                            }}
                        />
                    </div>
                </MuiThemeProvider>
            </div>
        </div>
    )
};

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n };
}

export default connect(mapStateToProps, null)(MultiDatePicker);
