import { FormConfig } from '../../types/formConfig';
import { REVIEW_COMMENT_MAX_LENGTH, REVIEW_COMMENT_MIN_LENGTH } from '../../const/validation';
import Validator from '../../validators';

const formConfig: FormConfig = {
    text: {
        name: 'text',
        label: '',
        placeholder: '',
        maxLength: REVIEW_COMMENT_MAX_LENGTH,
        validate: [
            Validator.required(),
            Validator.minLength(REVIEW_COMMENT_MIN_LENGTH),
            Validator.noSpacesOnly(),
        ],
    }
};

export default formConfig;
