import { FormConfig } from "../../types/formConfig";
import Validator from "../../validators";
import { formatTimeSimple } from "../../pipes/time";
import { SIMPLE_TIME_MAX_LENGTH } from "../../const/validation";

let formConfig: FormConfig = {
    etaHours: {
        name: 'etaHours',
        label: 'ETA Hours*',
        placeholder: 'Hours',
        format: formatTimeSimple,
        maxLength: SIMPLE_TIME_MAX_LENGTH,
        validate: [
            Validator.required(),
            // Validator.timeSimple()
        ]
    },
    etaMinutes: {
        name: 'etaMinutes',
        label: 'ETA Minutes*',
        placeholder: 'Minutes',
        format: formatTimeSimple,
        maxLength: SIMPLE_TIME_MAX_LENGTH,
        validate: [
            Validator.required(),
            // Validator.timeSimple()
        ]
    },
};

export default formConfig;