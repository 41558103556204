import React from 'react';
import { Link } from 'react-router-dom';

import { joinClassNames } from '../../../helpers/className';
import Icon from '../../Icon';
import Flex from '../../Flex';

import styles from '../index.module.scss';

const Popup = ({ logo, title, subtitle, className, id }: any) => {
    const classes = joinClassNames(className, styles.popover);
    let Logo = <Icon icon='sorPlaceholder' className={styles.logo} />;
    if (logo)
        Logo = < img src={logo} alt='logo' className={styles.logo} />

    return (
        <Link to={`/user/dashboard/service-details/${id}`} >
            <Flex className={classes}>
                {Logo}
                <div className={styles.text}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.subtitle}>
                        {subtitle}
                    </div>
                </div>
            </Flex>
        </Link >
    )
}

export default Popup;
