import axios from 'axios';
import { cacheAdapter } from "../helpers/axiosCacheAdapter";
import { authorizationRequestInterceptor, handleResponseErrorInterceptor, mapResponseInterceptor } from "./interseptors";

interface HttpParams {
    baseURL: string
}

class Http {

    static api = {
        common: process.env.REACT_APP_API
    };

    static versions = {
        v1: 'v1'
    };

    static createInstance(params: any) {
        return axios.create(params);
    }

    private readonly instance: any;

    constructor(config?: any) {
        let { baseURL = `${Http.api.common}${Http.versions.v1}` }: HttpParams = config || {};

        this.instance = Http.createInstance({
            baseURL,
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        this.useRequestInterceptors(authorizationRequestInterceptor);
        this.useResponseInterceptors(mapResponseInterceptor, handleResponseErrorInterceptor);
    }

    useRequestInterceptors(...params: any[]) {
        this.instance.interceptors.request.use(...params);
    }

    useResponseInterceptors(...params: any[]) {
        this.instance.interceptors.response.use(...params);
    }

    get(...params: any[]) {
        return this.instance.get.apply(this.instance, params);
    }

    patch(...params: any[]) {
        return this.instance.patch.apply(this.instance, params);
    }

    put(...params: any[]) {
        return this.instance.put.apply(this.instance, params);
    }

    post(...params: any[]) {
        return this.instance.post.apply(this.instance, params);
    }

    delete(...params: any[]) {
        return this.instance.delete.apply(this.instance, params);
    }
}


export const CachedApiInstance = Http.createInstance({
    adapter: cacheAdapter.adapter,
    baseURL: `${Http.api.common}${Http.versions.v1}`
});


export default Http;
