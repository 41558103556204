import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import BasePopup from '../BasePopup';
import { closePopup } from '../../redux/actions';
import ContractsService from '../../services/ContractsService';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import Flex from '../../components/Flex';
import Button from '../../components/Button';
import { default as FormControl, FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import Dropdown from '../../components/Dropdown';
import ErrorMessage from '../../components/ErrorMessage';
import Label from '../../components/Label';
import { joinClassNames } from '../../helpers/className';
import formInternalization from '../../utils/form-internalization';
import { etaHours, etaMinutes } from '../../const/eta-values';
import formConfig from './form-config';

import styles from './index.module.scss';

class ETAPopup extends BasePopup {
    props: any;
    id: number;
    model: any;
    dateStart: any[];
    callback: any;

    constructor(props: any) {
        super(props);
        let { initialData } = props;
        let { id, callback } = initialData;
        this.className = styles.Card;
        this.dateStart = [];
        this.callback = callback;
        this.id = id;
        this.i18n = props.i18n;
        this.withDivider = false;
        this.title = this.i18n.bookings.offer_ETA;
    }

    send(model: any) {
        const { etaHours: { name: hours }, etaMinutes: { name: minutes } } = model;

        const requestModel: any = {
            eta: `${hours}:${minutes}`
        };

        new ContractsService().createOffer(this.id, requestModel).then(
            () => {
                this.close();
                this.callback();
            }
        );
    }

    renderContent() {
        formInternalization(formConfig);

        const { change, $values } = this.props;
        const hoursStyles = joinClassNames(styles.dropdownHours, styles.fullWidth);

        return (
            <>
                <StyledDialogContent>
                    <form className={styles.form}>
                        <Flex className={styles.row}>
                            <FormControl {...formConfig.etaHours} className={hoursStyles}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.label} />
                                <Dropdown
                                    options={etaHours}
                                    onChange={(value) => change('etaHours', value)}
                                    value={$values.etaHours} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <FormControl {...formConfig.etaMinutes} className={styles.fullWidth}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Dropdown
                                    options={etaMinutes}
                                    className={styles.dropdownMinutes}
                                    onChange={(value) => change('etaMinutes', value)}
                                    value={$values.etaMinutes} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>
                        </Flex>
                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Flex className={styles.btnWrap}>
                        <Button
                            small={true}
                            large={false}
                            onClick={this.close}
                            className={styles.btn}
                            variant={'outlined'}>
                            {this.i18n.common.cancel}
                        </Button>

                        <Button
                            small={true}
                            large={false}
                            color={'alter'}
                            type={'submit'}
                            disabled={this.props.invalid}
                            className={styles.btn}
                            onClick={() => this.send($values)}
                            variant={'text'}>
                            {this.i18n.popups.submit}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n };
};

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
        form,
        initialValues: {
            etaHours: '',
            etaMinutes: '',
        },
    })(ETAPopup)
);
