import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: false,
    bodyClassName: 'toastBody'
};

export default class ToasterService {

    static success(message: string) {
        toast.warn(message, { ...options, className: 'toast toastSuccess' });
    }

    static error(message: string) {
        toast.error(message, { ...options, className: 'toast toastError' });
    }

    static warn(message: string) {
        toast.warn(message, { ...options, className: 'toast' });
    }

    static resendVerification(content: any) {
        toast.error(content, {
            ...options,
            autoClose: 8 * 1000,
            className: 'toastResend toastError',
            bodyClassName: 'resendToastBody',
        })
    }

    static showRoleWarn(content: any) {
        toast.error(content, {
            ...options,
            className: 'toastResend toastError',
            bodyClassName: 'resendToastBody',
        })
    }
}
