import { Dispatch } from "redux";
import {
  SEND_VERIFICATION,
  SEND_VERIFICATION_FAIL,
  SEND_VERIFICATION_SUCCESS,
  SEND_VERIFICATION_COOLDOWN,
} from "../constants";

import VerificationService from "../../services/VerificationService";
import ToasterService from "../../services/ToasterService";

export const resendVerification = (dispatch: Dispatch) => (email: string) => {
  dispatch({
    type: SEND_VERIFICATION,
  });

  new VerificationService()
    .sendVerification({email: email})
    .then(({ data }: any) => data)
    .then(({ message }: any) => {
      ToasterService.success(message);
      dispatch({ type: SEND_VERIFICATION_SUCCESS });
      setTimeout(() => dispatch({ type: SEND_VERIFICATION_COOLDOWN }), 60 * 1000);
    })
    .catch((error: Error) => {
      dispatch({ type: SEND_VERIFICATION_FAIL });
    })
};
