/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { reduxForm } from "redux-form";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "../../../../../../components/Button/index";
import Label from "../../../../../../components/Label/index";
import Dropdown from "../../../../../../components/Dropdown/index";
import BasePopup from "../../../../../../popups/BasePopup/index";
import FormControl, { FORM_CONTROL_ELEMENT } from "../../../../../../components/FormControl/index";
import ErrorMessage from "../../../../../../components/ErrorMessage/index";
import { KEY_NEW_BOOKING_STEPS, KEY_LANGUAGE } from "../../../../../../const/local-storage";
import StoreService from "../../../../../../services/StorageService";
import { getAllServices, getSelectedFavorites } from "../../../../../../redux/actions";
import ServiceCentersService from "../../../../../../services/ServiceCentersService";
import { getQueryParams } from "../../../../../../utils/location";
import Flex from "../../../../../../components/Flex";
import TextareaField from "../../../../../../components/Textarea";
import { joinClassNames } from "../../../../../../helpers/className";
import formInternalization from "../../../../../../utils/form-internalization";
import { BusinessTypesTranslationsEnum } from "../../../../../../const/business-type";
import { capitalizeFirstLetter } from "../../../../../../popups/MuiDatePicker/components/Datepicker/utils";
import { DEFAULT_LANGUAGE } from "../../../../../../const/languages";
import Divider from "../../../../../../components/Divider/dividerWithText";
import { ScreensEnum } from "../screens.enum";
import formConfig from "./form-config";
import SearchService from '../../../../../../components/SearchService';

import innerStyles from './index.module.scss';
import styles from '../../index.module.scss';

let isFilled = false;

interface CollectionItem {
    id: number,
    businessTypeId: number,
}
const TypeOfService = (props: any) => {
    const { myServices, getAllServices, i18n, selectedFavorites: { collection: selectedCollection }, isDirect } = props;

    const storage = new StoreService();
    const filledSteps = storage.get(KEY_NEW_BOOKING_STEPS, []);
    const steps = filledSteps[ScreensEnum.TypeOfService];

    const prefilledTypeOfWork = steps?.selectedService?.services.length > 1 ? null : steps?.selectedService?.services[0];

    const [selectedService, setSelectedService] = useState<any>();
    const [selectedServiceName, setSelectedServiceName] = useState<any>();
    const [descriptionTranslation, setDescriptionTranslation] = useState<string>('');
    const [serviceObject, setServiceObject] = useState<CollectionItem | null>(prefilledTypeOfWork);
    
    let { collection } = myServices;

    let { isDirection } = getQueryParams();
    let { serviceCenterId } = getQueryParams();
    let { isMulti } = getQueryParams();
    serviceCenterId && props.change('serviceCenterId', serviceCenterId);
    const { regUser } = getQueryParams();

    useEffect(() => {
        isMulti && props.change('favorites', selectedCollection);

        return () => {
            isFilled = false;
        }
    }, []);

    if (!isDirection && !isMulti) {
        collection = myServices.collection;
    }

    useEffect(() => {
        if (serviceObject?.id) {
            const collectionItem = collection.find((item: CollectionItem) => item.id === serviceObject.businessTypeId)
            props.change('serviceId', serviceObject.id);
            const newServiceObject = {
                ...collectionItem,
                services: [serviceObject]
            }
            props.change('selectedService', newServiceObject);
            setSelectedService(newServiceObject);
            setSelectedServiceName(serviceObject);
        }
    }, [serviceObject]);

    const clearValues = () => {
        props.reset()
        setSelectedServiceName('')
        setDescriptionTranslation('')
        setSelectedService(null);
        setSelectedServiceName(null);
        filledSteps[ScreensEnum.TypeOfService] = null;
        storage.set(KEY_NEW_BOOKING_STEPS, filledSteps)
    }

    useEffect(() => {
        !isDirection && getAllServices();
        const locale = storage.get(KEY_LANGUAGE, DEFAULT_LANGUAGE).shortName;
        const nameToChange = `name${capitalizeFirstLetter(locale)}`;
        const serviceTypes = i18n.car_service_types;

        collection.map((item: any) => {
            item.name = serviceTypes[BusinessTypesTranslationsEnum[item.id]];
        });

        if (selectedService) {
            const serviceTypeKey = BusinessTypesTranslationsEnum[selectedService.id];
            const selectedServiceTranslated = selectedService;

            // change business type name translation
            selectedServiceTranslated.name = serviceTypes[serviceTypeKey];

            // change each service name list translation
            selectedServiceTranslated.services.map((service: any) => {
                service.name = service.nameTranslations && service.nameTranslations[nameToChange];
            });

            setSelectedService(selectedServiceTranslated);
            setDescriptionTranslation('')
        }
    }, [i18n, selectedService]);

    useEffect(() => {
        if (selectedServiceName) {
            const locale = storage.get(KEY_LANGUAGE, DEFAULT_LANGUAGE).shortName;
            const nameToChange = `name${capitalizeFirstLetter(locale)}`;

            const descriptionToChange = `description${capitalizeFirstLetter(locale)}`;
            const translatedSelectedServiceName = selectedServiceName;
            translatedSelectedServiceName.name = selectedServiceName.nameTranslations && selectedServiceName.nameTranslations[nameToChange];

            setSelectedServiceName(translatedSelectedServiceName);
            setDescriptionTranslation(selectedServiceName.descriptionTranslations[descriptionToChange]);
        }
    }, [selectedServiceName, i18n]);

    if (steps && !isFilled && steps.selectedService) {
        isFilled = true;
        props.change('serviceCenterId', steps.serviceCenterId);
        props.change('serviceId', steps.serviceId);
        props.change('selectedService', steps.selectedService);
        props.change('serviceDescription', steps.serviceDescription);
        props.change('comment', steps.comment);

        if (isDirection) {
            setSelectedService(steps.selectedService);
            let serviceName = steps.selectedService.services
                .find((el: any) => el.serviceId === steps.serviceId);

            setSelectedServiceName(serviceName);
        } else if (isMulti) {

        } else {
            setSelectedService(steps.selectedService);
            let serviceName = steps.selectedService.services
                .find((el: any) => el.id === steps.serviceId);
            setSelectedServiceName(serviceName);
        }
    }

    formInternalization(formConfig);

    return (
        <form className={styles.form} onSubmit={props.handleSubmit}>
            {!isDirect &&
                <>
                    <SearchService
                        onSelect={(service: CollectionItem) => setServiceObject(service)}
                        clearValues={clearValues}
                        serviceObject={serviceObject}
                        isDirect={isDirect}
                    />

                    <Divider text={i18n.find_request_page.divider_text} />
                </>
            }
            <Label>{formConfig.serviceType.label}</Label>
            <Dropdown
                className={styles.offsetBottom}
                onChange={(option: any) => {
                    if (!isDirection) {
                        new ServiceCentersService().getServices({ types: [option.id] })
                            .then((val: any) => {
                                option.services = val.data;
                                setSelectedService(option);
                                setSelectedServiceName(undefined);
                            });
                    } else {
                        setSelectedService(option);
                        setSelectedServiceName(undefined);
                    }
                }}
                options={collection}
                value={selectedService} />

            <Label>{formConfig.serviceCenterId.label}</Label>
            <Dropdown
                onChange={(option: any) => {
                    let { serviceId, serviceCenterId, id } = option;

                    props.change('serviceCenterId', serviceCenterId);
                    props.change('serviceId', serviceId || id);
                    props.change('selectedService', selectedService);
                    setSelectedServiceName(option);
                }}
                disabled={!selectedService}
                className={styles.offsetBottom}
                options={selectedService && selectedService.services}
                value={selectedServiceName} />

            <FormControl {...formConfig.serviceDescription}>
                <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.label} />
                <TextareaField
                    className={innerStyles.description}
                    is={FORM_CONTROL_ELEMENT.TEXTAREA} />
                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
            </FormControl>

            {descriptionTranslation &&
                <>
                    <Divider className={innerStyles.divider} />
                    <div className={styles.descr}>{i18n.common.description}</div>
                    <div className={styles.desctText}>{descriptionTranslation}</div>
                </>
            }

            <Flex className={!!regUser ? innerStyles.regUserButton : styles.buttons}>
                {!regUser &&
                    <Button
                        className={styles.button}
                        variant='outlined'
                        color='default'
                        onClick={() => { window.location.href = '/user/dashboard/booking-history' }}>
                        {i18n.booking_history.subtitle}
                    </Button>
                }

                <Button
                    type='submit'
                    variant='text'
                    color='alter'
                    disabled={props.invalid || !selectedServiceName}
                    className={joinClassNames(styles.button, styles.mlAuto)}>
                    {i18n.common.next_step}
                </Button>
            </Flex>
        </form >
    )
};

const mapStateToProps = (state: any) => {
    let { myServices, services, i18n, selectedFavorites } = state;
    return { myServices, services, i18n, selectedFavorites };
};

const mapDispatchToProps = (dispatch: any) => ({
    getAllServices: getAllServices(dispatch),
    getSelectedFavorites: getSelectedFavorites(dispatch)
});

const form = BasePopup.generateKey();
export default reduxForm({
    form,
    initialValues: {
        serviceCenterId: '',
        favorites: [],
        serviceId: '',
        selectedService: '',
        comment: ''
    }
})(
    connect(mapStateToProps, mapDispatchToProps)(TypeOfService)
);
