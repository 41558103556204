import Http from "./HttpService";

interface ConfirmPhone {
    code: string,
    phoneNumber: string
}

interface ResendConfirmPhone {
    phoneNumber: string
}

interface ResendVerificationEmail {
    email: string,
    userId?: number
}

class ProfileService extends Http {

    public getMe() {
        return this.get('/settings/me/profile')
            .then(({ data }: any) => data);
    }

    public changeServiceOwnerEmail(email: string) {
        return this.post('/service-owners/me/email', { email })
    }

    public editUser(model: any) {
            return this.put('/settings/me/profile', model);
    }

    public changePassword(model: any) {
        return this.put('/settings/me/password', { ...model, newPassword: model.password })
            .then(({ data }: any) => data.message)
    }

    public confirmEmail(model: any) {
        return this.put('/settings/me/email', model)
            .then(({ data }: any) => data.message)
    }

    public resendConfirmationLink() {
        return this.post('/settings/me/email')
            .then(({ data }: any) => data.message)
    }

    public confirmPhone(model: ConfirmPhone) {
        return this.put('/settings/me/phone', model, {disableToast: true});
    }

    public resendConfirmationSMS(model: ResendConfirmPhone) {
        return this.post('/settings/me/phone', model);
    }

    public resendVerificationLink(model: ResendVerificationEmail) {
        return this.post('/verifications/email-password/resend', model)
            .then(({ data }: any) => data.message)
    }

}

export default ProfileService;
