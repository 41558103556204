import { AnyAction } from "redux";
import { CLOSE_MENU, OPEN_MENU } from "../constants";

let initialState = false;

export const isOpenedMenu = (state: boolean = initialState, action: AnyAction) => {
    switch (action.type) {
        case OPEN_MENU:
            return true;
        case CLOSE_MENU:
            return false;
        default:
            return state;
    }
};
