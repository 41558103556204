import Http from "./HttpService";

class ImageService extends Http {

    public uploadImage(dataURI: string) {
        return fetch(dataURI)
            .then(res => res.blob())
            .then(blob => {
                const fd = new FormData();
                fd.append('file', blob);

                return this.post('/upload/image', fd)
                    .then(({ data }: any) => data);
        })
    }

}

export default ImageService;
