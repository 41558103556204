import { Dispatch } from 'redux';

import ReviewService from '../../services/ReviewService';
import {
    CREATE_SERVICE_REVIEW_FAIL,
    CREATE_SERVICE_REVIEW_REPLY_FAIL,
    CREATE_SERVICE_REVIEW_REPLY_SUCCESS,
    CREATE_SERVICE_REVIEW_SUCCESS,
    GET_SERVICE_REVIEW_FAIL,
    GET_SERVICE_REVIEW_SUCCESS,
    UPDATE_SERVICE_REVIEW_FAIL,
    UPDATE_SERVICE_REVIEW_SUCCESS
} from '../constants';

export const getServiceReview = (dispatch: Dispatch) => (id: number) => {
    return new ReviewService().getServiceReview(id)
        .then(({ data }: any) => data)
        .then((payload: any) => {
            return dispatch({ type: GET_SERVICE_REVIEW_SUCCESS, payload });
        })
        .catch((error: any) => dispatch({ type: GET_SERVICE_REVIEW_FAIL }));
};

export const createServiceReview = (dispatch: Dispatch) => (model: any) => {
    return new ReviewService().createServiceReview(model)
        .then(({ data }: any) => data)
        .then((payload: any) => {
            return dispatch({ type: CREATE_SERVICE_REVIEW_SUCCESS, payload });
        })
        .catch((error: any) => dispatch({ type: CREATE_SERVICE_REVIEW_FAIL }));
};

export const updateServiceReview = (dispatch: Dispatch) => (model: any) => {
    return new ReviewService().updateServiceReview(model)
        .then(({ data }: any) => data)
        .then((payload: any) => {
            return dispatch({ type: UPDATE_SERVICE_REVIEW_SUCCESS, payload });
        })
        .catch((error: any) => dispatch({ type: UPDATE_SERVICE_REVIEW_FAIL }));
};

export const createServiceReviewReply = (dispatch: Dispatch) => (id: number, model: any) => {
    return new ReviewService().createServiceReviewReply(id, model)
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: CREATE_SERVICE_REVIEW_REPLY_SUCCESS, payload }))
        .catch(() => dispatch({ type: CREATE_SERVICE_REVIEW_REPLY_FAIL }));
};
