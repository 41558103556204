import {FormConfig} from "../../types/formConfig";
import {
    EMAIL_MAX_LENGTH,
} from "../../const/validation";
import {trimLeft} from "../../pipes/trim";
import Validator from "../../validators";

let formConfig: FormConfig = {
    email: {
        name: 'email',
        label: 'Email',
        placeholder: 'Work@email.com',
        maxLength: EMAIL_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.required(),
            Validator.email()
        ]
    },
};


export default formConfig;
