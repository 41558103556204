import React from "react";
import {joinClassNames} from "../../helpers/className";
import styles from './index.module.scss'
import {COLOR} from "../../const/colors";
import IconsBusinessTypes from '../IconBusinessTypes';

interface Props {
    className: string;
    children?: any;
    label?: string;
    checked: boolean;
    onChange: (e: any) => any;
    color?: 'secondary' | 'primary';
    id: number;
    withBorder: boolean;
}

const Checkbox = ({className, checked, onChange, label, color, id, withBorder}: Props) => {
    const secondary = color === COLOR.SECONDARY && styles.secondary;

    return (
        <div className={joinClassNames(withBorder && styles.inlineBlock, checked && withBorder && styles.inlineBlockChecked)}>
            <label className={joinClassNames(styles.checkboxWrap, className, secondary)}>
                <input type={'checkbox'}
                       checked={checked}
                       onChange={onChange}
                       className={joinClassNames(styles.checkbox)}
                />
                <div className={joinClassNames(styles.checkMark, 'check-mark')}/>
                {id && <IconsBusinessTypes id={id} className={styles.icon}/>}
                <span> {label} </span>
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    id: null,
    className: '',
    label: '',
    withBorder: false
};

export default Checkbox;
