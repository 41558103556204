import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import uuid from 'uuid';
import { Link as ReactLink } from 'react-router-dom';

import StoreService from '../../services/StorageService';
import { KEY_LANGUAGE, KEY_NEW_BOOKING_STEPS } from '../../const/local-storage';
import StatusBar from '../../components/StatusBar';
import Card from '../../components/Card';
import Icon from '../../components/Icon';
import NavBarMobile from '../../components/NavBarMobile';
import RequestHeader from '../user/dashboard/create-new-booking/header';
import { CLOSE_MODAL } from '../../const/close-modal';
import TypeOfService from './../user/dashboard/create-new-booking/screens/TypeOfService/index';
import DateEndTime from './../user/dashboard/create-new-booking/screens/DateAndTime';
import Location from './../user/dashboard/create-new-booking/screens/Location/index';
import CarsInformation from "./../user/dashboard/create-new-booking/screens/CarsInformation";
import { setLanguage } from '../../redux/actions/i18n';
import Flex from '../../components/Flex';
import Dropdown from '../../components/Dropdown';
import Link from '../../components/Link';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../const/languages';
import { BusinessTypesEnum } from '../../const/business-type';
import updateDataLayer from "../../utils/data-layer";
import { ROLE } from '../../const/users';
import landingMenu from './menu';
import moment from 'moment';
import queryParser from '../../helpers/queryParses';
import qs from 'query-string';

import styles from './index.module.scss';

const PAGES = [
    TypeOfService,
    Location,
    DateEndTime,
    CarsInformation,
];

const TOW_TRUCK_PAGES = [
    TypeOfService,
    Location,
    CarsInformation,
];

const SERVICE_PAGE = 0;
const LOCATION_PAGE = 1;
const DATE_PAGE = 2;
const CAR_INFO_PAGE = 3;
interface IState {
    languageToggle: boolean;
    pickedLang: number;
}

interface Dates {
    startDate: string,
    endDate: string
}

interface IProps {
    history: any;
    location: any;
    openPopup: any;
    getMyServiceCenterInfo: (val: any) => any;
    getCompanyTypes: (val: any) => any;
    setLanguage: (val: any) => any;
}

interface Service {
    id: number,
}

const DEFAULT_PAGE = 0;
const PAGE_WITH_MAP = 1;
const langs = Object.values(LANGUAGES).map((item: any) => item);

class RegistrationWithBooking extends React.Component<IProps, IState> {
    pages: any[];
    captions: any[] = [];
    i18n: any;
    props: any;
    location: any;
    history: any;
    timestamp: number;
    timer: any;
    customCaptions: any[] = [];

    constructor(props: any) {
        super(props);
        const lang = new StoreService().get(KEY_LANGUAGE, DEFAULT_LANGUAGE);
        this.props = props;
        this.state = {
            languageToggle: false,
            pickedLang: lang.value
        };
        this.pages = PAGES;
        this.timestamp = Date.now();
    }

    componentWillMount() {
        updateDataLayer({
            'eventCategory': 'booking',
            'eventAction': 'visit page',
            'eventLabel': 'landing',
            'event': 'autoEvent'
        })

        this.timestamp = Date.now();
        this.timer = this.setTimer({
            'eventCategory': 'booking',
            'eventAction': 'create',
            'eventLabel': `1 step`,
            'event': 'autoEvent'
        });
    }

    private goToNext() {
        if (this.isComplete()) {
            return;
        }
        const { step, regUser } = queryParser(this.location.search)

        const nextStep = !!step ? Number(step) + 1 : 1;

        this.history.push({
            search: qs.stringify({
                regUser,
                step: nextStep,
            })
        })
    }

    private goToPrev() {
        const { step, regUser } = queryParser(this.location.search)
        const prevStep = !!step ? Number(step) - 1 : DEFAULT_PAGE;

        this.history.push({
            search: qs.stringify({
                regUser,
                step: prevStep,
            })
        })
    }

    private isComplete() {
        const { step } = queryParser(this.location.search)
        return this.pages.length === Number(step) + 1;
    }

    public handleLanguageChange = (option: any) => {
        this.props.setLanguage(option.shortName);
        this.setState({ pickedLang: option.value });
        new StoreService().set(KEY_LANGUAGE, option);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    private setTimer = (data: Object) => {
        const dataToSend = data as any;
        return setTimeout(() => {
            dataToSend['booking_timming'] = 300;
            updateDataLayer(data);
            this.timer = null
        }, 300 * 1000);
    }

    public render() {
        let { pickedLang } = this.state;
        const { step } = queryParser(this.props.location.search)
        const activePage = Number(step) || DEFAULT_PAGE;
        let storage = new StoreService();
        let filledSteps = storage.get(KEY_NEW_BOOKING_STEPS, []);

        const businessTypeId = filledSteps[0]?.selectedService.id;
        const isTowTruck = businessTypeId === BusinessTypesEnum['TowTruck'];

        this.i18n = this.props.i18n;
        this.history = this.props.history;
        this.location = this.props.location;

        if (isTowTruck) {
            this.pages = TOW_TRUCK_PAGES;

            this.customCaptions = [
                { header: this.i18n.find_request_page.type_of_service, description: this.i18n.find_request_page.type_of_service_step },
                { header: this.i18n.find_request_page.location, description: this.i18n.find_request_page.location_find_place_step },
                { header: this.i18n.find_request_page.car_details, description: this.i18n.find_request_page.car_details_step },
            ]
        } else {
            this.pages = PAGES;

            this.customCaptions = [
                { header: this.i18n.find_request_page.type_of_service, description: this.i18n.find_request_page.type_of_service_step },
                { header: this.i18n.find_request_page.location, description: this.i18n.find_request_page.location_find_place_step },
                { header: this.i18n.find_request_page.date_and_time, description: this.i18n.find_request_page.date_and_time_step },
                { header: this.i18n.find_request_page.car_details, description: this.i18n.find_request_page.car_details_step },
            ]
        }

        const Form = this.pages[activePage];

        landingMenu.forEach((menuItem: any, index: number) => {
            if (this.i18n.reg_with_booking_menu) {
                menuItem.children = this.i18n.reg_with_booking_menu[index];
            }
        });

        const handleSubmit = (model: any) => {
            if (model === CLOSE_MODAL) {
                return;
            }

            if (!model) {
                this.timestamp = Date.now();
                this.goToPrev();
                return;
            }
            
            filledSteps[activePage] = model;
            storage.set(KEY_NEW_BOOKING_STEPS, filledSteps);
            const seconds = Math.round((Date.now() - this.timestamp) / 1000);
            const data: any = {
                'eventCategory': 'booking',
                'eventAction': 'create',
                'eventLabel': `${activePage + 1} step`,
                'event': 'autoEvent',
                'booking_timming': seconds
            }
            this.timestamp = Date.now();

            if (activePage === SERVICE_PAGE) {
                data['booking_category'] = filledSteps[activePage].selectedService.name;
                data['booking_work_type'] = filledSteps[activePage].selectedService.services
                    .find((service: Service) => service.id === filledSteps[activePage].serviceId).name;
            }

            if (activePage === LOCATION_PAGE) {
                data['booking_location'] = filledSteps[activePage].location.center;
                data['booking_city'] = filledSteps[activePage].location.title;
                data['booking_search_radius'] = filledSteps[activePage].radius.value;
            }

            if (activePage === DATE_PAGE && !isTowTruck) {
                const { date } = filledSteps[activePage];
                if (date.length === 1 && moment(date[0].endDate).diff(moment(date[0].startDate), 'days') === 1) {
                    data['booking_chosen_time'] = 'any time';
                } else {
                    data['booking_chosen_time'] = date.map(({ startDate, endDate }: Dates) => {
                        return `${startDate} - ${endDate}`
                    });
                }
            }

            if (activePage === CAR_INFO_PAGE || (activePage === DATE_PAGE && isTowTruck)) {
                const { brand, fuelType, gearbox, model, year }
                    = !!filledSteps[activePage].selectedCar ? filledSteps[activePage].selectedCar : filledSteps[activePage];

                data['booking_chosen_car'] = !!filledSteps[activePage].selectedCar
                    ? "one of current car"
                    : "new car";
                data['booking_number_of_car'] = filledSteps[activePage].carsCount;
                data['booking_car_model'] = model;
                data['booking_car_brand'] = brand;
                data['booking_car_fuel_type'] = fuelType;
                data['booking_car_gearbox'] = gearbox;
                data['booking_car_year'] = year;
            }

            if (this.timer) {
                updateDataLayer(data);
            }

            this.goToNext();

            if (!this.isComplete()) {
                return;
            }
            updateDataLayer({
                eventCategory: 'booking',
                eventAction: 'sign up',
                event: 'autoEvent'
            });
            this.history.push('/potencial-car-services');
        }

        return (
            <>
                <StatusBar classNameMenu={styles.spaceBetween} className={styles.statusBar}>
                    <ReactLink to='/'>
                        <Icon icon='logoBlack' className={styles.logo} />
                    </ReactLink>
                    <div className={styles.links}>

                    </div>
                    <Flex className={styles.dropdownWrapper}>
                        <Link href={'/business'}
                            className={styles.spacing}>{this.i18n.landing_page.for_business}</Link>
                        <Dropdown
                            onChange={this.handleLanguageChange}
                            value={langs[pickedLang]}
                            options={langs}
                            className={styles.dropdown}
                        />
                        <Link href={'user/sign-in'}
                            className={styles.signInLink}>
                            {this.i18n.login_page.sign_in}
                        </Link>
                        <Link href={'user/sign-up'}>
                            <button className={styles.signInBtn}>
                                {this.i18n.login_page.sign_up}
                            </button>
                        </Link>
                    </Flex>
                </StatusBar>

                <NavBarMobile
                    role={ROLE.USER}
                    menu={landingMenu}
                    isFromLanding
                    className={styles.mobileMenu} />

                <Card large
                    className={activePage === PAGE_WITH_MAP ? styles.CardMap : styles.Card}>
                    <RequestHeader
                        activePage={activePage}
                        captions={this.customCaptions}
                    />
                    <div className={styles.padding}>
                        <Form
                            isTowTruck={isTowTruck}
                            customPageNumber={2}
                            customButtonText
                            notRegistered
                            isLastStep
                            onSubmit={handleSubmit}>
                        </Form>
                    </div>
                </Card>
            </>
        );
    }
}

const Popup: any = RegistrationWithBooking;
const Form: any = reduxForm({
    form: uuid(),
})(
    Popup
);

const mapStateToProps = (state: any) => {
    let { i18n } = state;
    return { i18n };
};

const mapDispatchtoProps = (dispatch: Dispatch) => ({
    setLanguage: setLanguage(dispatch)
})

export default connect(mapStateToProps, mapDispatchtoProps)(Form);
