import React, { useState } from 'react';

import StoreService from '../../services/StorageService';
import { KEY_BANNER_CLOSED } from '../../const/local-storage';
import Flex from '../Flex';

import styles from './index.module.scss';
import Icon from '../Icon';

const ApplePWABanner = () => {
    const store = new StoreService();
    const deviceDetails = navigator.userAgent.toLowerCase();
    const isAppleDevice = /iphone|ipad|ipod/.test(deviceDetails);
    const [bannerShown, setBannerShown] = useState(store.get(KEY_BANNER_CLOSED));
    const isInStandaloneMode = ('standalone' in window.navigator) && window.navigator['standalone'];
    const showBanner = !bannerShown && isAppleDevice && !isInStandaloneMode;

    function handleClose() {
        store.set(KEY_BANNER_CLOSED, true);
        setBannerShown(!bannerShown);
    }

    return showBanner ?
        <div className={styles.wrapper}>
            <Flex className={styles.header}>
                <Icon icon='bookmap' className={styles.bookmap} />
                <Icon icon='close'
                    onClick={handleClose}
                />
            </Flex>

            <div className={styles.content}>
                <p>You could add our site as an application.</p>
                <Flex className={styles.guide}>
                    <div className={styles.aths}>
                        Click "Share" {<Icon icon='share' className={styles.icon} />} for this and then&nbsp;
                    </div>
                    <div className={styles.aths}>choose {<Icon icon='aths' className={styles.icon} />} <b>"Add to Home Screen"</b></div>
                </Flex>
            </div>
        </div> : <></>
}

export default ApplePWABanner;