import {FormConfig} from "../../types/formConfig";
import {FIRST_NAME_MAX_LENGTH, FIRST_NAME_MIN_LENGTH, FORMAT_PHONE_NUMBER_MAX_LENGTH} from "../../const/validation";
import {trimLeft} from "../../pipes/trim";
import Validator from "../../validators";
import {formatPhone, parsePhone} from "../../pipes/phone";


let formConfig: FormConfig = {
    name: {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        maxLength: FIRST_NAME_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.required(),
            Validator.minLength(FIRST_NAME_MIN_LENGTH),
            Validator.maxLength(FIRST_NAME_MAX_LENGTH),
        ]
    },
    phone: {
        name: 'phone',
        label: 'Phone number',
        placeholder: 'Phone',
        format: formatPhone,
        parse: parsePhone,
        maxLength: FORMAT_PHONE_NUMBER_MAX_LENGTH ,
        validate: [
            Validator.required(),
            Validator.phone()
        ]
    },
};


export default formConfig;
