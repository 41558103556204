/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router";

import UsersService from "../../services/UsersService";
import { getQueryParams } from "../../utils/location";
import StoreService from "../../services/StorageService";
import { ROLE } from "../../const/users";
import { KEY_NEW_BOOKING_STEPS, KEY_ROLE, KEY_SESSION, IS_COMPANY } from "../../const/local-storage";
import businessRoutes from "../business/routesMap";
import userRoutes from "../user/routesMap";
import moment from "moment";

let routesMap = {
    [ROLE.BUSINESS]: businessRoutes,
    [ROLE.USER]: userRoutes,
};

const ConfirmEmail = ({ history }: any) => {
    let { email, token, data } = getQueryParams();
    const timeZoneOffset = moment().utcOffset() / 60;

    // revert replacing + to white space from returned value of (qs lib)
    if (email) {
        email = email.toString().replace(/ /g, '+');
    }

    let model = {
        email,
        token,
        timeZoneOffset
    } as any;

    if (data) {
        model.data = data;
    }
    useEffect(() => {
        new UsersService().confirmEmail(model)
            .then(({ data }: any) => {
                let sessionStorage = new StoreService();
                sessionStorage.clear();

                sessionStorage.set(KEY_SESSION, data.session.token);
                sessionStorage.set(KEY_ROLE, data.session.role);
                if (data.session.isCompany) {
                    sessionStorage.set(IS_COMPANY, data.session.isCompany)
                }
                let { preFilledUserData } = data;

                if (preFilledUserData) {
                    //Pre fill data
                    sessionStorage.set(KEY_NEW_BOOKING_STEPS, [
                        { serviceId: preFilledUserData.serviceId },
                        {
                            location: {
                                title: preFilledUserData.address.title,
                                center: {
                                    lat: preFilledUserData.address.geoLocation.lat,
                                    lng: preFilledUserData.address.geoLocation.lng,
                                }
                            },
                            radius: {
                                value: preFilledUserData.radius
                            }
                        },
                        { ...preFilledUserData.carOwner }
                    ]);

                    history.push(`${routesMap[ROLE.USER].DASHBOARD.path}/create-new-booking?step=3`);
                } else {
                    history.push(routesMap[data.session.role].DASHBOARD.path);
                }
            })
            .catch(() => {
                history.push('/');
            });
    }, [])
    return (<></>)
};

export default withRouter(ConfirmEmail as any);

