import { AnyAction } from 'redux';
import {
    CREATE_SERVICE_REVIEW_FAIL,
    CREATE_SERVICE_REVIEW_REPLY_FAIL,
    CREATE_SERVICE_REVIEW_REPLY_SUCCESS,
    CREATE_SERVICE_REVIEW_SUCCESS,
    GET_SERVICE_REVIEW_FAIL,
    GET_SERVICE_REVIEW_SUCCESS,
    UPDATE_SERVICE_REVIEW_FAIL,
    UPDATE_SERVICE_REVIEW_SUCCESS
} from '../constants';

const defaultLoginState = {};

export const review = (state = defaultLoginState, action: AnyAction) => {

    switch (action.type) {
        case GET_SERVICE_REVIEW_SUCCESS:
            return { ...state, wait: false, ...action.payload, error: null };
        case GET_SERVICE_REVIEW_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        case CREATE_SERVICE_REVIEW_SUCCESS:
            return { ...state, wait: false, ...action.payload, error: null };
        case CREATE_SERVICE_REVIEW_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        case UPDATE_SERVICE_REVIEW_SUCCESS:
            return { ...state, wait: false, ...action.payload, error: null };
        case UPDATE_SERVICE_REVIEW_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        case CREATE_SERVICE_REVIEW_REPLY_SUCCESS:
            return { ...state, wait: false, ...action.payload, error: null };
        case CREATE_SERVICE_REVIEW_REPLY_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        default:
            return state;
    }
};
