import Route from './Route';

export const EVENTS = {
    onHover: 'onMouseEnter'
};

function mergeHandlers(...handlers) {
    return e => handlers.map(f => f(e));
}

export default function preloadPageProps(urlProps, handlers = {}, events = [EVENTS.onHover]) {
    let [url] = Object.values(urlProps);
    let preload = () => Route.preloadRoute(url);
    let props = {};

    events.forEach(eventName => {

       if (handlers[eventName]) {
           props[eventName] = mergeHandlers(
               preload,
               handlers[eventName]
           );
           return null;
       }

       props[eventName] = preload;
    });

    return {
        ...urlProps,
        ...handlers,
        ...props
    };
}
