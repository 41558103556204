import UsersService from './UsersService';
import { CommentInterface, ReviewInterface } from '../types/models';

export default class ReviewService extends UsersService {
    createServiceReview(model: ReviewInterface) {
        return this.post('/reviews', model);
    }

    updateServiceReview(model: ReviewInterface) {
        const { complitedBookingId: id, text, rating } = model;
        return this.patch(`/reviews/${id}`, { text, rating });
    }

    createServiceReviewReply(id: number, model: CommentInterface) {
        const { reply } = model;
        return this.patch(`/reviews/${id}/reply`, { reply });
    }

    getServiceReview(id: number) {
        return this.get(`/reviews/${id}`);
    }
}
