import { FormConfig } from '../../types/formConfig';
import { trimLeft } from '../../pipes/trim';
import Validator from '../../validators';
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH, } from '../../const/validation';

let formConfig: FormConfig = {
    carServiceName: {
        name: 'companyName',
        label: 'Car service name',
        placeholder: 'Car service name',
        maxLength: INPUT_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.required(),
        ]
    },
    carServiceBranch: {
        name: 'branchName',
        label: 'Car service branch',
        placeholder: 'Car service branch',
        maxLength: INPUT_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.required(),
            Validator.minLength(INPUT_MIN_LENGTH)
        ]
    },
};


export default formConfig;

