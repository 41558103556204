import { Dispatch } from 'redux';
import {
    BADGE_CO_GET_REQUESTS_API,
    GET_BOOKINGS,
    GET_BOOKINGS_FAIL,
    GET_BOOKINGS_SUCCESS,
    GET_FAVORITES_REQUEST,
    GET_FAVORITES_REQUEST_FAIL,
    GET_FAVORITES_REQUEST_SUCCESS,
    GET_FIND_SERVICE_REQUEST_FAIL,
    GET_FIND_SERVICE_REQUEST_SUCCESS,
    GET_SELECTED_FAVORITES,
    MY_OFFERS_CREATE_FIND_SERVICE_FAIL,
    MY_OFFERS_INFO_FAIL,
    MY_OFFERS_INFO_REQUEST,
    MY_OFFERS_INFO_SUCCESS,
    MY_VEHICLES_INFO_FAIL,
    MY_VEHICLES_INFO_REQUEST,
    MY_VEHICLES_INFO_SUCCESS,
    SET_SELECTED_FAVORITES,
    REDIRECT_SUCCESS,
    CLEAR_ERROR,
} from '../constants';
import CarOwnersService from '../../services/CarOwnersService';
import { PAGINATION_LIMIT } from '../../const/pagination';
import STATUS_CODE from '../../const/status-codes';

export const clearError = (dispatch: Dispatch) => () => {
    dispatch({ type: CLEAR_ERROR });
}

export const getMyVehicles = (dispatch: Dispatch) => (offset = 0, limit = PAGINATION_LIMIT) => {

    dispatch({ type: MY_VEHICLES_INFO_REQUEST });

    return new CarOwnersService().getVehicles(offset, limit)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: MY_VEHICLES_INFO_SUCCESS, payload }))
        .catch(() => dispatch({ type: MY_VEHICLES_INFO_FAIL }));
};

export const getMyOffers = (dispatch: Dispatch) => (model: any) => {

    dispatch({ type: MY_OFFERS_INFO_REQUEST });

    return new CarOwnersService().getMyOffers(model)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: MY_OFFERS_INFO_SUCCESS, payload }))
        .catch((error: any) => {
            if (error.response.status === STATUS_CODE.UNPROCESSABLE_ENTITY) {
                dispatch({ type: MY_OFFERS_CREATE_FIND_SERVICE_FAIL });
                return;
            }

            dispatch({ type: MY_OFFERS_INFO_FAIL });
        });
};

export const getBookings = (dispatch: Dispatch) => (offset: number, option: string) => {

    dispatch({ type: GET_BOOKINGS });

    return new CarOwnersService().getBookings(offset, option)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_BOOKINGS_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_BOOKINGS_FAIL }));
};


export const getMyFavorites = (dispatch: Dispatch) => (offset: number, limit: number) => {
    dispatch({ type: GET_FAVORITES_REQUEST });

    return new CarOwnersService().getFavotites({ limit, offset })
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_FAVORITES_REQUEST_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_FAVORITES_REQUEST_FAIL }));
};

export const setSelectedFavorites = (dispatch: Dispatch) => (data: any[]) => {
    return dispatch({ type: SET_SELECTED_FAVORITES, data });
};

export const getSelectedFavorites = (dispatch: Dispatch) => () => {
    return dispatch({ type: GET_SELECTED_FAVORITES });
};

export const getCarOwnerBadgeRequests = (dispatch: Dispatch) => () => {
    return new CarOwnersService().getBadgeRequests()
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: BADGE_CO_GET_REQUESTS_API, payload }));
};

export const getFindServiceRequest = (dispatch: Dispatch) => () => {
    return new CarOwnersService().getFindService()
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_FIND_SERVICE_REQUEST_SUCCESS, payload: payload.data }))
        .catch(() => dispatch({ type: GET_FIND_SERVICE_REQUEST_FAIL }));
};

export const redirectSuccess = (disapatch: Dispatch) => () => {
    return disapatch({ type: REDIRECT_SUCCESS })
}