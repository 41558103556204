/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { IPropsClassName } from "../../types/props";
import Input from "../Input";
import { CarsAutocompleteService } from "../../services/AutocompleteService";
import { joinClassNames } from '../../helpers/className';
import { onKeyPressFilterKeys } from '../../utils/key-press-filter';

import styles from './index.module.scss'
import { IconType } from '../../types/icon';
import Icon from '../Icon';

interface IProps extends IPropsClassName {
    onSelect: (val: any) => any;
    disabled?: boolean;
    onChange?: (val: any) => any;
    value?: string;
    inputProps?: any;
    color?: 'secondary' | 'primary';
    lookingFor?: 'brand' | 'model';
    id?: number;
    is?: string;
    placeholder?: string;
    isInvalid?: boolean;
    onBlur?: any;
    dropdownClassName?: string;
    icon?: IconType;
}

export const CarsAutocomplete = ({ onSelect, value, onChange, isInvalid, lookingFor = 'brand', id, placeholder, disabled, icon, dropdownClassName, ...props }: IProps) => {
    const [suggestions, setSuggestions] = useState([]);
    const { color, is } = props;
    const hasSuggestions = !!onSelect && !!suggestions && suggestions.length > 0;
    const limit = 10;

    useEffect(() => {

        if (value && suggestions.length === 1 &&
            (suggestions[0] as any).name.toLowerCase() === value.toLowerCase()) {
            onSelect(suggestions[0]);
        }

    }, [suggestions])

    const getModels = (value: string) => {
        value.length >= 1 && new CarsAutocompleteService()
            .getModels({ search: value, limit }, (id || 0))
            .then(({ data: { data } }: any) => setSuggestions(data));

        value.length === 0 && setSuggestions([])
    }

    const getBrands = (value: string) => {
        value.length > 1 && new CarsAutocompleteService()
            .getBrands({ search: value, limit })
            .then(({ data: { data } }: any) => setSuggestions(data));

        value.length === 0 && setSuggestions([])
    }

    const handleChange = (e: any) => {
        let { value } = e.target;

        if (onChange) {
            (lookingFor === 'model' && !!id) ? getModels(value) : getBrands(value);
            onChange(value);
        }
    }

    const handleClick = (suggestion: any) => {
        if (onChange) {
            onChange(suggestion.name);
        }

        hasSuggestions && onSelect(suggestion);
    }

    const handleEnterKeyPress = (event: Event) => {
        event.preventDefault();

        if (hasSuggestions) {
            onSelect(suggestions[0]);
            event.target && (event.target as HTMLElement).blur()
        }
    }

    return (
        <div className={styles.wrap}>
            {icon && <Icon icon={icon} className={styles.icon} />}
            <Input
                color={color}
                onKeyPress={onKeyPressFilterKeys(['Enter'], handleEnterKeyPress)}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                withBorder
                is={is}
                autoComplete='off'
                id={'clickme'}
                onChange={handleChange}
                className={
                    joinClassNames(
                        isInvalid && styles.invalidControl,
                        icon && styles.withIcon
                    )
                }
                {...props}
                onBlur={() => {
                    setTimeout(() => {
                        setSuggestions([]);
                        (props as any).onBlur()
                    }, 300)
                }}
            />
            {
                suggestions && suggestions.length > 0 &&
                <div className={joinClassNames(styles.listResults, dropdownClassName)}>
                    {suggestions.map((suggestion: any) => {
                        return (
                            <div key={suggestion.id} className={styles.item} onClick={() => handleClick(suggestion)}>
                                {suggestion.name}
                            </div>
                        );
                    })}
                </div>
            }
        </div >
    )
};

CarsAutocomplete.defaultProps = {};
