/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { useCookies } from "react-cookie";

import Button from "../Button/index";
import { COOKIES_ACCEPTED } from "../../const/local-storage";

import styles from "./index.module.scss";

function CookiesPolicyBanner(props: any) {
    const [cookies, setCookie] = useCookies([COOKIES_ACCEPTED]);
    const { termsBanner, common } = props;
    const { cookieMessage, cookiesPolicy } = termsBanner || {};


    function handleAcceptCookies() {
        setCookie(COOKIES_ACCEPTED, true, {
            expires: moment().add(1, 'years').toDate(),
            path: '/'
        })
    }

    return !cookies[COOKIES_ACCEPTED] && window.location.pathname !== '/policy' ?
        <div className={styles.policyWrapper}>
            <div className={styles.policyText}>
                {cookieMessage} <a className={styles.cookiesLink} href={"/policy"} target="_blank">{cookiesPolicy}</a>.
            </div>

            <div className={styles.acceptButton}>
                <Button
                    onClick={handleAcceptCookies}
                    className={'large'}
                    type='submit'
                    variant='text'
                    color='primary'
                >
                    {common && common.accept}
                </Button>
            </div>
        </div> : <></>
}

const mapStateToProps = (state: any) => {
    const { i18n: { termsBanner, common } } = state;
    return { termsBanner, common };
}

export default connect(mapStateToProps)(CookiesPolicyBanner);