export const DEFAULT_ASPECT_RATIO = 1;

export const CROPPER_CONFIG = {
    minCropBoxWidth: 150,
    minCropBoxHeight: 150,
    aspectRatio: DEFAULT_ASPECT_RATIO,
    movable: false,
    center: false,
    zoomable: false,
    guides: false,
};
