import UsersService from "../services/UsersService";
import ServiceCentersService from "../services/ServiceCentersService";
import store from "../redux/store";

let validations_message: any = {};
store.subscribe(() => validations_message = store.getState().i18n.validations_message);

export class RegistrationValidator {
    static email = (email: string) => new UsersService().checkUserEmail(email);
    static branchName = (branchName: string) => new ServiceCentersService().checkBranch(branchName);
    static companyName = (companyName: string) => new ServiceCentersService().checkCompany(companyName)
}

export class BusinessRegistrationValidator {
    company: { v: string, e: string } = { v: '', e: '' };
    branch: { v: string, e: string } = { v: '', e: '' };
    checkBranch = (v: string) => new ServiceCentersService().checkBranch(v);
    checkCompany = (v: string) => new ServiceCentersService().checkCompany(v);
}

export const createAsyncValidator = (o: any) => ((values: any) => {
    const arr: Promise<any>[] = [];

    if (o.branch.v !== values.branchName) {
        arr.push(o.checkBranch(values.branchName));
        o.branch.v = String(values.branchName);
    } else {
        arr.push(new Promise((resolve) => resolve(undefined)))
    }

    if (o.company.v !== values.companyName) {
        arr.push(o.checkCompany(values.companyName));
        o.company.v = String(values.companyName);
    } else {
        arr.push(new Promise((resolve) => resolve(undefined)))
    }

    return Promise.all(arr).then((r: any) => {
        const [branch, company] = r;
        const error: any = {};

        if (company && company.data) {
            o.company.e = company.data.isExist ? validations_message.user_with_such_company_name_already_exists : '';
        }

        if (branch && branch.data) {
            o.branch.e = branch.data.isExist ? validations_message.user_with_such_branch_name_already_exists : '';
        }

        if (o.branch.e.length) {
            error.branchName = o.branch.e;
        }

        if (o.company.e.length) {
            error.companyName = o.company.e;
        }

        if (Object.keys(error).length > 0) {
            throw error
        }

    })
});
