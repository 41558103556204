import ChunkLoader from '../../helpers/ChunkLoader';
import UserRoleGuard from "../../guards/UserRoleGuard";
import KeepInSystem from "../../guards/KeepInSystem";

const SignUp = ChunkLoader(() => import('./sign-up'));
const SignUpComplete = ChunkLoader(() => import('./sign-up-complete'));
const SignIn = ChunkLoader(() => import('./sign-in'));
const ResetPassword = ChunkLoader(() => import('./reset-password'));
const ResetPasswordRequest = ChunkLoader(() => import('./forgot-password'));
const ForgotPasswordWasSent = ChunkLoader(() => import('./forgot-password-status'));
const Dashboard = ChunkLoader(() => import('./dashboard'));
const BranchesManagement = ChunkLoader(() => import('./branches-management'));
const Landing = ChunkLoader(() => import('./landing'));

export default {
    LANDING_PAGE: {
        exact: true,
        component: Landing,
        path: '/business'
    },
    SIGN_UP: {
        exact: true,
        component: SignUp,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/sign-up',
    },
    SIGN_UP_COMPLETE: {
        exact: true,
        component: SignUpComplete,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/sign-up-complete',
    },
    SIGN_IN: {
        exact: true,
        component: SignIn,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/sign-in',
    },
    RESTORE_PASSWORD_REQUEST: {
        exact: true,
        component: ResetPasswordRequest,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/restore-password-request',
    },
    RESTORE_PASSWORD_REQUEST_STATUS: {
        exact: true,
        component: ForgotPasswordWasSent,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/restore-password-request/status',
    },
    RESTORE_PASSWORD: {
        exact: true,
        component: ResetPassword,
        routeGuards: [KeepInSystem],
        redirectTo: ['/business/dashboard'],
        path: '/business/restore-password',
    },
    DASHBOARD: {
        exact: false,
        routeGuards: [UserRoleGuard],
        redirectTo: ['/404'],
        component: Dashboard,
        path: '/business/dashboard',
    },
    BRANCHES_MANAGEMENT: {
        exact: false,
        routeGuards: [UserRoleGuard],
        redirectTo: ['/business/dashboard'],
        component: BranchesManagement,
        path: '/business/branches-management'
    }
};

