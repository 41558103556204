import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";
import Flex from "../Flex";
import Headline from "../Headline";
import { IPropsClassName } from "../../types/props";

interface IProps extends IPropsClassName {
    title?: any;
    classNameMenu?: string;
    Component?: any
}

const StatusBar = (props: IProps) => {
    let { children, className, title, classNameMenu, Component } = props;
    let classNames = joinClassNames(styles.StatusBar, className);

    return (
        <Flex className={classNames}>
            <Headline type={"h5"} className={styles.Headline}>{title}</Headline>
            <Flex className={joinClassNames(styles.menu, classNameMenu)}>
                {children}
                {Component && <Component />}
            </Flex>
        </Flex>
    )
};

export default StatusBar;
