import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { closePopup } from '../../redux/actions';
import { ActionButtonClose } from '../../components/ActionButton';
import Popup from '../../components/OfferMarker/popup';

import styles from './index.module.scss'

const OfferOnMapPopup = (props: any) => {
    const { initialData, close } = props;

    return (
        <>
            <ActionButtonClose icon='close' onClick={() => close(props.uuid)} className={styles.close} />
            <Popup {...initialData} />
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    close: closePopup(dispatch)
})

export default connect(null, mapDispatchToProps)(OfferOnMapPopup);
