import React from 'react';
import Slider from '@material-ui/core/Slider/Slider';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const MS_IN_DAY = 85800000;
export const MS_IN_STEP = 10 * 60 * 1000;

const THEME = {
    overrides: {
        MuiSlider: {
            root: {
                color: '#FFDF66',
                height: 2,
                padding: '13px 0',
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -12,
                marginLeft: -13,
                boxShadow: '#ebebeb 0px 2px 2px',
                '&:focus,&:hover,&$active': {
                    boxShadow: '#ccc 0px 2px 3px 1px',
                    border: '4px solid currentColor',
                },
            },
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 3,
            },
            rail: {
                color: '#d8d8d8',
                opacity: 1,
                height: 3,
            },
        }
    }
};

interface IProps {
    onChange: any;
    value: any[];
    color?: 'primary' | 'secondary';
    className?: string;
}

const TimeSlider = ({ value, color, className, ...props }: IProps) => {
    if (color === 'secondary') {
        THEME.overrides.MuiSlider.root.color = '#ffdf66';
    }

    const muiTheme = createMuiTheme(THEME);

    return (
        <ThemeProvider theme={muiTheme}>
            <Slider
                className={className}
                value={value}
                step={MS_IN_STEP}
                max={MS_IN_DAY}
                {...props} />
        </ThemeProvider>
    );
};

export default TimeSlider;
