import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import uuid from "uuid/v4";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ScreensEnum } from "../screens.enum";
import Button from "../../../../../../components/Button/index";
import Map from "../../../../../../components/Map";
import { DEFAULT_LOCATION } from "../../../../../../popups/LocationPopup";
import Label from "../../../../../../components/Label";
import Dropdown from "../../../../../../components/Dropdown";
import Flex from "../../../../../../components/Flex";
import { INPUT_MAX_LENGTH } from "../../../../../../const/validation";
import { trimLeft } from "../../../../../../pipes/trim";
import MapAutocomplete from "../../../../../../components/MapAutocomplete";
import { MapAutocompleteService } from "../../../../../../services/AutocompleteService";
import { KEY_NEW_BOOKING_STEPS } from "../../../../../../const/local-storage";
import StoreService from "../../../../../../services/StorageService";
import { MOBILE_BP, TABLET_BP } from "../../../../../../const/users";
import { joinClassNames } from "../../../../../../helpers/className";
import formInternalization from "../../../../../../utils/form-internalization";
import { RADIUS, RADIUS_DEFAULT_INDEX } from "./searchRadiusValues";
import formConfig from "./form-config";

import styles from '../../index.module.scss';

let isFilled = false;

enum MapSizes {
    SMALL = '230px',
    MEDIUM = '396px',
    LARGE = '350px'
}

const Location = (props: any) => {
    const { i18n, isTowTruck } = props;
    const translatedRangeValues = RADIUS
        .map((item: any) => ({ value: item, name: `${item} ${i18n.common.km}` }));
    const matches = useMediaQuery(TABLET_BP);
    const matchesMobile = useMediaQuery(MOBILE_BP);

    formInternalization(formConfig)

    const [radius, setRadius] = useState(translatedRangeValues[RADIUS_DEFAULT_INDEX]);
    const [state, setState] = useState({
        center: DEFAULT_LOCATION,
        title: '',
    });

    let mapSize = MapSizes.LARGE;
    if (matches && !matchesMobile) {
        mapSize = MapSizes.MEDIUM
    } else if (matchesMobile) {
        mapSize = MapSizes.SMALL;
    }

    let storage = new StoreService();
    let filledSteps = storage.get(KEY_NEW_BOOKING_STEPS, []);
    let steps = filledSteps[ScreensEnum.Location];

    if (steps && !isFilled) {
        props.change('location', steps.location);
        props.change('radius', radius);
        setState(steps.location);
        setRadius(steps.radius);
        isFilled = true;
    }

    const detectLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
            let pos = {
                lng: position.coords.longitude,
                lat: position.coords.latitude
            };
            searchAddress(pos)
        });
    };

    const searchAddress = (coords: any) => {
        MapAutocompleteService.getAddress(coords).then((e: any) => {
            state.center = { lat: e.center[1], lng: e.center[0] };
            let position = { ...state, title: trimLeft(e.place_name) as any };
            setState(position);
            props.change('location', position);
        });
    };

    useEffect(() => {
        if (!steps?.location) {
            detectLocation();
        }
        props.change('radius', radius);
        return () => {
            isFilled = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <form onSubmit={props.handleSubmit}>
                <Flex className={styles.distanceDropdownContainer}>
                    <div className={styles.autoComplete}>
                        <MapAutocomplete
                            inputProps={{
                                maxLength: INPUT_MAX_LENGTH
                            }}
                            color={'secondary'}
                            value={state.title}
                            isNamed={false}
                            searchOptions={{ country: 'ee' }}
                            onChange={(text: string) => setState({ ...state, title: trimLeft(text) as any })}
                            onSelect={(center) => {
                                setState(center);

                                props.change('location', center);
                            }} />
                    </div>
                    {!isTowTruck && <Flex className={styles.distanceDropdownWrapper}>
                        <Label
                            className={joinClassNames(styles.offsetRight, styles.searchLabel)}>{i18n.find_service.search_radius}</Label>
                        <Dropdown
                            onChange={(option: any) => {
                                props.change('radius', option)
                                setRadius(option);
                            }}
                            className={styles.distanceDropdown}
                            options={translatedRangeValues}
                            selectClass={styles.selectClassName}
                            baseClassName={`${styles.baseClassName} dropdown`}
                            itemClassName={styles.liClassName}
                            selectedItemClassName={styles.selectedItem}
                            value={radius} />
                    </Flex>}
                </Flex>

                <Map height={mapSize}
                    defaultCenter={state.center}
                    center={state.center}
                    zoom={11}
                    onClick={(position) => {
                        searchAddress(position);
                    }}
                    className={styles.map}>
                </Map>

                <Flex className={styles.buttons}>
                    <Button
                        variant={"outlined"}
                        onClick={() => props.onSubmit(null)}
                        className={styles.button}>
                        {i18n.common.previous}
                    </Button>

                    <Button
                        color={'secondary'}
                        variant={"text"}
                        type={'submit'}
                        disabled={props.invalid || !props.$values.location}
                        className={styles.button}>
                        {i18n.common.next_step}
                    </Button>
                </Flex>
            </form>
        </>
    )
};

const form = uuid();

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n }
};

const Form = reduxForm({
    form,
    initialValues: {
        location: '',
        radius: ''
    },
})(
    Location
);

export default connect(mapStateToProps, null)(Form)
