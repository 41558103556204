import { KEY_LANGUAGE, KEY_BANNER_CLOSED } from "../const/local-storage";
import { DEFAULT_LANGUAGE } from "../const/languages";

const DEFAULT_STORAGE = localStorage;

class StoreService {
    private readonly _version: string;
    private readonly _store: any;

    constructor({ store = DEFAULT_STORAGE, version = '_v1' } = {}) {
        this._version = version;
        this._store = store;
    }

    private _decorateKey(key: string) {
        return `${key}${this._version}`;
    }

    private _objectToString(obj: object) {
        return JSON.stringify(obj);
    }

    private _parseString(str: string) {
        return JSON.parse(str);
    }

    public get(key: string, _default?: any) {
        const strData = this._store.getItem(this._decorateKey(key));
        const data = this._parseString(strData);
        return arguments.length === 1 ? data : data || _default;
    }

    public set(key: string, value: any) {
        this._store.setItem(this._decorateKey(key), this._objectToString(value));
    }

    public merge(key: string, value: any) {
        const keyName = this._decorateKey(key);
        const oldValue = this.get(key);
        const newValue = Object.assign(oldValue, value);

        this._store.setItem(keyName, this._objectToString(newValue));
    }

    public remove(key: string) {
        this._store.removeItem(this._decorateKey(key));
    }

    public clear() {
        const lang = this.get(KEY_LANGUAGE);
        const pwa = this.get(KEY_BANNER_CLOSED);
        this._store.clear();

        this.set(KEY_LANGUAGE, lang);
        this.set(KEY_BANNER_CLOSED, pwa)
    }

    public clearByScope(scope: string) {
        Object.keys(this._store)
            .filter(key => new RegExp(`^${scope}`).test(key))
            .map(key => this._store.removeItem(key));
    }

    public getCurrentLangKey() {
        return this.get(KEY_LANGUAGE, DEFAULT_LANGUAGE).shortName;
    }
}

export default StoreService;
