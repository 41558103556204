export const BOOKING_STATUS_WEIGHT = {
    WaitingForConfirmation: 1,
    Requested: 2,
    InProgress: 3,
    Upcoming: 4,
    Confirmed: 4,
    Completed: 5,
    CancelledByCarOwner: 7,
    CancelledByServiceOwner: 7,
    Cancelled: 7,
    DeclinedByServiceOwner: 8,
    DeclinedByCarOwner: 8
};
