import React from 'react';
import { DialogContent } from '@material-ui/core';

import styles from './index.module.scss';
import { joinClassNames } from "../../../helpers/className";

const StyledDialogContent = ({ fullScreen = false, children }: any) => (
    <DialogContent className={ joinClassNames(styles.DialogContent, fullScreen && styles.fullScreen) }>
        { children }
    </DialogContent>
);


export default StyledDialogContent;

