import React from "react";

import { joinClassNames } from "../../helpers/className";
import BrowserLink from '../../modules/navigation/Link';
import preloadPageProps from '../../modules/navigation/preloadProps';
import styles from './index.module.scss';
import { COLOR } from "../../const/colors";

interface Props {
    className: string;
    href: string;
    children: any;
    color?: 'primary' | 'secondary';
    onClick?: (e?: Event | undefined) => void;
}

const Link = ({ className, href, children, color, onClick = () => { } }: Props) => {
    const colorClassName = color === COLOR.SECONDARY ? styles.secondary : styles.primary;

    return (
        <BrowserLink {...preloadPageProps({ to: href })}
            onClick={onClick}
            className={joinClassNames(styles.link, colorClassName, className)}>
            {children}
        </BrowserLink>
    )
};

Link.defaultProps = {
    className: '',
};

export default Link;
