import {FormConfig} from "../../../../../../types/formConfig";
import Validator from "../../../../../../validators";


let formConfig: FormConfig = {
    location: {
        name: 'location',
        label: '',
        placeholder: '',
        validate: [
            Validator.required(),
        ]
    },
};


export default formConfig;

