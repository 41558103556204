/* eslint-disable react/style-prop-object */
import React, { useState } from 'react'
import ReactMapboxGl, { ZoomControl } from 'react-mapbox-gl';

import { IPropsClassName } from "../../types/props";
import MapServiceMarker from '../MapServiceMarker';
import MapUserMarker from '../MapUserMarker';
import OfferMarker from '../OfferMarker';

interface IPosition {
    lat: number;
    lng: number;
}

interface ISreviceCentre {
    position: IPosition;
    title: string;
    subtitle: string;
    id: number;
    logo?: string;
}

interface IProps extends IPropsClassName {
    center: IPosition;
    defaultCenter?: IPosition;
    height?: string;
    zoom?: number,
    onClick?: (val: any) => any;
    itemList?: ISreviceCentre[];
    onLoad?: () => any;
    offerList?: any[];
    refresh?: any;
    onDragEnd?: any;
}

const key = process.env.REACT_APP_MAPS_KEY;

const MapBox = ReactMapboxGl({
    accessToken: key,
    doubleClickZoom: false
} as any);

const Map = ({ center, zoom, height, className, defaultCenter, onClick, itemList, offerList, refresh, onDragEnd }: IProps) => {
    const [currentZoom, setCurrentZoom] = useState(zoom);

    return (
        <div style={{ height }} className={className}>
            <MapBox
                style="mapbox://styles/mapbox/streets-v9"
                center={center as any}
                zoom={currentZoom ? [currentZoom] : [zoom] as any}
                containerStyle={{
                    height: '100%',
                    width: '100%'
                }}
                onClick={(e: any, evt: any) => {
                    center = evt.lngLat;
                    setCurrentZoom(e.getZoom());
                    if (onClick) {
                        onClick(center);
                    }
                }}
                movingMethod='easeTo'
                flyToOptions={flyToOptions}
            >
                <ZoomControl zoomDiff={2} position='top-right' />
                <MapUserMarker position={center} handleZoom={setCurrentZoom} onDragEnd={onDragEnd} key={'user-location'} />
                <>
                    {itemList && itemList.map((item: any, index: number) => (
                        <MapServiceMarker item={item} key={index} />
                    ))}

                    {offerList && offerList.map((item: any, index: number) => (
                        <OfferMarker item={item} key={index} refresh={refresh} />
                    ))}
                </>
            </MapBox>
        </div >
    )
};

Map.defaultProps = {
    zoom: 11,
};

const flyToOptions = {
    speed: 0.2,
}

export default Map;
