import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { joinClassNames } from '../../helpers/className';
import { Link } from 'react-router-dom';
import Divider from '../Divider';
import { IPropsClassName } from '../../types/props';
import Icon from '../Icon';

import styles from '../BranchListDropdown/index.module.scss';
import { getQueryParams } from '../../utils/location';

interface IProps extends IPropsClassName {
    branches: any[];
    refresh?: any;
}

const BranchList = ({ branches, refresh, ...props }: IProps) => {
    const hasBranches = branches && branches.length;
    const itemClasses = joinClassNames(styles.NavBarLink, styles.BranchListItem);
    const serviceCenterId = getQueryParams().serviceCenterId;
    const icon = <Icon icon={'sorPlaceholder'} className={styles.Logo} />;
    return (
        <>
            {hasBranches && <div className={styles.BranchList}>
                <Divider className={styles.Divider} />
                <Scrollbars
                    autoHeight
                    renderThumbVertical={props => <div {...props} className={styles.Scroll} />}>
                    {branches.map((branch: any) => {
                        return (
                            <Link to={`/business/branches-management/branch?serviceCenterId=${branch.id}`} onClick={refresh}>
                                <div className={itemClasses}>
                                    {branch.image && branch.image.compactPath
                                        ? <img className={styles.Logo} src={branch.image.compactPath} alt="logo" />
                                        : icon}
                                    <p>{branch.branchName}</p>
                                    {serviceCenterId === branch.id && <><Icon icon='success' /></>}
                                </ div>
                            </Link>
                        )
                    })}
                </Scrollbars>
            </div>}
        </>
    )
}

export default BranchList;
