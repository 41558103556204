import { FormConfig } from "../../../../../../types/formConfig";
import { trimLeft } from "../../../../../../pipes/trim";
import {
    CAR_BRAND_MAX_LENGTH,
    CAR_BRAND_MIN_LENGTH,
    CAR_ENGINE_POWER_MAX_LENGTH,
    CAR_ENGINE_POWER_MIN_LENGTH,
    CAR_ENGINE_VOLUME_MAX_LENGTH,
    CAR_ENGINE_VOLUME_MIN_LENGTH,
    CAR_MODEL_MAX_LENGTH,
    CAR_MODEL_MIN_LENGTH,
    CAR_REGISTRATION_NUMBER_MAX_LENGTH,
    MAX_YEAR_CAR_MANUFACTURE,
    MILEAGE_MAX_LENGTH,
    MILEAGE_MIN_LENGTH,
    MIN_YEAR_CAR_MANUFACTURE
} from "../../../../../../const/validation";
import Validator from "../../../../../../validators/index";
import VehicleValidator from "../../../../../../validators/vehicle";
import { integerOnly } from "../../../../../../pipes/phone";
import { formatMilleage, parseMilleage } from "../../../../../../pipes/mileage";


let formConfig: FormConfig = {
    registrationNumber: {
        name: 'registrationNumber',
        label: 'Registration Number*',
        placeholder: '',
        format: trimLeft,
        maxLength: CAR_REGISTRATION_NUMBER_MAX_LENGTH,
        validate: [
            Validator.required(),
            VehicleValidator.carRegistrationNumber()
        ]
    },
    brand: {
        name: 'brand',
        label: 'Car Brand*',
        placeholder: '',
        format: trimLeft,
        maxLength: CAR_BRAND_MAX_LENGTH,
        validate: [
            Validator.required(),
            Validator.minLength(CAR_BRAND_MIN_LENGTH)
        ]
    },
    model: {
        name: 'model',
        label: 'Car model*',
        placeholder: '',
        format: trimLeft,
        maxLength: CAR_MODEL_MAX_LENGTH,
        validate: [
            Validator.required(),
            Validator.minLength(CAR_MODEL_MIN_LENGTH)
        ]
    },
    year: {
        name: 'year',
        label: 'Year*',
        placeholder: '',
        maxLength: 4,
        format: integerOnly,
        validate: [
            Validator.min(MIN_YEAR_CAR_MANUFACTURE),
            Validator.max(MAX_YEAR_CAR_MANUFACTURE)
        ]
    },
    volume: {
        name: 'volume',
        label: 'Engine volume',
        placeholder: '',
        format: trimLeft,
        maxLength: CAR_ENGINE_VOLUME_MAX_LENGTH,
        validate: [
            Validator.minLength(CAR_ENGINE_VOLUME_MIN_LENGTH)
        ]
    },
    fuelType: {
        name: 'fuelType',
        label: 'Fuel type',
        placeholder: 'Select type',
        validate: []
    },
    gearbox: {
        name: 'gearbox',
        label: 'Gearbox',
        placeholder: 'Select type',
        validate: []
    },
    enginePower: {
        name: 'enginePower',
        label: 'Engine power',
        placeholder: '',
        format: trimLeft,
        maxLength: CAR_ENGINE_POWER_MAX_LENGTH,
        validate: [
            Validator.minLength(CAR_ENGINE_POWER_MIN_LENGTH)
        ]
    },
    mileage: {
        name: 'mileage',
        label: 'Mileage',
        placeholder: '',
        format: formatMilleage,
        parse: parseMilleage,
        maxLength: MILEAGE_MAX_LENGTH,
        validate: [
            Validator.minLength(MILEAGE_MIN_LENGTH)
        ]
    }
};


export default formConfig;
