import { AnyAction } from "redux";
import {
    GET_BRANCHES,
    GET_BRANCHES_FAIL,
    GET_BRANCHES_SUCCESS,

    GET_BRANCH_CONTRACTS,
    GET_BRANCH_CONTRACTS_FAIL,
    GET_BRANCH_CONTRACTS_SUCCESS,

    GET_BRANCH_REQUESTS_FAIL,
    GET_BRANCH_REQUESTS,
    GET_BRANCH_REQUESTS_SUCCESS,
} from '../constants';

export const branches = (state = { wait: true, collection: [], pagination: {} }, action: AnyAction) => {

    switch (action.type) {
        case GET_BRANCHES:
            return { ...state, wait: true, pagination: {} };

        case GET_BRANCHES_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        default:
            return { ...state }
    }
};

export const branchesRequests = (state = { collection: [], pagination: {} }, action: AnyAction) => {
    switch (action.type) {

        case GET_BRANCHES_FAIL:
            return { ...state, collection: [], pagination: {} };

        case GET_BRANCH_CONTRACTS:
            return { ...state, collection: [], pagination: {} };

        case GET_BRANCH_CONTRACTS_FAIL:
            return { ...state, collection: [], pagination: {} };

        case GET_BRANCH_CONTRACTS_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case GET_BRANCH_REQUESTS:
            return { ...state, collection: [], pagination: {} };

        case GET_BRANCH_REQUESTS_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case GET_BRANCH_REQUESTS_FAIL:
            return { ...state, collection: [], pagination: {} };

        default:
            return { ...state };
    }
}
