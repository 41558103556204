export const EMAIL_MAX_LENGTH = 129;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 50;

export const FIRST_NAME_MIN_LENGTH = 2;
export const FIRST_NAME_MAX_LENGTH = 50;

export const LAST_NAME_MIN_LENGTH = 2;
export const LAST_NAME_MAX_LENGTH = 50;

export const COMPANY_NAME_MIN_LENGTH = 3;
export const COMPANY_NAME_MAX_LENGTH = 100;

export const BRAND_NAME_MIN_LENGTH = 3;
export const BRAND_NAME_MAX_LENGTH = 100;

export const COMPANY_ADDRESS_MAX_LENGTH = 100;

export const FORMAT_PHONE_NUMBER_MAX_LENGTH = 20; // like this >>>> +(096) 900-067254

export const REGISTRATION_NUMBER_LENGTH = 8;

export const SERVICE_CENTER_DESC_MIN_LENGTH = 3;
export const SERVICE_CENTER_DESC_MAX_LENGTH = 700;

export const CAR_REGISTRATION_NUMBER_MIN_LENGTH = 4;
export const CAR_REGISTRATION_NUMBER_MAX_LENGTH = 9;

export const CAR_BRAND_MIN_LENGTH = 2;
export const CAR_BRAND_MAX_LENGTH = 25;

export const CAR_MODEL_MIN_LENGTH = 1;
export const CAR_MODEL_MAX_LENGTH = 25;

export const SERVICE_RATE_MIN = 1;
export const SERVICE_RATE_MAX = 9999.99;

export const INPUT_MIN_LENGTH = 3;
export const INPUT_MAX_LENGTH = 100;

export const MIN_YEAR_CAR_MANUFACTURE = 1900;
export const MAX_YEAR_CAR_MANUFACTURE = new Date().getFullYear();

export const CAR_ENGINE_VOLUME_MIN_LENGTH = 2;
export const CAR_ENGINE_VOLUME_MAX_LENGTH = 6;

export const CAR_ENGINE_POWER_MIN_LENGTH = 2;
export const CAR_ENGINE_POWER_MAX_LENGTH = 25;

export const MILEAGE_MIN_LENGTH = 1;
export const MILEAGE_MAX_LENGTH = 9;

export const TIME_MAX_LENGTH = 13;
export const SIMPLE_TIME_MAX_LENGTH = 5;

export const FUEL_TYPES = [
    'Gasoline',
    'Diesel',
    'Hybrid',
    'Electrical',
    'Gas'
];

export const GEARBOX = [
    'Automatic',
    'Manual',
    'Robotic',
    'CVT'
];

export const REVIEW_COMMENT_MIN_LENGTH = 3;
export const REVIEW_COMMENT_MAX_LENGTH = 1000;

