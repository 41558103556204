import React from 'react'
import styles from './index.module.scss'
import Flex from "../Flex";
import Icon from '../Icon';
import { joinClassNames } from "../../helpers/className";

const SmallCarCard = ({ car, handleSelect, selectedId }: any) => {
    let isActive = selectedId === car.id;
    const cardClasses = joinClassNames(styles.SmallCarCard, isActive ? styles.active : '');

    return (
        <Flex className={cardClasses} onClick={() => handleSelect(car)}>
            <div>
                <div className={styles.Brand}>{car.brand}</div>
                <div className={styles.Model}>{car.model}</div>
            </div>
            <div>
                {selectedId === car.id && <div className={styles.CheckMark}>
                    <Icon icon={'checkMark'} />
                </div>}
                <div className={styles.RegistrationNumber}>{car.registrationNumber}</div>
            </div>
        </Flex>
    )
};

export default SmallCarCard;
