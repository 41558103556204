import React from 'react';

import Flex from "../Flex";
import Headline from "../Headline";
import Icon from "../Icon";
import { IPropsClassName } from "../../types/props";
import { IconType } from "../../types/icon";
import { COLOR } from "../../const/colors";
import { joinClassNames } from "../../helpers/className";

import styles from './index.module.scss'

interface IProps extends IPropsClassName {
    icon?: IconType;
    title?: string;
    afterTitle?: any;
    color?: 'primary' | 'secondary';
}

const CardTitle = ({ icon, title, afterTitle, children, color, className }: IProps) => {
    const classSecondary = color === COLOR.SECONDARY ? styles.secondary : '';

    return (
        <Flex className={styles.Flex}>
            {!!icon && <Icon icon={icon} className={joinClassNames(styles.Icon, classSecondary)} />}
            <Headline type={"h6"}
                className={joinClassNames(styles.CardTitle, className)}>
                {title}
                {afterTitle}
            </Headline>
            {children}
        </Flex>
    );
};

export default CardTitle;
