export const BADGE_SC_GET_REQUESTS_API = 'BADGE_SC_GET_REQUESTS_API';
export const BADGE_CO_GET_REQUESTS_API = 'BADGE_CO_GET_REQUESTS_API';

export const BADGES_SC_WS = 'BADGES_SC';
export const BADGES_CO_WS = 'BADGES_CO';

export const NEW_REQUEST = 'NEW_REQUEST';
export const REQUEST_CANCELLED = 'REQUEST_CANCELLED';
export const OFFER_CANCELED = 'OFFER_CANCELED';
export const NO_OFFER_REQUEST_CANCELLED_SC = 'NO_OFFER_REQUEST_CANCELLED_SC';

export const NEW_CONTRACT_FOR_CONFIRMATION = 'NEW_CONTRACT_FOR_CONFIRMATION';
export const NEW_CONTRACT_CONFIRMED = 'NEW_CONTRACT_CONFIRMED';
export const CONTRACT_CANCELLED = 'CONTRACT_CANCELLED';
export const CONTRACT_STATUS_CHANGED = 'CONTRACT_STATUS_CHANGED';
export const NEW_CONTRACT_COMPLETED = 'NEW_CONTRACT_COMPLETED';
export const REDIRECT_SUCCESS = 'REDIRECT_SUCCESS';

export const BADGE_REVIEWS_API_SUCCESS = 'BADGE_REVIEWS_API_SUCCESS';
export const BADGE_REVIEWS_API_FAIL = 'BADGE_REVIEWS_API_FAIL';
export const BADGE_REVIEWS_WS = 'BADGE_REWIEWS_WS';

export const ACTIVATED_WS = 'ACTIVATED_WS';