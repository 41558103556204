import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import BasePopup from '../BasePopup';
import Button from "../../components/Button";
import StyledDialogActions from '../../components/Dialog/DialogActions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import FormControl, { FORM_CONTROL_ELEMENT } from "../../components/FormControl";
import Label from "../../components/Label";
import Input from "../../components/Input";
import Flex from "../../components/Flex";
import ErrorMessage from "../../components/ErrorMessage";
import Caption from "../../components/Caption";
import CheckboxesControl from "../../components/CheckboxesControl";
import Validator from "../../validators";
import { closePopup, getCompanyTypes, getMyServiceCenterInfo } from "../../redux/actions";
import ServiceCentersService from "../../services/ServiceCentersService";
import formInternalization from "../../utils/form-internalization";
import { CompanyTypesEnum } from "../../const/business-type";
import formConfig from "./form-config";

import styles from './index.module.scss'

class ServiceSummaryPopup extends BasePopup {
    description: any;
    imageId: number;

    constructor(props: any) {
        super(props);
        this.className = styles.Card;
        let { getCompanyTypes, myServiceCenterInfo } = props;
        let { branchName, businessTypes, companyName, image = {} } = myServiceCenterInfo;
        let { id } = image || {};
        this.i18n = props.i18n;
        this.title = this.i18n.popups.summary_information;
        this.state = { isChildFormValid: false };

        this.imageId = id;

        props.change('branchName', branchName);
        props.change('companyName', companyName);
        props.change('types', businessTypes.map(({ id }: any) => id));

        getCompanyTypes();
    }

    public onSubmit(model: any) {
        let props: any = this.props;

        delete model.companyName;

        if (this.imageId) {
            model.imageId = this.imageId;
        }

        new ServiceCentersService().updateMyServiceCenterInfo(model)
            .then(() => {
                props.getMyServiceCenterInfo();
                this.close();
            });
    }

    public renderContent() {
        let props: any = this.props;
        let { invalid, $values } = props;
        let { companyTypes } = props;

        const companyTypesTranslated: any = [];

        companyTypes.collection.forEach((element: any) => {
            const translatedElement = {
                ...element,
                name: this.i18n.car_service_types[CompanyTypesEnum[element.id]]
            };
            companyTypesTranslated.push(translatedElement);
        });

        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    <form className={styles.form}>

                        <Flex className={styles.serviceName}>
                            <FormControl {...formConfig.carServiceName}
                                className={styles.control}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} disabled withBorder={true} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <FormControl {...formConfig.carServiceBranch}
                                className={styles.control}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder={true} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>
                        </Flex>

                        <Caption
                            className={styles.Caption}
                            size={"16"}>
                            {this.i18n.popups.company_service_type}:
                        </Caption>

                        <Flex className={styles.checkBoxes}>
                            <CheckboxesControl
                                className={styles.checkBoxItem}
                                name={'types'}
                                collection={companyTypesTranslated}
                                validate={[
                                    Validator.arrayNotEmpty(),
                                ]}
                            />
                        </Flex>
                    </form>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button
                        fontSize={16}
                        className={styles.btnCancel}
                        color={"secondary"}
                        onClick={this.close}
                        variant={"outlined"}>
                        {this.i18n.common.cancel}
                    </Button>

                    <Button
                        fontSize={16}
                        className={styles.btnSave}
                        disabled={invalid}
                        color={"secondary"}
                        onClick={() => this.onSubmit({ ...$values })}
                        variant={"text"}>
                        {this.i18n.common.save}
                    </Button>
                </StyledDialogActions>
            </>
        )
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let { companyTypes, myServiceCenterInfo } = state;
    let { values = {} } = state.form[form] || {};
    return { companyTypes, myServiceCenterInfo, $values: values, i18n: state.i18n }
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
    getCompanyTypes: getCompanyTypes(dispatch)
});

const Popup: any = ServiceSummaryPopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        companyName: '',
        branchName: '',
        types: []
    }
})(
    Popup
);

export default connect(mapStateToProps, mapDispatchToProps)(
    Form
);

