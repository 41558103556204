import React from "react";

import Icon from "../Icon";
import Caption from "../Caption";
import Button from "../Button";
import { IPropsClassName } from "../../types/props";
import { IconType } from "../../types/icon";
import { joinClassNames } from "../../helpers/className";
import { COLOR } from "../../const/colors";

import styles from './index.module.scss'

interface IProps extends IPropsClassName {
    icon?: IconType;
    onClick?: (val: any) => any;
    color?: 'secondary' | 'primary';
    captionStyle?: any;
    disabled?: true | false
}

const ActionButton = ({ icon, children, className, color, captionStyle, disabled, ...props }: IProps) => {
    const secondaryClass = color === COLOR.SECONDARY ? styles.secondary : '';
    const captionStyles = joinClassNames(captionStyle, 'Caption');
    const buttonStyles = joinClassNames(styles.Button, secondaryClass, className);
    return (
        <Button className={buttonStyles}
            {...props}
            disabled={disabled}
            small={false}
            large={false}>
            {icon && <Icon icon={icon} />}
            <Caption size={"16"} className={captionStyles}>{children}</Caption>
        </Button>
    )
};



export const ActionButtonAdd = (props: IProps) => (
    <ActionButton {...props} />
);

ActionButtonAdd.defaultProps = {
    icon: 'add',
    children: 'Add'
};


export const ActionButtonAnswer = (props: IProps) => (
    <ActionButton {...props} />
);

ActionButtonAnswer.defaultProps = {
    icon: 'reply',
    children: 'Answer'
};



export const ActionButtonEdit = (props: IProps) => (
    <ActionButton {...props} />
);

ActionButtonEdit.defaultProps = {
    icon: 'edit',
    children: 'Edit'
};


export const ActionButtonClose = (props: IProps) => (
    <ActionButton {...props} />
);

ActionButtonClose.defaultProps = {
    icon: 'close',
    children: 'Close'
};


export default ActionButton
