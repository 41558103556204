import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { closePopup } from "../../redux/actions";
import BasePopup from "../BasePopup";
import StyledDialogContent from '../../components/Dialog/DialogContent';
import { FORM_CONTROL_ELEMENT, default as FormControl } from "../../components/FormControl";
import StyledDialogActions from '../../components/Dialog/DialogActions';
import Button from "../../components/Button";
import Label from "../../components/Label";
import Dropdown from "../../components/Dropdown";
import TextareaField from "../../components/Textarea";
import ErrorMessage from "../../components/ErrorMessage";
import Flex from '../../components/Flex';
import ContractsService from "../../services/ContractsService";
import formInternalization from "../../utils/form-internalization";
import formConfig from "./form-config";

import styles from './index.module.scss'

class DeclineRequestsPopup extends BasePopup {
    props: any;
    id: number;
    type: any;
    callback: any;
    reasons: any[];

    constructor(props: any) {
        super(props);
        let { initialData = {} } = props;
        let { type, id, callback } = initialData;
        this.i18n = props.i18n;
        this.reasons = this.i18n.decline_reasons_SOR.map((item: string, index: number) => ({ name: item, id: index }))

        this.state = {
            isTemplate: true,
            dropDownValue: this.reasons[0],
            isButtonDisabled: false
        };

        this.type = type;

        this.id = id;
        this.callback = callback;
        this.withDivider = false;

        this.title = (type === 'decline' || type === 'declineNewRequest')
            ? <div className={styles.type}>
                {this.i18n.popups.are_you_sure_you_want_to_decline_this_request}
            </div>
            : <div className={styles.type}>
                {this.i18n.popups.are_you_sure_you_want_to_cancel_this_booking}
            </div>
    }

    send = (model: any) => {
        const { type, callback, close, id } = this;
        this.setState({ isButtonDisabled: true });

        if (type === 'decline') {
            new ContractsService().declineContract(id, model)
                .then(() => {
                    this.setState({ isButtonDisabled: false });
                    callback();
                    close();
                })
        } else if (type === 'declineNewRequest') {
            new ContractsService().declineNewRequest(id)
                .then(() => {
                    this.setState({ isButtonDisabled: false });
                    callback();
                    close();
                })
        } else {
            new ContractsService().cancelBooking(id, model)
                .then(() => {
                    this.setState({ isButtonDisabled: false });
                    callback();
                    close();
                })
        }
    }

    renderContent() {
        const props: any = this.props;
        const state: any = this.state;
        const { change, invalid, $values } = props;
        const { send, i18n, close } = this;
        const isDeclineNew = this.type === 'declineNewRequest';

        formInternalization(formConfig);

        return (
            <>
                {!isDeclineNew &&
                    <StyledDialogContent>
                        <form className={styles.form}>
                            <>
                                <div className={styles.plainText}>{i18n.popups.if_so_you_must_specify_the_reason}</div>
                                <Label>{i18n.popups.choose_a_reason}</Label>
                                <Dropdown
                                    className={styles.dropDown}
                                    options={this.reasons}
                                    value={state.dropDownValue}
                                    placeholder={i18n.popups.template_reason}
                                    onChange={(option: any) => {
                                        this.setState({ isTemplate: false, dropDownValue: option });
                                        change('reason', option.name);

                                        if (option.id === 0) {
                                            this.setState({ isTemplate: true, dropDownValue: option });
                                            change('reason', '');
                                        }
                                    }} />

                                {state.isTemplate && <FormControl {...formConfig.reason} className={styles.desc}>
                                    <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                    <TextareaField
                                        is={FORM_CONTROL_ELEMENT.TEXTAREA}
                                    />
                                    <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                                </FormControl>}
                            </>
                        </form>
                    </StyledDialogContent>
                }
                <StyledDialogActions>
                    <Flex className={isDeclineNew ? styles.declineButtons : styles.buttons}>
                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            onClick={close}
                            variant={"outlined"}>
                            {i18n.popups.nevermind}
                        </Button>

                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            disabled={invalid || state.isButtonDisabled}
                            onClick={() => send($values)}
                            color="alter"
                            type={'submit'}
                            variant={"text"}>
                            {i18n.bookings.confirm}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    const { i18n } = state;
    return { $values: values, i18n };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
        form,
        initialValues: {
            reason: '',
        },
    })(DeclineRequestsPopup)
);
