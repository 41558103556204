

export const USERS_FORGOT_PASSWORD_REQUEST = 'USERS_FORGOT_PASSWORD_REQUEST';
export const USERS_FORGOT_PASSWORD_REQUEST_SUCCESS = 'USERS_FORGOT_PASSWORD_REQUEST_SUCCESS';
export const USERS_FORGOT_PASSWORD_REQUEST_FAIL = 'USERS_FORGOT_PASSWORD_REQUEST_FAIL';
export const USERS_FORGOT_PASSWORD_CLEAR_STATE = 'USERS_FORGOT_PASSWORD_CLEAR_STATE';

export const USERS_RESET_PASSWORD_REQUEST = 'USERS_RESET_PASSWORD_REQUEST';
export const USERS_RESET_PASSWORD_REQUEST_SUCCESS = 'USERS_RESET_PASSWORD_REQUEST_SUCCESS';
export const USERS_RESET_PASSWORD_REQUEST_FAIL = 'USERS_RESET_PASSWORD_REQUEST_FAIL';
export const USERS_RESET_PASSWORD_CLEAR_STATE = 'USERS_RESET_PASSWORD_CLEAR_STATE';

export const USERS_CREATE_PASSWORD_REQUEST = 'USERS_CREATE_PASSWORD_REQUEST';
export const USERS_CREATE_PASSWORD_REQUEST_SUCCESS = 'USERS_CREATE_PASSWORD_REQUEST_SUCCESS';
export const USERS_CREATE_PASSWORD_REQUEST_FAIL = 'USERS_CREATE_PASSWORD_REQUEST_FAIL';
