import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';

import BasePopup from '../BasePopup';
import Button from '../../components/Button';
import ActionButton, { ActionButtonAdd } from '../../components/ActionButton';
import FormControl, { FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import Label from '../../components/Label';
import Input from '../../components/Input';
import ErrorMessage from '../../components/ErrorMessage';
import { CheckboxControl } from '../../components/CheckboxesControl';
import Divider from '../../components/Divider';
import HoursRange from '../../components/HoursRange';
import Validator from '../../validators';
import ServiceCentersService from '../../services/ServiceCentersService';
import BranchesService from '../../services/BranchesService';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import formInternalization from "../../utils/form-internalization";
import { closePopup, getMyServiceCenterInfo, getServiceCenterById } from '../../redux/actions';
import { filterNotWeekendContacts } from '../../pipes/contacts';
import { checkPhone, isContactUnique } from '../ContactsPopup';
import { DAYS } from '../../const/days';
import formConfig from './form-config';

import styles from './index.module.scss';

function createContact({ phone = '', name = '' }: any = {}) {
    return {
        phone,
        name,
    };
}

const renderContacts = (props: any) => {
    const { fields, i18n } = props;
    const { common } = i18n;

    formInternalization(formConfig);
    return (
        <>
            {
                fields.map((contact: string, index: number) => {
                    let allPhones: any = {};
                    fields.getAll().forEach(({ phone }: any, i: number) => {
                        checkPhone(phone, i, index, allPhones);
                    });

                    return (
                        <div className={styles.row} key={index}>

                            <FormControl {...formConfig.name}
                                className={styles.formControl}
                                name={`${contact}.${formConfig.name.name}`}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.font} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder={true} className={styles.font} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <FormControl {...formConfig.phone}
                                className={styles.formControl}
                                name={`${contact}.${formConfig.phone.name}`}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.font} />
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder={true} className={styles.font} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                                {allPhones[fields.getAll()[index].phone] > 1 && <ErrorMessage>{
                                    i18n.validations_message.contact_with_such_phone_number_has_already_been_added
                                }</ErrorMessage>}
                            </FormControl>

                            {fields.length > 1 &&
                                <ActionButton icon={'delete'}
                                    className={styles.btnDelete}
                                    onClick={() => fields.remove(index)}>
                                    {common.delete}
                                </ActionButton>}
                        </div>
                    );
                })
            }

            <ActionButtonAdd
                children={common.add_new_contact}
                className={styles.btnAdd}
                onClick={() => fields.push(createContact())} />
        </>
    );
};

class WorkingHoursPopup extends BasePopup {

    constructor(props: any) {
        super(props);
        this.className = styles.Card;

        let { i18n, initialData = {} } = props;
        let { weekSchedule, contacts } = initialData;
        this.i18n = i18n;
        this.title = this.i18n.common.working_Hours;
        this.state = { wait: false };
        if (weekSchedule) {
            DAYS.forEach(({ name }: any) => {
                let from = `${name}From`;
                let to = `${name}To`;
                if (!weekSchedule[from]) {
                    return;
                }
                props.change(name, true);
                props.change(from, weekSchedule[from].slice(0, 5) + ' - ' + weekSchedule[to].slice(0, 5));
            });
        }

        if (contacts && contacts.length) {
            props.change('showContacts', true);
            props.change('contacts', contacts);
        }
    }

    public save(values: any) {
        let model = {} as any;
        let { contacts } = values;
        let props: any = this.props;
        const { initialData: { serviceCenterId } } = props;

        let serviceCenter = new ServiceCentersService();
        let bookingsService = new BranchesService();
        DAYS.forEach(({ name }: any) => {
            let fields = values[`${name}From`].split(' - ');
            let timeFrom = fields[0];
            let timeTo = fields[1];

            if (timeFrom && timeTo && values[name]) {
                model[`${name}From`] = timeFrom;
                model[`${name}To`] = timeTo;
            }

            if (contacts.length > 0)
                model[`onAgreement`] = true;
        });

        this.setState({ wait: true });

        if (serviceCenterId) {
            bookingsService.editBranchSchedule(serviceCenterId, model)
                .then(() => this.saveContacts(contacts, values.showContacts))
                .then(() => {
                    props.getServiceInfoById(serviceCenterId);
                    this.close();
                })
                .catch(() => this.setState({ wait: false }));
            return;
        }

        serviceCenter.changeSchedule(model)
            .then(() => this.saveContacts(contacts, values.showContacts))
            .then(() => {
                props.getMyServiceCenterInfo();
                this.close();
            })
            .catch(() => this.setState({ wait: false }));
    }

    public saveContacts(contacts: any[], showContacts: boolean) {
        let props: any = this.props;
        let { myServiceCenterInfo, initialData: { serviceCenterId }, service } = props;
        let { contacts: oldContacts } = myServiceCenterInfo;
        let serviceCenter = new ServiceCentersService();
        let branchesService = new BranchesService();

        if (!contacts.length) return Promise.resolve();

        if (!showContacts) {
            contacts = [];
        }

        if (serviceCenterId) {
            const branchOldContacts = service.contacts;
            return branchesService.editBranchContacts(serviceCenterId, [
                ...branchOldContacts.filter(filterNotWeekendContacts).map(createContact),
                ...contacts.map((contact: any) => ({ ...contact, onWeekends: showContacts }))
            ]);
        }

        return serviceCenter.changeContacts([
            ...oldContacts.filter(filterNotWeekendContacts).map(createContact),
            ...contacts.map((contact: any) => ({ ...contact, onWeekends: showContacts }))
        ]);
    }

    public renderContent() {
        let props: any = this.props;
        let { wait }: any = this.state;
        let { invalid, $values } = props;
        let isSelectedDays = DAYS.some(({ name }) => $values[name]);

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={() => this.save($values)}
                        className={styles.form}>
                        {
                            DAYS.map(({ name }: any) => (
                                <Fragment key={name}>
                                    <CheckboxControl
                                        name={name}
                                        label={this.i18n.week[name]} />
                                    {$values[name] &&
                                        <HoursRange
                                            classNameControl={styles.control}
                                            className={styles.hours}
                                            field={{ name: `${name}From` }}
                                            values={$values[`${name}From`]}
                                            $values={$values}
                                            change={props.change}
                                        />
                                    }
                                </Fragment>
                            ))
                        }

                        <Divider className={styles.line} />

                        <CheckboxControl
                            name={'showContacts'}
                            label={this.i18n.common.working_on_weekends_on_agreement} />

                        {$values.showContacts &&
                            <FieldArray
                                name="contacts"
                                component={connectedRenderContacts} />
                        }
                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Button
                        large={false}
                        small={true}
                        fontSize={16}
                        className={styles.btnCancel}
                        color={'secondary'}
                        onClick={this.close}
                        variant={'outlined'}>
                        {this.i18n.common.cancel}
                    </Button>

                    <Button
                        large={false}
                        small={true}
                        fontSize={16}
                        className={styles.btnSave}
                        disabled={invalid || wait || !isSelectedDays || isContactUnique($values.contacts)}
                        color={'alter'}
                        onClick={() => this.save($values)}
                        variant={'text'}>
                        {this.i18n.common.save}
                    </Button>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    let { myServiceCenterInfo, service, i18n } = state;
    return { $values: values, myServiceCenterInfo, service, i18n };
};
const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
    getServiceInfoById: getServiceCenterById(dispatch)
});

const Popup: any = WorkingHoursPopup;
const Form: any = reduxForm({
    form,
    validate: (values: any) => {
        let errors: any = {};

        DAYS.forEach(({ name }: any) => {
            if (!values[name]) return;

            let from = `${name}From`;
            let to = `${name}To`;

            errors[to] = Validator.minTime(values[from])(values[to]);
            errors[from] = Validator.maxTime(values[to])(values[from]);
        });

        return errors;
    },
    initialValues: {
        'monday': false,
        'mondayFrom': '',
        'mondayTo': '',

        'tuesday': false,
        'tuesdayFrom': '',
        'tuesdayTo': '',

        'wednesday': false,
        'wednesdayFrom': '',
        'wednesdayTo': '',

        'thursday': false,
        'thursdayFrom': '',
        'thursdayTo': '',

        'friday': false,
        'fridayFrom': '',
        'fridayTo': '',

        'saturday': false,
        'saturdayFrom': '',
        'saturdayTo': '',

        'sunday': false,
        'sundayFrom': '',
        'sundayTo': '',

        'onAgreement': false,

        showContacts: false,
        contacts: [
            createContact()
        ]
    }
})(Popup);

export default connect(mapStateToProps, mapDispatchToProps)(Form);

const mapContactsStateToProps = (state: any) => {
    let { i18n } = state;
    return { i18n };
};

const connectedRenderContacts = connect(mapContactsStateToProps, null)(renderContacts);

