import { FormConfig } from "../../../../../../types/formConfig";
import Validator from "../../../../../../validators/index";
import { trimLeft } from "../../../../../../pipes/trim";

let formConfig: FormConfig = {
    serviceType: {
        name: 'serviceId',
        label: 'Category',
        placeholder: 'Select option',
        validate: [
            Validator.required(),
        ]
    },
    serviceCenterId: {
        name: 'serviceCenterId',
        label: 'Type of work',
        placeholder: 'Select option',
        validate: [
            Validator.required(),
        ]
    },
    serviceDescription: {
        name: 'comment',
        label: 'Description',
        placeholder: 'Description',
        format: trimLeft,
        maxLength: 250,
        validate: [
            Validator.minLength(3),
        ]
    }
};


export default formConfig;

