import { AnyAction } from "redux";
import {
    USERS_CREATE_PASSWORD_REQUEST_SUCCESS,
    USERS_FORGOT_PASSWORD_CLEAR_STATE,
    USERS_FORGOT_PASSWORD_REQUEST,
    USERS_FORGOT_PASSWORD_REQUEST_FAIL,
    USERS_FORGOT_PASSWORD_REQUEST_SUCCESS, USERS_RESET_PASSWORD_CLEAR_STATE,
    USERS_RESET_PASSWORD_REQUEST,
    USERS_RESET_PASSWORD_REQUEST_FAIL,
    USERS_RESET_PASSWORD_REQUEST_SUCCESS
} from "../constants";

const defaultForgotPasswordState = {
    wait: false,
    isSent: false,
};

export const forgotPassword = (state = defaultForgotPasswordState, action: AnyAction) => {

    switch (action.type) {
        case USERS_FORGOT_PASSWORD_REQUEST:
            return { isSent: false, wait: true };
        case USERS_FORGOT_PASSWORD_REQUEST_SUCCESS:
            return { isSent: true, wait: false };
        case USERS_FORGOT_PASSWORD_REQUEST_FAIL:
            return { isSent: false, wait: false };
        case USERS_FORGOT_PASSWORD_CLEAR_STATE:
            return { isSent: false, wait: false };
        default:
            return state
    }
};

export const resetPassword = (state = defaultForgotPasswordState, action: AnyAction) => {

    switch (action.type) {
        case USERS_RESET_PASSWORD_REQUEST:
            return { isSent: false, wait: true };
        case USERS_RESET_PASSWORD_REQUEST_SUCCESS:
        case USERS_CREATE_PASSWORD_REQUEST_SUCCESS:
            return { isSent: true, wait: false };
        case USERS_RESET_PASSWORD_REQUEST_FAIL:
            return { isSent: false, wait: false };
        case USERS_RESET_PASSWORD_CLEAR_STATE:
            return { isSent: false, wait: false };
        default:
            return state
    }
};