import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";

interface Props {
    className: string;
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    children: any;
    onClick?: (e: any) => void;
}

const Headline = ({ children, className, onClick, type }: Props) => {
    let Tag = type;
    let classNames = joinClassNames(
        type,
        styles.Headline,
        className
    );

    return (
        <Tag className={classNames} onClick={onClick}>
            {children}
        </Tag>
    );
};

Headline.defaultProps = {
    className: '',
};

export default Headline;
