import React, { useState } from 'react';
import { Marker } from 'react-mapbox-gl';
import { Popover } from '@material-ui/core';

import Flex from '../Flex';
import Popup from './popup';
import { ReactComponent as UserLocation } from '../MapUserMarker/map-pin.svg';

import styles from './index.module.scss';

const MapServiceMarker = ({ item }: any) => {
    const [anchorEl, setAnchorEl] = useState<any>();
    const { title, position, subtitle, id, logo } = item;

    const handleClick = (event: Event) => {
        setAnchorEl(event.currentTarget);
    }

    return (
        <Marker
            coordinates={[position.lng, position.lat]}
            anchor="center" className={styles.marker}
        >
            <>
                <Popover
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                >
                    <Popup
                        title={title}
                        subtitle={subtitle}
                        id={id}
                        logo={logo}
                    />
                </Popover>

                <Flex onClick={handleClick} className={styles.markerWrapper}>
                    <UserLocation />
                </Flex>
            </>
        </Marker>
    )
}

export default MapServiceMarker;
