import {FormConfig} from "../../types/formConfig";
import Validator from "../../validators";
import {
    SERVICE_RATE_MAX,
    SERVICE_RATE_MIN
} from "../../const/validation";
import {floatOnly} from "../../pipes/phone";

const formatRate = (val: any) => {
  let float = floatOnly(val);

  if (float) {
      return `€${float}`
  }

  return float
};

let formConfig: FormConfig = {
    serviceName: {
        name: 'serviceId',
        label: 'Car service name',
        placeholder: 'Car service name',
        validate: [
            Validator.required(),
        ]
    },
    description: {
        name: 'description',
        label: 'Description',
    },
    rate: {
        name: 'rate',
        label: 'Rate',
        placeholder: 'Rate',
        format: formatRate,
        parse: floatOnly,
        maxLength: 8,
        validate: [
            Validator.required(),
            Validator.min(SERVICE_RATE_MIN),
            Validator.max(SERVICE_RATE_MAX)
        ]
    },
};


export default formConfig;

