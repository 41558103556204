import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { HashLink } from 'react-router-hash-link';
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom';

import StoreService from '../../../services/StorageService';
import { KEY_LANGUAGE, KEY_NEW_BOOKING_STEPS, KEY_POTENCIAL_COUNT } from '../../../const/local-storage';
import StatusBar from '../../../components/StatusBar';
import Card from '../../../components/Card';
import Icon from '../../../components/Icon';
import NavBarMobile from '../../../components/NavBarMobile';
import { setLanguage } from '../../../redux/actions/i18n';
import Flex from '../../../components/Flex';
import Dropdown from '../../../components/Dropdown';
import Link from '../../../components/Link';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../../const/languages';
import { ROLE } from '../../../const/users';
import landingMenu from './../menu';
import CarOwnersService from '../../../services/CarOwnersService';

import styles from '../index.module.scss';
import Button from '../../../components/Button';
import useSaveTime from "../../../helpers/hooks/useSaveTime";
const TOW_TRUCK_ID = 6;

const langs = Object.values(LANGUAGES)
const storeService = new StoreService();

interface i18n {
    landing_page: {
        how_it_works: string,
        for_business: string,
    },
    login_page: {
        sign_in: string,
        sign_up: string,
        in_order_to: string,
    },
    find_request_page: {
        we_found: string,
        car_services: string,
        oops: string,
        try_to_set: string,
        potential: string,
        no_services: string,
    },
    common: {
        or: string,
    },
}

interface Props {
    i18n: i18n,
    setLanguage: (lang: string) => void
}

interface Option {
    shortName: string,
    value: string
}

function PotencialServices({ i18n, setLanguage }: Props) {

    const lang = storeService.get(KEY_LANGUAGE, DEFAULT_LANGUAGE);
    const location = useLocation();
    const history = useHistory();

    const { startTimer } = useSaveTime();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [serviceCounter, setServiceCounter] = useState<number>(0);
    const [pickedLang, setPickedLang] = useState(lang.value)

    const filledData = useMemo(() => storeService.get(KEY_NEW_BOOKING_STEPS), [location]);

    const isTowTruck = filledData[0].selectedService.id === TOW_TRUCK_ID;

    const services_not_found = isTowTruck ? i18n.find_request_page.no_services : i18n.find_request_page.try_to_set;
    const textStyles = isTowTruck ? styles.notFound : undefined;

    const getPotencialServices = useCallback(
        async () => {
            try {
                const { data: { count } } = await new CarOwnersService().getPotencialCarServices(filledData);
                setServiceCounter(count);
                storeService.set(KEY_POTENCIAL_COUNT, count)

            } finally {
                startTimer();
                setIsLoading(false)
            }
        },
        [filledData, location, history],
    );

    const handleLanguageChange = (option: Option) => {
        setLanguage(option.shortName);
        setPickedLang(option.value);
        storeService.set(KEY_LANGUAGE, option);
    }

    useEffect(() => {
        getPotencialServices()
    }, [getPotencialServices]);

    return (
        <>
            <StatusBar classNameMenu={styles.spaceBetween} className={styles.statusBar}>
                <ReactLink to='/'>
                    <Icon icon='logoBlack' className={styles.logo} />
                </ReactLink>
                <div className={styles.links}>
                    <HashLink to='/#how-it-works'
                        className={styles.spacing}>{i18n.landing_page.how_it_works}</HashLink>
                    <Link href={'/business'}
                        className={styles.spacing}>{i18n.landing_page.for_business}</Link>
                </div>
                <Flex className={styles.dropdownWrapper}>
                    <Dropdown
                        onChange={handleLanguageChange}
                        value={langs[pickedLang]}
                        options={langs}
                        className={styles.dropdown}
                    />
                    <Link href={'user/sign-in'}
                        className={styles.signInLink}>
                        {i18n.login_page.sign_in}
                    </Link>
                    <Link href={'user/sign-up'}>
                        <button className={styles.signInBtn}>
                            {i18n.login_page.sign_up}
                        </button>
                    </Link>
                </Flex>
            </StatusBar>

            <NavBarMobile
                role={ROLE.USER}
                menu={landingMenu}
                className={styles.potencialMobileMenu}
            />
            {!isLoading &&
                <Card large
                    className={styles.Card}>

                    <section className={styles.headerContainer}>
                        <Icon icon="backArrow" onClick={() => history.goBack()} />
                        <Icon icon="closeArrow" onClick={() => history.push('/')} />
                    </section>

                    <section className={styles.potencialInfoContainer}>
                        <section>
                            <Icon icon='potencialService' />
                            {!!serviceCounter &&
                                <label>
                                    <span>{serviceCounter}</span>
                                </label>
                            }
                        </section>
                        {serviceCounter ?
                            <>
                                <span>{i18n.find_request_page.we_found} <b>{serviceCounter}</b> {i18n.find_request_page.potential}</span>
                                <span>{i18n.find_request_page.car_services}</span>
                            </>
                            :
                            <>
                                <span><b>{i18n.find_request_page.oops}</b></span>
                                <span className={textStyles}>{services_not_found}</span>
                            </>
                        }

                    </section>
                    <section className={styles.buttonsContainer}>
                        <span> {i18n.login_page.in_order_to}</span>
                        <Button
                            color='primary'
                            onClick={() => history.push('/user/sign-up?withBooking=true')}
                        >
                            {i18n.login_page.sign_up}
                        </Button>
                        <span>{i18n.common.or}</span>
                        <Button
                            variant={"outlined"}
                            onClick={() => history.push('/user/sign-in?withBooking=true')}
                        >
                            {i18n.login_page.sign_in}
                        </Button>
                    </section>
                </Card>
            }
        </>
    )
}

interface State {
    i18n: i18n,
}

const mapStateToProps = (state: State) => {
    let { i18n } = state;
    return { i18n };
};

const mapDispatchtoProps = (dispatch: Dispatch) => ({
    setLanguage: setLanguage(dispatch)
})

export default connect(mapStateToProps, mapDispatchtoProps)(PotencialServices)