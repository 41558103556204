/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { IPropsClassName } from "../../types/props";
import { joinClassNames } from "../../helpers/className";
import styles from './index.module.scss'
import Flex from "../Flex";
import Icon from "../Icon";
import { IconType } from "../../types/icon";
import Button from '../Button';

interface IProps extends IPropsClassName {
    icon: IconType;
    text: string;
    text2?: string;
    link?: string;
    buttonCallback?: () => any;
    action?: any;
    accentedText?: string;
    buttonCaption?: string;
    actionButtonDisabled?: boolean;
    alterText?: string
}

const HighlightedMessage = ({ actionButtonDisabled, className, icon, text, text2, accentedText, alterText, link, action, buttonCaption, buttonCallback }: IProps) => {
    return (
        <Flex
            className={joinClassNames(
                styles.HighlightedMessage,
                className,
            )}>

            <Icon icon={icon} />
            <div className={styles.text}>
                {text}
                <b>{accentedText}</b>
                <br />
                {
                    link && <a
                        className={styles.link}
                        href='javascript:void(0)'
                        onClick={action}>
                        {link}
                    </a>
                }
                {text2}
            </div>
            {buttonCaption &&
                <Button
                    className={styles.btn}
                    disabled={actionButtonDisabled}
                    color={'secondary'}
                    onClick={buttonCallback}>
                    {buttonCaption}
                </Button>
            }
            {!buttonCaption && alterText && <div className={styles.alterText}>{alterText}</div>}
        </Flex>
    )
};

export default HighlightedMessage;
