import React from 'react';
// @ts-ignore
import MuiPhoneNumber from 'material-ui-phone-number';

import styles from './index.module.scss';
import inputStyles from '../index.module.scss';
import { joinClassNames } from '../../../helpers/className';

const PhoneInput = ({ isInvalid, withBorder,  ...rest }: any) => (
    <MuiPhoneNumber
        InputProps={ {
            className: joinClassNames(
                inputStyles.input,
                styles.phoneInput,
                isInvalid && inputStyles.invalidControl,
                withBorder && inputStyles.bordered
            )
        } }
        dropdownClass={ styles.dropdown }
        { ...rest }
    />
);

export default PhoneInput;
