import React from "react";
import { connect } from "react-redux";

import CarOwnersService from "../../services/CarOwnersService";
import { closePopup, getMyVehicles } from "../../redux/actions";
import StyledDialogContent from "../../components/Dialog/DialogContent";
import Button from "../../components/Button";
import Flex from "../../components/Flex";
import BasePopup from "../BasePopup";

import styles from './index.module.scss'

class DeleteVehicle extends BasePopup {
    constructor(props: any) {
        super(props);
        this.state = { wait: false };
        this.i18n = props.i18n;
        this.title = ' ';
        this.withDivider = false;
    }

    public deleteVehicle(id: number) {
        let props: any = this.props;
        let { getMyVehicles } = props;

        this.setState({ wait: true });

        new CarOwnersService()
            .deleteVehicles(id)
            .then(() => {
                getMyVehicles();
                this.close();
            })
            .catch(() => this.setState({ wait: false }))
    }

    public renderContent() {
        let props: any = this.props;
        let state: any = this.state;
        let { initialData } = props;
        let { id } = initialData;

        return (
            <StyledDialogContent fullScreen={ true }>
                <Flex className={styles.content}>
                    <Flex className={styles.title}>
                        {this.i18n.popups.delete_vehicle_from_the_list}
                    </Flex>

                    <Flex className={styles.buttons}>
                        <Button
                            fontSize={16}
                            color={"default"}
                            onClick={this.close}
                            variant={"outlined"}>
                            {this.i18n.common.cancel}
                        </Button>

                        <Button
                            fontSize={16}
                            className={styles.btnAccept}
                            disabled={state.wait}
                            color={"danger"}
                            onClick={() => this.deleteVehicle(id)}
                            variant={"text"}>
                            {this.i18n.popups.delete_vehicle}
                        </Button>
                    </Flex>
                </Flex>
            </StyledDialogContent>
        )
    }
}

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getMyVehicles: getMyVehicles(dispatch),
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteVehicle);
