export const CONTRACT_STATUS = {
    Upcoming: 'Upcoming',
    InProgress: 'InProgress',
    Requested: 'Requested',
    WaitingForConfirmation: 'WaitingForConfirmation',
    Confirmed: 'Confirmed',
    Completed: 'Completed',
    CancelledByService: 'CancelledByService',
    CancelledByServiceOwner: 'CancelledByServiceOwner',
    CancelledByCarOwner: 'CancelledByCarOwner',
    DeclinedByServiceOwner: 'DeclinedByServiceOwner',
    DeclinedByCarOwner: 'DeclinedByCarOwner',
    Expired: 'Expired',
    Cancelled: 'Cancelled'
};
