import React from 'react';
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { joinClassNames } from '../../helpers/className';
import { IS_COMPANY } from '../../const/local-storage';
import { ROLE } from '../../const/users';
import StoreService from '../../services/StorageService';
import BranchListDropdown from '../BranchListDropdown';
import NavBarLinkMobile from '../NavBarLinkMobile';
import LanguageToggle from '../LanguageToggle';
import ActionButton from '../ActionButton';
import Flex from '../Flex';
import Divider from '../Divider';
import Icon from '../Icon';
import { closeMenu, openMenu } from "../../redux/actions/menu";

import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

interface Props {
    menu: any[];
    openMenu: () => void;
    closeMenu: () => void;
    isOpenedMenu: boolean;
    className?: string;
    role?: string;
    classNameLink?: string;
    classNameDivider?: string;
    isBranchesMenu?: boolean;
    i18n?: any,
    isFromLanding?: boolean;
}

const NavBarMobile = ({ role, menu, classNameLink, isBranchesMenu, isOpenedMenu, openMenu, closeMenu, className, i18n, isFromLanding }: Props) => {
    const isCompany = new StoreService().get(IS_COMPANY);
    const business = role === ROLE.BUSINESS;
    const open = isOpenedMenu ? 'open' : 'closed';
    const linkActive = joinClassNames(business ? styles.NavBarLinkServiceActive : styles.activeLink);
    const history = useHistory();

    const linkClasses = joinClassNames(
        styles.link,
        classNameLink,
        business ? styles.NavBarLinkService : '');

    const wrapperClasses = joinClassNames(
        styles.wrapper,
        styles[open],
        business ? styles.Service : '',
        className);

    const menuClasses = business ? styles.links : styles.userLinks;

    return (
        <Flex className={wrapperClasses}>
            <Flex className={styles.topBar}>
                {business ?
                    <Flex className={styles.businessLogoWrapper}>
                        <Icon icon='bookmap' className={styles.Logo} /> 
                    </Flex>:
                    <Flex className={styles.userLogoWrapper}>
                        <Icon icon='bookmap' className={styles.userLogo} onClick={() => {
                            closeMenu();
                            if(isFromLanding){
                                history.push('/')
                                return;
                            }
                            history.push('/user/dashboard/booking-history')
                        }} />
                    </Flex>
                }
                <ActionButton className={styles.burger}
                    icon={isOpenedMenu ? 'close' : 'burgerMenu'}
                    onClick={() => isOpenedMenu ? closeMenu() : openMenu()} />
            </Flex>

            {isOpenedMenu && (
                <Flex className={menuClasses}>
                    <LanguageToggle color={!business && 'secondary'} />
                    <Divider className={business ? styles.DividerService : styles.Divider} />
                    {isBranchesMenu && <BranchListDropdown />}
                    {menu.map(link => ((link.children === i18n.branches_page.title && !isCompany)
                        ? null
                        : <NavBarLinkMobile
                            callback={() => closeMenu()}
                            NavBarLinkActiveOverridden={linkActive}
                            className={linkClasses}
                            key={link.href}
                            isBusiness={business && business}
                            {...link} />)
                    )}

                    {!business && !isFromLanding &&
                        <>
                            <NavBarLinkMobile
                                callback={() => closeMenu()}
                                NavBarLinkActiveOverridden={linkActive}
                                className={linkClasses}
                                isBusiness={false}
                                children={i18n.landingCOR.my_profile}
                                Icon={() => <Icon icon={"profileLogo"} />}
                                href="/user/dashboard/settings"
                            />
                            <NavBarLinkMobile
                                callback={() => closeMenu()}
                                NavBarLinkActiveOverridden={styles.disableActiveLink}
                                className={linkClasses}
                                isBusiness={false}
                                children={i18n.landingCOR.back_to_landing}
                                Icon={() => <Icon icon={"cardLink"} />}
                                href="/"
                            />
                            <section className={styles.toBottom}>
                                <Divider className={styles.Divider} />
                                <NavBarLinkMobile
                                    callback={() => closeMenu()}
                                    NavBarLinkActiveOverridden={linkActive}
                                    className={linkClasses}
                                    isBusiness={false}
                                    children={i18n.landingCOR.sign_out}
                                    Icon={() => <Icon icon={"signOut"} />}
                                    href="/sign-out"
                                />
                            </section>
                        </>
                    }
                </Flex>
            )}
        </Flex>
    )
};

const mapStateToProps = ({ isOpenedMenu, i18n }: any) => {
    return { isOpenedMenu, i18n };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openMenu: openMenu(dispatch),
    closeMenu: closeMenu(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarMobile);
