import React from 'react'
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import BasePopup from "../BasePopup";
import { closePopup, getMyVehicles } from "../../redux/actions";
import CarOwnersService from "../../services/CarOwnersService";
import FormControl, { FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import { CarsAutocomplete } from '../../components/CarAutocomplete';
import FormControlInput from "../../components/FormControlInput";
import ErrorMessage from '../../components/ErrorMessage';
import ActionButton from '../../components/ActionButton';
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Flex from '../../components/Flex';
import formInternalization from "../../utils/form-internalization";
import { joinClassNames } from '../../helpers/className';
import { FUEL_TYPES, GEARBOX } from "../../const/validation";
import formConfig from './form-config'

import styles from './index.module.scss'

export const clearEmptyFields = (model: any) => {
    Object.keys(model).forEach(key => {
        if (!model[key]) {
            delete model[key]
        }
    });

    return model;
};

const initialValues = {
    registrationNumber: '',
    brand: '',
    model: '',
    year: '',
    mileage: '',
    enginePower: '',
    fuelType: null,
    gearbox: null,
    volume: '',
};

class AddVehicle extends BasePopup {
    props: any;
    id?: number;

    constructor(props: any) {
        super(props);
        this.title = props.i18n.popups.add_new_vehicle;
        this.className = styles.Card;
        this.state = {
            wait: false,
            inputsDisabled: false,
            brandId: '',
            modelName: '',
            isBrandTouched: false,
            isModelTouched: false,
            brandDropdownValue: ''
        };
        this.withDivider = false;

        let { initialData = {} } = props;
        let { vehicle } = initialData;

        // Edit popup
        if (vehicle) {
            this.id = vehicle.id;
            this.title = props.i18n.popups.edit_vehicle;

            Object.keys(initialValues).forEach(key => {
                props.change(key, vehicle[key] || '')
            });
        }
    }

    handleBrandSelect = (val: any) => {
        const { name, id } = val;
        this.setState({ brandId: id, brandDropdownValue: name })
        this.props.change('brand', name);
    }

    handleModelSelect = (val: any) => {
        const { name } = val;
        this.setState({ modelName: name });
        this.props.change('model', name);
    }

    fillModel = (model: any) => {
        const { change } = this.props;
        Object.keys(model).map(item => change(item, model[item]));
        this.setState({ inputsDisabled: true })
    }

    findMyCar = () => {
        const { $values } = this.props;

        new CarOwnersService().findCar($values.registrationNumber.toUpperCase())
            .then(({ data }: any) => this.fillModel(data))
    }

    save(model: any) {
        let _model = clearEmptyFields({ ...model });
        let carOwnersService = new CarOwnersService();

        this.setState({
            wait: true
        });

        let promise = this.id ?
            carOwnersService.editVehicles(this.id, _model) :
            carOwnersService.addVehicles(_model);

        promise
            .then(() => {
                this.close();
                this.props.getMyVehicles();
            })
            .catch(() => this.setState({ wait: false }))
    }

    renderContent() {
        let props: any = this.props;
        let state: any = this.state;
        let { invalid, $values, i18n = {} } = props;
        let isEdit: boolean = !!this.id;
        const fuelTypes = FUEL_TYPES.map(type => ({ name: i18n.fuels[type], id: type }));
        const gearboxTypes = GEARBOX.map(type => ({ name: i18n.gears[type], id: type }));

        let fuelType = fuelTypes.filter((type: any) => type.id === $values.fuelType)[0] || null;
        let gearbox = gearboxTypes.filter((type: any) => type.id === $values.gearbox)[0] || null;
        const hasValues = !!$values && $values.registrationNumber;
        const { inputsDisabled, brandId } = state;
        const formClasses = joinClassNames(styles.offsetTop, styles.form);
        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={props.handleSubmit}
                        className={formClasses}>
                        <Flex className={styles.findCarWrapper}>
                            <FormControlInput
                                withBorder='true'
                                {...formConfig.registrationNumber}
                                className={styles.formControlLarge} />

                            <ActionButton onClick={this.findMyCar}
                                className={styles.findCar}
                                color='secondary'
                                disabled={hasValues && $values.registrationNumber.length < 4}>
                                {i18n.popups.find_your_car}
                            </ActionButton>
                        </Flex>

                        <Flex className={styles.carInfo}>
                            <FormControl {...formConfig.brand}
                                className={styles.formControl}
                            >
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <CarsAutocomplete
                                    is={FORM_CONTROL_ELEMENT.INPUT}
                                    value={$values.brand}
                                    disabled={inputsDisabled}
                                    onSelect={(val) => this.handleBrandSelect(val)} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <FormControl {...formConfig.model}
                                className={styles.formControl}>
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <CarsAutocomplete
                                    is={FORM_CONTROL_ELEMENT.INPUT}
                                    value={state.model}
                                    lookingFor='model'
                                    disabled={!brandId || inputsDisabled}
                                    onSelect={(val) => this.handleModelSelect(val)}
                                    id={brandId} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>
                        </Flex>

                        <Flex className={styles.dropdownsWrapper}>
                            <Flex className={styles.dropdownWraper}>
                                <Label>{formConfig.fuelType.label}</Label>
                                <Dropdown
                                    className={styles.dropDown}
                                    onChange={(option: any) => {
                                        let { id } = option;
                                        props.change('fuelType', id);
                                    }}
                                    disabled={inputsDisabled}
                                    options={fuelTypes}
                                    value={fuelType} />
                            </Flex>
                            <Flex className={styles.dropdownWraper}>
                                <Label>{formConfig.gearbox.label}</Label>
                                <Dropdown
                                    className={styles.dropDown}
                                    onChange={(option: any) => {
                                        let { id } = option;
                                        props.change('gearbox', id);
                                    }}
                                    disabled={inputsDisabled}
                                    options={gearboxTypes}
                                    value={gearbox} />
                            </Flex>
                        </Flex>

                        <Flex className={styles.carInfoDetails}>
                            <Flex className={styles.carInfoDetailsSection}>
                                <FormControlInput
                                    withBorder
                                    {...formConfig.year}
                                    disabled={inputsDisabled}
                                    className={styles.formControlTiny} />

                                <FormControlInput
                                    withBorder  {...formConfig.mileage}
                                    disabled={inputsDisabled}
                                    className={styles.formControlTiny} />
                            </Flex>

                            <Flex className={styles.carInfoDetailsSection}>
                                <FormControlInput
                                    withBorder disabled={inputsDisabled}
                                    {...formConfig.volume}
                                    className={styles.formControlTiny} />

                                <FormControlInput
                                    withBorder disabled={inputsDisabled}
                                    {...formConfig.enginePower}
                                    className={styles.formControlTiny} />
                            </Flex>
                        </Flex>
                    </form>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Flex className={styles.buttons}>
                        <Button large={true}
                            color='alter'
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            disabled={invalid || state.wait}
                            onClick={() => this.save($values)}
                            variant='text'>
                            {isEdit ? i18n.common.save_changes : i18n.vehicles.add_a_new_vehicle}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();
const Popup: any = AddVehicle;

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n }
};
const mapDispatchToProps = (dispatch: any) => ({
    getMyVehicles: getMyVehicles(dispatch),
    closePopup: closePopup(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
        form,
        initialValues,
    })(Popup)
);
