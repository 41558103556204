import React from 'react'
import { connect } from 'react-redux';
import Icon from '../../components/Icon';
import {
    Dialog,
    useMediaQuery,
} from '@material-ui/core';
import StorageService from '../../services/StorageService';
import VerificationService from '../../services/VerificationService';
import { KEY_USER_INFO } from '../../const/local-storage';
import { MOBILE_BP } from '../../const/users';
import styles from './index.module.scss';
import ButtonWithTimer from '../../components/Button/buttonWithTimer';

const storeService = new StorageService();
const verificationService = new VerificationService();

interface StateProps {
    bookings: {
        offer_accepted: string,
        offer_accepted_descr: string,
        email_not_validated: string,
        email_not_validated_descr: string,
        offer_accepted_validate: string,
    },
    common: {
        resend_link: string,
        sec: string,
    }
}

interface IProps {
    toggle: (values: boolean) => void,
    isOpen: boolean,
    isFromOffers?: boolean,
    i18n: StateProps,
}

function NotVerifiedEmailPopup({ isOpen, toggle, i18n, isFromOffers = false }: IProps) {

    const { common, bookings } = i18n;
    const isMobile = useMediaQuery(MOBILE_BP);

    const userInfo = storeService.get(KEY_USER_INFO, {})

    const handleResendLink = async () => {
        try {
            await verificationService.resendConfirmEmail({ email: userInfo.email })
        } finally {
            //
        }
    }

    return (
        <Dialog open={isOpen} fullScreen={isMobile}>
            <section className={styles.dialogContent}>
                <Icon icon={isFromOffers ? "successMessage" : "dangerMessage"} className={styles.successIcon} />
                <Icon icon="closeX" className={styles.closeModal} onClick={toggle} />
                <span className={styles.modelHeader}>
                    {isFromOffers ? bookings.offer_accepted : bookings.email_not_validated}
                </span>
                <span className={styles.modalDescription}>
                    {isFromOffers &&
                        <section className={styles.iconWrapper}>
                            <Icon icon="smallAlert" />
                            <span>{bookings.offer_accepted_validate}</span>
                        </section>
                    }
                    {isFromOffers ? bookings.offer_accepted_descr : bookings.email_not_validated_descr}
                </span>
                <ButtonWithTimer
                    sectionClassName={styles.timerSection}
                    buttonStyles={styles.modalButton}
                    timerStyles={styles.timer}
                    action={handleResendLink}
                    buttonText={common.resend_link}
                    timerText={common.sec}
                />
            </section>
        </Dialog>
    );
}

interface State {
    i18n: StateProps,
}

const mapStateToProps = ({ i18n }: State) => {
    return {
        i18n,
    }
}
export default connect(mapStateToProps)(NotVerifiedEmailPopup);