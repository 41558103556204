import * as firebase from 'firebase/app';
import "firebase/messaging";

import Http from "./HttpService";

const config: any = {
    appId: process.env.REACT_APP_FIREBASE_ID,
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export default class FirebaseService extends Http {
    readonly messaging: any;
    readonly fb: any;
    isSupported: boolean

    private permission: NotificationPermission = "default";
    private isTokenSentToServer = false;

    constructor() {
        super();
        this.fb = firebase.initializeApp(config);
        this.isSupported = firebase.messaging.isSupported();
        if (this.isSupported) {
            this.messaging = firebase.messaging();

            try {
                this.requestPermission();
            } catch (e) {
                console.log(e)
            }
        }
    }

    requestPermission(): void {
        Notification.requestPermission()
            .then((permission: NotificationPermission) => {
                this.permission = permission;
                if (this.permission !== 'granted') {
                    console.log('Unable to get permission to notify.');
                }
            });
    }

    setTokenSentToServer(isSet: boolean) {
        this.isTokenSentToServer = isSet;
    }

    registerTokenRefresh(): void {
        this.messaging.onTokenRefresh(() => {
            this.messaging.getToken()
                .then((refreshedToken: string) => {
                    console.log('Token refreshed.');
                    // Indicate that the new Instance ID token has not yet been sent to the app server.
                    this.setTokenSentToServer(false);
                    // Send Instance ID token to app server.
                    return this.sendTokenToServer(refreshedToken);
                })
                .catch((err: any) => {
                    console.log('Unable to retrieve refreshed token ', err);
                });
        });
    }

    sendTokenToServer(deviceToken: string) {
        return this.patch('/settings/me/device', { deviceToken })
            .then(() => {
                console.log('Device registered ');
                this.setTokenSentToServer(true)
            });
    }

    deleteToken() {
        this.messaging.getToken()
            .then((currentToken: string) => {
                this.messaging.deleteToken(currentToken)
                    .then(() => {
                        this.setTokenSentToServer(false)
                    })
                    .catch((err: any) => {
                        console.log('Unable to delete token. ', err);
                    });
            })
            .catch((err: any) => {
                console.log('Error retrieving Instance ID token. ', err);
            });

    }

    registerDevice(): void {
        this.messaging.getToken()
            .then((deviceToken: string) => {
                if (deviceToken) {
                    return this.sendTokenToServer(deviceToken);
                } else {
                    // Show permission request.
                    console.log('No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    this.setTokenSentToServer(false);
                }
            })
            .then(() => {
                this.registerTokenRefresh();
                this.setTokenSentToServer(true);
            })
            .catch((err: any) => {
                console.log('An error occurred while retrieving token. ', err);
                this.setTokenSentToServer(false);
            });
    }
}