export function msToTime(s: number) {
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;

    let hh = hrs < 10 ? '0' + hrs : hrs;
    let mm = mins < 10 ? '0' + mins : mins;

    return hh + ':' + mm;
}

export function timeToMs(timesInMs: any) {
    timesInMs = timesInMs.split(' - ');

    timesInMs = timesInMs.map((time: string) =>
        +(time && time.slice(0, 2)) * 1000 * 60 * 60
    );

    return [...timesInMs];
}
