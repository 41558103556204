import * as React from 'react';
import uuid from 'uuid/v4';

import Flex from '../../components/Flex';
import Headline from '../../components/Headline';
import { Divider } from '@material-ui/core';
import { ActionButtonClose } from '../../components/ActionButton';
import { joinClassNames } from '../../helpers/className';
import StyledDialogTitle from '../../components/Dialog/DialogTitle';

import Classes from '../../scss';
import styles from './index.module.scss';

class BasePopup extends React.Component<any, any> {
    title: any = '';
    className: string = '';
    i18n: any;
    withDivider: boolean = true;

    constructor(props: any) {
        super(props);
        this.title = props.title || 'Title';
    }

    static generateKey = () => uuid();

    public close = () => {
        let props: any = this.props;
        props.closePopup(props.uuid);
    };

    public renderContent(): any {
        return null;
    }

    render() {
        const props: any = this.props;
        const { initialData = {} } = props;
        const { alterTitle } = initialData;

        return (
            <>
                <StyledDialogTitle>
                    <Flex className={joinClassNames(
                        Classes.textCenter,
                        Classes.spaceBetween,
                    )}>
                        {typeof (this.title) === 'string'
                            ? <Headline type={'h5'} className={alterTitle ? styles.alterHeadline : styles.headline}>
                                {this.title}
                            </Headline>
                            : this.title
                        }
                        <ActionButtonClose onClick={this.close} className={styles.close}> </ActionButtonClose>
                    </Flex>
                </StyledDialogTitle>
                {this.withDivider && < Divider />}
                {this.renderContent()}
            </>
        );
    }
}

export default BasePopup;
