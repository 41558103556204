import { Dispatch } from "redux";
import BranchesService from "../../services/BranchesService";
import {
    GET_BRANCHES,
    GET_BRANCHES_SUCCESS,
    GET_BRANCHES_FAIL,

    GET_BRANCH_CONTRACTS,
    GET_BRANCH_CONTRACTS_SUCCESS,
    GET_BRANCH_CONTRACTS_FAIL,

    GET_BRANCH_REQUESTS,
    GET_BRANCH_REQUESTS_SUCCESS,
    GET_BRANCH_REQUESTS_FAIL,
} from "../constants";

export const getBranches = (dispatch: Dispatch) => (offset = 0) => {

    dispatch({ type: GET_BRANCHES });

    return new BranchesService().getBranches(offset)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_BRANCHES_SUCCESS, payload }))
        .catch((error: any) => {
            dispatch({ type: GET_BRANCHES_FAIL });
        })
};

export const getBranchContracts = (dispatch: Dispatch) => (id: number, model: any) => {

    dispatch({ type: GET_BRANCH_CONTRACTS });

    return new BranchesService().getBranchContracts(id, model)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_BRANCH_CONTRACTS_SUCCESS, payload }))
        .catch((error: any) => {
            dispatch({ type: GET_BRANCH_CONTRACTS_FAIL })
        })
}

export const getRequests = (dispatch: Dispatch) => (id: number, model: any) => {

    dispatch({ type: GET_BRANCH_REQUESTS });

    return new BranchesService().getBranchRequests(id, model)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_BRANCH_REQUESTS_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_BRANCH_REQUESTS_FAIL }))
};
