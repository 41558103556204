import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { buildStyles } from "react-circular-progressbar";
import { connect } from 'react-redux';

import Flex from "../../../../../components/Flex";
import { IPropsClassName } from '../../../../../types/props';

import styles from './index.module.scss';
import 'react-circular-progressbar/dist/styles.css';

const PROGRESS_BAR_STYLE = { pathColor: '#ffdf66', trailColor: 'rgba(255, 223, 102, 0.3)', textColor: '#2A2728', textSize: '22px' };

interface IProps extends IPropsClassName {
    activePage?: any;
    captions?: any;
}

const RequestHeader = ({ activePage, captions }: IProps) => (
    <div>
    <Flex className={styles.headingCard}>
        <CircularProgressbar
            strokeWidth={3}
            className={styles.progressBar}
            value={(activePage + 1) * 100 / captions.length}
            text={`${activePage + 1} of ${captions.length}`}
            styles={buildStyles(PROGRESS_BAR_STYLE)} />
        <div>
            <div className={styles.title}>{captions[activePage]?.header}</div>
            <div className={styles.text}>{captions[activePage]?.description}</div>
        </div>
    </Flex>
    <div className={styles.line}></div>
    </div>
);

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n };
}

export default connect(mapStateToProps, null)(RequestHeader);
