export const etaHours = [
    { value: 0, name: '0' },
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
    { value: 4, name: '4' },
    { value: 5, name: '5' },
    { value: 6, name: '6' },
    { value: 7, name: '7' },
    { value: 8, name: '8' },
]

export const etaMinutes = [
    { value: 1, name: '00' },
    { value: 2, name: '10' },
    { value: 3, name: '20' },
    { value: 4, name: '30' },
    { value: 5, name: '40' },
    { value: 6, name: '50' },
]
