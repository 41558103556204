import {SERVICE_CENTER_DESC_MAX_LENGTH, SERVICE_CENTER_DESC_MIN_LENGTH} from "../../const/validation";
import {trimLeft} from "../../pipes/trim";
import Validator from "../../validators";
import {FormConfig} from "../../types/formConfig";

let formConfig: FormConfig = {
    descriptionEe: {
        name: 'descriptionEe',
        label: '',
        placeholder: '',
        maxLength: SERVICE_CENTER_DESC_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.minLength(SERVICE_CENTER_DESC_MIN_LENGTH)
        ]
    },
    descriptionRu: {
        name: 'descriptionRu',
        label: '',
        placeholder: '',
        maxLength: SERVICE_CENTER_DESC_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.minLength(SERVICE_CENTER_DESC_MIN_LENGTH)
        ]
    },
    descriptionEn: {
        name: 'descriptionEn',
        label: '',
        placeholder: '',
        maxLength: SERVICE_CENTER_DESC_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.minLength(SERVICE_CENTER_DESC_MIN_LENGTH)
        ]
    }
};

export default formConfig;
