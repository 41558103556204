import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';

import ContractsService from '../../../services/ContractsService';
import Icon from '../../Icon';
import Flex from '../../Flex';
import Button from '../../Button';
import { joinClassNames } from '../../../helpers/className';
import { formatPrice } from '../../../pipes/price';
import { PRICE_FORMAT } from '../../../const/price-formats';
import { STATUS_OFFER } from '../../../const/offer-status';
import { OFFER_DETAILS_POPUP } from '../../../popups';
import { openPopup } from '../../../redux/actions';
import { TIME_PATTERN, DAY_PATTERN } from '../../../const/date-time-format';

import styles from '../index.module.scss';
import { useHistory } from 'react-router-dom';

const Popup = (props: any) => {
    const { logo, companyName, branchName, className, id, openPopup, serviceName, date, eta, price, priceFormat, refresh, i18n, status } = props;
    const classes = joinClassNames(className, styles.popover);
    const format = priceFormat === PRICE_FORMAT.FIXED ? PRICE_FORMAT.FIXED : PRICE_FORMAT.PER_HOUR;
    const { bookings, common } = i18n;
    const isDeclined = status === STATUS_OFFER.DeclinedByCarOwner || status === STATUS_OFFER.DeclinedByServiceOwner;
    const history = useHistory();

    const declinedText = status === STATUS_OFFER.DeclinedByServiceOwner
        ? bookings.service_center_declined_your_request
        : bookings.CancelledByServiceOwner;

    const showDetails = () => {
        !isDeclined && openPopup(OFFER_DETAILS_POPUP, { id, history })
    }

    let Logo = <Icon icon='sorPlaceholder' className={styles.logo} />;
    if (logo)
        Logo = <img src={logo.compactPath} alt='logo' className={styles.logo} />;


    const declineOffer = (event: Event) => {
        event.stopPropagation()

        new ContractsService()
            .declineOffer(id)
            .then(() => refresh());
    };

    const acceptOffer = (event: Event) => {
        event.stopPropagation()

        new ContractsService()
            .acceptOffer(id)
            .then(() => refresh());
    };

    return (
        <Flex className={classes} onClick={showDetails}>
            <Flex>
                {Logo}
                <div className={styles.serviceInfo}>
                    <p className={styles.companyName}>{companyName}</p>
                    <p style={{ lineHeight: '26px', padding: '1px 5px 0' }}>{' · '}</p>
                    <p className={styles.companyName}>{branchName}</p>
                </div>
            </Flex>
            <div className={styles.serviceName}>{serviceName}</div>
            <Flex className={styles.date}>{date ? moment(date).local().format(DAY_PATTERN) : <>&nbsp;</>}</Flex>
            <Flex className={joinClassNames(styles.price, styles.date)}>
                <div className={styles.date}>
                    {eta ? `ETA ${(eta.slice(0, 5))}` : moment(date).local().format(TIME_PATTERN)}
                </div>
                <div className={styles.priceValue}>{formatPrice(price, format)}</div>
            </Flex>

            {status === STATUS_OFFER.WaitingForAcceptance
                ? <Flex className={styles.buttons}>
                    <Button color='danger' variant='outlined' large={false} small onClick={declineOffer}>{bookings.decline}</Button>
                    <Button color='alter' small large={false} onClick={acceptOffer}>{common.accept}</Button>
                </Flex>
                : isDeclined
                    ? <span className={styles.declined}>{declinedText}</span>
                    : <span className={styles.buttons}>{bookings.waiting_for_confirmation_progress}</span>
            }
        </Flex >
    )
}

const mapStateToProp = (state: any) => {
    const { i18n } = state;
    return { i18n }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openPopup: openPopup(dispatch)
})

export default connect(mapStateToProp, mapDispatchToProps)(Popup);
