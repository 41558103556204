import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import FormControl, { FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import formInternalization from "../../utils/form-internalization";
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import ErrorMessage from '../../components/ErrorMessage';
import { joinClassNames } from '../../helpers/className';
import TextareaField from '../../components/Textarea';
import { closePopup } from '../../redux/actions';
import Button from '../../components/Button';
import Label from '../../components/Label';
import Flex from '../../components/Flex';
import BasePopup from '../BasePopup';
import formConfig from './form-config';

import styles from './index.module.scss';

class ReportPopup extends BasePopup {
    props: any;
    callback: any;
    withDivider = false;
    validation: any;
    buttonText: string;

    constructor(props: any) {
        super(props);
        const { initialData = {}, i18n: { bookings, common } } = props;
        const { callback } = initialData;
        const { status } = initialData;
        const isInProgress = status === 'InProgress';

        this.callback = callback;

        this.title = isInProgress ? bookings.Finish_booking : bookings.add_report;
        this.buttonText = isInProgress ? common.finish : common.submit;
        this.validation = isInProgress ? formConfig.finishReport : formConfig.addReport;
    }

    send = (model: any) => {
        this.callback(model);
        this.close();
    }

    renderContent() {
        const props: any = this.props;
        const { i18n: { popups, common }, $values, invalid } = props;
        const { send, close, buttonText } = this;

        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    <div className={styles.subtitle}>
                        {popups.if_so}
                    </div>
                    <form className={styles.form}>
                        <FormControl {...this.validation}
                            className={joinClassNames(styles.descControl, styles.offsetTop)}>
                            <Label>{common.write_a_report}</Label>
                            <TextareaField
                                color={'primary'}
                                className={styles.desc}
                                is={FORM_CONTROL_ELEMENT.TEXTAREA}
                            />
                            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                        </FormControl>
                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Flex className={styles.buttons}>
                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            onClick={close}
                            variant={"outlined"}>
                            {popups.nevermind}
                        </Button>

                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            disabled={invalid}
                            onClick={() => send($values)}
                            color='alter'
                            type={'submit'}
                            variant={"text"}>
                            {buttonText}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    let { i18n } = state;
    return { $values: values, i18n };
};

export default reduxForm({
    form,
    initialValues: {
        report: '',
    }
})(
    connect(mapStateToProps, mapDispatchToProps)(ReportPopup)
);