import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import BasePopup from '../BasePopup';
import moment from 'moment';

import { closePopup } from '../../redux/actions';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import Flex from '../../components/Flex';
import Button from '../../components/Button';
import MultiDatePicker from '../../components/MultiDatePicker';
import { default as FormControl, FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import ErrorMessage from '../../components/ErrorMessage';
import Input from '../../components/Input';
import Label from '../../components/Label';
import ContractsService from '../../services/ContractsService';
import formInternalization from '../../utils/form-internalization';
import { LONG_FORMAT, LONG_WITH_TIME_FORMAT } from '../../const/date-time-format';
import { joinClassNames } from '../../helpers/className';
import formConfig from './form-config';

import styles from './index.module.scss';

const MAX_COUNT_OF_DATE = 1;

class OfferTimePopup extends BasePopup {
    props: any;
    id: number;
    model: any;
    dateStart: any[];
    callback: any;
    availableDates: any[]

    constructor(props: any) {
        super(props);
        let { initialData, i18n } = props;
        let { id, callback, availableDates } = initialData;
        this.availableDates = availableDates;
        this.className = styles.Card;
        this.dateStart = [];
        this.callback = callback;
        this.id = id;
        this.i18n = i18n;
        this.title = i18n.bookings.offer_time;
        this.withDivider = false;
    }

    send(model: any) {
        const requestModel = {
            start: moment(moment(model.startDate[0].startDate).format(LONG_FORMAT) + ' ' + model.startTime, LONG_WITH_TIME_FORMAT)
                .toISOString(false),
        } as any;

        new ContractsService().createOffer(this.id, requestModel)
            .then(() => {
                this.close();
                this.callback();
            });
    }

    renderContent() {
        const { $values, invalid, change } = this.props;
        formInternalization(formConfig);
        let processedAvailableDates = this.availableDates.map((item: any) => (item.dateStart));

        return (
            <>
                <StyledDialogContent>
                    <form className={styles.form}>
                        <div className={styles.datePickerInput}>
                            <label className={styles.label}>{this.i18n.common.date}</label>
                            <MultiDatePicker
                                color={'primary'}
                                maxCount={MAX_COUNT_OF_DATE}
                                dates={this.dateStart}
                                suggestedEndTime={this.availableDates[0].dateEnd}
                                onChange={(currentDates) => {
                                    change('startDate', currentDates);
                                    this.dateStart = currentDates;
                                }}
                                suggestedDates={processedAvailableDates}
                                isOfferTime={true}
                                onCancel={() => this.close()}
                                invalid={invalid}
                                offerTimeSubmit={(currentDates) => {
                                    change('startDate', currentDates[0].startDate)
                                    this.send({ startDate: currentDates, startTime: $values.startTime })
                                }}
                            />
                        </div>

                        <div style={{ display: 'none' }}>
                            <FormControl {...formConfig.startTime} >
                                <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                                <Input iconRight
                                    withBorder
                                    icon={'clockGray'}
                                    color={'primary'}
                                    is={FORM_CONTROL_ELEMENT.INPUT} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>
                        </div>

                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Flex className={joinClassNames(styles.offsetTop, styles.btnWrap)}>
                        <Button
                            small={true}
                            large={false}
                            onClick={this.close}
                            className={styles.btn}
                            variant={'outlined'}>
                            {this.i18n.common.cancel}
                        </Button>

                        <Button
                            small={true}
                            large={false}
                            color={'alter'}
                            type={'submit'}
                            onClick={() => { this.send($values) }}
                            disabled={
                                invalid ||
                                this.dateStart.length === 0}
                            className={styles.btn}
                            variant={'text'}>
                            {this.i18n.popups.submit}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n };
};

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
        form,
        initialValues: {
            startDate: '',
            startTime: '',
        },
    })(OfferTimePopup)
);
