import React, { useState } from 'react'
import { connect } from 'react-redux';

import Input from "../Input";
import { IPropsClassName } from "../../types/props";
import { MapAutocompleteService } from "../../services/AutocompleteService";
import { onKeyPressFilterKeys } from '../../utils/key-press-filter';

import styles from './index.module.scss'

interface IProps extends IPropsClassName {
    value?: string;
    inputProps?: any;
    onChange?: (val: any) => any;
    onSelect: (val: any) => any;
    isNamed?: boolean;
    color?: 'secondary' | 'primary';
    i18n?: any;
    searchOptions?: Object;
}

const MapAutocomplete = ({ onSelect, value, onChange, color, i18n, searchOptions }: IProps) => {
    let [suggestions, setSuggestions] = useState([]);
    const handleChange = (e: any) => {
        let { value } = e.target;
        if (onChange) {
            onChange(value);
        }

        MapAutocompleteService.getLocationByAddress(value, searchOptions).then((e: any) => {
            setSuggestions(e);
        });
    };

    const handleSelect = (suggestion: any) => {
        if (onChange) {
            onChange(suggestion.place_name);
        }

        onSelect({
            title: suggestion.place_name,
            center: {
                lat: suggestion.center[1],
                lng: suggestion.center[0]
            }
        });
    }

    const handleEnterKeyPress = (event: Event) => {
        event.preventDefault();

        if (suggestions && suggestions.length) {
            handleSelect(suggestions[0])
            setSuggestions([]);
        }
    }

    return (
        <div className={ styles.wrap }>
            <Input
                onKeyPress={ onKeyPressFilterKeys(['Enter'], handleEnterKeyPress) }
                wrapClassName={ styles.icon }
                withBorder={ true }
                color={ color }
                icon='search'
                className={ styles.input }
                placeholder={ i18n.common.search + '...' }
                value={ value }
                onBlur={ () => setTimeout(() => setSuggestions([]), 1000) }
                onChange={ handleChange }/>
            { suggestions && suggestions.length > 0 && <div className={ styles.listResults }>
                { suggestions.map((suggestion: any) => {
                    return (
                        <div key={ suggestion.id } className={ styles.item }
                             onClick={ () => handleSelect(suggestion) }>
                            { suggestion.place_name }
                        </div>
                    );
                }) }
            </div> }
        </div>
    )
};

MapAutocomplete.defaultProps = {};

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n };
}

export default connect(mapStateToProps, null)(MapAutocomplete);
