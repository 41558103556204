import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

import styles from './index.module.scss';
import StoreService from "../../services/StorageService";
import { KEY_ROLE } from "../../const/local-storage";
import { ROLE } from "../../const/users";

const ColorCircularProgress = withStyles({
    colorPrimary: {
        color: '#777',
    },
    colorSecondary: {
        color: '#777',
    },
})(CircularProgress);

const Loader = () => {
    const sessionStorage = new StoreService();
    const isBusinessRole = sessionStorage.get(KEY_ROLE) === ROLE.BUSINESS;
    let color: any = 'primary';
    if (!!sessionStorage.get(KEY_ROLE)) {
        color = isBusinessRole ? 'primary' : 'secondary';
    }

    return (
        <div className={styles.Loader}>
            <ColorCircularProgress color={color} />
        </div>
    );
};

export default Loader;