import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Clear";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Input from "../../../../components/Input";
import FormControl, {
  FORM_CONTROL_ELEMENT,
} from "../../../../components/FormControl";
import ErrorMessage from "../../../../components/ErrorMessage";
import { formatTimeSimple } from "../../../../pipes/time";
import { SIMPLE_TIME_MAX_LENGTH } from "../../../../const/validation";
import Validator from "../../../../validators";
import { joinClassNames } from "../../../../helpers/className";
import { TABLET_BP } from "../../../../const/users";
import formInternalization from "../../../../utils/form-internalization";
import { DAY_PATTERN, TIME_PATTERN } from "../../../../const/date-time-format";

import inputStyles from "../../../OfferTimePopup/index.module.scss";

const styles = (theme) => ({
  root: {
    width: "366px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
  },
  rootMobile: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    margin: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
  },
  subheading: {
    margin: theme.spacing(2),
    marginTop: "-14px",
  },
  list: {
    flex: "1",
    overflowY: "auto",
  },
  colorBlack: {
    color: "#2A2728",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  message: {
    color: "#9D9FA2",
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "18px",
  },
  gray: {
    color: "#9D9FA2",
    display: "flex",
    marginTop: "10px",
  },
  sorMessage: {
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "18px",
    maxWidth: "245px",
  },
});

const DateDisplay = (props) => {
  const {
    classes,
    selectedDates,
    readOnly,
    children,
    monthDaySelected,
    onRemoveAtIndex,
    selectedDatesTitle,
    suggestedDates,
    suggestedEndTime,
    isOfferTime,
    i18n,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [selectedYear, setSelectedYear] = useState(false);
  const totalSuggestions = suggestedDates && suggestedDates.length;
  const matches = useMediaQuery(TABLET_BP);

  useEffect(() => {
    if (!monthDaySelected) {
      setSelectedYear(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef();

  const getFormattedDate = (date) => {
    return moment(date).format("ll");
  };

  const removeDateAtIndex = (index) => () => {
    onRemoveAtIndex(index);
  };

  const timeInputFilled =
    inputRef && inputRef.current && inputRef.current.value.length > 4;

  const formConfig = {
    startTime: {
      name: "startTime",
      label: "Start time",
      placeholder: "Time",
      format: formatTimeSimple,
      maxLength: SIMPLE_TIME_MAX_LENGTH,
      validate: [Validator.required(), Validator.serviceOwnerTimeSimple()],
    },
  };

  const userStartTime =
    !!suggestedDates && moment(suggestedDates[0]).format(TIME_PATTERN);
  const userEndTime =
    !!suggestedEndTime && moment(suggestedEndTime).format(TIME_PATTERN);

  const isEqual = userStartTime === userEndTime;
  const usersTime = isEqual ? (
    i18n.datepicker.anyTime
  ) : (
    <>
      {" "}
      {userStartTime}-{userEndTime}
    </>
  );
  formInternalization(formConfig);

  return (
    <div className={!matches ? classes.root : classes.rootMobile}>
      {!suggestedDates && (
        <>
          <div className={classes.header}>
            <Typography variant="subtitle1" className={classes.colorBlack}>
              {selectedDatesTitle}
            </Typography>
            <Typography
              variant="subtitle1"
              color={readOnly ? "textSecondary" : "primary"}
            >
              {selectedDates.length}
            </Typography>
          </div>
          {!suggestedDates && (
            <div className={classes.subheading}>
              <p className={classes.message}>
                {i18n.datepicker.you_can_choose}{" "}
                <strong>{i18n.datepicker.cor_limit}</strong>
              </p>
            </div>
          )}

          <List dense className={classes.list}>
            {selectedDates.map((date, index) => (
              <ListItem
                key={`${date.toString()}`}
                button={readOnly}
                disabled={readOnly}
                onClick={removeDateAtIndex(index)}
              >
                <ListItemText primary={getFormattedDate(date)} />
                {!readOnly && <DeleteIcon color="error" className={inputStyles.cursor}/>}
              </ListItem>
            ))}
          </List>
        </>
      )}

      {!!suggestedDates && (
        <div style={{ overflow: "visible" }}>
          <div className={classes.header}>
            <Typography variant="subtitle1" className={classes.colorBlack}>
              {i18n.datepicker.suggested}
            </Typography>
          </div>

          <div style={{ padding: "0 16px" }}>
            <h6 className={classes.suggestedDatesTitle}>
              {i18n.datepicker.clientDates}:
            </h6>
            <div className={classes.suggestedDates}>
              {suggestedDates.map(
                (date, index) =>
                  moment(date).format(DAY_PATTERN) +
                  (index + 1 < totalSuggestions ? ", " : ". ")
              )}
            </div>
            <h6
              className={classes.suggestedDatesTitle}
              style={{ marginTop: "12px" }}
            >
              {i18n.datepicker.clientTime}:
            </h6>
            <div className={classes.suggestedDates}>{usersTime}</div>
            <div className={classes.gray}>
              *
              <div className={classes.sorMessage}>
                {i18n.datepicker.sor_choose_time}
              </div>
            </div>
            {selectedDates && selectedDates[0] && (
              <div>
                <h6
                  className={classes.suggestedDatesTitle}
                  style={{ marginTop: "16px" }}
                >
                  {i18n.datepicker.ourDate}:
                </h6>
                <div className={classes.suggestedDates}>
                  {moment(selectedDates[0]).format(DAY_PATTERN)}
                </div>

                {timeInputFilled && (
                  <>
                    <h6
                      className={classes.suggestedDatesTitle}
                      style={{ marginTop: "16px" }}
                    >
                      {i18n.datepicker.ourTime}:
                    </h6>
                    <div className={classes.suggestedDates}>
                      {inputRef.current.value}
                    </div>
                  </>
                )}

                {!!isOfferTime && (
                  <FormControl
                    {...formConfig.startTime}
                    className={joinClassNames(
                      inputStyles.fullWidth,
                      inputStyles.inputIcon
                    )}
                  >
                    <Input
                      ref={inputRef}
                      iconRight
                      withBorder
                      icon={"clockGray"}
                      color={"primary"}
                      is={FORM_CONTROL_ELEMENT.INPUT}
                    />
                    <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                  </FormControl>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { i18n } = state;
  return { i18n };
};

export default withStyles(styles)(connect(mapStateToProps, null)(DateDisplay));
