import { createStore, combineReducers, Store } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
    companyTypes,
    signUp,
    restorePassword,
    myVehicles,
    myServices,
    login,
    services,
    service,
    forgotPassword,
    resetPassword,
    myServiceCenterInfo,
    popups,
    myOffers,
    requests,
    bookings,
    i18n,
    branches,
    branchesRequests,
    favorites,
    selectedFavorites,
    statistics,
    servicesOnMap,
    serviceOwnerBadges,
    carOwnerBadges,
    reviews,
    findServiceRequest,
    review,
    isOpenedMenu,
    loader,
    serviceOwnerReviewsBadges,
    verification
} from '../reducers';

const reducer = combineReducers({
    form: reduxFormReducer,
    popups,
    companyTypes,
    restorePassword,
    signUp,
    login,
    myVehicles,
    services,
    service,
    myServiceCenterInfo,
    resetPassword,
    myServices,
    myOffers,
    forgotPassword,
    requests,
    bookings,
    i18n,
    branches,
    branchesRequests,
    favorites,
    selectedFavorites,
    statistics,
    servicesOnMap,
    serviceOwnerBadges,
    carOwnerBadges,
    reviews,
    findServiceRequest,
    review,
    isOpenedMenu,
    loader,
    serviceOwnerReviewsBadges,
    verification
});

const store: Store = createStore(reducer);

export default store;
