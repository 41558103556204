import {
    RESTORE_PASSWORD_REQUEST, RESTORE_PASSWORD_REQUEST_FAIL,
    RESTORE_PASSWORD_REQUEST_SUCCESS,
} from "../constants";
import {AnyAction} from "redux";


const defaultState = {
    forgotPassword: {
        wait: false,
        error: null,
        payload: null
    },
};

export const restorePassword = (state = defaultState, action: AnyAction) => {
    switch (action.type) {
        case RESTORE_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    wait: true
                }
            };
        case RESTORE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    wait: false,
                    payload: action.payload,
                    error: null
                }
            };
        case RESTORE_PASSWORD_REQUEST_FAIL:
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    wait: false,
                    payload: null,
                    error: action.error
                }
            };
        default:
            return state
    }
};

