import * as React from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../../redux/actions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import StyledDialogTitle from '../../components/Dialog/DialogTitle';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import { joinClassNames } from '../../helpers/className';

import styles from './index.module.scss'

class ConfirmPopup extends React.Component {
    title: string;
    action: string;
    cancel: string;
    color: 'primary' | 'danger' | 'default' | 'secondary';
    callback: () => any;
    text: string;
    withoutCancel: boolean;
    cancelCallback: () => any;
    confirmButton?: any;
    isReport?: boolean;

    constructor(props: any) {
        super(props);
        let { initialData, common } = props;
        let { title, action, color, callback, cancel, text, withoutCancel, cancelCallback, confirmButtonClassName, isReport } = initialData;
        this.title = title || 'Title';
        this.isReport = isReport;
        this.action = action || 'Ok';
        this.cancel = cancel || common.cancel;
        this.color = color || 'primary';
        this.confirmButton = joinClassNames(styles.button, confirmButtonClassName)
        this.callback = callback;
        this.cancelCallback = cancelCallback;
        this.text = text;
        this.withoutCancel = withoutCancel;
    }

    public close = () => {
        let props: any = this.props;

        props.closePopup(props.uuid);
    };

    render() {
        let titleEmpty = !this.title || this.title === ' ';
        if (typeof (this.title) === 'string') {
            titleEmpty = this.title.trim().length === 0
        }

        return (
            <>
                <StyledDialogTitle>
                    {!!this.text && !titleEmpty &&
                        < div className={styles.title}>{this.title}</div>
                    }
                    <div className={styles.close} onClick={this.close}>
                        <Icon icon="close" />
                    </div>
                </StyledDialogTitle>

                <StyledDialogContent>
                    {!!this.text
                        ? <div className={titleEmpty ? styles.titleText : this.isReport ? styles.report : styles.text}>{this.text}</div>
                        : <div className={styles.title}>{this.title}</div>
                    }
                </StyledDialogContent>
                <StyledDialogActions>
                    <Flex className={this.isReport ? styles.reportButtons : styles.buttons}>

                        {!this.withoutCancel && <Button
                            variant={'outlined'}
                            className={styles.button}
                            onClick={() => {
                                if (this.cancelCallback) {
                                    this.cancelCallback();
                                }
                                this.close();
                            }}>
                            {this.cancel}
                        </Button>
                        }

                        <Button
                            color={this.color}
                            variant={'text'}
                            className={this.isReport ? '' : this.confirmButton}
                            onClick={
                                () => {
                                    this.callback();
                                    this.close();
                                }
                            }
                            type={'submit'}>
                            {this.action}
                        </Button>

                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { i18n: { common } } = state;
    return { common }
}

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);
