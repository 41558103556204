import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import Icon from "../Icon";
import Flex from "../Flex";
import { CONFIRM_POPUP, CROP_IMAGE_POPUP } from "../../popups";
import { openPopup } from "../../redux/actions";
import { joinClassNames } from "../../helpers/className";

import styles from "./index.module.scss";

interface Props {
    openPopup: any;
    isEditable: boolean;
    onChange?: (id: number) => any;
    onDelete?: (id: number) => any;
    image: any;
    className?: string;
    i18n?: any;
}

const CompanyLogo = ({ openPopup, isEditable, onChange, onDelete, image, className, i18n }: Props) => {
    let companyLogo = 'companyLogo';

    const edit = () => {
        if (!isEditable) {
            return;
        }

        openPopup(CROP_IMAGE_POPUP, { onChange });
    };

    const deleteHandler = () => {
        if (!isEditable) {
            return;
        }

        openPopup(CONFIRM_POPUP,
            {
                title: i18n.bookings.are_you_sure,
                text: i18n.service_management.delete_logo,
                action: i18n.common.delete,
                color: 'danger',
                callback: onDelete
            });
    };

    return (
        <Flex
            className={joinClassNames(styles.logoContainer, className, companyLogo)}
            onClick={edit}>
            {
                image ?
                    <>
                        <img
                            className={styles.image}
                            src={image.originalPath}
                            alt="logo" />
                        {isEditable &&
                            <div
                                className={styles.delete}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    deleteHandler();

                                }}>
                                <Icon
                                    icon={'deleteCross'}
                                />
                            </div>}
                    </>
                    :
                    <Icon icon={'sorPlaceholder'} />
            }
        </Flex>
    );
};

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openPopup: openPopup(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLogo);
