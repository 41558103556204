import React from 'react'
import styles from './index.module.scss'
import {joinClassNames} from "../../helpers/className";

interface Props {
    className?: string
    children: any
}

const Title = ({children, className}: Props) =>
    (<p className={joinClassNames(styles.Title, className)}>{children}</p>);

export default Title
