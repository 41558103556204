import Http from "./HttpService";

interface ConfirmPhone {
  code: string,
  phoneNumber: string,
}

interface ResendSms {
  phoneNumber: string,
}

interface CheckToken {
  userId: number,
  requestId: number,
  token?: string,
}

interface SetPassword {
  userId: number,
  requestId: number,
  token?: string,
  password: string,
  confirmPassword: string,
}

interface SendEmail {
  id?: number | null,
  email?: string,
  userId?: number,
  requestId?: number,
}

interface VerifyEmail {
  email?: string
  userId?: number,
  requestId?: number,
}

class VerificationService extends Http {
  public sendVerification(verifyEmail: VerifyEmail) {
    return this.post('/verifications/email', verifyEmail);
  }

  public verifyVersion() {
    return this.get('/verifications/app-version');
  }

  public confirmPhone(values: ConfirmPhone) {
    return this.put(`/verifications/phone`, values, { disableToast: true });
  }

  public resendSms(values: ResendSms) {
    return this.post(`/verifications/phone`, values);
  }

  public checkEmailTokenRelevance(values: CheckToken) {
    return this.post(`/verifications/token-email-password`, values);
  }

  public checkPasswordTokenRelevance(values: CheckToken,) {
    return this.post(`/verifications/car-owner/token-email/forgot`, values);
  }

  public setPassword(values: SetPassword) {
    return this.put(`/verifications/email-password`, values);
  }

  public setPasswordFromEmail(values: SetPassword) {
    return this.put(`/verifications/car-owner/email/forgot`, values);
  }

  public forgotEmail(values: ResendSms) {
    return this.post(`/verifications/car-owner/email`, values);
  }

  public sendCodeForEmail(values: ConfirmPhone) {
    return this.post(`/verifications/car-owner/email/code`, values, { disableToast: true });
  }

  public sendEmail(values: SendEmail) {
    return this.post(`/verifications/car-owner/email/forgot`, values);
  }

  public resendEmail(values: SendEmail) {
    return this.post(`/verifications/car-owner/email/forgot/resend`, values);
  }

  public resendConfirmEmail(values: VerifyEmail) {
    return this.post(`/verifications/email-password/resend`, values);
  }
}

export default VerificationService;
