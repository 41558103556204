import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";

interface Props {
    className?: string;
    children?: any;
    onClick?: any;
    style?: any;
    id?: string;
    tabIndex?: number;
    onBlur?: any;
}

const Flex = ({ children, style, className, ...props }: Props) => {

    return (
        <div style={style} className={joinClassNames(styles.flex, className)} {...props}>
            {children}
        </div >)
};


export default Flex;
