import { AnyAction } from "redux";
import { HIDE_LOADER, SHOW_LOADER } from "../constants";

let initialState = false;

export const loader = (state: boolean = initialState, action: AnyAction) => {
    switch (action.type) {
        case SHOW_LOADER:
            return true;
        case HIDE_LOADER:
            return false;
        default:
            return state;
    }
};
