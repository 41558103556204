import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";

interface Props {
    className: string;
    children?: any;
    large?: boolean;
    borderRadius: 'x1' | 'x2';
    withoutShadow?: boolean;
}

const borderRadiusClass = {
    x1: styles.x1,
    x2: styles.x2
};

const Card = ({ children, large, className, withoutShadow, borderRadius }: Props) => {
    let classNames = joinClassNames(
        large ? 'large' : styles.fixedWidth,
        !withoutShadow ? styles.shadow : '',
        styles.Card,
        borderRadiusClass[borderRadius],
        className
    );

    return (
        <div className={classNames}>
            {children}
        </div>
    );
};

Card.defaultProps = {
    className: '',
    borderRadius: 'x1'
};

export default Card;
