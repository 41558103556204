import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';

import Http from "./HttpService";
import StoreService from './StorageService';
import { KEY_LANGUAGE } from '../const/local-storage';
import { DEFAULT_LANGUAGE } from '../const/languages';

let http = Http.createInstance({
    baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
});

const api = Http.createInstance({
    baseURL: `${ Http.api.common }${ Http.versions.v1 }`
})

export class MapAutocompleteService {
    static getLocationByAddress(address: string, searchOptions?: any) {
        let key = process.env.REACT_APP_MAPS_KEY;
        if (!address) {
            return Promise.resolve();
        }
        let url = `${ address }.json?access_token=${ key }`

        if (!!searchOptions) {
            const { country } = searchOptions;
            return http.get(url + `&country=${country}`)
                .then(({ data }) => data.features);
        }

        return http.get(url)
            .then(({ data }) => data.features);
    }

    static getAddress({ lat, lng }: any) {
        const key = process.env.REACT_APP_MAPS_KEY;
        const params = `${ lng },${ lat }`;
        const store = new StoreService();
        const language = (store.get(KEY_LANGUAGE, DEFAULT_LANGUAGE));

        return http.get(`${ params }.json?access_token=${ key }&language=${ language.shortName }`).then(({ data }) => {
            return data && data.features.length > 0 && data.features[0]
        })
    }
}

export class CarsAutocompleteService {

    public getBrands = (model: any) => {
        return api.get('car-owners/vehicles/brands', {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }

    public getModels = (model: any, id: number) => {
        return api.get(`car-owners/vehicles/brands/${ id }/models`, {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }
}
