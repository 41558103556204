import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import BasePopup from '../BasePopup';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import Button from "../../components/Button";
import FormControl, { FORM_CONTROL_ELEMENT } from "../../components/FormControl";
import formConfig from "./form-config";
import Label from "../../components/Label";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import ServiceCentersService from "../../services/ServiceCentersService";
import { SUCCESS_POPUP } from '../index';
import { closePopup, getMyServiceCenterInfo, openPopup } from "../../redux/actions";
import formInternalization from '../../utils/form-internalization';

import styles from './index.module.scss'

class AddBranchPopup extends BasePopup {

    constructor(props: any) {
        super(props);
        this.title = props.i18n.popups.add_new_branch;
        this.className = styles.Card;
        this.state = { wait: false };
        this.withDivider = false;
    }

    public save(model: any) {
        let props: any = this.props;
        const { openPopup, i18n: { popups } } = props;

        this.setState({ wait: true });

        new ServiceCentersService().sendBranchInvite(model)
            .then(() => {
                props.getMyServiceCenterInfo();
                openPopup(SUCCESS_POPUP, { title: popups.branch_invite_sent, heading: ' ', text: popups.ok_good, color: 'primary' })
                this.close();
            })
            .finally(() => this.setState({ wait: false }))
    }

    public renderContent() {
        let props: any = this.props;
        let state: any = this.state;
        let { handleSubmit, invalid, $values, i18n } = props;

        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={handleSubmit}
                        className={styles.form}>
                        <FormControl {...formConfig.email}
                            className={styles.formControl}>
                            <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.label} />
                            <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder
                                icon='email' />
                            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                        </FormControl>
                    </form >
                </StyledDialogContent>

                <StyledDialogActions>
                    <Button
                        small={true}
                        fontSize={16}
                        className={styles.btnSave}
                        disabled={invalid || state.wait}
                        color='alter'
                        onClick={() => this.save($values)}
                        variant={"text"}>
                        {i18n.popups.send_invite}
                    </Button>
                </StyledDialogActions>
            </>

        )
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let { companyTypes, myServiceCenterInfo, i18n } = state;
    let { values = {} } = state.form[form] || {};
    return { companyTypes, myServiceCenterInfo, $values: values, i18n }
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    openPopup: openPopup(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
});
const Popup: any = AddBranchPopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        email: ''
    }
})(
    Popup
);

export default connect(mapStateToProps, mapDispatchToProps)(
    Form
);

