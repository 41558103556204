import React from 'react'
import FormControlInput from '../../../../../../components/FormControlInput/index';
import Label from '../../../../../../components/Label/index';
import Dropdown from '../../../../../../components/Dropdown/index';
import FormControl, { FORM_CONTROL_ELEMENT } from '../../../../../../components/FormControl/index';
import ErrorMessage from '../../../../../../components/ErrorMessage/index';
import { CarsAutocomplete } from '../../../../../../components/CarAutocomplete';
import Checkbox from '../../../../../../components/Checkbox';
import Flex from '../../../../../../components/Flex/index';
import { joinClassNames } from '../../../../../../helpers/className';

import formStyles from './index.module.scss';
import styles from '../../index.module.scss';

interface TypesProps {
    id: string,
    name: string
}

type FormConfig = any

interface CarProps {
    formChange: (name: string, value: string) => {},
    formConfig: FormConfig,
    carInfoDisabled: boolean,
    i18n: Map,
    brandId: number,
    brandDropdownValue: string | undefined,
    handleBrandSelect: (val: TypesProps) => void,
    modelName: string,
    handleModelSelect: (val: TypesProps) => void,
    saveToMyVehicles: boolean,
    setSaveToMyVehicles: (val: boolean) => void,
    setFuelType: (option: TypesProps) => void,
    fuelTypes: Array<TypesProps>,
    fuelType: string,
    setGearbox: (option: TypesProps) => void,
    gearboxTypes: Array<TypesProps>,
    gearbox: string,
}

type Map = {[key: string]: {[key: string]: string}}

export default function CarForm({
    formChange,
    formConfig,
    carInfoDisabled,
    i18n,
    brandId,
    brandDropdownValue,
    handleBrandSelect,
    modelName,
    handleModelSelect,
    saveToMyVehicles,
    setSaveToMyVehicles,
    setFuelType,
    fuelTypes,
    fuelType,
    setGearbox,
    gearboxTypes,
    gearbox,
}: CarProps) {
    return (
        <>
            <Flex className={formStyles.carInfo}>
                <FormControl {...formConfig.brand}
                    className={formStyles.formControl}>
                    <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.label} />
                    <CarsAutocomplete
                        is={FORM_CONTROL_ELEMENT.INPUT}
                        value={brandDropdownValue}
                        disabled={carInfoDisabled}
                        onSelect={(val) => handleBrandSelect(val)} />
                    <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                </FormControl>

                <FormControl {...formConfig.model}
                    className={formStyles.formControl}>
                    <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.label} />
                    <CarsAutocomplete
                        is={FORM_CONTROL_ELEMENT.INPUT}
                        value={modelName}
                        lookingFor='model'
                        disabled={!brandId || carInfoDisabled}
                        onSelect={(val) => handleModelSelect(val)}
                        id={brandId} />
                    <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                </FormControl>
            </Flex>

            <Flex className={formStyles.dropdownsWrapper}>
                <Flex className={formStyles.dropdownWraper}>
                    <Label className={styles.label}>{formConfig.fuelType.label}</Label>
                    <Dropdown
                        onChange={(option: any) => {
                            let { id } = option;
                            formChange('fuelType', id);
                            setFuelType(option);
                        }}
                        disabled={carInfoDisabled}
                        hideDisabledClass
                        className={joinClassNames(formStyles.dropDown, formStyles.dropdown600)}
                        options={fuelTypes}
                        value={fuelType} />
                </Flex>

                <Flex className={formStyles.dropdownWraper}>
                    <Label className={styles.label}>{formConfig.gearbox.label}</Label>
                    <Dropdown
                        className={joinClassNames(styles.dropDown, formStyles.dropdown600)}
                        onChange={(option: any) => {
                            let { id } = option;
                            setGearbox(option)
                            formChange('gearbox', id);
                        }}
                        hideDisabledClass
                        disabled={carInfoDisabled}
                        options={gearboxTypes}
                        value={gearbox} />
                </Flex>
            </Flex>

            <Flex className={formStyles.carInfoDetails}>
                <Flex className={formStyles.carInfoDetailsSection}>
                    <FormControlInput
                        {...formConfig.year}
                        withBorder
                        disabled={carInfoDisabled}
                        className={formStyles.formControlTiny} />

                    <FormControlInput
                        {...formConfig.mileage}
                        withBorder
                        disabled={carInfoDisabled}
                        className={formStyles.formControlTiny} />
                </Flex>

                <Flex className={formStyles.carInfoDetailsSection}>
                    <FormControlInput
                        {...formConfig.volume}
                        withBorder
                        disabled={carInfoDisabled}
                        className={formStyles.formControlTiny} />

                    <FormControlInput
                        {...formConfig.enginePower}
                        withBorder
                        disabled={carInfoDisabled}
                        className={formStyles.formControlTiny} />
                </Flex>
            </Flex>

            <Checkbox
                className={styles.saveToMyVehiclesCheckbox}
                checked={saveToMyVehicles}
                label={i18n.common.save_to_my_vehicle}
                onChange={() => {
                    setSaveToMyVehicles(!saveToMyVehicles)
                }}
            />
        </>
    );
}