import React from 'react'
import { connect } from "react-redux";

import BasePopup from '../BasePopup';
import Button from "../../components/Button";
import Map from "../../components/Map";
import MapAutocomplete from "../../components/MapAutocomplete";
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH } from "../../const/validation";
import ServiceCentersService from "../../services/ServiceCentersService";
import { closePopup, getMyServiceCenterInfo, getServiceCenterById } from "../../redux/actions";
import { MapAutocompleteService } from "../../services/AutocompleteService";
import { trimLeft } from "../../pipes/trim";
import BranchesService from '../../services/BranchesService';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from "../../components/Dialog/DialogActions";
import { onKeyPressFilterKeys } from '../../utils/key-press-filter';

import styles from './index.module.scss';

export const DEFAULT_LOCATION = {
    lat: 59.436962,
    lng: 24.753574
};

class LocationPopup extends BasePopup {

    constructor(props: any) {
        super(props);
        this.title = props.i18n.popups.location;
        this.i18n = props.i18n;
        this.className = styles.Card;

        let { initialData = {} } = props;
        let { address } = initialData;
        let state = {
            wait: false,
            center: DEFAULT_LOCATION,
            title: ''
        };

        if (address) {
            state = {
                center: address.geoLocation,
                title: address.title,
                wait: false,
            };
        }
        this.state = state;
    }

    public save(title: string, geoLocation: any) {
        let props: any = this.props;

        if (!title) return;
        const { initialData } = props;
        this.setState({
            wait: true
        });

        if (initialData.serviceCenterId) {
            new BranchesService()
                .editBranchAddress(initialData.serviceCenterId, { title, geoLocation })
                .then(() => {
                    props.getServcieById(initialData.serviceCenterId)
                    this.close();
                })
            return;
        }

        new ServiceCentersService()
            .changeAddress({ title, geoLocation })
            .then(() => {
                props.getMyServiceCenterInfo();
                this.close();
            })
            .catch(() => {
                this.setState({
                    wait: false
                });
            })
    }

    public searchAddress(coords: any) {
        MapAutocompleteService.getAddress(coords).then((e: any) => {
            this.setState({
                center: { lat: e.center[1], lng: e.center[0] },
                title: trimLeft(e.place_name) as any
            })
        });
    }

    public handleDrag = (coords: any) => {
        const { lng, lat } = coords;
        this.setState({
            center: { lng, lat }
        })
    }

    public handleEnterKeyPress = (event: Event) => {
        event.preventDefault();
    }

    public renderContent() {
        let { center, title, wait }: any = this.state;

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={() => this.save(title, center)}
                        onKeyPress={onKeyPressFilterKeys(['Enter'], this.handleEnterKeyPress)}
                        className={styles.form}>
                        <div className={styles.addressLabel}>{this.i18n.common.address}</div>
                        <MapAutocomplete
                            inputProps={{
                                maxLength: INPUT_MAX_LENGTH
                            }}
                            value={title}
                            searchOptions={ { country: 'ee' } }
                            onChange={(text: string) => this.setState({
                                ...this.state,
                                title: trimLeft(text) as any
                            })}
                            isNamed={false}
                            onSelect={(center) => {
                                this.setState(center);
                            }}
                        />

                        <Map height={'400px'}
                            defaultCenter={center}
                            center={center}
                            onClick={(position) => {
                                this.searchAddress(position);
                            }}
                            onDragEnd={this.handleDrag}
                            className={styles.map}>
                        </Map>
                    </form>
                </StyledDialogContent>

                <StyledDialogActions className={styles.buttons}>
                    <Button large={false}
                        small={true}
                        fontSize={16}
                        className={styles.btnCancel}
                        color={"secondary"}
                        onClick={this.close}
                        variant={"outlined"}>
                        {this.i18n.common.cancel}
                    </Button>

                    <Button large={false}
                        small={true}
                        fontSize={16}
                        className={styles.btnSave}
                        disabled={!title || wait || title.length < INPUT_MIN_LENGTH}
                        onClick={() => this.save(title, center)}
                        color={"secondary"}
                        variant={"text"}>
                        {this.i18n.common.save}
                    </Button>
                </StyledDialogActions>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
    getServcieById: getServiceCenterById(dispatch)
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(
    LocationPopup
);
