import Validator from "./index";
import store from "../redux/store";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let validations_message: any = {};
store.subscribe(() => validations_message = store.getState().i18n.validations_message);

const CAR_REG_NUM_PATTERN = /^[A-Z0-9-]{4,9}$/i;


export default class VehicleValidator {
    static carRegistrationNumber = () =>
        Validator.regexp(CAR_REG_NUM_PATTERN,
            `:error[Must_be_from] 4 :error[to] 9 :error[char_long]`)
}
