import React from "react";
import Redirect from "../../modules/navigation/Redirect";
import { getQueryParams } from "../../utils/location";
import ProfileService from "../../services/ProfileService";
import StoreService from "../../services/StorageService";

const ConfirmChangeEmail = () => {
    let { email, token } = getQueryParams();

    let sessionStorage = new StoreService();
    sessionStorage.clear();

    if (email && token) {
        // revert replacing + to white space from returned value of (qs lib)
        email = email.toString().replace(/ /g, '+');
        new ProfileService().confirmEmail({ email, token });
    }

    return (<Redirect to={ '/' }/>);
};

export default ConfirmChangeEmail;

