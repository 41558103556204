import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Redirect from "../../modules/navigation/Redirect";
import StoreService from "../../services/StorageService";
import UsersService from "../../services/UsersService";
import { closeMenu } from "../../redux/actions/menu";
import { KEY_ROLE } from "../../const/local-storage";
import { ROLE } from "../../const/users";

const SignOut = ({ closeMenu, history }: any) => {
    let sessionStorage = new StoreService();
    const role = sessionStorage.get(KEY_ROLE);

    let urlToRedirect = role === ROLE.BUSINESS ? '/business' : '/';

    new UsersService().logout()
        .finally(() => {
            closeMenu();
            sessionStorage.clear();
            history.push(urlToRedirect);
        });

    return (<Redirect to={urlToRedirect} />);
};

const mapStateToProps = (state: any) => {
    return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeMenu: closeMenu(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignOut));
