import React from 'react'
import {ReactComponent as ArrowSvg} from '../../images/partials/ic-arrow-down.svg'
import styles from './index.module.scss'
import {IPropsClassName} from "../../types/props";
import {joinClassNames} from "../../helpers/className";


const ARROW_FILL_COLORS = {
  blue: styles.blue,
  white: styles.white,
  grey: ''
};

interface IProps extends IPropsClassName{
    fill: 'blue' | 'white' | 'grey';
    direction?: 'up' | 'down'
}

const Arrow = (props: IProps) => {
    let {fill, direction, className} = props;

    return (
        <ArrowSvg className={joinClassNames(
            ARROW_FILL_COLORS[fill],
            direction === 'up' && styles.up,
            className
        )}/>
    )
};


Arrow.defaultProps = {
    fill: 'grey'
};

export default Arrow
