export const GET_BRANCHES = 'GET_BRANCHES_REQUEST';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAIL = 'GET_BRANCHES_FAIL';

export const GET_BRANCH_CONTRACTS = 'GET_BRANCH_CONTRACTS';
export const GET_BRANCH_CONTRACTS_SUCCESS = 'GET_BRANCH_CONTRACTS_SUCCESS';
export const GET_BRANCH_CONTRACTS_FAIL = 'GET_BRANCH_CONTRACTS_FAIL';

export const GET_BRANCH_REQUESTS = 'GET_BRANCH_REQUESTS';
export const GET_BRANCH_REQUESTS_SUCCESS = 'GET_BRANCH_REQUESTS_SUCCESS';
export const GET_BRANCH_REQUESTS_FAIL = 'GET_BRANCH_REQUESTS_FAIL';