import {integerOnly} from "./phone";

export const formatMilleage = (value: any) => {
    let viewResult = '';

    if (!value) {
        return value
    }

    // x xxx xxx

    const onlyNums = integerOnly(value);

    viewResult += onlyNums.slice(0, 3);

    if (onlyNums.length > 3) {
        viewResult = viewResult.slice(0, 1) + ' ' +
            viewResult.slice(1);
    }

    viewResult += onlyNums.slice(3, 7);

    if(onlyNums.length === 5) {
        viewResult = viewResult.replace(/ /g, '');
        viewResult = viewResult.slice(0, 2) + ' ' +
            viewResult.slice(2, 5)
    }

    if(onlyNums.length === 6) {
        viewResult = viewResult.replace(/ /g, '');
        viewResult = viewResult.slice(0, 3) + ' ' +
            viewResult.slice(3, 6);
    }

    if (onlyNums.length >= 7) {
        viewResult = viewResult.replace(/ /g, '');
        viewResult = viewResult.slice(0, 1) + ' ' +
            viewResult.slice(1, 4) + ' ' +
            viewResult.slice(4, 7)
    }

    return viewResult;
};

export const parseMilleage = (val: any) => {
    return `${val.replace(/\D/g, '')}`
};
