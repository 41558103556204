import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";

interface Props {
    className: string;
    text?: string;
}

const Divider = ({ className, text }: Props) => {
    return (
        <div className={joinClassNames(styles.divider, className)}>
            <span className={styles.line}/>
            <span className={styles.text}>{text}</span>
            <span className={styles.line}/>
        </div>
    );
};

Divider.defaultProps = {
    className: '',
    text: '',
};

export default Divider;
