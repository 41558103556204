
interface Params {
    fallbackUrl: string;
    currentUrl: string;
}

class Guard {
    fallbackUrl: string;
    currentUrl: string;

    constructor({ fallbackUrl, currentUrl } : Params) {
        this.fallbackUrl = fallbackUrl;
        this.currentUrl = currentUrl;
    }

    get fallback(): string {
        return this.fallbackUrl;
    }
}

export default Guard;
