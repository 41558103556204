import { ServiceOwnerRegister } from '../types/ServiceOwnerRegister'
import UsersService from "./UsersService";
import { IForgotPasswordModel, InvitationInterface } from "../types/models";

class ServiceOwnersService extends UsersService {

    public restorePassword(model: IForgotPasswordModel) {
        return this.post('/verifications/service-owner/password', model);
    }

    public createNewPassword(model: IForgotPasswordModel) {
        return this.put('/verifications/password', model);
    }

    public register(model: ServiceOwnerRegister) {
        return this.post('/service-owners', model);
    }

    public invite(model: InvitationInterface) {
        return this.post('/service-owners/invitation', model);
    }
}


export default ServiceOwnersService;
