/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from 'moment';
import { connect } from "react-redux";

import Icon from "../../Icon";
import { DAY_PATTERN_BOOKINGS, DAY_WITH_TIME_PATTERN_BOOKINGS, TIME_PATTERN } from "../../../const/date-time-format";
import { KEY_LANGUAGE } from "../../../const/local-storage";
import { DEFAULT_LANGUAGE } from "../../../const/languages";
import StoreService from "../../../services/StorageService";

import styles from '../index.module.scss';
import { joinClassNames } from "../../../helpers/className";

interface Props {
    start: Date;
    end?: Date;
    registrationNumber?: string;
    i18n?: any;
}

const StartEndTimeRow = ({ start, end, registrationNumber, i18n }: Props) => {
    const store = new StoreService();
    const [locale, setLocale] = useState(DEFAULT_LANGUAGE.origin);
    let isSameDate = moment(start).isSame(end, 'day');
    let transformedDate;
    let transformedTime;

    useEffect(() => {
        const lang = store.get(KEY_LANGUAGE, DEFAULT_LANGUAGE).origin;
        setLocale(lang);
    }, [i18n]);

    if (isSameDate && !end) {
        transformedDate = `${moment(start).locale(locale).format(DAY_PATTERN_BOOKINGS)}`;
        transformedTime = `${moment(start).format(TIME_PATTERN)}`;
    }

    if (isSameDate && end) {
        transformedDate = `${moment(start).locale(locale).format(DAY_PATTERN_BOOKINGS)}`;
        transformedTime = `${moment(start).format(TIME_PATTERN)} - ${moment(end).format(TIME_PATTERN)}`;
    }

    if (!isSameDate && end) {
        transformedDate = `${moment(start).locale(locale).format(DAY_WITH_TIME_PATTERN_BOOKINGS)} - ${moment(end).locale(locale).format(DAY_WITH_TIME_PATTERN_BOOKINGS)}`
    }

    if (!isSameDate && !end) {
        transformedDate = `${moment(start).locale(locale).format(DAY_PATTERN_BOOKINGS)}`;
        transformedTime = `${moment(start).format(TIME_PATTERN)}`
    }

    return (
        <div className={styles.row}>
            {!!registrationNumber && 
                <div className={styles.timeRow}>
                    <Icon icon="carRegistrationNumber" className={styles.calendar} />
                    <span className={styles.gray}>{registrationNumber}</span>
                </div>
            }
            <div className={styles.timeRow}>
                <Icon icon="calendar" className={!!registrationNumber ? joinClassNames(styles.calendar,styles.offsetLeft) : styles.calendar} />
                <span className={styles.gray}>{transformedDate}</span>
            </div>
            {!!transformedTime &&
                <div className={styles.timeRow}>
                    <Icon icon={'clockGray'} className={styles.offsetLeft} />
                    <span className={styles.gray}>{transformedTime}</span>
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n };
}

export default connect(mapStateToProps)(StartEndTimeRow);
