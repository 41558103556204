import React from 'react'
import { connect } from "react-redux";
// @ts-ignore
import Highlighter from 'react-highlight-words'

import BasePopup from "../BasePopup";
import { closePopup } from "../../redux/actions";
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import Icon from "../../components/Icon";
import Flex from "../../components/Flex";
import Button from "../../components/Button";

import styles from './index.module.scss'

class ClientInformationPopup extends BasePopup {
    props: any;
    id?: number;
    model: any;
    highlightText?: string;

    constructor(props: any) {
        super(props);
        let { initialData, i18n } = props;
        this.i18n = i18n;
        this.withDivider = false;
        this.title = i18n.common.client_information;
        let { booking, highlightText } = initialData;

        this.highlightText = highlightText;

        this.model = [
            {
                icon: 'user',
                title: i18n.common.name,
                value: booking.firstName + ' ' + booking.lastName,
            },
            {
                icon: 'phone',
                title: i18n.common.phone_number,
                value: booking.phoneNumber,
            },
            {
                icon: 'email',
                title: i18n.common.email,
                value: booking.email,
            },
        ];
    }

    renderContent() {
        return (
            <>
                <StyledDialogContent>
                    <div className={styles.wrapper}>
                        {
                            this.model.map((item: any, index: number) =>
                                item.value &&
                                <div key={index}>
                                    <Flex className={styles.row}>
                                        <Icon icon={item.icon} />
                                        <p>{item.title}</p>
                                    </Flex>
                                    <p className={styles.value}>
                                        <Highlighter
                                            searchWords={[this.highlightText]}
                                            textToHighlight={item.value}
                                        />
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button
                        small={true}
                        large={false}
                        color={"alter"}
                        onClick={this.close}
                        className={styles.btn}
                        variant={"text"}>
                        {this.i18n.common.close}
                    </Button>
                </StyledDialogActions>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInformationPopup);
