const SCHEMA = {
    HOW_IT_WORKS: {
        path: '/#how-it-works'
    },
    FOR_BUSINESS: {
        path: '/business'
    },
    SIGN_IN: {
        path: '/user/sign-in'
    },
    SIGN_UP: {
        path: '/user/sign-up'
    },

}

export default [
    {
        children: 'How it works',
        href: SCHEMA.HOW_IT_WORKS.path,
        Icon: null
    },
    {
        children: 'For business',
        href: SCHEMA.FOR_BUSINESS.path,
        Icon: null
    },
    {
        children: 'Sign In',
        href: SCHEMA.SIGN_IN.path,
        Icon: null
    },
    {
        children: 'SIGN UP',
        href: SCHEMA.SIGN_UP.path,
        Icon: null
    },
];
