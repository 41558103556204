import { LONG_FORMAT, LONG_WITH_TIME_FORMAT } from "../const/date-time-format";
import { ScreensEnum } from "../pages/user/dashboard/create-new-booking/screens/screens.enum";
import moment from "moment";

const MOMENT_MS = 'ms';
const DEFAULT_START_TIME = "0:00"
const DEFAULT_END_TIME = "24:00"
export interface Address {
    title: string,
    geoLocation: {
        lat: number,
        lng: number,
    }
}

export interface Dates {
    startDate: string,
    endDate: string,
}

export interface Account {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    timeZoneOffset?: number,
}

export interface Vehicle {
    registrationNumber: string,
    volume: string,
    enginePower: string,
    fuelType: string,
    mileage: string,
    gearbox: string,
    year: string,
    brand: string,
    model: string,
}
export interface RequestModel {
    comment?: string | null,
    serviceId: number,
    address?: Address,
    availableDates?: Array<Dates>,
    phoneNumber?: string,
    firstName?: string,
    lastName?: string,
    vehicleId?: string | number,
    vehicle?: Vehicle,
    account?: Account,
    radius?: number | string,
    favourites?: Array<number>,
}

interface TypeOfService {
    comment: string, 
    favorites: number[],
    serviceCenterId: string | number,
    serviceId: number,

}

const TOW_TRUCK_ID = 6;

export const clean = (obj: any) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
}

export const processDates = (dates: Array<Dates>, startTime: string, endTime: string) => (
    dates.map(({ startDate, endDate }: Dates) => {

        const start = moment(startDate).format(LONG_FORMAT);
        const end = moment(endDate).subtract(1, MOMENT_MS).format(LONG_FORMAT);

        const startWithTime = moment(start + " " + startTime, LONG_WITH_TIME_FORMAT).toISOString();
        const endWithTime = moment(end + ' ' + endTime, LONG_WITH_TIME_FORMAT).toISOString();

        return {
            startDate: startWithTime,
            endDate: endWithTime
        };
    })
)

export const ModelTypes: TypeOfService | Address | Vehicle | Account | null | any = ""
 
export const createRequestModel = (model: Array<typeof ModelTypes>) => {
    const comment = model[ScreensEnum.TypeOfService].comment;
    const presonalInfo = model.find((item) => item?.phoneNumber);
    const vehicleInfo = model.find((item) => item?.registrationNumber || item?.selectedCar);
    const locationInfo = model.find((item) => item?.location);

    const requestModel: RequestModel = {
        comment: !!comment ? comment : null,
        serviceId: +model[ScreensEnum.TypeOfService].serviceId,
        phoneNumber: presonalInfo?.phoneNumber,
        firstName: presonalInfo?.firstName,
        lastName: presonalInfo?.lastName,
    }

    if (locationInfo) {
        requestModel.address = {
            title: locationInfo.location.title,
            geoLocation: {
                lat: +locationInfo.location.center.lat,
                lng: +locationInfo.location.center.lng,
            }
        }
    }

    const isTowTruck = model[ScreensEnum.TypeOfService].selectedService.id === TOW_TRUCK_ID;

    if (!isTowTruck) {
        const dateTimeModel = model.find((item) => item?.date);
        const dates = dateTimeModel.date;
        const [startTime = DEFAULT_START_TIME, endTime = DEFAULT_END_TIME] = dateTimeModel.time.split(' - ');
        requestModel.availableDates = processDates(dates, startTime, endTime);
    }

    if (!vehicleInfo.registrationNumber) {
        requestModel.vehicleId = vehicleInfo.selectedCar.id;
    } else {
        requestModel.vehicle = vehicleInfo;
        clean(requestModel.vehicle);
    }

    return requestModel;
}