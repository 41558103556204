import React, { useState } from 'react';
import { Marker } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Popover, useMediaQuery } from '@material-ui/core';

import Flex from '../Flex';
import Popup from './popup';
import { MOBILE_BP } from '../../const/users';
import { openPopup } from '../../redux/actions';
import { OFFER_ON_MAP_POPUP } from '../../popups';
import { ReactComponent as UserLocation } from '../MapUserMarker/map-pin.svg';

import styles from './index.module.scss';

const OfferMarker = (props: any) => {
    const { item, refresh, openPopup } = props;
    const [anchorEl, setAnchorEl] = useState<any>();
    const {
        address: { geoLocation },
        id,
        image,
        companyName,
        branchName,
        service,
        rate,
        priceFormat,
        eta,
        offerStatus,
        start
    } = item;

    const mathces = useMediaQuery(MOBILE_BP);

    const handleClick = (event: Event) => {
        mathces ? openPopup(OFFER_ON_MAP_POPUP, {
            companyName,
            branchName,
            priceFormat,
            refresh,
            eta,
            id,
            status: offerStatus,
            serviceName: service.name,
            logo: image,
            price: rate,
        }) : setAnchorEl(event.currentTarget);
    }

    return (
        <Marker
            coordinates={[geoLocation.lng, geoLocation.lat]}
            anchor="center" className={styles.marker}
        >
            <>
                <Popover
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                >
                    <Popup
                        companyName={companyName}
                        branchName={branchName}
                        status={offerStatus}
                        serviceName={service.name}
                        refresh={refresh}
                        price={rate}
                        date={start}
                        eta={eta}
                        priceFormat={priceFormat}
                        id={id}
                        logo={image}
                    />
                </Popover>

                <Flex onClick={handleClick} className={styles.markerWrapper}>
                    <UserLocation />
                </Flex>
            </>
        </Marker>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openPopup: openPopup(dispatch)
})

export default connect(null, mapDispatchToProps)(OfferMarker);
