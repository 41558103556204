import { Dispatch } from "redux";
import { CLOSE_POPUP, OPEN_POPUP } from "../constants";
import { Popup } from "../reducers";

export const openPopup = (dispatch: Dispatch) => (popup: Popup, data: any) => {
    dispatch({ type: OPEN_POPUP, popup, initialData: data })
};

export const closePopup = (dispatch: Dispatch) => (key: string) => {
    dispatch({ type: CLOSE_POPUP, popup: { key } })
};
