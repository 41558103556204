import {FormConfig} from "../../types/formConfig";
import Validator from "../../validators";
import {formatTimeSimple} from "../../pipes/time";
import { SIMPLE_TIME_MAX_LENGTH } from "../../const/validation";

let formConfig: FormConfig = {
    startTime: {
        name: 'startTime',
        label: 'Start time',
        placeholder: 'Time',
        format: formatTimeSimple,
        maxLength: SIMPLE_TIME_MAX_LENGTH,
        validate: [
            Validator.required(),
            Validator.timeSimple()
        ]
    },
    endTime: {
        name: 'endTime',
        label: 'End time',
        placeholder: 'Time',
        format: formatTimeSimple,
        maxLength: SIMPLE_TIME_MAX_LENGTH,
        validate: [
            Validator.timeSimple()
        ]
    },
};


export default formConfig;

