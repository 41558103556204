import React from "react";

import { joinClassNames } from "../../helpers/className";
import { IconType } from "../../types/icon";
import { COLOR } from "../../const/colors";
import Icon from "../Icon";

import styles from './index.module.scss'

export interface Props {
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    type?: 'text' | 'email' | 'password' | 'time'
    wrapClassName?: string;
    isInvalid?: boolean;
    icon?: IconType | null,
    is?: string;
    maxLength?: number;
    iconRight?: boolean;
    step?: any
    min?: any
    max?: any;
    value?: any;
    onChange?: (v: any) => any;
    onBlur?: any;
    color?: 'primary' | 'secondary';
    onKeyPress?: any;
    id?: string;
    autoComplete?: 'on' | 'off';
    withBorder?: any;
}

const Input = React.forwardRef(({ className, placeholder, iconRight, disabled, wrapClassName, isInvalid, type, icon, is, color, onBlur, onChange, id, withBorder, ...props }: Props, ref) => {
    const colorClassName = color === COLOR.SECONDARY ? styles.inputSecondary : styles.input;
    const iconClassName = joinClassNames(
        !iconRight ? styles.Icon : styles.IconRight,
        !iconRight && withBorder ? styles.IconBorderInput : ''
    );

    return (
        <div className={joinClassNames(styles.wrapInput, wrapClassName)}>
            <input
                placeholder={placeholder}
                ref={ref as any}
                className={joinClassNames(
                    colorClassName,
                    styles.noBorderRadius,
                    className,
                    withBorder ? styles.bordered : '',
                    icon && !iconRight ? styles.offsetLeft : '',
                    (withBorder && icon && !iconRight) ? styles.borderedWithIcon : '',
                    (withBorder && icon && iconRight) ? styles.borderedWithIconRight : '',
                    (isInvalid && withBorder) && styles.borderedInvalid,
                    (isInvalid && !withBorder) && styles.invalidControl,
                )}
                id={id}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                {...props}
            />
            {icon && <Icon icon={icon} className={iconClassName} />}
        </div>
    );
});

Input.defaultProps = {
    className: '',
    disabled: false,
    placeholder: '',
    type: 'text'
};

export default Input;
