import React from 'react';
import { DialogTitle } from '@material-ui/core';

import styles from './index.module.scss';

const StyledDialogTitle = (props: any) => (
    <DialogTitle className={styles.DialogTitle}>
        {props.children}
    </DialogTitle>
);

export default StyledDialogTitle;
