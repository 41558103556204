export const DAYS = [
    {
        label: 'Monday',
        name: 'monday',
    },
    {
        label: 'Tuesday',
        name: 'tuesday',
    },
    {
        label: 'Wednesday',
        name: 'wednesday',
    },
    {
        label: 'Thursday',
        name: 'thursday',
    },
    {
        label: 'Friday',
        name: 'friday',
    },
    {
        label: 'Saturday',
        name: 'saturday',
    },
    {
        label: 'Sunday',
        name: 'sunday',
    },
];
