import { FormConfig } from '../../types/formConfig';
import Validator from '../../validators';
import { formatTime } from '../../pipes/time';
import { TIME_MAX_LENGTH } from '../../const/validation';


let formConfig: FormConfig = {
    time: {
        name: 'time',
        label: '',
        placeholder: 'Time',
        format: formatTime,
        maxLength: TIME_MAX_LENGTH,
        validate: [
            Validator.required(),
            Validator.time()
        ]
    },
};


export default formConfig;
