import ChunkLoader from '../../helpers/ChunkLoader';
import UserRoleGuard from "../../guards/UserRoleGuard";
import KeepInSystem from "../../guards/KeepInSystem";

const SignUp = ChunkLoader(() => import('./sign-up'));
const SignUpComplete = ChunkLoader(() => import('./sign-up-complete'));
const VerifyMobile = ChunkLoader(() => import('./sign-up/verifyPhone'));
const ForgotEmail = ChunkLoader(() => import('./forgot-email'));
const SetNewEmail = ChunkLoader(() => import('./forgot-email/setNewEmail'));
const SuccessLinkSend = ChunkLoader(() => import('./successLinkSend'));
const SignIn = ChunkLoader(() => import('./sign-in'));
const SetPassword = ChunkLoader(() => import('./confirm-email-password'));
const ResetPassword = ChunkLoader(() => import('./reset-password'));
const ResetPasswordRequest = ChunkLoader(() => import('./forgot-password'));
const ExpiredLink = ChunkLoader(() => import('./ExpiredLink'));
const Dashboard = ChunkLoader(() => import('./dashboard'));
const PasswordChaged = ChunkLoader(() => import('./successLinkSend/successGoToSignIn'));

export default {
    SIGN_UP: {
        exact: true,
        component: SignUp,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/sign-up',
    },
    SIGN_UP_COMPLETE: {
        exact: true,
        component: SignUpComplete,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/sign-up-complete',
    },
    VERIFY_MOBILE: {
        exact: true,
        component: VerifyMobile,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/verify-mobile',
    },
    SET_PASSWORD: {
        exact: true,
        component: SetPassword,
        redirectTo: ['/user/dashboard'],
        path: '/user/confirm-email-password',
    },    
    SET_PASSWORD_EMAIL: {
        exact: true,
        component: SetPassword,
        redirectTo: ['/user/dashboard'],
        path: '/user/forgot-email',
    },
    FORGOT_EMAIL: {
        exact: true,
        component: ForgotEmail,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/restore-email',
    },
    EXPIRED_LINK: {
        exact: true,
        component: ExpiredLink,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/expired-link',
    },
    SUCCESS_LINK: {
        exact: true,
        component: SuccessLinkSend,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/success-link',
    },    
    SUCCESS_LINK_TO_SIGN_IN: {
        exact: true,
        component: PasswordChaged,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/password-changed',
    },
    SET_NEW_EMAIL: {
        exact: true,
        component: SetNewEmail,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/new-email',
    },
    SIGN_IN: {
        exact: true,
        component: SignIn,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/sign-in',
    },
    RESTORE_PASSWORD_REQUEST: {
        exact: true,
        component: ResetPasswordRequest,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/restore-password-request',
    },
    RESTORE_PASSWORD_REQUEST_STATUS: {
        exact: true,
        component: SuccessLinkSend,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/restore-password-request/status',
    },
    RESTORE_PASSWORD: {
        exact: true,
        component: ResetPassword,
        routeGuards: [KeepInSystem],
        redirectTo: ['/user/dashboard'],
        path: '/user/restore-password',
    },
    DASHBOARD: {
        exact: false,
        routeGuards: [UserRoleGuard],
        redirectTo: ['/404'],
        component: Dashboard,
        path: '/user/dashboard',
    },
};

