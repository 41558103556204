import {
    LOGIN_SERVICE_OWNER_FAIL,
    LOGIN_SERVICE_OWNER_REQUEST, LOGIN_SERVICE_OWNER_SUCCESS,
    SIGN_UP_CLEAR_DATA,
    SIGN_UP_FAIL,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    RESET_LOGIN_STATE
} from "../constants";
import { AnyAction } from "redux";


const defaultState = {
    wait: false,
    error: null,
    payload: null
};

export const signUp = (state = defaultState, action: AnyAction) => {
    switch (action.type) {
        case SIGN_UP_REQUEST:
            return { ...state, wait: true };
        case SIGN_UP_SUCCESS:
            return { ...state, wait: false, payload: action.payload, error: null };
        case SIGN_UP_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        case SIGN_UP_CLEAR_DATA:
            return { ...defaultState };
        default:
            return state
    }
};

const defaultLoginState = {
    wait: false,
    email: null,
    id: null,
    role: null,
    token: null,
    error: null
};

export const login = (state = defaultLoginState, action: AnyAction) => {

    switch (action.type) {
        case LOGIN_SERVICE_OWNER_REQUEST:
            return { ...state, wait: true };
        case LOGIN_SERVICE_OWNER_SUCCESS:
            return { ...state, wait: false, ...action.payload, error: null };
        case LOGIN_SERVICE_OWNER_FAIL:
            return { ...state, wait: false, payload: null, error: action.error };
        case RESET_LOGIN_STATE: {
            return { ...defaultLoginState }
        }
        default:
            return state
    }
};

