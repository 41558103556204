export const integerOnly = (value: any) => {

    if (!value) {
        return value
    }

    return value.toString().replace(/[^\d]/g, '');
};

const replaceAt = (string: string, index: number, replacement: string) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};

export const formatTime = (value: any) => {
    let viewResult = '';

    if (!value) {
        return value
    }

    // xx:xx - xx:xx

    const onlyNums = integerOnly(value);

    viewResult += onlyNums.slice(0, 2);

    if (Number(onlyNums.slice(0, 2)) > 24) {
        viewResult = replaceAt(viewResult, 0, '24');
    }

    if (onlyNums.length > 2) {
        viewResult += ':';
    }

    if (Number(onlyNums.slice(0, 2)) > 23) {
        viewResult = replaceAt(viewResult, 0, '23');
    }

    viewResult += onlyNums.slice(2, 4);

    const firstMinutes = Number(onlyNums.slice(2, 4));

    if (firstMinutes > 50) {
        viewResult = replaceAt(viewResult, 3, '50');
    }

    if (onlyNums.slice(2, 4).length === 2 && firstMinutes % 10 !== 0) {
        viewResult = replaceAt(viewResult, 4, '0');
    }

    if (onlyNums.length > 4) {
        viewResult += ' - ';
    }

    viewResult += onlyNums.slice(4, 6);

    if (Number(onlyNums.slice(4, 6)) > 23) {
        viewResult = replaceAt(viewResult, 8, '23');
    }

    if (onlyNums.length > 6) {
        viewResult += ':';
    }

    viewResult += onlyNums.slice(6, 8);

    const secondMinutes = Number(onlyNums.slice(6, 8));

    if (secondMinutes > 50) {
        viewResult = replaceAt(viewResult, 11, '50');
    }

    if (onlyNums.slice(6, 8).length === 2 && secondMinutes % 10 !== 0) {
        viewResult = replaceAt(viewResult, 11, '00');
    }

    return viewResult;
};

export const formatTimeSimple = (value: any) => {
    let viewResult = '';

    if (!value) {
        return value
    }

    // xx:xx
    const onlyNums = integerOnly(value);

    viewResult += onlyNums.slice(0, 2);

    if (onlyNums.length === 1 && onlyNums[0] > 2) {
        viewResult = replaceAt(viewResult, 0, `0${onlyNums[0]}`)
    }
    
    if (Number(onlyNums.slice(0, 2)) > 23) {
        viewResult = replaceAt(viewResult, 0, '23');
    }

    if (onlyNums.length > 2) {
        viewResult += ':';
    }

    viewResult += onlyNums.slice(2, 4);

    const firstMinutes = Number(onlyNums.slice(2, 4));

    if (firstMinutes > 50) {
        viewResult = replaceAt(viewResult, 3, '50');
    }

    if (onlyNums.slice(2, 4).length === 2 && firstMinutes % 10 > 0) {
        viewResult = replaceAt(viewResult, 4, '0');
    }

    return viewResult;
};

export const parseTime = (val: any) => {
    return val.replace(/\D/g, '')
};
