import { AnyAction } from "redux";
import { CLOSE_POPUP, OPEN_POPUP } from "../constants";

export interface Popup {
    key: string;
    Component: any;
    initialData: null,
    fullScreen?: boolean;
    alwaysFullScreen?: boolean;
}

interface IState {
    popups: Popup[]
}

let initialState = {
    popups: []
};

export const popups = (state: IState = initialState, action: AnyAction) => {
    switch (action.type) {
        case OPEN_POPUP:
            return {
                popups: [
                    ...state.popups,
                    { ...action.popup, initialData: action.initialData }
                ]
            };
        case CLOSE_POPUP:
            return {
                popups: state.popups.filter(
                    popup => popup.key !== action.popup.key
                ),
            };
        default:
            return state;
    }
};
