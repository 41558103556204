import React from "react";

import Icon from "../Icon";
import { IPropsClassName } from "../../types/props";
import { joinClassNames } from "../../helpers/className";

import styles from './index.module.scss'

interface IProps extends IPropsClassName {
    onClick?: (val: any) => any;
    disabled?: true | false
}

const SearchButton = ({ disabled, onClick }: IProps) => {
    const classNames = joinClassNames(
        styles.searchButton,
        disabled && styles.disabled,
    )
    return (
        <Icon icon="searchButton" className={classNames} onClick={!disabled ? onClick : () => {}} />
    )
};

export default SearchButton;
