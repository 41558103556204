import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import './index.scss';

function getDefaultValue(placeholder: string) {
    return {
        name: placeholder,
        value: ''
    };
}

interface IProps {
    options: any[],
    placeholder: string,
    onChange: (v?: any) => any,
    value: any,
    className: string,
    renderItem: (v?: any, c?: any) => any,
    isSelected: (v?: any, c?: any, d?: any) => any,
    keyName: string,
    keyValue: string,
    baseClassName: string,
    disabled?: boolean,
    selectClass?: string,
    i18n: any,
    itemClassName?: string,
    selectedItemClassName?: string,
    hideDisabledClass?: boolean
}

class Dropdown extends React.Component<IProps> {
    mounted: boolean;

    static defaultProps = {
        baseClassName: 'dropdown',
        className: '',
        options: [],
        keyValue: 'id',
        keyName: 'name',
        placeholder: '',
        renderItem: (item: any, keyName: string) => item[keyName],
        isSelected: (option: any, stateSelected: any, keyValue: any) => option[keyValue] === stateSelected[keyValue]
    };

    constructor(props: any) {
        super(props);
        this.state = {
            selected: props.value || getDefaultValue(props.i18n.Dropdown.placeholder),
            isOpen: false,
            placeholder: props.i18n.Dropdown.placeholder
        };

        this.mounted = true;
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.fireChangeEvent = this.fireChangeEvent.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, false);
        document.addEventListener('touchend', this.handleDocumentClick, false);
    }

    componentWillReceiveProps(nextProps: any) {
        let currentState: any = this.state;
        if (nextProps.value !== currentState.selected) {
            this.setState({
                selected: nextProps.value || getDefaultValue(nextProps.i18n.Dropdown.placeholder),
                placeholder: nextProps.i18n.Dropdown.placeholder
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick, false);
        document.removeEventListener('touchend', this.handleDocumentClick, false);
    }

    handleMouseDown(event: any) {
        let currentState: any = this.state;
        if (event.type === 'mousedown' && event.button !== 0) return;
        event.stopPropagation();
        event.preventDefault();

        if (!this.props.disabled) {
            this.setState({
                isOpen: !currentState.isOpen
            });
        }
    }

    setValue(selected: any) {
        const newState = {
            selected,
            isOpen: false
        };

        this.setState(newState);
        this.fireChangeEvent(newState);
    }

    fireChangeEvent(newState: any) {
        this.props.onChange(newState.selected);
    }

    renderOption(option: any, key: any, selectedValue: any) {
        const { renderItem, keyName, selectedItemClassName, itemClassName, baseClassName } = this.props;
        const liContent = renderItem(option, keyName);
        const liProps = {
            key,
            className: `${baseClassName}-option ${itemClassName} ${option.name === selectedValue ? selectedItemClassName : ''}`,
            onMouseDown: this.setValue.bind(this, option),
            onClick: this.setValue.bind(this, option)
        };

        return this.generateElement('li', liProps, liContent);
    }

    renderSelect(selectedValue: any) {
        const { options, baseClassName, selectClass } = this.props;
        const ops = options.map((option, index) => this.renderOption(option, index, selectedValue));

        return ops.length
            ? <div className={`${baseClassName}-select ${selectClass} `}>
                <ul>{ops}</ul>
            </div>
            : <div className={`${baseClassName}-noresults`}>{this.props.i18n.common.no_options_found}</div>;
    }

    handleDocumentClick(event: any) {
        let dom: any = ReactDOM;

        if (this.mounted) {
            if (!dom.findDOMNode(this).contains(event.target)) {
                this.setState({ isOpen: false });
            }
        }
    }

    generateElement(HtmlElement: any, props: any, value: any) {
        if (React.isValidElement(value)) {
            return (<HtmlElement {...props} >{value}</HtmlElement>);
        }
        return (<HtmlElement {...props} dangerouslySetInnerHTML={{ __html: value }} />);
    }

    render() {
        let currentState: any = this.state;
        const { baseClassName, className, keyName, disabled, hideDisabledClass } = this.props;
        const disabledClass = disabled && !hideDisabledClass ? `${baseClassName}-disabled` : '';
        const dropdownClass = `${baseClassName} ${currentState.isOpen ? 'is-open' : ''}`;
        const selectedValue = currentState.selected[keyName];
        const placeholder = currentState.placeholder;
        const elementProps = {
            className: `${baseClassName}-option ${placeholder === selectedValue ? 'placeholder' : ''}`
        };

        return (
            <div className={`${dropdownClass} ${className}`}>
                <div className={`${baseClassName}-current ${disabledClass}`}
                    onMouseDown={this.handleMouseDown.bind(this)}
                    onTouchEnd={this.handleMouseDown.bind(this)}>
                    {this.generateElement('div', elementProps, selectedValue)}
                </div>
                {currentState.isOpen && this.renderSelect(selectedValue)}
            </div>
        );
    }
}


const mapStateToProps = ({ i18n }: any) => {
    return { i18n };
};

export default connect(mapStateToProps)(Dropdown);
