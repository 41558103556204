import {joinClassNames} from "../../helpers/className";
import React from "react";
import styles from './index.module.scss'

interface Props {
    className: string;
    children?: any;
    is?: string;
}

const Label = ({children, className}: Props) => {
    return (
        <div className={joinClassNames(styles.Label, className)}>
            {children}
        </div>
    );
};

Label.defaultProps = {
    className: ''
};

export default Label;
