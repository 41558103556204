import {useCallback} from "react";
import StoreService from '../../services/StorageService';

const SAVED_TIME_FOR_5_STEP = "5StepTimer";
const MS_IN_SEC = 1000;

const StorageService = new StoreService();

export default function useSaveTime() {

    const startTimer = useCallback(() => {
        const currentTime = Date.now();
        StorageService.set(SAVED_TIME_FOR_5_STEP, currentTime);
    }, []);

    const getSavedTime = useCallback(() => {
        return StorageService.get(SAVED_TIME_FOR_5_STEP, Date.now());
    }, []);

    const getTimerValueInSeconds = useCallback(() => {
        return (Date.now() - getSavedTime()) / MS_IN_SEC;
    }, [getSavedTime]);

    const clearTimerValues = useCallback(() => {
        StorageService.remove(SAVED_TIME_FOR_5_STEP);
    }, []);

    return {startTimer, getSavedTime, getTimerValueInSeconds, clearTimerValues}
}