export const BADGES_CO = 'badges_co';
export const BADGES_SC = 'badges_sc';

export const REQUEST_CREATED = 'request_created';
export const REQUEST_CANCELLED_SC = 'request_cancelled_sc';
export const CONTRACT_WAITING_FOR_CONFIRMATION = 'contract_waiting_for_confirmation';
export const WAITING_FOR_CONFIRMATION_CANCELLED_SC = 'waiting_for_confirmation_cancelled_sc';
export const OFFER_DECLINED_SC = 'offer_declined_sc';
export const CONTRACT_CANCELLED_SC = 'contract_cancelled_sc';

export const OFFER_CREATED = 'offer_created';
export const DIRECT_OFFER_CREATED = 'direct_offer_created';
export const OFFER_DECLINED_CO = 'offer_declined_co';
export const REQUEST_CANCELLED_CO = 'request_cancelled_co';
export const NO_OFFER_REQUEST_CANCELLED = 'request_cancelled_sc_without_offer';

export const CONTRACT_CONFIRMED = 'contract_confirmed';
export const CONTRACT_CANCELLED_CO = 'contract_cancelled_co';

export const CONTRACT_IN_PROGRESS_SC = 'contract_in_progress_sc';
export const CONTRACT_COMPLETED_SC = 'contract_completed_sc';

export const CONTRACT_IN_PROGRESS_CO = 'contract_in_progress_co';
export const CONTRACT_COMPLETED_CO = 'contract_completed_co';

export const BADGE_REVIEWS = 'review_badge_sc';

export const SC_ACTIVATED = 'sc_activated';