/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import DateUtilities from "./utils";
import Calendar from "./Calendar";

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        display: "flex",
        margin: 0,
    }
}));

function initState(selectedDates) {
    return {
        selectedDates: selectedDates ? [...selectedDates] : [],
        minDate: null,
        maxDate: null
    };
}

function reducer(state, action) {
    switch (action.type) {
        case "setSelectedDates":
            return { ...state, selectedDates: action.payload };
        default:
            return new Error("wrong action type in multiple date picker reducer");
    }
}

const DatePicker = ({
    open,
    readOnly,
    onCancel,
    onSubmit,
    minDate,
    maxDate,
    onChange,
    isOfferTime,
    maxCount,
    suggestedDates,
    cancelButtonText,
    suggestedEndTime,
    selectedDates: outerSelectedDates,
    submitButtonText = "Submit",
    i18n,
    invalid
}) => {
    if (cancelButtonText == null) {
        cancelButtonText = readOnly ? "Dismiss" : "Cancel";
    }

    const [{ selectedDates }, dispatch] = useReducer(
        reducer,
        outerSelectedDates,
        initState
    );

    const classes = useStyles();

    const onSelect = useCallback(day => {
        if (readOnly) return;

        if (DateUtilities.dateIn(selectedDates, day)) {
            dispatch({
                type: "setSelectedDates",
                payload: selectedDates.filter(date => !DateUtilities.isSameDay(date, day))
            });
            onChange(selectedDates.filter(date => !DateUtilities.isSameDay(date, day)));
        } else {
            let payload = (!!selectedDates && selectedDates.length > maxCount)
                ? [...selectedDates, day] : [...selectedDates, day].slice(0, 10);

            if (maxCount === 1) {
                payload = [day];
            }
            dispatch({ type: "setSelectedDates", payload });
            onChange([...selectedDates, day]);
        }
    }, [selectedDates, dispatch, readOnly]);

    const onRemoveAtIndex = useCallback(index => {
        if (readOnly) return;
        const newDates = [...selectedDates];
        if (index > -1) {
            newDates.splice(index, 1);
        }
        onChange(newDates);
        dispatch({ type: "setSelectedDates", payload: newDates });
    }, [selectedDates, dispatch, readOnly]);

    const dismiss = useCallback(() => {
        onCancel();
    }, [dispatch, onCancel]);

    const handleCancel = useCallback(e => {
        e.preventDefault();
        dismiss();
    }, [dismiss]);

    const handleOk = useCallback(e => {
        e.preventDefault();
        if (readOnly) return;
        onSubmit(selectedDates);
    }, [onSubmit, selectedDates, readOnly]);

    return (
        <Dialog open={open}
            maxWidth={"md"}
            classes={{ paper: classes.dialogPaper, }}>
            <Calendar
                selectedDates={selectedDates}
                suggestedDates={suggestedDates}
                onSelect={onSelect}
                onRemoveAtIndex={onRemoveAtIndex}
                minDate={minDate}
                maxDate={maxDate}
                onCancel={handleCancel}
                onOk={handleOk}
                suggestedEndTime={suggestedEndTime}
                readOnly={readOnly}
                cancelButtonText={cancelButtonText}
                submitButtonText={submitButtonText}
                selectedDatesTitle={i18n.common.selected_dates}
                isOfferTime={isOfferTime}
                invalid={invalid || !selectedDates.length}
            />
        </Dialog>
    );
};

DatePicker.propTypes = {
    open: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedDates: PropTypes.array,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    selectedDatesTitle: PropTypes.string,
    suggestedDates: PropTypes.array,
    isOfferTime: PropTypes.bool,
    suggestedEndTime: PropTypes.string
};

const mapStateToProps = ({ i18n }) => {
    return { i18n };
}

export default connect(mapStateToProps)(DatePicker);
