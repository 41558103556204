import Http from "./HttpService";
import { ScreensEnum } from "../pages/user/dashboard/create-new-booking/screens/screens.enum";
import { createRequestModel, ModelTypes } from '../pipes/find-request';

enum CustomScreensEnum {
    TypeOfService = 0,
    Location = 1,
    DateAndTime = 2,
    CarsInformation = 3,
    PersonalInfo = 4,
}

class ContractsService extends Http {

    public createBooking(model: typeof ModelTypes) {
        const requestModel: typeof ModelTypes = createRequestModel(model);
        requestModel.serviceCenterId = +model[ScreensEnum.TypeOfService].serviceCenterId;

        return this.post(`/contracts/requests`, requestModel);
    }

    public createFavoriteBooking(model: typeof ModelTypes) {
        const requestModel = createRequestModel(model);
        requestModel.favourites = model[ScreensEnum.TypeOfService].favorites;

        return this.post('contracts/requests/find-service', requestModel);
    }

    public createFindRequest(model: typeof ModelTypes, isCustomEnum?: boolean) {

        const EnumOfScreens = isCustomEnum ? CustomScreensEnum : ScreensEnum;

        const hasDataTime = !!model[EnumOfScreens.DateAndTime]
        const requestModel: typeof ModelTypes = createRequestModel(model);

        requestModel.radius = !!hasDataTime ? model[EnumOfScreens.Location]?.radius.value : undefined;

        return this.post(`/contracts/requests/find-service`, requestModel)
            .then(({ data }: any) => data);
    }

    public acceptOffer(id: number) {
        return this.patch(`/contracts/offers/${id}/accept`);
    }

    public declineOffer(id: number) {
        return this.patch(`/contracts/offers/${id}/decline`);
    }

    public getOfferInfoById(id: number) {
        return this.get(`/contracts/offers/${id}`)
            .then(({ data }: any) => data);
    }

    public cancelFindRequest(id: number) {
        return this.patch(`/contracts/requests/find-service/${id}/cancel`)
    }

    public declineNewRequest(id: number) {
        return this.patch(`/contracts/requests/${id}`);
    }

    public cancelRequest(id: number) {
        return this.patch(`/contracts/requests/${id}/cancel`)
    }

    public confirmContract(id: number) {
        return this.patch(`/contracts/${id}/confirm`)
    }

    public declineContract(id: number, reason: string) {
        return this.patch(`/contracts/${id}/decline`, reason)
    }

    public confirmContractAsCarOwner(id: number) {
        return this.patch(`/contracts/offers/${id}/accept`)
    }

    public declineContractAsCarOwner(id: number) {
        return this.patch(`/contracts/offers/${id}/decline`)
    }

    public cancelBooking(id: number, reason: string) {
        return this.patch(`/contracts/bookings/${id}/cancel`, reason)
    }

    public createOffer(id: number, model: any) {
        return this.post(`/contracts/${id}/offers`, model)
    }

    public completeContract(id: number, model?: any) {
        if (model && model.report)
            return this.patch(`contracts/${id}/complete`, model);

        return this.patch(`contracts/${id}/complete`, {});
    }

    public addReport(id: number, model: any) {
        return this.patch(`contracts/${id}/report`, model)
    }
}


export default ContractsService;
