import moment from "moment";
import query from 'querystring';
import Http from "./HttpService";

import { IEmailConfirmationModel, IForgotPasswordModel, ILoginModel } from "../types/models";

class UsersService extends Http {

    public restorePassword(model: IForgotPasswordModel) {
        return this.post('/verifications/car-owner/password', model);
    }

    public confirmEmail(model: IEmailConfirmationModel) {
        return this.put('/verifications/email', model);
    }

    public login(model: ILoginModel, role: string) {
        const offset = moment().utcOffset() / 60;
        return this.post(`/sessions/${role}`, { timeZoneOffset: offset, ...model })
    }

    public logout() {
        return this.delete('/sessions/');
    }

    public checkUserEmail(value: string) {
        const obj = {
            email: value
        };

        return this.get(`/verifications/email?${query.stringify(obj)}`)
    }
}

export default UsersService;
