/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import moment from "moment";

// moment.js languages
import 'moment/locale/et';
import 'moment/locale/ru';

// react-toastify styles
import 'react-toastify/dist/ReactToastify.css';

import { KEY_LANGUAGE, KEY_ROLE, KEY_SESSION, KEY_VERSION } from './const/local-storage';
import { DEFAULT_LANGUAGE } from './const/languages';
import { setLanguage } from './redux/actions/i18n';
import { Popup } from './redux/reducers';
import StoreService from './services/StorageService';
import AppRoutes from './pages/Routes';
import Loader from "./components/Loader";
import { joinClassNames } from "./helpers/className";
import FirebaseService from "./services/FirebaseService";
import { closePopup, getRequestsBadgeCounter, getReviewsBadgeCounter } from './redux/actions';
import { MOBILE_BP, ROLE } from './const/users';
import CookiesPolicyBanner from "./components/CookiesPolicyBanner";
import ApplePWABanner from './components/ApplePWABanner';
import VerificationService from './services/VerificationService';

toast.configure();

interface Version {
    data: {
        version: number,
    }
}

const App: React.FC = (props: any) => {
    const { popups, setLanguage, isOpenedMenu, loader, closePopup, getServiceReview, getBadges, i18n } = props;
    const sessionStorage = new StoreService();
    const firebaseService = useRef<FirebaseService>();
    // Initial language setup
    const lang = sessionStorage.get(KEY_LANGUAGE, DEFAULT_LANGUAGE);
    const isLoggedIn = !!sessionStorage.get(KEY_SESSION);
    const role = sessionStorage.get(KEY_ROLE);
    const matches = useMediaQuery(MOBILE_BP);
    const popupPadding = matches ? '32px 24px' : '32px';

    const makeDialogStyles = makeStyles({
        paper: {
            padding: popupPadding,
            borderRadius: '0px'
        },
        paperFullWidth: {
            width: 'calc(100% - 32px)',
            margin: '16px'
        },
        paperFullScreen: {
            margin: 0,
            width: '100%'
        },
        h5: {
            marginBottom: 'initial'
        }
    });

    const makeFullScreenStyles = makeStyles({
        paper: {
            padding: '0',
            borderRadius: '0px'
        },
        paperFullWidth: {
            width: '100%',
            margin: '0'
        },
        paperFullScreen: {
            margin: 0,
            width: '100%'
        },
        h5: {
            marginBottom: 'initial'
        }
    });

    const styles = makeDialogStyles();
    const fullScreenStyles = makeFullScreenStyles();
    const [open, setOpen] = useState(!!popups.length);
    const [latestVersion, setLatestVersion] = useState<boolean>(false);
    const clearCacheAndReload = () => {
        if (caches) {
            caches.keys()
                .then((names) => {
                    return Promise.all(names.map((name) => caches.delete(name)))
                })
                .then(() => {
                    window.location.reload(true);
                });
        }
    };

    useEffect(() => {
        firebaseService.current = new FirebaseService();
        // cache reset
        const localVersion = sessionStorage.get(KEY_VERSION);
        new VerificationService().verifyVersion()
            .then(({ data }: Version) => {
                if (localVersion && localVersion !== data.version) {
                    clearCacheAndReload();
                }
                sessionStorage.set(KEY_VERSION, data.version);
                setLanguage(lang.shortName);
                setLatestVersion(true);
            });
    }, [])

    useEffect(() => {
        moment.locale(lang.origin);
    }, [i18n]);

    useEffect(() => {
        if (isLoggedIn && !!firebaseService.current && firebaseService.current.isSupported) {
            firebaseService.current.registerDevice();
        }

        if (isLoggedIn && role === ROLE.BUSINESS) {
            getServiceReview();
            getBadges();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        setOpen(!!popups.length)
    }, [popups]);

    useEffect(() => {
        // disable background scrolling on popup or mobile menu opened
        if (isOpenedMenu || (popups.length && matches)) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [isOpenedMenu, popups, popups.length]);

    return (
        <>
            { loader && <Loader />}
            <ToastContainer className='toastContainer' />
            { latestVersion && (
                <div className={joinClassNames('content-wrapper', loader ? 'blurry' : undefined)}>
                    <AppRoutes />
                    {
                        popups.map(({ Component, key, fullScreen, alwaysFullScreen, ...props }: Popup) => (
                            <Dialog
                                classes={!!alwaysFullScreen ? fullScreenStyles : styles}
                                fullWidth={matches}
                                fullScreen={(matches && !!fullScreen) || alwaysFullScreen}
                                maxWidth='md'
                                onClose={() => {
                                    closePopup(key);
                                    setOpen(false);
                                }}
                                scroll="paper"
                                key={key}
                                open={open}>
                                <Component {...props} uuid={key} />
                            </Dialog>
                        ))
                    }
                </div>
            )}
            { <CookiesPolicyBanner />}
            { <ApplePWABanner />}
        </>
    );
};

const mapStateToProps = (state: any) => {
    const { popups, isOpenedMenu, loader, i18n } = state;
    return { popups: popups.popups, isOpenedMenu, loader, i18n };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLanguage: setLanguage(dispatch),
    closePopup: closePopup(dispatch),
    getServiceReview: getReviewsBadgeCounter(dispatch),
    getBadges: getRequestsBadgeCounter(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
