import Label from "../Label";
import { FORM_CONTROL_ELEMENT } from "../FormControl";
import Input from "../Input";
import ErrorMessage from "../ErrorMessage";
import FormControl from "../FormControl";
import React from "react";
import Icon from '../Icon';
import { Props } from '../Input';

interface InputProps extends Props {
    clearValues?: any
    iconClassName?: string
    isIconVisible?: boolean
}

const FormControlInput = (props: InputProps) => {
    let { color, disabled, withBorder } = props;
    return (
        <FormControl {...props}>
            <Label is={FORM_CONTROL_ELEMENT.LABEL} />
            <Input color={color} is={FORM_CONTROL_ELEMENT.INPUT} disabled={disabled} withBorder={withBorder} />
            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
        </FormControl>
    );
};

export const FormControlInputClearIcon = (props: InputProps) => {
    const { color, disabled, withBorder, clearValues, iconClassName, isIconVisible } = props;
    return (
        <FormControl {...props}>
            <Label is={FORM_CONTROL_ELEMENT.LABEL} />
            <Input color={color} is={FORM_CONTROL_ELEMENT.INPUT} disabled={disabled} withBorder={withBorder} />
            {isIconVisible && <Icon icon='clear' onClick={clearValues} className={iconClassName} />}
            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
        </FormControl>
    )
}

export default FormControlInput;
