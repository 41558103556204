
export const GET_COMPANY_TYPES = 'GET_COMPANY_TYPES';

export const GET_SERVICES_BY_COMPANY_TYPES = 'GET_SERVICES_BY_COMPANY_TYPES';

export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';

export const GET_MY_SERVICES = 'GET_MY_SERVICES';
export const GET_SERVICE_CENTERS_SUCCESS = 'GET_SERVICE_CENTERS_SUCCESS';
export const GET_SERVICE_CENTERS_FAIL = 'GET_SERVICE_CENTERS_SUCCESS';
export const GET_SERVICE_CENTERS_REQUEST = 'GET_SERVICE_CENTERS_REQUEST';

export const SERVICE_CENTER_INFO_REQUEST = 'SERVICE_CENTER_INFO_REQUEST';
export const SERVICE_CENTER_INFO_SUCCESS = 'SERVICE_CENTER_INFO_SUCCESS';
export const SERVICE_CENTER_INFO_FAIL = 'SERVICE_CENTER_INFO_FAIL';

export const GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS = 'GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS';
export const GET_SERVICE_CENTER_INFO_BY_ID_FAIL = 'GET_SERVICE_CENTER_INFO_BY_ID_FAIL';

export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_FAIL = 'GET_REQUESTS_FAIL';

export const GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST';
export const GET_REQUESTS_REQUEST_SUCCESS = 'GET_REQUESTS_REQUEST_SUCCESS';
export const GET_REQUESTS_REQUEST_FAIL = 'GET_REQUESTS_REQUEST_FAIL';

export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL';

export const GET_ON_MAP = 'GET_ON_MAP';
export const GET_ON_MAP_SUCCESS = 'GET_ON_MAP_SUCCESS';
export const GET_ON_MAP_FAIL = 'GET_ON_MAP_FAIL';

export const GET_SERVICE_REVIEWS_BY_ID_SUCCESS = 'GET_SERVICE_REVIEWS_BY_ID_SUCCESS';
export const GET_SERVICE_REVIEWS_BY_ID_FAIL = 'GET_SERVICE_REVIEWS_BY_ID_FAIL';
