import ChunkLoader from '../helpers/ChunkLoader';
import SignOut from './sign-out';
import ConfirmEmail from "./confirm-email";
import ConfirmChangeEmail from "./confirm-change-email";
import RegistrationWithBooking from './registration-with-booking';
import PotencialServices from './registration-with-booking/registration-with-booking-success/PotencialServices';

const Business = ChunkLoader(() => import('./business'));
const User = ChunkLoader(() => import('./user'));
const Page404 = ChunkLoader(() => import('./page-404'));
const LandingPage = ChunkLoader(() => import('./landing-page'));
const GuestSignUpComplete = ChunkLoader(() => import('./registration-with-booking/registration-with-booking-success'));
const PrivacyPolicy = ChunkLoader(() => import('./privacy-policy'));
const Terms = ChunkLoader(() => import('./terms-of-use'))

export default {
    BUSINESS: {
        exact: false,
        component: Business,
        path: '/business',
    },
    USER: {
        exact: false,
        component: User,
        path: '/user',
    },
    CONFIRM_EMAIL: {
        exact: true,
        component: ConfirmEmail,
        path: '/confirm-email',
    },
    LANDING_PAGE: {
        exact: true,
        component: LandingPage,
        path: '/',
    },
    CREATE_NEW_BOOKING: {
        exact: true,
        component: RegistrationWithBooking,
        path: '/registration-with-booking',
    },
    POTENCIAL_CAR_SERVICES: {
        exact: true,
        component: PotencialServices,
        path: '/potencial-car-services',
    },
    CONFIRM_CHANGE_EMAIL: {
        exact: true,
        component: ConfirmChangeEmail,
        path: '/confirm-change-email',
    },
    SIGN_OUT: {
        exact: true,
        component: SignOut,
        path: '/sign-out',
    },
    GUEST_SIGN_UP_SUCCESS: {
        exact: true,
        component: GuestSignUpComplete,
        path: '/sign-up-complete'
    },
    PRIVACY_POLICY: {
        exact: true,
        component: PrivacyPolicy,
        path: '/policy'
    },
    TERMS: {
        exact: true,
        component: Terms,
        path: '/terms'

    },
    NOT_FOUND: {
        component: Page404,
    },
};
