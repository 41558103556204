import { AnyAction } from 'redux';
import {
    BADGE_CO_GET_REQUESTS_API,
    BADGES_CO_WS,
    CONTRACT_CANCELLED,
    CONTRACT_STATUS_CHANGED,
    GET_BOOKINGS,
    GET_BOOKINGS_FAIL,
    GET_BOOKINGS_SUCCESS,
    GET_FAVORITES_REQUEST,
    GET_FAVORITES_REQUEST_FAIL,
    GET_FAVORITES_REQUEST_SUCCESS,
    GET_FIND_SERVICE_REQUEST_FAIL,
    GET_FIND_SERVICE_REQUEST_SUCCESS,
    GET_SELECTED_FAVORITES,
    MY_OFFERS_CREATE_FIND_SERVICE_FAIL,
    MY_OFFERS_INFO_FAIL,
    MY_OFFERS_INFO_REQUEST,
    MY_OFFERS_INFO_SUCCESS,
    MY_VEHICLES_INFO_FAIL,
    MY_VEHICLES_INFO_REQUEST,
    MY_VEHICLES_INFO_SUCCESS,
    NEW_CONTRACT_COMPLETED,
    NEW_CONTRACT_CONFIRMED,
    NEW_DIRECT_OFFER,
    NEW_OFFER,
    OFFER_CANCELED,
    REDIRECT_SUCCESS,
    REQUEST_CANCELLED,
    SET_SELECTED_FAVORITES,
    CLEAR_ERROR,
} from '../constants';
import bookingCreationState from '../../const/booking-creation-state';
import { PAGINATION_LIMIT } from "../../const/pagination";
import { CONTRACT_STATUS } from "../../const/contract-status";
import updateDataLayer from "../../utils/data-layer";
import { BOOKING_STATUS_WEIGHT } from "../../const/bookingStatusWeight";

export const myVehicles = (state = { collection: [], pagination: {}, wait: true }, action: AnyAction) => {

    switch (action.type) {
        case MY_VEHICLES_INFO_REQUEST:
            return { ...state, wait: true };

        case MY_VEHICLES_INFO_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case MY_VEHICLES_INFO_FAIL:
            return { ...state, wait: false };

        default:
            return state;
    }
};

const defaultOffersState = {
    collection: [],
    pagination: {},
    bookingState: bookingCreationState.pending,
    wait: false
};
export const myOffers = (state = defaultOffersState, action: AnyAction) => {
    switch (action.type) {
        case MY_OFFERS_INFO_REQUEST:
            return { ...state, wait: true, bookingState: bookingCreationState.pending };

        case MY_OFFERS_INFO_SUCCESS:
            return {
                ...state,
                wait: false,
                bookingState: bookingCreationState.findService,
                collection: action.payload.data,
                pagination: action.payload.pagination
            };

        case MY_OFFERS_INFO_FAIL:
            return { ...state, wait: false };

        case MY_OFFERS_CREATE_FIND_SERVICE_FAIL:
            return { ...state, bookingState: bookingCreationState.creation, wait: false };

        case OFFER_CANCELED:
            return {
                ...state,
                collection: state.collection.map((booking: any) => {
                    booking.contractStatus = action.payload.contractStatus;
                    return booking.id === action.payload.id ? action.payload : booking
                })
            };

        case NEW_OFFER:
            return {
                ...state,
                bookingState: bookingCreationState.findService,
                collection: [action.payload].concat(state.collection)
            };
        default:
            return state;
    }
};

const defaultBookingsState = {
    wait: false,
    type: `Requested`,
    collection: [],
    pagination: {},
    redirect: {
        required: false,
        to: '/'
    }
};

export const bookings = (state = defaultBookingsState, action: AnyAction) => {
    switch (action.type) {
        case GET_BOOKINGS:
            return { ...state, type: action.payload, wait: true };

        case GET_BOOKINGS_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case GET_BOOKINGS_FAIL:
            return { ...state, collection: [], pagination: {}, wait: false };

        case NEW_DIRECT_OFFER:
        case CONTRACT_STATUS_CHANGED:
            return {
                ...state,
                collection: state.collection.map((booking: any) => booking.id === action.payload.id ? action.payload : booking)
            };

        case NEW_CONTRACT_CONFIRMED:
            updateDataLayer({
                'eventCategory': 'booking',
                'eventAction': 'final accept',
                'eventLabel': '9  step',
                'event': 'autoEvent',
            });

            if (window.location.href.includes('/user/dashboard/find-service')) {
                return {
                    ...state,
                    redirect: {
                        required: true,
                        to: '/user/dashboard/booking-history'
                    }
                };
            }

            return {
                ...state,
                collection: [action.payload, ...state.collection.filter((booking: any) => booking.id !== action.payload.id)]
            }

        case REDIRECT_SUCCESS:
            return {
                ...state,
                redirect: {
                    required: false,
                    to: ''
                }
            };

        case NEW_CONTRACT_COMPLETED:
            return moveBetweenColumns(state, action.payload, CONTRACT_STATUS.Completed, true);

        case REQUEST_CANCELLED:
            return {
                ...state,
                collection: state.collection.filter((booking: any) => booking.id !== action.payload.id)
            };

        case CONTRACT_CANCELLED:
            return moveBetweenColumns(state, action.payload, CONTRACT_STATUS.CancelledByServiceOwner);

        default:
            return state;
    }
};

function moveBetweenColumns(state = defaultBookingsState, payload: AnyAction, type: string, triggerSort = false) {
    // This condition is available form my bookings page (CO role)
    if (state.type === undefined || triggerSort) {
        // Attach element with updated data
        const collection = [payload]
            .concat(state.collection.filter((booking: any) => booking.id !== payload.id));

        // Updated order
        collection.sort((a: any, b: any) => {
            const weight: any = BOOKING_STATUS_WEIGHT;
            return weight[a.contractStatus] - weight[b.contractStatus];
        });

        return {
            ...state,
            collection
        };
    }
    return state.type === type
        ? {
            ...state,
            collection: [payload].concat(state.collection.slice(0, PAGINATION_LIMIT - 2))
        }
        : {
            ...state,
            collection: state.collection.filter((booking: any) => booking.id !== payload.id)
        };
}


export const favorites = (state = { collection: [], pagination: {}, wait: true }, action: AnyAction) => {
    switch (action.type) {

        case GET_FAVORITES_REQUEST:
            return { ...state, wait: true, collection: [], pagination: {} };

        case GET_FAVORITES_REQUEST_SUCCESS:
            return { ...state, wait: false, collection: action.payload.data, pagination: action.payload.pagination };

        case GET_FAVORITES_REQUEST_FAIL:
            return { ...state, wait: false, collection: [], pagination: {} };
        default:
            return state;
    }
};

export const selectedFavorites = (state = { collection: [] }, action: AnyAction) => {

    switch (action.type) {
        case SET_SELECTED_FAVORITES:
            return { ...state, collection: action.data };

        case GET_SELECTED_FAVORITES:
            return { ...state };

        default:
            return state;
    }
};

const BADGES = {
    active: '',
    inActive: '',
    all: '',
};

export const carOwnerBadges = (state = BADGES, action: AnyAction) => {
    switch (action.type) {
        case BADGE_CO_GET_REQUESTS_API:
            return { ...state, ...action.payload.data };

        case BADGES_CO_WS:
            return { ...state, ...action.payload }

        default:
            return { ...state };
    }
};

const defaultFindServiceRequestState = {
    id: null,
    radius: null,
    service: {
        id: null,
        businessTypeId: null,
        businessTypeName: null,
        name: '',
        defaultDescription: ''
    },
    address: null, // object
    contractVehicle: {
        id: null,
        registrationNumber: '',
        brand: '',
        model: '',
        year: '',
        volume: null,
        fuelType: null,
        enginePower: null,
        mileage: null
    },
    startTime: null,
    endTime: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    availableDates: [],
    createdAt: null,
    updatedAt: null,
    error: false,
};

export const findServiceRequest = (state = defaultFindServiceRequestState, action: AnyAction) => {
    switch (action.type) {
        case GET_FIND_SERVICE_REQUEST_SUCCESS:
            return { ...state, ...action.payload, error: false, };
        case GET_FIND_SERVICE_REQUEST_FAIL:
            return { ...state, error: true, };
        case CLEAR_ERROR:
            return { ...state, error: false }
        default:
            return { ...state };
    }
};
