import React from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../../redux/actions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogTitle from '../../components/Dialog/DialogTitle';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import { joinClassNames } from '../../helpers/className';

import styles from '../ConfirmPopup/index.module.scss'

class ConfirmPopupFullScreen extends React.Component {
    title: string;
    action: string;
    cancel: string;
    color: 'primary' | 'danger' | 'default' | 'secondary';
    callback: () => any;
    text: string;
    withoutCancel: boolean;
    cancelCallback: () => any;
    confirmButton?: any;
    isReport?: boolean;
    isReason?: boolean;
    textClassName?: string;
    wrapperClassName?: string;
    titleClassName?: string;

    constructor(props: any) {
        super(props);
        let { initialData } = props;
        let {
            title,
            action,
            color,
            callback,
            wrapperClassName,
            cancel,
            text,
            withoutCancel,
            cancelCallback,
            confirmButtonClassName,
            isReport,
            isReason,
            textClassName,
            titleClassName,
        } = initialData;
        this.title = title || 'Title';
        this.isReport = isReport;
        this.isReason = isReason;
        this.action = action || 'Ok';
        this.cancel = cancel || props.i18n.common.cancel;
        this.color = color || 'primary';
        this.confirmButton = joinClassNames(styles.button, confirmButtonClassName)
        this.callback = callback;
        this.cancelCallback = cancelCallback;
        this.text = text;
        this.withoutCancel = withoutCancel;
        this.textClassName = textClassName;
        this.wrapperClassName = wrapperClassName;
        this.titleClassName = titleClassName;
    }

    public close = () => {
        let props: any = this.props;

        props.closePopup(props.uuid);
    };

    render() {
        let titleEmpty = !this.title || this.title === ' ';

        let textStyle = joinClassNames(
            titleEmpty ? styles.titleText :
                this.isReport ? styles.report : styles.text
        );

        textStyle = this.isReason ? styles.textReason : textStyle;
        textStyle = !!this.textClassName ? this.textClassName : textStyle;

        let buttonsStyles = this.isReport ? styles.reportButtons : styles.buttons;
        buttonsStyles = this.isReason ? styles.reasonButtons : buttonsStyles;

        if (typeof (this.title) === 'string') {
            titleEmpty = this.title.trim().length === 0
        }

        return (
            <>
                <StyledDialogTitle>
                    {!!this.text && !titleEmpty &&
                        <div className={joinClassNames(this.isReason ? styles.titleReason : styles.title, this.titleClassName)}>{this.title}</div>
                    }
                    <div className={styles.close} onClick={this.close}>
                        <Icon icon="close" />
                    </div>
                </StyledDialogTitle>

                <StyledDialogContent fullScreen={true}>
                    <Flex className={styles.fullScreenContent}>
                        <Flex className={this.wrapperClassName ? this.wrapperClassName :
                            this.isReason ? 'mb-auto' : styles.fullScreenTitle
                        }>
                            {!!this.text
                                ? <div className={textStyle}>{this.text}</div>
                                : <div className={this.isReport ? styles.titleRepot : styles.title}>{this.title}</div>
                            }
                        </Flex>

                        <Flex className={buttonsStyles}>
                            {!this.withoutCancel &&
                                <Button
                                    variant={'outlined'}
                                    className={styles.button}
                                    onClick={() => {
                                        if (this.cancelCallback) {
                                            this.cancelCallback();
                                        }
                                        this.close();
                                    }}>
                                    {this.cancel}
                                </Button>
                            }

                            <Button
                                color={this.color}
                                variant={'text'}
                                className={this.isReport ? '' : this.confirmButton}
                                onClick={
                                    () => {
                                        this.callback();
                                        this.close();
                                    }
                                }
                                type={'submit'}>
                                {this.action}
                            </Button>
                        </Flex>
                    </Flex>
                </StyledDialogContent>
            </>
        );
    }
}

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch)
});

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopupFullScreen);
