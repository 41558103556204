export const LANGUAGES = {
    EN: {
        shortName: 'en',
        fullName: 'English',
        origin: 'en',
        name: "EN",
        value: 0
    },
    EE: {
        shortName: 'ee',
        fullName: 'Eesti',
        origin: 'et',
        name: "EE",
        value: 1
    },
    RU: {
        shortName: 'ru',
        fullName: 'Русский',
        origin: 'ru',
        name: "RU",
        value: 2
    },
};

export const DEFAULT_LANGUAGE = LANGUAGES.EE;