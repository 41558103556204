import Guard from "./Guard";
import StoreService from "../services/StorageService";
import {KEY_SESSION} from "../const/local-storage";


class KeepInSystem extends Guard{

    canActivate() {
        let sessionStorage = new StoreService();
        let session = sessionStorage.get(KEY_SESSION);

        return !session;
    }
}

export default KeepInSystem;
