import {FormConfig} from "../../types/formConfig";
import {trimLeft} from "../../pipes/trim";
import Validator from "../../validators";
import {
    SERVICE_CENTER_DESC_MAX_LENGTH, SERVICE_CENTER_DESC_MIN_LENGTH,

} from "../../const/validation";

let formConfig: FormConfig = {
    reason: {
        name: 'reason',
        label: 'Reason',
        placeholder: '',
        maxLength: SERVICE_CENTER_DESC_MAX_LENGTH,
        format: trimLeft,
        validate: [
            Validator.required(),
            Validator.minLength(SERVICE_CENTER_DESC_MIN_LENGTH)
        ]
    }
};


export default formConfig;

