/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import uuid from 'uuid/v4';
import { useLocation } from 'react-router-dom';
import { FormControlInputClearIcon } from '../../../../../../components/FormControlInput/index';
import { FUEL_TYPES, GEARBOX } from '../../../../../../const/validation';
import Button from '../../../../../../components/Button/index';
import Icon from '../../../../../../components/Icon/index';
import Flex from '../../../../../../components/Flex/index';
import SmallCarCard from '../../../../../../components/SmallCarCard/index';
import { joinClassNames } from '../../../../../../helpers/className';
import StoreService from '../../../../../../services/StorageService';
import { KEY_NEW_BOOKING_STEPS, KEY_USER_ID, KEY_SAVE_TO_VEHICLES } from '../../../../../../const/local-storage';
import { ScreensEnum } from '../screens.enum';
import CarOwnersService from '../../../../../../services/CarOwnersService';
import { clearEmptyFields } from '../../../../../../popups/AddVehicle';
import { getMyVehicles, openPopup } from '../../../../../../redux/actions';
import formInternalization from '../../../../../../utils/form-internalization';
import SearchButton from '../../../../../../components/SearchButton';
import formConfig from './form-config'
import Divider from "../../../../../../components/Divider/dividerWithText";
import CarForm from './CarForm';

import styles from '../../index.module.scss';
import formStyles from './index.module.scss';

let initialValues = {
    registrationNumber: '',
    brand: '',
    model: '',
    year: '',
    mileage: '',
    enginePower: '',
    fuelType: '',
    gearbox: '',
    volume: '',
};

const clearValues: any = {
    brand: '',
    model: '',
    year: '',
    mileage: '',
    enginePower: '',
    fuelType: '',
    gearbox: '',
    volume: '',
};

let isFilled = false;

const COMMON_PAGE_NUMBER = ScreensEnum.CarsInformation;
const TOW_PAGE_NUMBER = 3;

const CarsInformation = (props: any) => {
    const { getMyVehicles, myVehicles, i18n, $values, isTowTruck, notRegistered, isLastStep } = props;
    const { collection } = myVehicles;
    const storage = new StoreService();
    const location = useLocation();

    const fuelTypes = FUEL_TYPES.map(item => ({ id: item, name: i18n.fuels[item] }));
    const gearboxTypes = GEARBOX.map(item => ({ id: item, name: i18n.gears[item] }));
    const [carInfoDisabled, setCarInfoDisabled] = useState(false);
    const [isOpenEnterManually, setIsOpenEnterManually] = useState(false);

    const filledSteps = useMemo(() => storage.get(KEY_NEW_BOOKING_STEPS, []), [location]);
    const user = useMemo(() => storage.get(KEY_USER_ID), [location]);

    const currentPageNumber = isTowTruck ? TOW_PAGE_NUMBER : COMMON_PAGE_NUMBER;

    const steps = useMemo(() => filledSteps[currentPageNumber], [filledSteps]);

    const [selectedCar, setSelectedCar] = useState<any>();
    const [brandDropdownValue, setBrandDropdownValue] = useState<any>((steps && steps.brand) || '');

    const [brandId, setBrandId] = useState<any>(0);
    const [modelName, setModelName] = useState<any>((steps && steps.model) || '');

    useEffect(() => {
        !notRegistered && getMyVehicles(0, 50);

        return () => {
            isFilled = false;
        }
    }, [getMyVehicles]);

    const [isOpen, updateStateOpen] = useState<any>(false);
    const [fuelType, setFuelType] = useState<any>();
    const [gearbox, setGearbox] = useState<any>();
    const [saveToMyVehicles, setSaveToMyVehicles] = useState<any>(notRegistered);

    useEffect(() => {
        if(notRegistered){
            storage.set(KEY_SAVE_TO_VEHICLES, saveToMyVehicles);
        }
    }, [saveToMyVehicles]);

    useEffect(() => {
        if (myVehicles && myVehicles.collection && user) {
            updateStateOpen(!!myVehicles.collection.length);
        }
    }, [myVehicles]);

    useEffect(() => {
        if (steps && steps.registrationNumber) {
            setCarInfoDisabled(true);
        }
    }, [steps]);

    useEffect(() => {
        fuelType && setFuelType(fuelTypes.find((element: any) => element.id === fuelType.id));
        gearbox && setGearbox(gearboxTypes.find((element: any) => element.id === gearbox.id));
    }, [i18n]);

    if (steps && !isFilled) {
        for (const [key, value] of Object.entries(steps)) {
            props.change(key, value);
        }
        setSelectedCar(steps?.selectedCar)
        setFuelType(fuelTypes.find((element: any) => element.id === steps.fuelType));
        setGearbox(gearboxTypes.find((element: any) => element.id === steps.gearbox));
        isFilled = true;
    }

    const handleBrandSelect = (val: any) => {
        const { name, id } = val;
        setBrandDropdownValue(name);
        setBrandId(id);
        props.change('brand', name);
    };

    const handleModelSelect = (val: any) => {
        const { name } = val;
        setModelName(name);
        props.change('model', name)
    };

    const clearData = () => {
        setBrandId(0);
        setModelName('');
        setFuelType(null);
        setGearbox(null);
        setIsOpenEnterManually(false);
        setCarInfoDisabled(false);
        Object.keys(clearValues).map(key => props.change(key, clearValues[key]));
        filledSteps[currentPageNumber] = null;
        storage.set(KEY_NEW_BOOKING_STEPS, filledSteps);
    }

    const fillModel = (model: any) => {
        Object.keys(model).map(item => props.change(item, model[item]));
        setModelName(model.brand)
        setFuelType(fuelTypes.find((element: any) => element.id === model.fuelType));
        setGearbox(gearboxTypes.find((element: any) => element.id === model.gearbox));
        setCarInfoDisabled(true);
        filledSteps[currentPageNumber] = model;
        storage.set(KEY_NEW_BOOKING_STEPS, filledSteps);
    };

    const findMyCar = () => {
        new CarOwnersService().findCar($values.registrationNumber.toUpperCase())
            .then(({ data }: any) => { fillModel(data); setIsOpenEnterManually(false) })
            .catch(() => clearData());
    };

    const handleSelect = (car: any) => setSelectedCar(car);

    formInternalization(formConfig);

    useEffect(() => {
        filledSteps[currentPageNumber] = props.$values;
        storage.set(KEY_NEW_BOOKING_STEPS, filledSteps);
    }, [props.$values])

    useEffect(() => {
        return () => {
            setCarInfoDisabled(false);
        }
    }, [])

    return (
        <>
            {collection && !!collection.length && user &&
                <Flex className={joinClassNames(styles.ToggleButton, isOpen && styles.ToggleButtonSelected)}
                    onClick={() => updateStateOpen(true)}>
                    <Icon icon='car' />
                    {i18n.common.my_vehicles_list}
                </Flex>}

            {isOpen && <div className={styles.carsContainer}>
                {collection && collection.map((element: any) =>
                    <SmallCarCard
                        key={element.id}
                        car={element}
                        selectedId={selectedCar && selectedCar.id}
                        handleSelect={handleSelect} />)}
            </div>}

            {user &&
                <Flex className={joinClassNames(styles.ToggleButton, styles.offsetTop, !isOpen && styles.ToggleButtonSelected)}
                    onClick={() => {
                        updateStateOpen(false)
                        setSelectedCar(null)
                    }}>
                    <Icon icon='add' />
                    {i18n.common.add_a_new_vehicle}
                </Flex>
            }

            {isOpen &&
                <Flex className={joinClassNames(styles.buttons, formStyles.buttons)}>
                    <Button
                        variant='outlined'
                        onClick={() => props.onSubmit(null)}
                        className={styles.button}>
                        {i18n.common.previous}
                    </Button>

                    <Button
                        type='submit'
                        color='secondary'
                        className={styles.button}
                        disabled={!selectedCar}
                        onClick={() => {
                            props.onSubmit({ selectedCar, carsCount: collection.length });
                        }}
                        variant='text'>
                        {!!isTowTruck ? i18n.common.finish : i18n.common.next_step}
                    </Button>
                </Flex>}

            {!isOpen && <form
                className={styles.form}
                onSubmit={props.handleSubmit}>

                <Flex className={formStyles.findCarWrapper}>
                    <FormControlInputClearIcon
                        withBorder
                        {...formConfig.registrationNumber}
                        iconClassName={formStyles.clearIcon}
                        clearValues={() => { props.reset(); clearData() }}
                        isIconVisible={!!$values.registrationNumber}
                    />

                    <SearchButton onClick={findMyCar}
                        className={formStyles.findCar}
                        disabled={$values.registrationNumber.length < 4}
                    />
                </Flex>

                {!isOpenEnterManually && carInfoDisabled &&
                    <section className={formStyles.preffiledWrapper}>
                        <CarForm
                            formChange={props.change}
                            formConfig={formConfig}
                            carInfoDisabled={!isOpenEnterManually}
                            i18n={i18n}
                            brandId={brandId}
                            brandDropdownValue={brandDropdownValue}
                            handleBrandSelect={handleBrandSelect}
                            modelName={modelName}
                            handleModelSelect={handleModelSelect}
                            saveToMyVehicles={saveToMyVehicles}
                            setSaveToMyVehicles={setSaveToMyVehicles}
                            setFuelType={setFuelType}
                            fuelTypes={fuelTypes}
                            fuelType={fuelType}
                            setGearbox={setGearbox}
                            gearboxTypes={gearboxTypes}
                            gearbox={gearbox}
                        />
                    </section>
                }

                <Divider text={i18n.find_request_page.divider_text} className={formStyles.dividerMargins} />

                <div className={(carInfoDisabled || isOpenEnterManually) ? formStyles.addCardInfo : formStyles.hiddenCarInfo}
                    onClick={() => setIsOpenEnterManually(!isOpenEnterManually)}>
                    {!isOpenEnterManually ? "+ " : "- "}{i18n.common.add_car_info}
                </div>

                {isOpenEnterManually &&
                    <section className={formStyles.manuallyWrapper}>
                        <CarForm
                            formChange={props.change}
                            formConfig={formConfig}
                            carInfoDisabled={!isOpenEnterManually}
                            i18n={i18n}
                            brandId={brandId}
                            brandDropdownValue={brandDropdownValue}
                            handleBrandSelect={handleBrandSelect}
                            modelName={modelName}
                            handleModelSelect={handleModelSelect}
                            saveToMyVehicles={saveToMyVehicles}
                            setSaveToMyVehicles={setSaveToMyVehicles}
                            setFuelType={setFuelType}
                            fuelTypes={fuelTypes}
                            fuelType={fuelType}
                            setGearbox={setGearbox}
                            gearboxTypes={gearboxTypes}
                            gearbox={gearbox}
                        />
                    </section>
                }

                <Flex className={joinClassNames(styles.buttons, formStyles.buttons)}>
                    <Button
                        variant={'outlined'}
                        onClick={() => props.onSubmit(null)}
                        className={styles.button}>
                        {i18n.common.previous}
                    </Button>

                    <Button type="submit"
                        large={true}
                        small={true}
                        fontSize={16}
                        onClick={() => {
                            if (!saveToMyVehicles) {
                                return;
                            }
                            const model = clearEmptyFields({ ...$values });
                            !notRegistered && new CarOwnersService()
                                .addVehicles(model);
                        }}
                        disabled={!modelName || props.invalid || !$values.registrationNumber}
                        className={joinClassNames(styles.button, formStyles.finishButton)}
                        color={'secondary'}
                        variant={'text'}>
                        {(isTowTruck || isLastStep) ? i18n.common.finish : i18n.common.next_step}
                    </Button>
                </Flex>
            </form>
            }
        </>
    )
};

const uuidForm = uuid();
const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[uuidForm] || {};
    let { myVehicles } = state;
    return { $values: values, myVehicles, i18n: state.i18n }
};

const mapDispatchToProps = (dispatch: any) => ({
    getMyVehicles: getMyVehicles(dispatch),
    openPopup: openPopup(dispatch),
});

export default reduxForm({
    form: uuidForm,
    initialValues,
})(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(CarsInformation))
)

