import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'

import Week from './Week'
import { defaultUtils as utils } from './dateUtils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    lineHeight: '1.25',
    position: 'relative'
  }
})

class Weeks extends Component {
  render() {
    const { classes } = this.props
    return <div className={classes.root}>{this.renderWeeks(this.props.displayDate)}</div>
  }

  renderWeeks = () => {
    const weekArray = utils.getWeekArray(
      this.props.displayDate,
      moment.localeData().firstDayOfWeek()
    )

    let suggestedDates
    if (this.props.suggestedDates && this.props.suggestedDates.length)
      suggestedDates = this.props.suggestedDates.map(date => (new Date(date)))

    return weekArray.map(
      (s, i) => (
        <Week
          key={i}
          week={s}
          selectedDates={this.props.selectedDates}
          suggestedDates={suggestedDates}
          onSelect={this.props.onSelect}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          readOnly={this.props.readOnly}
        />
      ),
      this
    )
  }
}

export default withStyles(styles)(Weeks)
