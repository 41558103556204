import React from 'react'
import styles from './index.module.scss'
import {joinClassNames} from "../../helpers/className";
import {COLOR} from "../../const/colors";

interface IRadioProps {
    name: string
    value?: any
    label?: string
    is?: string;
    disabled?: boolean;
    className?: string;
    onChange?: () => any;
    color?: 'primary' | 'secondary';
}

 const Radio = ({label, name, value, onChange, className, color, ...props}: IRadioProps) => {
    const container = color === COLOR.SECONDARY ?
        joinClassNames(styles.container, styles.containerSecondary) : styles.container;
    return (
        <label className={joinClassNames(container, className)}>  {label}
            <input type="radio"
                   name={name}
                   checked={value}
                   value={value}
                   onChange={onChange}
                   defaultChecked={value}
                   {...props}
            />
            <span className={styles.checkmark}/>
        </label>
)};



export default Radio
