import React from "react";
import { connect } from "react-redux";

import { closePopup, getMyServiceCenterInfo, getServiceCenterById } from "../../redux/actions";
import ServiceCentersService from "../../services/ServiceCentersService";
import BranchesService from '../../services/BranchesService';
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import Flex from "../../components/Flex";
import { TOW_TRUCK_RANGE_VALUES } from "../../const/tow-truck-range-values";

import styles from "./index.module.scss";
import StyledDialogContent from "../../components/Dialog/DialogContent";
import StyledDialogActions from "../../components/Dialog/DialogActions";
import StyledDialogTitle from "../../components/Dialog/DialogTitle";
import Headline from "../../components/Headline";
import { ActionButtonClose } from "../../components/ActionButton";
import Divider from "../../components/Divider";

interface IProps {
    closePopup: () => any;
    initialData: any;
    getServiceCenterById: any;
}

class TowRangePopup extends React.Component<IProps> {
    i18n: any;
    isBranch: boolean = false;

    constructor(props: any) {
        super(props);
        this.i18n = props.i18n;
        const { initialData: { service, isBranch } } = props;
        const initialRange = !!service.maxDistance && service.maxDistance;
        const initialValue = {
            value: initialRange,
            name: initialRange + ' ' + this.i18n.common.km
        }

        this.state = {
            range: !!initialRange ? initialValue : null
        }
        this.isBranch = isBranch;
    }

    public close = () => {
        let props: any = this.props;

        let { initialData = {} } = this.props;
        let { redirect } = initialData;
        props.closePopup(props.uuid);
        if (redirect) { redirect() };
    };

    public submit = () => {
        const props: any = this.props;
        const state: any = this.state;
        const { initialData: { service }, getMyServiceCenterInfo, getServiceCenterById } = props;
        const { range } = state;
        const { image, description, businessTypes, branchName, id } = service;
        const types = businessTypes.map((item: any) => item.id);
        const Service = new ServiceCentersService();
        const BranchService = new BranchesService();

        const requestModel = {
            description,
            branchName,
            types,
            imageId: image ? image.id : null,
            maxDistance: range.value
        }

        this.isBranch
            ? BranchService.editBranchInfo(id, requestModel)
                .then(() => getServiceCenterById(id))
                .then(() => this.close())
            : Service.updateMyServiceCenterInfo(requestModel)
                .then(() => getMyServiceCenterInfo(id))
                .then(() => this.close());
    }

    render() {
        let { initialData = {} } = this.props;
        const { range } = this.state as any;
        const { color } = initialData;
        const translatedRangeValues = TOW_TRUCK_RANGE_VALUES
            .map((item: any) => ({ value: item, name: `${item} ${this.i18n.common.km}` }))

        return (
            <>
                <StyledDialogTitle>
                    <Flex className={styles.title}>
                        <Headline type={'h5'}>
                            {this.i18n.common.set_tow_range}
                        </Headline>
                        <ActionButtonClose onClick={this.close} className={styles.close}> </ActionButtonClose>
                    </Flex>
                </StyledDialogTitle>
                <Divider />

                <StyledDialogContent>
                    <Dropdown
                        onChange={(val) => { this.setState({ range: val }) }}
                        options={translatedRangeValues}
                        value={range}
                        selectClass={styles.select}
                    />
                </StyledDialogContent>

                <StyledDialogActions>
                    <Flex className={styles.buttons}>
                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.btn}
                            color='default'
                            onClick={this.close}
                            variant='outlined'>
                            {this.i18n.common.close}
                        </Button>

                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.btn}
                            color={(!!color && color) || "secondary"}
                            onClick={this.submit}
                            variant="text">
                            {this.i18n.common.save}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
    closePopup: closePopup(dispatch),
    getServiceCenterById: getServiceCenterById(dispatch)
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n };
};

export default connect(mapStateToProps, mapDispatchToProps)(TowRangePopup);
