import StoreService from "../services/StorageService";
import {KEY_ROLE} from "../const/local-storage";
import {ROLE} from "../const/users";
import Guard from "./Guard";

class UserRoleGuard extends Guard {

    canActivate() {
        let sessionStorage = new StoreService();
        let role = sessionStorage.get(KEY_ROLE);

        if (role === ROLE.BUSINESS) {
            return this.currentUrl.includes('/business/');
        }

        if (role === ROLE.USER) {
            return this.currentUrl.includes('/user/');
        }

        return false;
    }
}

export default UserRoleGuard;
