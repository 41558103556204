import { Dispatch } from "redux";
import {
    GET_ALL_SERVICES,
    GET_COMPANY_TYPES,
    GET_MY_SERVICES,
    GET_ON_MAP,
    GET_ON_MAP_FAIL,
    GET_ON_MAP_SUCCESS,
    GET_REQUESTS_FAIL,
    GET_REQUESTS_REQUEST_FAIL,
    GET_REQUESTS_REQUEST_SUCCESS,
    GET_REQUESTS_SUCCESS,
    GET_SERVICE_CENTER_INFO_BY_ID_FAIL,
    GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS,
    GET_SERVICE_CENTERS_FAIL,
    GET_SERVICE_CENTERS_REQUEST,
    GET_SERVICE_CENTERS_SUCCESS,
    GET_SERVICE_REVIEWS_BY_ID_FAIL,
    GET_SERVICE_REVIEWS_BY_ID_SUCCESS,
    GET_SERVICES_BY_COMPANY_TYPES,
    GET_STATISTICS,
    GET_STATISTICS_FAIL,
    GET_STATISTICS_SUCCESS,
    SERVICE_CENTER_INFO_FAIL,
    SERVICE_CENTER_INFO_REQUEST,
    SERVICE_CENTER_INFO_SUCCESS,
    BADGE_SC_GET_REQUESTS_API,
    BADGE_REVIEWS_API_SUCCESS,
    BADGE_REVIEWS_API_FAIL
} from "../constants";
import ServiceCentersService from "../../services/ServiceCentersService";
import { CompanyType } from "../../types/CompanyType";
import CarOwnersService from "../../services/CarOwnersService";

export const getCompanyTypes = (dispatch: Dispatch) => () => {

    new ServiceCentersService().getTypeOfCompanies()
        .then(({ data }: any) => data)
        .then((payload: CompanyType[]) => dispatch({ type: GET_COMPANY_TYPES, payload }));
};


export const getMyServiceCenterInfo = (dispatch: Dispatch) => () => {

    dispatch({ type: SERVICE_CENTER_INFO_REQUEST });

    return new ServiceCentersService().getMyServiceCenterInfo()
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: SERVICE_CENTER_INFO_SUCCESS, payload }))
        .catch(() => dispatch({ type: SERVICE_CENTER_INFO_FAIL }));
};


export const getServices = (dispatch: Dispatch) => (types: number[]) => {

    return new ServiceCentersService().getServices({ types })
        .then(({ data }: any) => data)
        .then((payload: CompanyType[]) => dispatch({ type: GET_SERVICES_BY_COMPANY_TYPES, payload }));
};

export const getAllServices = (dispatch: Dispatch) => () => {

    return new ServiceCentersService().getAllServices()
        .then((data: any) => data)
        .then((payload: CompanyType[]) => dispatch({ type: GET_ALL_SERVICES, payload }));
};

export const getServiceCenters = (dispatch: Dispatch) => (model: any) => {

    dispatch({ type: GET_SERVICE_CENTERS_REQUEST });

    return new ServiceCentersService().getServiceCenters(model)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_SERVICE_CENTERS_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_SERVICE_CENTERS_FAIL }));
};

export const getServiceCenterById = (dispatch: Dispatch) => (id: number) => {

    return new ServiceCentersService().getServiceCenterById(id)
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS, payload }))
        .catch((error: Error) => {
            dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_FAIL });
            throw error;
        });
};

export const getMyServices = (dispatch: Dispatch) => (model: any) => {
    new ServiceCentersService().getMyServices(model)
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: GET_MY_SERVICES, payload }));
};

export const getRequests = (dispatch: Dispatch) => (type: string, offset: number, businessTypes: number[], search: string) => {

    return new ServiceCentersService()
        .getContracts(type, offset, businessTypes, search)
        .then((data: any) => ({ ...data, type }))
        .then((payload: any) => dispatch({ type: GET_REQUESTS_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_REQUESTS_FAIL }));
};

export const getNewRequests = (dispatch: Dispatch) => (offset: number, businessTypes: number[], search: string) => {

    return new ServiceCentersService()
        .getRequests(offset, businessTypes, search)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_REQUESTS_REQUEST_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_REQUESTS_REQUEST_FAIL }));
};

export const getStatistics = (dispatch: Dispatch) => (scope: string) => {

    dispatch({ type: GET_STATISTICS });

    return new ServiceCentersService().getStatistics(scope)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_STATISTICS_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_STATISTICS_FAIL }));
};

export const getServiceCentersOnMap = (dispatch: Dispatch) => (model: any) => {

    dispatch({ type: GET_ON_MAP });

    return new ServiceCentersService().getServiceCentersOnMap(model)
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: GET_ON_MAP_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_ON_MAP_FAIL }));
};

export const getReviewsBadgeCounter = (dispatch: Dispatch) => () => {

    return new ServiceCentersService().getReviewsBadge()
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: BADGE_REVIEWS_API_SUCCESS, payload }))
        .catch(() => dispatch({ type: BADGE_REVIEWS_API_FAIL }));
}

export const getRequestsBadgeCounter = (dispatch: Dispatch) => () => {

    return new ServiceCentersService().getBadgeRequests()
        .then((data: any) => data)
        .then((payload: any) => dispatch({ type: BADGE_SC_GET_REQUESTS_API, payload }))
        .catch(() => dispatch({ type: GET_ON_MAP_FAIL }));
};

export const getServiceReviewsById = (dispatch: Dispatch) => (id: number, offset: number) => {

    return new ServiceCentersService().getServiceReviewsById(id, offset)
        .then((payload: any) => {
            return dispatch({ type: GET_SERVICE_REVIEWS_BY_ID_SUCCESS, payload });
        })
        .catch(() => dispatch({ type: GET_SERVICE_REVIEWS_BY_ID_FAIL }));
};

export const addServiceToFavourites = (dispatch: Dispatch) => (id: number) => {
    return new CarOwnersService().addToFavorite(id)
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_FAIL }));
};

export const removeServiceFromFavorite = (dispatch: Dispatch) => (id: number) => {
    return new CarOwnersService().deleteFromFavorite(id)
        .then(({ data }: any) => data)
        .then((payload: any) => dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_SUCCESS, payload }))
        .catch(() => dispatch({ type: GET_SERVICE_CENTER_INFO_BY_ID_FAIL }));
};
