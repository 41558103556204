import { AnyAction } from "redux";

import {
  SEND_VERIFICATION,
  SEND_VERIFICATION_FAIL,
  SEND_VERIFICATION_SUCCESS,
  SEND_VERIFICATION_COOLDOWN,
} from "../constants";

const defaultResetState = {
  wait: false,
  onCooldown: false
};

export const verification = (state = defaultResetState, action: AnyAction) => {
  switch (action.type) {
    case SEND_VERIFICATION:
      return { ...state, wait: true };

    case SEND_VERIFICATION_SUCCESS:
      return { ...state, wait: false, onCooldown: true };

    case SEND_VERIFICATION_FAIL:
      return defaultResetState;

    case SEND_VERIFICATION_COOLDOWN:
      return { ...state, onCooldown: false };

    default:
      return state;
  };
};
