import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import uuid from "uuid/v4";

import Button from "../../../../../../components/Button/index";
import { default as FormControl, FORM_CONTROL_ELEMENT } from "../../../../../../components/FormControl/index";
import ErrorMessage from "../../../../../../components/ErrorMessage/index";
import Input from "../../../../../../components/Input/index";
import Label from "../../../../../../components/Label/index";
import { getQueryParams } from '../../../../../../utils/location';
import { joinClassNames } from "../../../../../../helpers/className";
import StoreService from "../../../../../../services/StorageService";
import { KEY_NEW_BOOKING_STEPS } from "../../../../../../const/local-storage";
import Radio from "../../../../../../components/Radio";
import Flex from "../../../../../../components/Flex";
import TimeSlider, { MS_IN_DAY } from "../../../../../../components/TimeSlider";
import MultiDatePicker from "../../../../../../components/MultiDatePicker";
import { msToTime, timeToMs } from "../../../../../../utils/time-utils";
import { ScreensEnum } from "../screens.enum";
import formConfig from "./form-config";
import formInternalization from "../../../../../../utils/form-internalization";
import { useLocation } from "react-router";

import formStyles from '../CarsInformation/index.module.scss';
import styles from '../../index.module.scss';

let DEFAULT_TIMES = [0, MS_IN_DAY];
const DEFAULT_TIMES_IN_STRING = '00:00 - 23:50';
const MAX_COUNT_OF_DATE = 10;

let isFilled = false;

const DateAndTime = (props: any) => {
    const [dates, setDates] = useState<any>([]);
    const [times, setTimes] = useState<any>();

    const { i18n, customPageNumber, customButtonText } = props;
    const storage = new StoreService();

    let filledSteps = storage.get(KEY_NEW_BOOKING_STEPS, []);
    let steps = filledSteps[customPageNumber || ScreensEnum.DateAndTime];

    const location = useLocation();

    useEffect(() => {
        return () => {
            isFilled = false;
        }
    }, []);

    let { isDirection } = getQueryParams();
    let finishButton = isDirection ? i18n.common.create_a_new_booking : i18n.common.search_service_center;
    if (steps && !isFilled) {
        props.change('date', steps.date);
        setDates(steps.date || []);

        if (steps.time && steps.time.length > 0) {
            props.change('time', steps.time);
            timeToMs(steps.time);
            setTimes(DEFAULT_TIMES);
        }

        isFilled = true;
    }

    useEffect(() => {
        if (steps?.time) {
            setTimes(timeToMs(steps.time))
        }
    }, [location])

    formInternalization(formConfig);

    return (
        <>
            <form className={styles.form} onSubmit={props.handleSubmit}
                onInput={() => {
                    DEFAULT_TIMES = timeToMs(props.$values.time);
                }}>
                <FormControl {...formConfig.date} className={styles.hidden}>
                    <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                    <Input is={FORM_CONTROL_ELEMENT.INPUT} />
                    <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                </FormControl>

                <MultiDatePicker
                    color='secondary'
                    dates={dates}
                    isOfferTime={false}
                    maxCount={MAX_COUNT_OF_DATE}
                    onChange={(currentDates) => {
                        const dates = currentDates.map(({ startDate }: any) => (startDate));
                        props.change('date', currentDates);
                        const newStepValues = {
                            date: currentDates,
                            time: props.$values.time,
                        }
                        filledSteps[2] = newStepValues;
                        storage.set(KEY_NEW_BOOKING_STEPS, filledSteps)
                        setDates(dates);
                    }}
                />

                <Radio
                    className={joinClassNames(styles.offsetTop, styles.radio)}
                    name="date"
                    value={!times}
                    onChange={() => {
                        setTimes(undefined);
                        props.change('time', '');
                    }}
                    label={i18n.common.any_time} />

                <Flex className={joinClassNames(styles.sliderWithRadio, styles.secondRadio, styles.radio)}>
                    <Radio
                        name="date"
                        className={styles.radio}
                        value={times}
                        onChange={() => {
                            props.change('time', DEFAULT_TIMES_IN_STRING);
                            timeToMs(DEFAULT_TIMES_IN_STRING);
                            setTimes([0, MS_IN_DAY]);
                        }}
                        label={i18n.common.time_window} />
                </Flex>

                {(times && times[0] !== `0:00` && times[1] !== `23:50`) &&
                    <Flex className={styles.sliderWithTime}>
                        <TimeSlider
                            color={'secondary'}
                            value={times}
                            onChange={(event: any, values: number[]) => {
                                setTimes(values);
                                const newTimeValues =  (msToTime(+values[0]) + ' - ' + msToTime(+values[1]));
                                props.change('time',newTimeValues);
                                const newStepValues = {
                                    date: props.$values.date,
                                    time: newTimeValues,
                                }
                                filledSteps[2] = newStepValues;
                                storage.set(KEY_NEW_BOOKING_STEPS, filledSteps)
                            }}
                        />

                        <FormControl {...formConfig.time} className={styles.fakeInput}>
                            <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                            <Input color={'secondary'} is={FORM_CONTROL_ELEMENT.INPUT} disabled/>
                            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                        </FormControl>
                    </Flex>
                }

                <Flex className={styles.buttons}>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            filledSteps[ScreensEnum.DateAndTime] = props.$values;
                            props.onSubmit(null);
                        }}
                        className={joinClassNames(styles.button)}>
                        {i18n.common.previous}
                    </Button>

                    <Button
                        color={'secondary'}
                        variant={"text"}
                        type={'submit'}
                        disabled={props.invalid || !!!dates.length}
                        className={joinClassNames(styles.button, formStyles.finishButton)}>
                        {customButtonText ? i18n.common.next_step : finishButton}
                    </Button>
                </Flex>
            </form>
        </>
    )
};

const form = uuid();

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n }
};

const Form = reduxForm({
    form,
    initialValues: {
        date: [],
        time: "",
    },
})(
    DateAndTime
);

export default connect(mapStateToProps)(Form)
