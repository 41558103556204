import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Rating from 'react-rating';

import BasePopup from '../BasePopup';
import { closePopup, createServiceReview, openPopup, updateServiceReview } from '../../redux/actions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import Label from '../../components/Label';
import Button from '../../components/Button';
import Caption from '../../components/Caption';
import TextareaField from '../../components/Textarea';
import Flex from '../../components/Flex';
import { default as FormControl, FORM_CONTROL_ELEMENT } from '../../components/FormControl';
import ErrorMessage from '../../components/ErrorMessage';
import formInternalization from '../../utils/form-internalization';
import { joinClassNames } from '../../helpers/className';
import StarFilled from '../../images/rating/ic-star-filled.svg';
import StarOutlined from '../../images/rating/ic-star-outline.svg';
import formConfig from './form-config';

import styles from './index.module.scss';

class SaveReviewPopup extends BasePopup {
    id: number;
    isReviewed: boolean;
    model: any;
    quickEdit: boolean;

    constructor(props: any) {
        super(props);
        const { initialData = {}, change } = props;
        const { id, title, isReviewed, model, quickEdit } = initialData;
        this.className = (!!model && model.reply) ? styles.CardNoPadding : styles.Card;
        this.id = id;
        this.title = title;
        this.isReviewed = isReviewed;
        this.state = { wait: false, isEditMode: !isReviewed, submitted: false };
        this.quickEdit = !!quickEdit;
        this.withDivider = false;

        if (model) {
            const { rating, text } = model;

            this.model = model;
            change('rating', rating);
            change('text', text);
        }
    }

    public save(model: any) {
        let props: any = this.props;
        const { createServiceReview, initialData, valid } = props;
        const { rating } = model;
        const { redirect } = initialData;
        const dataToSend = {
            ...model,
            complitedBookingId: this.id
        };
        this.setState({ submitted: true });

        if (valid && rating) {
            this.setState({ wait: true });
            createServiceReview(dataToSend)
                .then(() => {
                    this.setState({ wait: false });
                    this.close();
                    if (redirect) {
                        redirect();
                    }
                });
        }
    }

    public close = (skipRedirect = false) => {
        const props: any = this.props;
        const { initialData } = props;
        const { redirect } = initialData;
        props.closePopup(props.uuid);
        if (redirect && !skipRedirect) {
            redirect();
        }
    };

    componentDidMount() {
        !!this.quickEdit && this.setState({ isEditMode: true });
    }

    public renderContent(): any {
        const props: any = this.props;
        const state: any = this.state;
        const { handleSubmit, invalid, $values, i18n, change, updateServiceReview, initialData, anyTouched, touch, reset } = props;
        const { wait, isEditMode, submitted } = state;
        const { refresh, alter, model } = initialData;
        const { text, rating } = $values;
        const isDisabled = invalid || wait || (submitted && !rating) || rating < 1;
        const isRequiredRating = !rating && submitted;
        const handleEditClick = () => {
            if (isEditMode) {
                if (!anyTouched) {
                    this.setState({ isEditMode: !isEditMode });
                    return;
                }

                const dataToSend = {
                    ...$values,
                    complitedBookingId: this.id
                };

                updateServiceReview(dataToSend)
                    .then(() => {
                        this.setState({ isEditMode: !isEditMode });
                        const { rating, text } = $values;
                        reset();
                        change('rating', rating);
                        change('text', text);
                        !!refresh && refresh();
                        !!refresh && this.close();
                    });
            } else {
                this.setState({ isEditMode: !isEditMode });
            }
        };

        formInternalization(formConfig);

        const createActions = (
            <Flex className={styles.buttons}>
                <Button large={false}
                    small={true}
                    fontSize={16}
                    className={styles.btnCancel}
                    onClick={() => this.close(true)}
                    variant={'outlined'}>
                    {i18n.common.cancel}
                </Button>

                <Button
                    small={true}
                    fontSize={16}
                    className={styles.btnSave}
                    disabled={isDisabled}
                    color={'secondary'}
                    onClick={() => this.save($values)}
                    variant={'text'}>
                    {i18n.popups.send_a_review}
                </Button>
            </Flex>
        );

        const editActions = (
            <Flex className={styles.buttons}>
                <Button large={false}
                    small={true}
                    fontSize={16}
                    className={styles.btnCancel}
                    onClick={() => this.close(true)}
                    variant={'outlined'}
                >
                    {i18n.common.cancel}
                </Button>

                {!model?.reply ?
                    <Button
                        small={true}
                        fontSize={16}
                        className={styles.btnSave}
                        color={'secondary'}
                        onClick={handleEditClick}
                        disabled={isEditMode && invalid}
                        variant={'text'}>
                        {isEditMode ? i18n.common.save : i18n.common.edit}
                    </Button>
                    :
                    <></>
                }
            </Flex>
        );

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.paddingBottom}>
                            {!this.isReviewed &&
                                <Caption size={'14'}
                                    className={styles.caption}>{i18n.popups.service_rating}*</Caption>}
                            <Rating
                                readonly={!isEditMode}
                                initialRating={$values.rating}
                                onChange={(rating) => {
                                    touch(rating);
                                    change('rating', rating)
                                }}
                                emptySymbol={<img src={StarOutlined} className={styles.Star} alt="rating-empty" />}
                                fullSymbol={<img src={StarFilled} className={styles.Star} alt="rating-full" />}
                            />
                            {isRequiredRating && <ErrorMessage>{i18n.popups.service_rating_required}</ErrorMessage>}
                        </div>

                        {!isEditMode &&
                            <p className={joinClassNames(styles.formControl, styles.comment)}>{text}</p>}
                        {isEditMode && (
                            <FormControl {...formConfig.text} className={styles.formControl}>
                                {!this.isReviewed &&
                                    <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.caption} />}
                                <TextareaField className={alter ? styles.commentAlter : styles.comment}
                                    is={FORM_CONTROL_ELEMENT.TEXTAREA} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>
                        )}
                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    {(this.isReviewed) && editActions}
                    {!this.isReviewed && createActions}
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    const { i18n } = state;
    const { values = {} } = state.form[form] || {};
    return { $values: values, i18n };
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    openPopup: openPopup(dispatch),
    createServiceReview: createServiceReview(dispatch),
    updateServiceReview: updateServiceReview(dispatch)
});

const Popup: any = SaveReviewPopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        rating: 0,
        text: ''
    }
})(Popup);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
