import React from "react";
import {Field} from 'redux-form'

import {joinClassNames} from "../../helpers/className";
import {ValidatorFuncType} from "../../types/validatorFuncType";

import styles from './index.module.scss'
import Validator from "../../validators";

interface Props {
    className: string;
    name?: string; // TODO not optional
    children?: any;
    format?: (val: any) => any;
    parse?: (val: any) => any;
    normalize?: (val: any) => any;
    validate: ValidatorFuncType[]; //
}


export const FORM_CONTROL_ELEMENT = {
    LABEL: 'label',
    INPUT: 'input',
    TEXTAREA: 'textarea',
    ERROR_MESSAGE: 'error-message'
};

const formatErrorMessage = (message: string, params: any) => {
    const unitQuotedMarksRegExp = / *\([^)]*\) */;

    if (!message) return message;
    const errors = Validator.validationMessage();

    return message.toString()
        .replace(':label', params.label)
        .replace(/:error\[(.*?)]/g, (match, p1 ) => {
            return errors[p1];
        })
        .replace('*', '').replace(unitQuotedMarksRegExp, ' ');
};


const renderField = ({input, label, type, placeholder, maxLength, children, meta: {touched, error}}: any) => {
    let errorMessage = touched && error;

    return React.Children.toArray(children)
        .map((element: any) => {
            if (!React.isValidElement(element)) {
                return element;
            }

            let props: any = element.props;

            if ([FORM_CONTROL_ELEMENT.INPUT, FORM_CONTROL_ELEMENT.TEXTAREA].includes(props.is)) {
                return React.cloneElement(
                    element,
                    {
                        ...input,
                        placeholder,
                        maxLength,
                        isInvalid: !!errorMessage
                    },
                )
            }

            if (props.is === FORM_CONTROL_ELEMENT.ERROR_MESSAGE) {
                return React.cloneElement(
                    element,
                    {},
                    formatErrorMessage(errorMessage, {label})
                )
            }

            if (props.is === FORM_CONTROL_ELEMENT.LABEL) {
                return React.cloneElement(
                    element,
                    {},
                    label
                )
            }

            return element;
        });
};

const FormControl = ({children, className, name, parse, format, normalize, validate, ...props}: Props) => {

    return (
        <div className={joinClassNames(styles.formControl, className)}>
            <Field name={name}
                   type="text"
                   component={renderField}
                   format={format}
                   parse={parse}
                   normalize={normalize}
                   validate={validate}
                   {...props}
            >
                {children}
            </Field>
        </div>)
};


FormControl.defaultProps = {
    className: '',
    validate: [],
    format: (data: any) => data,
};

export default FormControl;
