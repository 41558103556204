import React from "react";
import { joinClassNames } from "../../helpers/className";
import styles from './index.module.scss'
import { IPropsClassName } from "../../types/props";

interface Props extends IPropsClassName {
    size: '12' | '14' | '16';
}

const sizes = {
    '12': styles.CaptionSize12,
    '14': styles.CaptionSize14,
    '16': styles.CaptionSize16,
};

const Caption = ({ className, children, size }: Props) => {
    const classes = joinClassNames(
        'Caption',
        styles.Caption,
        sizes[size],
        className)

    return (
        <p className={classes}>
            {children}
        </p>
    );
};

Caption.defaultProps = {
    className: '',
};

export default Caption;
