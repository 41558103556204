export const integerOnly = (value: any) => {

    if (!value) {
        return value
    }

    return value.toString().replace(/[^\d]/g, '');
};

export const floatOnly = (value: any) => {

    if (!value) {
        return value
    }

    let [int, ...float] = value.toString().replace(/[^\d.]/g, '').split('.');

    return `${int}${float.length ? '.' : ''}${float.join('').replace(/\./g, '').slice(0, 2)}`
};

export const formatPhone = (value: any) => {
    let viewResult = '';

    if (!value) {
        return value
    }

    // +(xxx) xxx-xxxx

    const onlyNums = integerOnly(value);

    if (onlyNums.length) {
        viewResult += '+';
    }

    viewResult += onlyNums.slice(0, 3);

    if (onlyNums.length > 3) {
        viewResult += ' ';
    }

    viewResult += onlyNums.slice(3, 6);

    if (onlyNums.length > 6) {
         viewResult += '-';
    }

    viewResult += onlyNums.slice(6);

    return viewResult;
};

export const parsePhone = (val: any) => {
    let hasPlus = val && val[0] === '+';

    return `${hasPlus ? '+' : ''}${val.replace(/\D/g, '')}`
};
