import React from 'react';

import { ReactComponent as Email } from '../../images/icons/ic-email.svg';
import { ReactComponent as Mail } from '../../images/icons/ic-mail.svg';
import { ReactComponent as Password } from '../../images/icons/ic-password.svg';
import { ReactComponent as Phone } from '../../images/icons/ic-phone.svg';
import { ReactComponent as User } from '../../images/icons/ic-user.svg';
import { ReactComponent as Profile } from '../../images/icons/ic-my-profile.svg';
import { ReactComponent as Statistic } from '../../images/icons/ic-statistic.svg';
import { ReactComponent as Bookings } from '../../images/icons/ic-bookings.svg';
import { ReactComponent as BookingHistory } from '../../images/icons/ic-booking-history.svg';
import { ReactComponent as Search } from '../../images/icons/ic-search.svg';
import { ReactComponent as Info } from '../../images/icons/ic-info.svg';
import { ReactComponent as InfoWarn } from '../../images/icons/ic-info-warn.svg';
import { ReactComponent as Edit } from '../../images/icons/ic-edit.svg';
import { ReactComponent as Clock } from '../../images/icons/ic-clock.svg';
import { ReactComponent as ClockGray } from '../../images/icons/ic-clock-2.svg';
import { ReactComponent as Branches } from '../../images/icons/ic-branches.svg';
import { ReactComponent as Location } from '../../images/icons/ic-location.svg';
import { ReactComponent as LocationFlat } from '../../images/icons/ic-location-flat.svg';
import { ReactComponent as Add } from '../../images/icons/ic-add.svg';
import { ReactComponent as Delete } from '../../images/icons/ic-delete.svg';
import { ReactComponent as Answer } from '../../images/icons/ic-answer.svg';
import { ReactComponent as Reply } from '../../images/icons/ic-reply.svg';
import { ReactComponent as Close } from '../../images/icons/ic-close.svg';
import { ReactComponent as SignOut } from '../../images/icons/ic-sing-out.svg';
import { ReactComponent as ServiceManagement } from '../../images/icons/ic-service-managment.svg';
import { ReactComponent as Car } from '../../images/icons/ic-car.svg';
import { ReactComponent as Favorite } from '../../images/icons/ic-favorite.svg';
import { ReactComponent as FavoriteSelected } from '../../images/icons/ic-favorite-selected.svg';
import { ReactComponent as CheckMark } from '../../images/icons/ic-checkmark.svg';
import { ReactComponent as Calendar } from '../../images/icons/ic-calendar.svg';
import { ReactComponent as Success } from '../../images/icons/ic-success.svg';
import { ReactComponent as Warning } from '../../images/icons/ic-warnming.svg';
import { ReactComponent as Ee } from '../../images/icons/estonian.svg';
import { ReactComponent as Ru } from '../../images/icons/ic-flag-rassian.svg';
import { ReactComponent as En } from '../../images/icons/ic-flag-england.svg';
import { ReactComponent as Review } from '../../images/icons/ic-review.svg';
import { ReactComponent as Camera } from '../../images/icons/ic-upload-logo.svg';
import { ReactComponent as Upload } from '../../images/icons/ic-upload.svg';
import { ReactComponent as Balon } from '../../images/icons/ic-balon.svg';
import { ReactComponent as DeleteCross } from '../../images/icons/ic-delete-cross.svg';
import { ReactComponent as More } from '../../images/icons/ic-more.svg';
import { ReactComponent as DropDown } from '../../images/icons/arrow_drop_down.svg';
import { ReactComponent as Cancelled } from '../../images/icons/ic_cancelled.svg';
import { ReactComponent as Confirmed } from '../../images/icons/ic_confirmed.svg';
import { ReactComponent as Missed } from '../../images/icons/ic_missed.svg';
import { ReactComponent as TotalRequests } from '../../images/icons/ic_total requests.svg';
import { ReactComponent as Back } from '../../images/icons/ic-back.svg';
import { ReactComponent as OnMap } from '../../images/icons/ic-pin-active.svg';
import { ReactComponent as List } from '../../images/icons/ic-list-inactive.svg';
import { ReactComponent as Burger } from '../../images/icons/ic_menu.svg';
import { ReactComponent as WatchReview } from '../../images/icons/ic-watch-review.svg';
import { ReactComponent as IconCarService } from '../../images/booking/ic-car-service.svg';
import { ReactComponent as IconCarWash } from '../../images/booking/ic-car-wash.svg';
import { ReactComponent as IconCheckUp } from '../../images/booking/ic-check-up.svg';
import { ReactComponent as IconTireWorks } from '../../images/booking/ic-tire-works.svg';
import { ReactComponent as IconTowTruck } from '../../images/booking/ic-tow-truck-accent.svg';
import { ReactComponent as IconTuning } from '../../images/booking/ic-tuning.svg';
import { ReactComponent as IconWashDetailing } from '../../images/booking/ic-wash-detailing.svg';
import { ReactComponent as IconFilters } from '../../images/icons/ic-filters.svg';
import { ReactComponent as IconWorker } from '../../images/partials/ic-worker.svg';
import { ReactComponent as IconBookmap } from '../../images/logos/ic-logo-white.svg';
import { ReactComponent as IconLogoYellow } from '../../images/logos/ic-logo-yellow.svg';
import { ReactComponent as LangArrow } from '../../images/icons/ic-lang-arrow.svg';
import { ReactComponent as IconArrowRight } from '../../images/icons/ic-arrow-right.svg';
import { ReactComponent as IconLangCheckmark } from '../../images/icons/ic-lang-checkmark.svg';
import { ReactComponent as IconPen } from '../../images/icons/ic-pen.svg';
import { ReactComponent as IconCarDiagonal } from '../../images/partials/ic-car-diagonal.svg';
import { ReactComponent as IconCarDiagonalTablet } from '../../images/partials/ic-car-dialog-tablet.svg';
import { ReactComponent as IconBookmapBlack } from '../../images/logos/ic-logo-black.svg';
import { ReactComponent as IconFacebook } from '../../images/icons/ic-facebook.svg';
import { ReactComponent as IconCloseBlack } from '../../images/icons/ic-close-black.svg';
import { ReactComponent as IconChart } from '../../images/icons/ic-chart.svg';
import { ReactComponent as IconCoins } from '../../images/icons/ic-coins.svg';
import { ReactComponent as IconLaptop } from '../../images/icons/ic-laptop.svg';
import { ReactComponent as IconReviews } from '../../images/icons/ic_reviews.svg';
import { ReactComponent as IconAlert } from '../../images/icons/ic_alert.svg';
import { ReactComponent as IconSorPlaceholder } from '../../images/icons/ic-sor-logo-ph.svg'
import { ReactComponent as IconGear } from '../../images/icons/ic-gear.svg';
import { ReactComponent as IconShare } from '../../images/icons/ic_share.svg';
import { ReactComponent as IconATHS } from '../../images/icons/ic_addhomescreen.svg';
import { ReactComponent as IconFindVehicleBigGray } from '../../images/icons/ic_check up_big_gray.svg';
import { ReactComponent as Clear } from '../../images/icons/button_clear.svg';
import { ReactComponent as SearchButton } from '../../images/booking/button_search yellow.svg';
import { ReactComponent as BackArrow } from '../../images/booking/ic_arrow_down.svg';
import { ReactComponent as CloseArrow } from '../../images/booking/ic_close_gray.svg';
import { ReactComponent as PotencialService } from '../../images/booking/ic_service.svg';
import { ReactComponent as MobileMenu } from '../../images/landingPictures/ic_menu.svg';
import { ReactComponent as ProfilePlaceholder } from '../../images/landingPictures/ic_user-1.svg';
import { ReactComponent as ProfileLogo } from '../../images/landingPictures/ic_profile@3x.svg';
import { ReactComponent as CardLink } from '../../images/landingPictures/ic_landing_active@3x.svg';
import { ReactComponent as PhoneMessage } from '../../images/landingPictures/im_message.svg';
import { ReactComponent as SuccessMessage } from '../../images/landingPictures/ic_done.svg';
import { ReactComponent as CarOwnerIcon } from '../../images/partials/ic-car-owner.svg';
import { ReactComponent as FavoriteNew } from '../../images/icons/ic_favorite.svg';
import { ReactComponent as IconCarRegistrationNumber } from '../../images/icons/reg-number.svg';
import { ReactComponent as IconArrowRightBold } from '../../images/icons/ic-arrow-right-bold.svg';
import { ReactComponent as IconCarRegistrationNumberBold } from '../../images/icons/reg-number-bold.svg';
import { ReactComponent as LocationBold } from '../../images/icons/ic-location-bold.svg';
import { ReactComponent as CloseX } from '../../images/icons/ic_close_black.svg';

import { ReactComponent as CarService } from "../../images/landingPictures/newIcons/ic_car_service.svg";
import { ReactComponent as CarWash } from "../../images/landingPictures/newIcons/ic_car_wash.svg";
import { ReactComponent as TireWorks } from "../../images/landingPictures/newIcons/ic_tire_works.svg";
import { ReactComponent as CheckUp } from "../../images/landingPictures/newIcons/ic_check_up.svg";
import { ReactComponent as Tuning } from "../../images/landingPictures/newIcons/ic_tuning.svg";
import { ReactComponent as TowTruck } from "../../images/landingPictures/newIcons/ic_tow_track.svg";
import { ReactComponent as reviewBold } from "../../images/icons/ic_review_Bold.svg";
import { ReactComponent as watchReviewBold } from "../../images/icons/ic_watch_review_Bold.svg";
import { ReactComponent as dangerMessage } from "../../images/icons/dangerMessage.svg";
import { ReactComponent as smallAlert } from "../../images/icons/ic_alert_small.svg";

import { IconType } from '../../types/icon';
import { joinClassNames } from '../../helpers/className';
import { IPropsClassName } from '../../types/props';

import styles from './index.module.scss';

const iconsMap = {
    email: Email,
    carServiceIcon: CarService,
    carWashIcon: CarWash,
    tireWorksIcon: TireWorks,
    checkUpIcon: CheckUp,
    tuningIcon: Tuning,
    towTruckIcon: TowTruck,
    mail: Mail,
    password: Password,
    phone: Phone,
    user: User,
    profile: Profile,
    statistic: Statistic,
    bookings: Bookings,
    bookingHistory: BookingHistory,
    favoriteSelected: FavoriteSelected,
    search: Search,
    info: Info,
    infoWarn: InfoWarn,
    add: Add,
    close: Close,
    favorite: Favorite,
    delete: Delete,
    edit: Edit,
    location: Location,
    locationFlat: LocationFlat,
    clock: Clock,
    clockGray: ClockGray,
    answer: Answer,
    signOut: SignOut,
    reply: Reply,
    branches: Branches,
    car: Car,
    calendar: Calendar,
    serviceManagement: ServiceManagement,
    checkMark: CheckMark,
    success: Success,
    warning: Warning,
    ee: Ee,
    ru: Ru,
    en: En,
    review: Review,
    camera: Camera,
    upload: Upload,
    balon: Balon,
    logoYellow: IconLogoYellow,
    deleteCross: DeleteCross,
    more: More,
    dropDown: DropDown,
    cancelled: Cancelled,
    confirmed: Confirmed,
    missed: Missed,
    total: TotalRequests,
    back: Back,
    onMap: OnMap,
    list: List,
    burgerMenu: Burger,
    watchReview: WatchReview,
    carService: IconCarService,
    carWash: IconCarWash,
    carCheckup: IconCheckUp,
    tireWorks: IconTireWorks,
    towTruck: IconTowTruck,
    tuning: IconTuning,
    washDetailing: IconWashDetailing,
    filters: IconFilters,
    bookmap: IconBookmap,
    worker: IconWorker,
    langArrow: LangArrow,
    arrowRight: IconArrowRight,
    langCheckmark: IconLangCheckmark,
    pen: IconPen,
    carDiagonal: IconCarDiagonal,
    carDiagonalTablet: IconCarDiagonalTablet,
    logoBlack: IconBookmapBlack,
    facebook: IconFacebook,
    closeBlack: IconCloseBlack,
    laptop: IconLaptop,
    chart: IconChart,
    coins: IconCoins,
    reviews: IconReviews,
    alert: IconAlert,
    sorPlaceholder: IconSorPlaceholder,
    gear: IconGear,
    share: IconShare,
    aths: IconATHS,
    bigGrayVehicle: IconFindVehicleBigGray,
    clear: Clear,
    searchButton: SearchButton,
    backArrow: BackArrow,
    closeArrow: CloseArrow,
    potencialService: PotencialService,
    mobileMenu: MobileMenu,
    profilePlaceholder: ProfilePlaceholder,
    profileLogo: ProfileLogo,
    cardLink: CardLink,
    phoneMessage: PhoneMessage,
    successMessage: SuccessMessage,
    carOwnerIcon: CarOwnerIcon,
    favoriteNew: FavoriteNew,
    carRegistrationNumber: IconCarRegistrationNumber,
    arrowRightBold: IconArrowRightBold,
    carRegistrationNumberBold: IconCarRegistrationNumberBold,
    locationBold: LocationBold,
    closeX: CloseX,
    watchReviewBold: watchReviewBold,
    reviewBold: reviewBold,
    dangerMessage: dangerMessage,
    smallAlert: smallAlert,
};

interface Props extends IPropsClassName {
    icon: IconType,
    width?: number;
    height?: number;
    onClick?: any
}

const Icon = ({ className, icon, ...props }: Props) => {
    let Svg = iconsMap[icon];
    const classes = joinClassNames(styles.icon, className);

    return <Svg className={classes} {...props} />;
};

Icon.defaultProps = {
    className: '',
};

export default Icon;
