import React from 'react'
import { ReactComponent as StarFilled } from '../../images/rating/ic-star-filled.svg'
import { ReactComponent as StarOutlined } from '../../images/rating/ic-star-outline.svg'
import Caption from "../Caption";
import Flex from "../Flex";
import styles from './index.module.scss'
import { IPropsClassName } from '../../types/props';
import { joinClassNames } from '../../helpers/className';
import ActionButton from '../../components/ActionButton';

interface Props extends IPropsClassName {
    rate: number,
    hideCounter?: boolean;
    onClick?: (e: any) => void;
    favouriteProps?: FavouriteProps
}

interface FavouriteProps{
    matches: boolean;
    isFavourite: boolean;
    isDisabled: boolean;
    addToFavoritesCallback: (value: React.SetStateAction<boolean>) => void;
    removeFromFavoritesCallback: () => void;
}

const LIST = [1, 2, 3, 4, 5];

const Rating = ({ rate, hideCounter, className, onClick, favouriteProps }: Props) => {
    return (
        <Flex className={joinClassNames(styles.Rating, className)} onClick={onClick}>
            <Caption size={"16"} className={styles.Caption}>
                {!hideCounter && rate.toFixed(1)}
            </Caption>
            {
                LIST.map(value => {
                    let Star = rate >= value ? StarFilled : StarOutlined;
                    return (<Star key={value} className={styles.Star} />)
                })
            }
            {favouriteProps && !favouriteProps.matches &&
                (!favouriteProps.isFavourite ?
                    <ActionButton
                        disabled={favouriteProps.isDisabled}
                        onClick={favouriteProps.addToFavoritesCallback}
                        className={styles.favoriteIcon}
                        icon='favorite'>
                    </ActionButton> :
                    <ActionButton
                        disabled={favouriteProps.isDisabled}
                        onClick={favouriteProps.removeFromFavoritesCallback}
                        className={styles.favoriteIcon}
                        icon='favoriteSelected'>
                    </ActionButton>)
            }
        </Flex>
    )
};

export default Rating
