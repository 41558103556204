import { Dispatch, AnyAction } from "redux";

import {
    BADGES_SC_WS,
    BADGES_CO_WS,
    NEW_REQUEST,
    REQUEST_CANCELLED,
    NEW_OFFER,
    NEW_DIRECT_OFFER,
    NEW_CONTRACT_FOR_CONFIRMATION,
    NEW_CONTRACT_CONFIRMED,
    CONTRACT_CANCELLED,
    CONTRACT_STATUS_CHANGED,
    NEW_CONTRACT_COMPLETED,
    OFFER_CANCELED,
    BADGE_REVIEWS_WS,
    NO_OFFER_REQUEST_CANCELLED_SC,
    ACTIVATED_WS
} from "../constants";

import {
    BADGES_CO,
    BADGES_SC,
    REQUEST_CREATED,
    REQUEST_CANCELLED_SC,
    OFFER_DECLINED_SC,
    OFFER_CREATED,
    DIRECT_OFFER_CREATED,
    CONTRACT_WAITING_FOR_CONFIRMATION,
    WAITING_FOR_CONFIRMATION_CANCELLED_SC,
    CONTRACT_CONFIRMED,
    CONTRACT_CANCELLED_CO,
    CONTRACT_IN_PROGRESS_CO,
    CONTRACT_COMPLETED_CO,
    CONTRACT_CANCELLED_SC,
    CONTRACT_IN_PROGRESS_SC,
    CONTRACT_COMPLETED_SC,
    OFFER_DECLINED_CO,
    REQUEST_CANCELLED_CO,
    BADGE_REVIEWS,
    NO_OFFER_REQUEST_CANCELLED,
    SC_ACTIVATED
} from "../../const/ws-events";

const EVENT_TO_ACTION_BINDING: { [key: string]: string } = {
    //New Events
    [BADGES_CO]: BADGES_CO_WS,
    [BADGES_SC]: BADGES_SC_WS,

    [REQUEST_CREATED]: NEW_REQUEST,
    [REQUEST_CANCELLED_SC]: REQUEST_CANCELLED,
    [OFFER_DECLINED_SC]: REQUEST_CANCELLED,

    [OFFER_CREATED]: NEW_OFFER,
    [DIRECT_OFFER_CREATED]: NEW_DIRECT_OFFER,
    [OFFER_DECLINED_CO]: OFFER_CANCELED,
    [REQUEST_CANCELLED_CO]: REQUEST_CANCELLED,
    [NO_OFFER_REQUEST_CANCELLED]: NO_OFFER_REQUEST_CANCELLED_SC,

    [CONTRACT_WAITING_FOR_CONFIRMATION]: NEW_CONTRACT_FOR_CONFIRMATION,
    [WAITING_FOR_CONFIRMATION_CANCELLED_SC]: REQUEST_CANCELLED,

    [CONTRACT_CONFIRMED]: NEW_CONTRACT_CONFIRMED,
    [CONTRACT_CANCELLED_CO]: CONTRACT_CANCELLED,
    [CONTRACT_IN_PROGRESS_CO]: CONTRACT_STATUS_CHANGED,
    [CONTRACT_COMPLETED_CO]: NEW_CONTRACT_COMPLETED,

    [CONTRACT_CANCELLED_SC]: CONTRACT_CANCELLED,
    [CONTRACT_IN_PROGRESS_SC]: CONTRACT_STATUS_CHANGED,
    [CONTRACT_COMPLETED_SC]: CONTRACT_STATUS_CHANGED,

    [BADGE_REVIEWS]: BADGE_REVIEWS_WS,

    [SC_ACTIVATED]: ACTIVATED_WS
};

export interface SocketResponse<T = any> {
    eventType: string;
    data: T;
}

export const handleSocketEvent = (dispatch: Dispatch<AnyAction>) => (response: SocketResponse) => {
    const type = EVENT_TO_ACTION_BINDING[response.eventType];
    if (type) {
        dispatch({ type, payload: response.data });
    }
};
