import React from "react";
import { useLocation, withRouter } from "react-router";
import { connect } from "react-redux";

import Link from '../../modules/navigation/NavLink'
import { joinClassNames } from "../../helpers/className";

import styles from './index.module.scss'


function NavBarLink (props: any) {
    let {
        i18n,
        href,
        children,
        Icon,
        className,
        highlightOn,
        highlightList,
        NavBarLinkActiveOverridden,
        callback,
        isBusiness,
        serviceOwnerReviewsBadges
    } = props;
    let classNames = joinClassNames(isBusiness ? styles.NavBarLink : styles.NavBarLinkSecondary, className);
    const { count } = serviceOwnerReviewsBadges;
    const location = useLocation();

    let isActive = highlightOn && location.pathname.includes(highlightOn);

    if (!!highlightList) {
        isActive = highlightList.indexOf(location.pathname) >= 0;
    }

    if (children === i18n.service_management.reviews) {
        children =
            <>
                {children} <span className={styles.badge}>{count}</span>
            </>
    }

    return (
        <Link
            to={href}
            onClick={callback}
            activeClassName={joinClassNames(isBusiness ? styles.NavBarLinkActive : styles.NavBarLinkActiveSecondary, NavBarLinkActiveOverridden)}
            className={joinClassNames(classNames, isActive ?
                joinClassNames(isBusiness ? styles.NavBarLinkActive : styles.NavBarLinkActiveSecondary, NavBarLinkActiveOverridden) : '')}>
            {Icon && <Icon />}
            {children}
        </Link>
    )
};

const mapStateToProps = (state: any) => {
    const { i18n, serviceOwnerReviewsBadges } = state;
    return { i18n, serviceOwnerReviewsBadges };
};

export default connect(mapStateToProps)(withRouter(NavBarLink));
