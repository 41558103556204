import { FormConfig } from "../../types/formConfig";
import Validator from "../../validators";
import { trimLeft } from "../../pipes/trim";

let formConfig: FormConfig = {
    finishReport: {
        name: 'report',
        label: 'Write a report',
        placeholder: '',
        format: trimLeft,
        maxLength: 250,
        validate: [
            Validator.lengthBetween(3, 250),
        ]
    },
    addReport: {
        name: 'report',
        label: 'Write a report',
        placeholder: '',
        format: trimLeft,
        maxLength: 250,
        validate: [
            Validator.lengthBetween(3, 250),
            Validator.required()
        ]
    }
};

export default formConfig;