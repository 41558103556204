import UsersService from "./UsersService";
import qs from 'qs';

import { PAGINATION_LIMIT } from "../const/pagination";

class BranchesService extends UsersService {

    public getBranches(offset: number) {
        return this.get(`branches`, { offset, PAGINATION_LIMIT });
    }

    public changeActivationStatus(id: number) {
        return this.patch(`branches/${id}`);
    }

    public editBranchAddress(id: number, model: any) {
        return this.put(`branches/${id}/address`, model)
    }

    public editBranchSchedule(id: number, model: any) {
        return this.put(`branches/${id}/schedule`, model)
    }

    public editBranchServices(id: number, model: any) {
        return this.put(`branches/${id}/services`, model)
    }

    public editBranchInfo(id: any, model: any) {
        return this.put(`branches/${id}`, model);
    }

    public editBranchContacts(id: number, model: any) {
        return this.put(`branches/${id}/contacts`, model);
    }

    public getBranchContracts(id: number, model: any) {
        return this.get(`branches/${id}/contracts`, {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }

    public getBranchRequests(id: number, model: any) {
        return this.get(`branches/${id}/requests`, {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
            }
        })
    }

    public getBranchCarBrands(id: number) {
        return this.get(`/branches/${id}/car-brand`);
    };

    public addBranchBrand(id: number, brand: { id: number, name: string }) {
        return this.post(`/branches/${id}/car-brand`, {
            ...brand
        });
    };

    public deleteBranchBrand(id: number, brandId: number) {
        return this.delete(`/branches/${id}/car-brand/${brandId}`)
    }
}

export default BranchesService;
