import React from "react";
import { BUSINESS_TYPES_MAP } from '../../const/business-type';


const IconsBusinessTypes = ({id, className}: any) => {

    let icon = BUSINESS_TYPES_MAP.get(id);

    return (
        <div className={className}>
            {icon}
        </div>
    );
};

export default IconsBusinessTypes;
