

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const SIGN_UP_CLEAR_DATA = 'SIGN_UP_CLEAR_DATA';


export const LOGIN_SERVICE_OWNER_REQUEST = 'LOGIN_SERVICE_OWNER_REQUEST';
export const LOGIN_SERVICE_OWNER_SUCCESS = 'LOGIN_SERVICE_OWNER_SUCCESS';
export const LOGIN_SERVICE_OWNER_FAIL = 'LOGIN_SERVICE_OWNER_FAIL';

export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE'; 