import React, { useEffect } from 'react';

import SCHEMA from "./routesMap";
import Route from '../modules/navigation/Route';
import Switch from '../modules/navigation/Switch';
import Router from '../modules/navigation/BrowserRouter';
import { withRouter } from 'react-router-dom';
import { KEY_LANGUAGE, KEY_SESSION, KEY_USER_ID } from "../const/local-storage";
import StoreService from "../services/StorageService";
import updateDataLayer from "../utils/data-layer";

Route.enablePreload(SCHEMA);

const DataLayer = withRouter(({ history }) => {

    useEffect(() => {
        const sessionStorage = new StoreService();
        const isLoggedIn = !!sessionStorage.get(KEY_SESSION);
        const userId = sessionStorage.get(KEY_USER_ID);
        const lang = sessionStorage.get(KEY_LANGUAGE);

        if (isLoggedIn) {
            updateDataLayer({
                userID: userId
            });
        }

        updateDataLayer({
            event: "PageView",
            url: window.location.pathname,
            lang: lang && lang.shortName
        });
    }, [history.location.key]);

    return (<></>);
})

const AppRoutes = () => {

    return (
        <Router>
            <DataLayer />
            <Switch>
                <Route {...SCHEMA.GUEST_SIGN_UP_SUCCESS} />
                <Route {...SCHEMA.BUSINESS} />
                <Route {...SCHEMA.USER} />
                <Route {...SCHEMA.CONFIRM_EMAIL} />
                <Route {...SCHEMA.CONFIRM_CHANGE_EMAIL} />
                <Route {...SCHEMA.POTENCIAL_CAR_SERVICES} />
                <Route {...SCHEMA.SIGN_OUT} />
                <Route {...SCHEMA.LANDING_PAGE} />
                <Route {...SCHEMA.CREATE_NEW_BOOKING} />
                <Route {...SCHEMA.PRIVACY_POLICY} />
                <Route {...SCHEMA.TERMS} />
                <Route {...SCHEMA.NOT_FOUND} />
            </Switch>
        </Router>
    );
};


export default AppRoutes;
