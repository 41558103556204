import React from 'react'
import { connect } from "react-redux";

import BasePopup from '../BasePopup';
import Map from "../../components/Map";
import { closePopup } from "../../redux/actions";

import styles from './index.module.scss'
import StyledDialogContent from '../../components/Dialog/DialogContent';
import Button from '../../components/Button';
import StyledDialogActions from '../../components/Dialog/DialogActions';
import { Divider } from '@material-ui/core';

export const DEFAULT_LOCATION = {
    lat: 59.436962,
    lng: 24.753574
};

class LocationPopup extends BasePopup {
    constructor(props: any) {
        super(props);
        this.title = props.i18n.popups.location;
        this.i18n = props.i18n;
        this.className = styles.Card;
        this.withDivider = false;
        let { initialData = {} } = props;
        let { booking: { address } } = initialData;
        let state = {
            wait: false,
            center: DEFAULT_LOCATION,
            title: ''
        };

        if (address) {
            state = {
                center: address.geoLocation,
                title: address.title,
                wait: false,
            };
        }
        this.state = state;
    }

    public renderContent() {
        let { center }: any = this.state;

        return (
            <>
                <Divider className={styles.divider} />
                <StyledDialogContent>
                    <Map height={'385px'}
                        zoom={11}
                        defaultCenter={center}
                        center={center}
                        className={styles.map}>
                    </Map>
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button
                        color='alter'
                        variant='text'
                        onClick={this.close}
                        className={styles.btn}
                    >
                        {this.i18n.common.close}
                    </Button>
                </StyledDialogActions>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(
    LocationPopup
);
