/* eslint-disable no-useless-escape */
export const formatAMPM = (time: any) => {
    if (!time) {
        return time
    }

    let [hours, minutes] = time.toString().split(':');

    hours = +hours % 24;
    hours = +hours < 10 ? '0' + hours : hours;
    hours = hours === 24 ? 0 : hours;
    return `${hours}:${minutes}`;
};


export const hoursToUTC = (time: any) => {
    const timezone = new Date().toString().match(/([-\+][0-9]+)\s/);
    const timeZoneOffset = Number(String(timezone![0]).slice(0, 3));
    let res = !!timezone![0] ? time - timeZoneOffset : time;

    if (res < 0)
        return 24 + res;
    else
        return res < 10 ? '0' + res % 24 : res % 24;
}

export const minToUTC = (time: any) => {
    const timezone = new Date().toString().match(/([-\+][0-9]+)\s/);
    const timeZoneOffset = Number(String(timezone![0]).slice(3, 1));
    let res = !!timezone![0] ? (Number(time) - timeZoneOffset) : time;

    if (res < 0)
        return res + 60;
    else
        return res < 10 ? '0' + res % 60 : res % 60;
}
