import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment';
import getSecondsFromMs from '../../helpers/getSecondsFromMs';
import Button from './index';
import { joinClassNames } from '../../helpers/className';
import styles from './index.module.scss';

const RESEND_TIME_OUT = 30;
const MS_IN_SECOND = 1000;

interface IProps {
    sectionClassName: string,
    buttonStyles: string,
    timerStyles: string,
    action: () => void,
    buttonText: string,
    timerText: string,
}

export default function ButtonWithTimer({
    sectionClassName,
    buttonStyles,
    timerStyles,
    action,
    buttonText,
    timerText,
}: IProps) {

    const [resendTimer, setResendTimer] = useState(false);
    const [counter, setCounter] = useState(RESEND_TIME_OUT);

    const timer = useRef<number>();

    function startTimer() {
        const countDownDate = moment().add(RESEND_TIME_OUT, 'second').valueOf();
        setResendTimer(true)
        timer.current = window.setInterval(function () {
            const now = new Date().getTime();
            const timeleft = countDownDate - now;
            const seconds = getSecondsFromMs(timeleft);
            setCounter(seconds)
            if (seconds <= 0) {
                clearInterval(timer.current);
                setResendTimer(false);
            }
        }, MS_IN_SECOND);
    }

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        }
    }, []);

    return (
        <section className={sectionClassName}>
            {resendTimer && <span className={timerStyles}>{counter} {timerText}</span>}
            <Button
                variant={"outlined"}
                className={joinClassNames(buttonStyles, styles.disabledState)}
                disabled={resendTimer}
                onClick={() => {
                    startTimer();
                    action();
                }}>
                {buttonText}
            </Button>
        </section>
    )
}