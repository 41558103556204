import { Dispatch } from "redux";
import { IForgotPasswordModel, IResetPasswordModel } from "../../types/models";
import {
  USERS_FORGOT_PASSWORD_CLEAR_STATE,
  USERS_FORGOT_PASSWORD_REQUEST,
  USERS_FORGOT_PASSWORD_REQUEST_FAIL,
  USERS_FORGOT_PASSWORD_REQUEST_SUCCESS,
  USERS_RESET_PASSWORD_REQUEST,
  USERS_RESET_PASSWORD_REQUEST_FAIL,
  USERS_RESET_PASSWORD_REQUEST_SUCCESS,
  USERS_CREATE_PASSWORD_REQUEST_SUCCESS,
  USERS_CREATE_PASSWORD_REQUEST_FAIL,
  USERS_CREATE_PASSWORD_REQUEST
} from "../constants";
import ServiceOwnersService from "../../services/ServiceOwnersService";
import { ROLE } from "../../const/users";
import UsersService from "../../services/UsersService";
import StorageService from '../../services/StorageService';
import { KEY_EMAIL_LINK } from "../../const/local-storage";

const storeService = new StorageService();

export const forgotPassword = (dispatch: Dispatch, role: string) => (model: IForgotPasswordModel) => {
  let service = role === ROLE.BUSINESS ? new ServiceOwnersService() : new UsersService();

  dispatch({
    type: USERS_FORGOT_PASSWORD_REQUEST,
  });

  service
    .restorePassword(model)
    .then(({ data }: any) => data)
    .then((payload: any) => {
      storeService.set(KEY_EMAIL_LINK, model.email)
      dispatch({ type: USERS_FORGOT_PASSWORD_REQUEST_SUCCESS, payload })
    })
    .catch((error: Error) => dispatch({ type: USERS_FORGOT_PASSWORD_REQUEST_FAIL, error }))
};

export const resetPassword = (dispatch: Dispatch) => (model: IResetPasswordModel) => {

  dispatch({
    type: USERS_RESET_PASSWORD_REQUEST,
  });

  new ServiceOwnersService()
    .restorePassword(model)
    .then(({ data }: any) => data)
    .then((payload: any) => {
      dispatch({ type: USERS_RESET_PASSWORD_REQUEST_SUCCESS, payload })
    })
    .catch((error: Error) => dispatch({ type: USERS_RESET_PASSWORD_REQUEST_FAIL, error }))
};

export const createNewPassword = (dispatch: Dispatch) => (model: IResetPasswordModel) => {

  dispatch({
    type: USERS_CREATE_PASSWORD_REQUEST,
  });

  new ServiceOwnersService()
    .createNewPassword(model)
    .then(({ data }: any) => data)
    .then((payload: any) => {
      dispatch({ type: USERS_CREATE_PASSWORD_REQUEST_SUCCESS, payload })
    })
    .catch((error: Error) => dispatch({ type: USERS_CREATE_PASSWORD_REQUEST_FAIL, error }))
};

export const clearForgotPasswordState = (dispatch: Dispatch) => () => {
  dispatch({
    type: USERS_FORGOT_PASSWORD_CLEAR_STATE,
  });
};
