/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import uuid from "uuid/v4";
import ee from '../../images/icons/estonian.svg';
import ru from '../../images/icons/ic-flag-rassian.svg';
import en from '../../images/icons/ic-flag-england.svg';

import Arrow from "../Arrow";
import Flex from "../Flex";
import TextareaField from "../Textarea";
import { joinClassNames } from "../../helpers/className";
import { default as FormControl, FORM_CONTROL_ELEMENT } from "../FormControl";
import {FILL_IN_DESCRIPTION} from "../../const/errorMessages";
import ErrorMessage from "../ErrorMessage";
import formConfig from "./form-config";

import styles from "./index.module.scss";
import store from "../../redux/store";

const PANELS = [
    {
        id: 0,
        name: 'Estonian',
        field: 'descriptionEe',
        icon: ee,
        label: 'Kirjeldus',
        placeholder: 'Sisesta kirjeldus'
    },
    {
        id: 1,
        name: 'Russian',
        field: 'descriptionRu',
        icon: ru,
        label: 'Описание',
        placeholder: 'Введите описание'
    },
    {
        id: 2,
        name: 'English',
        field: 'descriptionEn',
        icon: en,
        label: 'Description',
        placeholder: 'Enter your description'
    },
];

const LanguageExpansionPanel = (props: any) => {
    const { onChange, handleSubmit, initValues, valid, onValidationStatusChange = () => {} } = props;
    const [displayedTabs, setDisplayedTabs] = React.useState([true, false, false]);

    useEffect(() => {
        onValidationStatusChange(valid);
    }, [valid])

    useEffect(() => {
        props.change('descriptionEe', initValues.descriptionEe);
        props.change('descriptionEn', initValues.descriptionEn);
        props.change('descriptionRu', initValues.descriptionRu);
    }, [initValues]);

    const handleChange = (id: any) => {
        displayedTabs[id] = !displayedTabs[id];
        setDisplayedTabs([...displayedTabs]);
    };

    const handleTextChange = (model: any) => {
        onChange(model);
    };

    return (
        <form className={styles.form} onChange={handleSubmit(handleTextChange)}>
            {PANELS.map((item: any, index: number) =>
                <div key={item.id}>
                    <Flex
                        className={joinClassNames(styles.header, displayedTabs[index] ? styles.activeHeader : '')}
                        onClick={() => handleChange(index)}>
                        <Flex className={styles.alignCenter}>
                            <img src={item.icon} className={item.icon === en ? styles.iconEnglish:styles.icon} alt="lang" />
                            <div>
                                {item.name}
                                <span>{item.label}</span>
                            </div>
                        </Flex>
                        <Arrow fill={'grey'} className={displayedTabs[index] ? styles.arrowUp : ''} />
                    </Flex>
                    {<div
                        className={joinClassNames(styles.descr, displayedTabs[index] ? styles.show : '')}
                    >
                        <FormControl {...formConfig[item.field]}>
                            <TextareaField
                                is={FORM_CONTROL_ELEMENT.TEXTAREA}
                                color={'secondary'}
                                placeHolder={item.placeholder}
                            />
                            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                        </FormControl>
                    </div>}
                </div>)}
        </form>
    );
};

const Form: any = reduxForm({
        form: uuid(),
        validate: (model: any) => {
            let errors: any = {};
            const modelKeys = Object.keys(model);
            let counter: number = 0;
            modelKeys.forEach((modelKey: string) => {
                if(model[modelKey] === '') {
                    errors[modelKey] = store.getState().i18n.validations_message.fill_in_descriptions;
                    counter++;
                }
            });

            if(counter === modelKeys.length) {
                errors = {};
            }

            return errors;
        },
        initialValues: {
            descriptionEe: '',
            descriptionEn: '',
            descriptionRu: '',
        },
    })(
        LanguageExpansionPanel
    );

export default Form;
