import { RegistrationValidator } from './sign-up';
import store from '../redux/store';

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]{1,64}@[A-Z0-9.-]{1,59}\.[A-Z]{2,4}$/i;
const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/;
const NO_SPACES_PATTERN = /^(?!.* ).+$/;
const PHONE_PATTERN = /^\+\d{6,15}$/;
const TIME_PATTERN = /^[0-9][0-9]:[0-9][0-9] - [0-9][0-9]:[0-9][0-9]$/;
const TIME_PATTERN_SIMPLE = /^[0-9][0-9]:[0-9][0-9]$/;

export default class Validator {
    static validationMessage() {
        return store.getState().i18n.validations_message || {};
    }

    static asyncValidate(values: any, dispach: any, props: any, ...args: any[]) {
        if (props.initialValues.email === values.email) {
            return Promise.resolve();
        }

        return RegistrationValidator
            .email(values.email)
            .then((r: any) => {
                if (r.data && r.data.isExist) {
                    // eslint-disable-next-line no-throw-literal
                    throw { email: Validator.validationMessage().user_with_such_email_already_exists };
                }
            });
    };

    static passwordMatch = (values: any) => (
        values.password === values.confirmPassword
            ? undefined : `:error[confirm_not_match_v2]`
    );

    static required = () =>
        (value: any) =>
            (value || typeof value === 'number' ?
                undefined :
                `:label :error[is_required]`);

    static regexp = (pattern: any, message: string = `:label :error[is_invalid]`) =>
        (value: any) =>
            value && !pattern.test(value) ?
                message :
                undefined;

    static phone = () =>
        Validator.regexp(PHONE_PATTERN);

    static time = () =>
        Validator.regexp(TIME_PATTERN);

    static timeSimple = () =>
        Validator.regexp(TIME_PATTERN_SIMPLE);

    static serviceOwnerTimeSimple = () =>
        Validator.regexp(TIME_PATTERN_SIMPLE, ':label :error[is_invalid] :error[service_owner_time_invalid]');

    static email = () =>
        Validator.regexp(EMAIL_PATTERN, `:label :error[is_not_in_valid_format]`);

    static minLength = (min: number, message?: string) =>
        (value: any) =>
            value && value.length < min ?
                message || `:label :error[must_be] ${min} :error[characters_or_more]` :
                undefined;

    static maxLength = (max: number) =>
        (value: any) =>
            value && value.length > max ?
                `:label :error[must_be] ${max} :error[characters_or_less]` :
                undefined;

    static max = (max: number, message?: string) =>
        (value: any) =>
            value && Number(value) > max ?
                message || `:label :error[must_be_less_then] ${max} :error[or_equal] ` :
                undefined;

    static min = (min: number, message?: string) =>
        (value: any) =>
            value && Number(value) < min ?
                message || `:label :error[must_be_more_then] ${min} :error[or_equal]` :
                undefined;

    static minTime = (min: string) =>
        (value: any) => {
            if (!value || !min) {
                return undefined;
            }

            let [minHours, minMinutes] = (min || '').toString().split(':');
            let [hours, minutes] = value.toString().split(':');

            if (parseInt(hours, 10) > parseInt(minHours, 10)) {
                return;
            } else if (parseInt(hours, 10) < parseInt(minHours, 10)) {
                return `:label :error[must_be_more_then] ${min} `;
            }

            return parseInt(hours, 10) === parseInt(minHours, 10) &&
                parseInt(minutes, 10) <= parseInt(minMinutes, 10) ?
                `:label :error[must_be_more_then] ${min} ` :
                undefined;
        };

    static maxTime = (max: number) =>
        (value: any) =>
            Validator.minTime(value)(max) ? `:label :error[must_be_less_then] ${max}` : undefined;

    static noSpacesOnly = () =>
        (value: any) =>
            value && !value.trimLeft() ?
                `:label :error[cant_contain_spaces_only]` :
                undefined;

    static noSpaces = () =>
        Validator.regexp(NO_SPACES_PATTERN, `:label :error[cant_contain_spaces_only] `);

    static lengthFixed = (length: number) =>
        (value: any) =>
            Validator.minLength(length)(value) || Validator.maxLength(length)(value) ?
                `:label :error[must_be] ${length} :error[symbols_long]` :
                undefined;

    static lengthBetween = (min: number, max: number) =>
        (value: any) =>
            value && (Validator.minLength(min)(value) || Validator.maxLength(max)(value)) ?
                `:label :error[must_be_from] ${min} :error[to] ${max} :error[symbols]` :
                undefined;

    static password = () =>
        Validator.regexp(PASSWORD_PATTERN, `:label :error[should_contain_at_least_one_letter_and_one_digit]`);

    static arrayNotEmpty = () =>
        (value: any) =>
            value && value.length ?
                undefined :
                `:label :error[is_empty]`;

    static addressModel = () =>
        (value: any) => {
            const { title, geoLocation } = value;
            if (!title || !geoLocation || (!!geoLocation && !!title && (!geoLocation.lat || !geoLocation.lng))) {
                return `:label :error[is_invalid]`
            }

            return undefined
        }
}
