import React from 'react'
import _ from "lodash";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";


import BasePopup from '../BasePopup';
import Button from "../../components/Button";
import FormControl, { FORM_CONTROL_ELEMENT } from "../../components/FormControl";
import Label from "../../components/Label";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import Dropdown from "../../components/Dropdown";
import RadioGroupControl from "../../components/RadioGroupControl";
import Flex from "../../components/Flex";
import StyledDialogContent from '../../components/Dialog/DialogContent';
import { joinClassNames } from "../../helpers/className";
import {
    closePopup,
    getCompanyTypes,
    getMyServices,
    getServices,
    openPopup,
    getServiceCenterById
} from "../../redux/actions";
import ServiceCentersService from "../../services/ServiceCentersService";
import ActionButton from "../../components/ActionButton";
import { CONFIRM_POPUP } from "../index";
import LanguageExpansionPanel from "../../components/LanguageExpansionPanel";
import { PRICE_FORMAT } from "../../const/price-formats";
import BranchesService from '../../services/BranchesService';
import StyledDialogActions from "../../components/Dialog/DialogActions";
import { BusinessTypesTranslationsEnum } from "../../const/business-type";
import formInternalization from '../../utils/form-internalization';
import { capitalizeFirstLetter } from '../MuiDatePicker/components/Datepicker/utils';
import StoreService from '../../services/StorageService';
import { KEY_LANGUAGE } from '../../const/local-storage';
import { DEFAULT_LANGUAGE } from '../../const/languages';
import formConfig from "./form-config";

import styles from './index.module.scss'

export function toAddServiceModel({ serviceId, priceFormat, rate, descriptionTranslations }: any) {
    return { description: descriptionTranslations, priceFormat, rate, serviceId }
}

class AddNewServicePopup extends BasePopup {
    state: any;
    isEditing: boolean;
    openPopup: any;
    common: any;
    description: any = {};
    limit: number;
    translatedTypes: any[]
    selectedLanguage: string;

    constructor(props: any) {
        super(props);
        this.i18n = props.i18n;
        const store = new StoreService();

        this.className = styles.Card;
        this.selectedLanguage = capitalizeFirstLetter(store.get(KEY_LANGUAGE || DEFAULT_LANGUAGE).shortName);
        let { initialData = {}, getCompanyTypes } = props;
        let { type, model } = initialData;
        const { businessTypes } = props.myServiceCenterInfo;
        this.translatedTypes = businessTypes.map(({ id }: { id: number }) => ({
            name: this.i18n.car_service_types[BusinessTypesTranslationsEnum[id]],
            id
        }));

        this.state = {
            type: this.translatedTypes.find(item => item.id === type.id) || null,
            service: null
        };

        this.title = model ? this.i18n.popups.edit_service : this.i18n.popups.add_new_service;
        this.isEditing = !!model;
        this.openPopup = props.openPopup;

        getCompanyTypes();

        if (type) {
            props.getServices(type.id).then(() => {
                if (!model) return;
                this.setState({ service: { id: model.serviceId, name: model.nameTranslations[`name${this.selectedLanguage}`] } });
            })
        }

        if (model) {
            Object.keys(toAddServiceModel(model)).forEach(key => {
                props.change(key, model[key].toString())
            });
        }

        this.limit = 7;
    }

    public addService(model: any) {
        let props: any = this.props;
        let { myServiceCenterInfo, getMyServices, myServices, initialData = {} } = props;
        let { model: initModel } = initialData;
        let services: any = [];

        delete model.description;

        if (!_.isEmpty(_.pickBy(Object.values(this.description), _.identity))) {
            model.description = this.description;
        }

        myServices.collection.forEach((elem: any) => {
            elem.services.forEach((data: any) => {
                services.push(toAddServiceModel(data))
            });
        });

        if (initModel) {
            services = services.filter(({ name, serviceId }: any) => +serviceId !== +initModel.serviceId)
        }

        model.serviceId = +model.serviceId;

        model.rate = +model.rate;
        if (props.service.id) {
            const id = props.service.id;

            new BranchesService()
                .editBranchServices(id, [...services, model])
                .then(() => this.close())
                .then(() => getMyServices({ serviceCenters: [id], limit: this.limit }));
            return
        }

        new ServiceCentersService()
            .addService([...services, model])
            .then(() => this.close())
            .then(() => getMyServices({ ServiceCenters: [myServiceCenterInfo.id], limit: this.limit }))
    }

    public deleteService(model: any, id?: number) {

        this.openPopup(CONFIRM_POPUP,
            {
                title: this.i18n.popups.are_you_sure_you_want_to_delete_this_service,
                action: this.i18n.common.delete,
                color: 'danger',
                id: id,
                callback: () => {
                    let props: any = this.props;
                    let { myServiceCenterInfo, getMyServices, myServices } = props;

                    let services: any = [];

                    myServices.collection.forEach((elem: any) => {
                        elem.services.forEach((data: any) => {
                            services.push(toAddServiceModel(data));
                        });
                    });

                    services = services.filter((service: any) => service.serviceId !== Number(model.serviceId));

                    if (id) {
                        new BranchesService()
                            .editBranchServices(id, services)
                            .then(() => getMyServices({ serviceCenters: [id], limit: this.limit }))
                            .then(() => this.close())
                        return
                    }

                    new ServiceCentersService()
                        .addService(services)
                        .then(() => this.close())
                        .then(() => getMyServices({ ServiceCenters: [myServiceCenterInfo.id], limit: this.limit }))
                },
            });
    }

    public renderContent() {
        const props: any = this.props;
        const { name } = this.state.type;
        const { handleSubmit, invalid, services, $values, myServices, initialData = {} } = props;
        const { model = {} } = initialData;
        const hasModel = Object.keys(model).length;
        const { descriptionTranslations = {} } = model;
        const { services: createdServices = [] } = myServices.collection
            .find((elem: any) => elem.name === name) || {};
        const createdServicesMap: any = {};
        const filterCreated = ({ id }: any) => !createdServicesMap[id];
        const { service: { id: serviceCenterId } } = props;
        const { common, popups } = this.i18n
        const newService = Object.entries(model).length === 0
            && model.constructor === Object && this.state.service;
        createdServices.forEach(({ serviceId, ...data }: any) => {
            createdServicesMap[serviceId] = data;
        });
        const filteredService = services.collection.filter(filterCreated);
        const filteredServiceTranslated = filteredService.map((service: any) => {
            const translatedName = service.nameTranslations[`name${this.selectedLanguage}`];
            return { ...service, name: translatedName };
        })

        formInternalization(formConfig)

        return (
            <>
                <StyledDialogContent>
                    <form onSubmit={handleSubmit}
                        className={styles.form}>
                        <Flex className={styles.serviceName}>
                            <div className={styles.control}>
                                <Label>{popups.company_service_type}: </Label>
                                <Dropdown
                                    onChange={(option: any) => {
                                        const { id } = option;
                                        this.setState({ type: option, service: null });
                                        props.getServices(id);
                                        props.change('serviceId', null);
                                    }}
                                    options={this.translatedTypes}
                                    className={styles.dropDown}
                                    value={this.state.type} />
                            </div>
                            <div className={styles.control}>
                                <Label>{popups.car_service_name}</Label>
                                <Dropdown
                                    onChange={(option: any) => {
                                        const { id } = option;
                                        this.setState({ service: option });
                                        props.change('serviceId', id);
                                    }}
                                    options={filteredServiceTranslated}
                                    className={styles.dropDown}
                                    value={this.state.service} />
                            </div>
                        </Flex>

                        {model && !newService ? <>
                            <LanguageExpansionPanel
                                onChange={(model: any) => this.description = model}
                                initValues={descriptionTranslations}
                                label={'foo'} />
                        </> : <>
                                <LanguageExpansionPanel
                                    onChange={(model: any) => this.description = model}
                                    initValues={newService.descriptionTranslations}
                                    label={'foo'} />
                            </>
                        }

                        <Flex className={styles.pricePlace}>
                            <FormControl {...formConfig.rate}
                                className={joinClassNames(
                                    styles.priceControl
                                )}>
                                <Label>{common.rate}</Label>
                                <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder={true} className={styles.rate} />
                                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                            </FormControl>

                            <RadioGroupControl
                                label={common.price_per}
                                name={'priceFormat'}
                                className={styles.RadioGroupControl}
                                options={[
                                    { label: common.fixed, value: PRICE_FORMAT.FIXED },
                                    { label: common.per_hour, value: PRICE_FORMAT.PER_HOUR },
                                    { label: common.min, value: PRICE_FORMAT.MIN },
                                ]}
                            />
                        </Flex>

                        {!!hasModel &&
                            <Flex className={styles.buttonsBottom}>
                                <ActionButton icon={"delete"}
                                    className={styles.btnDelete}
                                    onClick={() => this.deleteService({ ...$values }, serviceCenterId)}>
                                    {this.i18n.common.delete_service}
                                </ActionButton>
                            </Flex>
                        }
                    </form>

                    <StyledDialogActions>
                        <Flex className={styles.buttons}>
                            <Button large={false}
                                small={true}
                                fontSize={16}
                                className={styles.btnCancel}
                                color={"secondary"}
                                onClick={this.close}
                                variant={"outlined"}>
                                {this.i18n.common.cancel}
                            </Button>

                            <Button large={false}
                                small={true}
                                fontSize={16}
                                className={styles.btnSave}
                                disabled={invalid || !$values.serviceId}
                                color={"secondary"}
                                onClick={() => this.addService({ ...$values, serviceCenterId })}
                                variant={"text"}>
                                {this.i18n.common.save}
                            </Button>
                        </Flex>
                    </StyledDialogActions>
                </StyledDialogContent>
            </>
        );
    }
}

const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let { companyTypes, services, myServiceCenterInfo, myServices, i18n, service } = state;
    let { values = {} } = state.form[form] || {};
    return { companyTypes, $values: values, services, myServiceCenterInfo, myServices, i18n, service }
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    openPopup: openPopup(dispatch),
    getServices: getServices(dispatch),
    getMyServices: getMyServices(dispatch),
    getServiceInfoById: getServiceCenterById(dispatch),
    getCompanyTypes: getCompanyTypes(dispatch)
});

const Popup: any = AddNewServicePopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        description: {},
        serviceId: '',
        priceFormat: PRICE_FORMAT.FIXED,
        rate: ''
    }
})(
    Popup
);

export default connect(mapStateToProps, mapDispatchToProps)(
    Form
);

