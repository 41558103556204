import * as React from "react";
import { closePopup } from "../../redux/actions";
import { connect } from "react-redux";
import styles from "./index.module.scss";
import { joinClassNames } from "../../helpers/className";
import ActionButton from "../../components/ActionButton";
import Flex from "../../components/Flex";

// @ts-ignore
import Cropper from 'react-cropper';
// General style
import 'cropperjs/dist/cropper.css';
// Overridden style
import './cropper.style.css';
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Grid, Switch, Typography } from "@material-ui/core";
import { CROPPER_CONFIG, DEFAULT_ASPECT_RATIO } from "./cropper.config";
import {
    ALLOWED_FORMAT,
    ERROR_FORMAT_NOT_ALLOWED,
    ERROR_INVALID_FILE_FORMAT,
    ERROR_MAX_SIZE_OF_FILE_IS_10MB,
    ERROR_MIN_HEIGHT_OF_PICTURE_150PX,
    ERROR_MIN_WIDTH_OF_PICTURE_150PX,
    MAX_SIZE_FILE,
    MIN_SIZE_OF_PICTURE,
} from "./image.validation";
import ImageService from "../../services/ImageService";
import StyledDialogContent from "../../components/Dialog/DialogContent";
import StyledDialogTitle from "../../components/Dialog/DialogTitle";

const cropper = React.createRef();

interface IProps {
    onChange: (id: number) => any;
}

interface IState {
    isFilled: boolean;
    error: string;
    wait: boolean;
    aspectRatio: number;
}

const RATIO_4_TO_3 = 4 / 3;

class CropImagePopup extends React.Component<IProps, IState> {
    onChange: (id: number) => any;
    i18n: any;

    constructor(props: any) {
        super(props);

        let { initialData } = props;
        let { onChange } = initialData;
        this.i18n = props.i18n;

        this.onChange = onChange;

        this.state = {
            wait: false,
            isFilled: false,
            error: '',
            aspectRatio: DEFAULT_ASPECT_RATIO
        }
    }

    private crop() {
        const type = (cropper as any).current.img.src.includes('data:image/jpeg') ? 'image/jpeg' : 'image.png'
        this.setState({ wait: true });
        // @ts-ignore
        new ImageService().uploadImage(cropper.current.getCroppedCanvas().toDataURL(type)).then((image: any) => {
            this.onChange(image.id);
            this.close();
        });
    }

    private close = () => {
        let props: any = this.props;

        props.closePopup(props.uuid);
    };

    private onChangeImage = (file: any) => {
        this.setState({ error: '' });
        if (!file) {
            return;
        }

        let format = ALLOWED_FORMAT.split(',').filter((type: string) => type === file.type);

        if (format.length === 0) {
            this.setState({ error: ERROR_FORMAT_NOT_ALLOWED });
            return;
        }

        if (file.size > MAX_SIZE_FILE) {
            this.setState({ error: ERROR_MAX_SIZE_OF_FILE_IS_10MB });
            return;
        }

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            let image = new Image();

            image.onerror = () => {
                this.setState({ error: ERROR_INVALID_FILE_FORMAT });
            };

            image.onload = (e: any) => {
                let imageWidth = e.target.width;
                let imageHeight = e.target.height;

                if (imageWidth < MIN_SIZE_OF_PICTURE) {
                    this.setState({ error: ERROR_MIN_WIDTH_OF_PICTURE_150PX });
                    return;
                }

                if (imageHeight < MIN_SIZE_OF_PICTURE) {
                    this.setState({ error: ERROR_MIN_HEIGHT_OF_PICTURE_150PX });
                    return;
                }

                this.setState({ isFilled: true });
            };

            // @ts-ignore
            image.src = reader.result;

            if (cropper.current) {
                // @ts-ignore
                cropper.current.replace(reader.result);
            }
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    render() {
        const state = this.state as any;
        const { aspectRatio } = this.state;
        const cropperConfigs = { ...CROPPER_CONFIG, aspectRatio };

        return (
            <>
                <StyledDialogTitle>
                    <Flex className={ styles.spaceBetween }>
                        <div className={ styles.title }>
                            { this.i18n.popups.upload_logo }
                        </div>
                        <ActionButton
                            className={ styles.close }
                            icon={ 'close' }
                            onClick={ this.close }/>
                    </Flex>
                </StyledDialogTitle>
                <StyledDialogContent>
                    { !state.isFilled && <label>
                        <Flex className={ styles.uploadPlace }>
                            <input
                                hidden={ true }
                                type='file'
                                accept={ ALLOWED_FORMAT }
                                onChange={ (e: any) => this.onChangeImage(e.target.files[0]) }/>
                            <Icon
                                icon={ 'upload' }
                                width={ 52 }
                                height={ 52 }/>
                            <div className={ joinClassNames(styles.title, styles.offsetTop) }>
                                { this.i18n.popups.select_file_to_upload }
                            </div>
                        </Flex>
                        <div className={ styles.error }>{ state.error }</div>
                    </label> }
                    <Cropper
                        ref={ cropper }
                        className={ styles.cropper }
                        style={ { display: !state.isFilled ? 'none' : 'block' } }
                        { ...cropperConfigs } />

                    { state.isFilled &&
                    <Flex className={ joinClassNames(styles.spaceBetween, styles.offsetTop32, styles.column) }>
                        <Button
                            className={ styles.widthAuto }
                            onClick={ () => {
                                this.setState({ isFilled: false })
                            } }
                            fontSize={ 16 }
                            variant={ "outlined" }>
                            { this.i18n.popups.previous }
                        </Button>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={ 1 }>
                                <Grid item>1:1</Grid>
                                <Grid item>
                                    <Switch checked={ aspectRatio !== DEFAULT_ASPECT_RATIO } onChange={
                                        () => {
                                            this.setState({ aspectRatio: aspectRatio === DEFAULT_ASPECT_RATIO ? RATIO_4_TO_3 : DEFAULT_ASPECT_RATIO })
                                        }
                                    }/>
                                </Grid>
                                <Grid item>4 : 3</Grid>
                            </Grid>
                        </Typography>

                        <Flex className={ joinClassNames(styles.column) }>
                            <Button
                                className={ joinClassNames(styles.widthAuto, styles.offsetRight) }
                                onClick={ () => {
                                    // @ts-ignore
                                    cropper.current.reset();
                                } }
                                fontSize={ 16 }
                                variant={ "outlined" }>
                                { this.i18n.popups.reset }
                            </Button>

                            <Button
                                className={ joinClassNames(styles.widthAuto, styles.submit) }
                                color={ 'secondary' }
                                variant={ "text" }
                                fontSize={ 16 }
                                disabled={ state.wait }
                                onClick={ () => {
                                    this.crop();
                                } }
                                type={ 'submit' }>
                                { this.i18n.popups.upload }
                            </Button>
                        </Flex>
                    </Flex> }
                </StyledDialogContent>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, mapDispatchToProps)(CropImagePopup);
