import store from "../redux/store";
import { KEY_SESSION } from "../const/local-storage";
import StoreService from "./StorageService";
import { SocketResponse, handleSocketEvent } from "../redux/actions/sockets";
import { Dispatch, AnyAction } from "redux";

class WebSocketService {
    static key: string;
    private uri: string = `${process.env.REACT_APP_WS}`;
    private socket: any;

    constructor() {
        this.socket = WebSocket;
    }

    public close = () => {
        this.socket.close();
    }

    public open = () => {
        const session = new StoreService().get(KEY_SESSION);
        WebSocketService.key = session && session.accessToken;

        this.socket = new WebSocket(this.uri, WebSocketService.key);

        this.socket.onerror = () => {
            this.open();
        };

        this.socket.onmessage = (event: any) => {
            const response: SocketResponse = JSON.parse(event.data);
            handleSocketEvent((store.dispatch) as Dispatch<AnyAction>)(response);
        }
    }
}

export default WebSocketService;
