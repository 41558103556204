import axios from 'axios';
import store from "../redux/store";

import StoreService from "./StorageService";
import { KEY_ROLE, KEY_SESSION, KEY_LANGUAGE } from "../const/local-storage";
import { ROLE } from "../const/users";
import STATUS_CODE from "../const/status-codes";
import ToasterService from "./ToasterService";
import AuthService from "./AuthService";
import WebSocketService from "./WebSocketService";
import { HIDE_LOADER, SHOW_LOADER } from "../redux/constants";
import { AnyAction } from "redux";

const REDIRECT_URL = {
    [ROLE.USER]: '/user/sign-in',
    [ROLE.BUSINESS]: '/business/sign-in'
};

export const authorizationRequestInterceptor = (request: any) => {
    store.dispatch({ type: SHOW_LOADER } as AnyAction);

    const storageService = new StoreService();
    const { accessToken } = storageService.get(KEY_SESSION, {});
    const language = storageService.get(KEY_LANGUAGE, { origin: 'et' });

    request.headers = {
        'Authorization': accessToken ? `Bearer ${accessToken}` : '',
        'Accept-Language': language.origin
    };

    return request;
};


export const mapResponseInterceptor = (response: any) => {
    store.dispatch({ type: HIDE_LOADER } as AnyAction);
    return response.data;
};

export const backToLoginErrorInterceptor = (error: any) => {
    const storageService = new StoreService();
    const role = storageService.get(KEY_ROLE);
    const redirectUrl = REDIRECT_URL[role] || '/';
    storageService.remove(KEY_SESSION);
    window.location.href = `${window.location.origin}${redirectUrl}`;
    return Promise.reject(error);
};

export const handleSessionErrorInterceptor = (error: any) => {
    const storageService = new StoreService();
    const { refreshToken } = storageService.get(KEY_SESSION, {});

    if (!refreshToken) {
        return backToLoginErrorInterceptor(error);
    }

    return new AuthService()
        .refreshToken({ refreshToken })
        .then(({ data }: any) => {
            const session = data.data;
            storageService.set(KEY_SESSION, session);

            error.config.headers['Authorization'] = `${session.type} ${session.accessToken}`;
            // retry request
            WebSocketService.key = session.accessToken
            return axios.request(error.config).then(mapResponseInterceptor);
        })
        .catch(backToLoginErrorInterceptor);
};

export const handleResponseErrorInterceptor = (error: any) => {
    store.dispatch({ type: HIDE_LOADER } as AnyAction);
    let dataObject: any = {},
        statusCode
    //    errorCode,
    //    errorMessage = 'Something went wrong!'
    const { response } = error;
    const {
        UNAUTHORIZED,
        UNPROCESSABLE_ENTITY,
        NOT_FOUND,
    } = STATUS_CODE;

    if (response) {
        dataObject = response.data;
    }
    statusCode = dataObject.code || dataObject.statusCode || (response && response.status);

    if (statusCode === UNPROCESSABLE_ENTITY) {
        //   throw error;
    }

    if (response && response.status === UNAUTHORIZED) {
        return handleSessionErrorInterceptor(error);
    }

    if (response && response.status === NOT_FOUND) {
        window.location.href = '/404';
        return Promise.reject(error);
    }
    
    if (dataObject.errors
        && dataObject.errors.length
        && dataObject.errors[0].message
        && response.status !== STATUS_CODE.UNPROCESSABLE_ENTITY
        && response.status !== STATUS_CODE.CONFLICT
        && !response.config.disableToast
    ) {
        ToasterService.error(dataObject.errors[0].message);
    }

    return Promise.reject(error);
};
