import React from 'react'
import { joinClassNames } from "../../helpers/className";
import styles from "./index.module.scss";
import { IPropsClassName } from "../../types/props";
import { COLOR } from '../../const/colors';

interface IProps extends IPropsClassName {
    placeHolder?: string
    disabled?: boolean
    isInvalid?: boolean
    is?: string;
    name?: string
    maxLength?: number,
    value?: any;
    color?: 'primary' | 'secondary';
}

const TextareaField = ({ placeHolder, className, name, disabled, isInvalid, color, ...props }: IProps) => {
    const colorClassName = color === COLOR.SECONDARY ? styles.secondary : styles.primary;

    let classNames = joinClassNames(
        styles.input,
        className,
        colorClassName,
        isInvalid && styles.invalidControl
    );

    return (
        <div className={classNames}>
            <textarea
                name={name}
                disabled={disabled}
                placeholder={placeHolder}
                {...props}
            />
        </div>
    );
};

export default TextareaField;
