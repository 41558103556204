import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import BasePopup from '../BasePopup';
import { closePopup } from '../../redux/actions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import DottedLine from '../../components/DottedLine';
import Flex from '../../components/Flex';
import { formatAMPM } from '../../helpers/dateTime';
import { formatPhone } from '../../pipes/phone';
import { DAYS } from '../../const/days'

import styles from './index.module.scss';
import Button from '../../components/Button';

class SchedulePopup extends BasePopup {
    props: any;
    weekSchedule: any;
    contacts: any;
    common: any;

    constructor(props: any) {
        super(props);

        const { initialData = {} } = props;
        const { i18n: { common } } = props;
        const { weekSchedule, contacts } = initialData;

        this.props = props;
        this.i18n = props.i18n;
        this.title = common.working_Hours;
        this.className = styles.Card;
        this.weekSchedule = weekSchedule;
        this.contacts = contacts;
        this.common = common
        this.state = { wait: false };
        this.withDivider = false;
    }


    renderContent() {
        const placeholder =
            <Flex>
                <div className={styles.placeholder}>{this.common.no_data}</div>
            </Flex>

        const workingHours = this.weekSchedule && DAYS.map(({ name }) => {
            if (!this.weekSchedule[`${name}From`]) {
                return null
            }
            return (<DottedLine key={name}>
                <span className={styles.label}>{this.i18n.week[name]}</span>
                <span className={styles.schedule}>
                    {formatAMPM(this.weekSchedule[`${name}From`])}
                    -
                    {formatAMPM(this.weekSchedule[`${name}To`])}
                </span>
            </DottedLine>)
        })

        const hasContacts = this.contacts && this.contacts.length;

        const contacts = hasContacts && this.contacts.map(({ name, phone }: { name: string, phone: string }, index: number) => (
            <DottedLine key={index} className={styles.dottedLine}>
                <span> {name}</span>
                <span className={styles.onAgreementData}>{formatPhone(phone)}</span>
            </DottedLine >
        ))

        const content =
            <Flex className={styles.popup}>
                <div className={styles.content}>
                    {workingHours}
                    {!!hasContacts &&
                        <>
                            <div className={styles.onAgreement}>
                                {this.common.working_on_weekends_on_agreement}
                            </div>
                            {contacts}
                        </>
                    }
                </div>
                <Flex className={styles.btnWrapper}>
                    <Button
                        className={styles.btn}
                        color='alter'
                        onClick={this.close}>
                        {this.i18n.common.close}
                    </Button>
                </Flex>
            </Flex>

        return <StyledDialogContent>
            {workingHours ? content : placeholder}
        </StyledDialogContent>
    }

}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    closePopup: closePopup(dispatch)
})

const mapStateToProps = ({ i18n }: any) => {
    return { i18n };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePopup);
