import Http from "./HttpService";

class AuthService {

    public refreshToken(model: any) {

        return Http.createInstance({
            baseURL: `${Http.api.common }${Http.versions.v1}`,
        }).put('/sessions', model);
    }

}

export default AuthService;
