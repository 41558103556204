import React from 'react'
import {connect} from "react-redux";
import {FieldArray, reduxForm} from "redux-form";
import _ from 'lodash';

import { Divider } from '@material-ui/core';
import BasePopup from '../BasePopup';
import Button from "../../components/Button";
import ActionButton, {ActionButtonAdd} from "../../components/ActionButton";
import FormControl, {FORM_CONTROL_ELEMENT} from "../../components/FormControl";
import formConfig from "./form-config";
import Label from "../../components/Label";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import styles from './index.module.scss'
import {closePopup, getMyServiceCenterInfo, getServiceCenterById} from "../../redux/actions";
import {filterNotWeekendContacts, filterWeekendContacts} from "../../pipes/contacts";
import ServiceCentersService from "../../services/ServiceCentersService";
import formInternalization from "../../utils/form-internalization";
import BranchesService from '../../services/BranchesService';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import StyledDialogActions from "../../components/Dialog/DialogActions";
import Flex from "../../components/Flex";
import PhoneInput from "../../components/Input/phoneInput/phoneInput";
import { preferredCountries } from "../../const/preferredContries";

function createContact() {
    return {
        phone: '',
        name: ''
    }
}

export function isContactUnique(contacts: any[]) {
    if (contacts) {
        return contacts.length !== _.uniqBy(contacts, 'phone').length
    }
}

export function checkPhone(phone: any, i: number, index: number, allPhones: any) {
    if (index < i || !phone) {
        return;
    }

    if (!allPhones[phone]) {
        allPhones[phone] = 0
    }

    allPhones[phone]++;
}

class ContactsPopup extends BasePopup {
    weekContacts: any[];

    constructor(props: any) {
        super(props);
        this.title = props.i18n.common.contacts;
        this.i18n = props.i18n;
        this.className = styles.Card;
        let {initialData = {}} = props;
        let {contacts} = initialData;
        if (contacts.length === 0) {
            contacts = [{}];
        }

        this.weekContacts = [];

        if (contacts) {
            props.change('contacts', [...contacts.filter(filterNotWeekendContacts)]);
            this.weekContacts = contacts.filter(filterWeekendContacts);
        }
    }

    public save(contacts: any[]) {
        let props: any = this.props;
        const {initialData} = props;
        if (initialData.serviceCenterId) {
            new BranchesService().editBranchContacts(initialData.serviceCenterId, [...contacts, ...this.weekContacts])
                .then(() => {
                    props.getServiceInfoById(initialData.serviceCenterId)
                    this.close()
                })
            return
        }

        new ServiceCentersService().changeContacts([...contacts, ...this.weekContacts])
            .then(() => {
                props.getMyServiceCenterInfo();
                this.close();
            });
    }

    renderContacts = (props: any) => {
        const {fields} = props;

        formInternalization(formConfig);

        return (
            <>
                <StyledDialogContent>
                    {fields.map((contact: string, index: number) => {
                            let allPhones: any = {};

                            fields.getAll().forEach(({phone}: any, i: number) => {
                                checkPhone(phone, i, index, allPhones);
                            });

                            return (
                                <div className={styles.row} key={index}>
                                    {!!index && <Divider className={styles.divider}/>}

                                    <FormControl {...formConfig.name}
                                                 className={styles.formControl}
                                                 name={`${contact}.${formConfig.name.name}`}>
                                        <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.font}/>
                                        <Input is={FORM_CONTROL_ELEMENT.INPUT} withBorder={true} className={styles.font}/>
                                        <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE}/>
                                    </FormControl>

                                    <FormControl {...formConfig.phone}
                                                 className={styles.formControl}
                                                 name={`${contact}.${formConfig.phone.name}`}>
                                        <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.font}/>
                                        <PhoneInput fullWidth
                                                    withBorder
                                                    is={ FORM_CONTROL_ELEMENT.INPUT }
                                                    countryCodeEditable={ false }
                                                    defaultCountry={ 'ee' }
                                                    preferredCountries={ preferredCountries }
                                                    regions={ 'europe' }/>
                                        <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE}/>
                                        {allPhones[fields.getAll()[index].phone] > 1 && <ErrorMessage>{
                                            this.i18n.validations_message.contact_with_such_phone_number_has_already_been_added
                                        }</ErrorMessage>}
                                    </FormControl>

                                    <ActionButton icon={"delete"}
                                                  className={styles.btnDelete}
                                                  onClick={() => fields.remove(index)}>
                                        {this.i18n.common.delete}
                                    </ActionButton>
                                </div>)
                        }
                    )}
                </StyledDialogContent>

                <ActionButtonAdd
                    children={this.i18n.common.add_new_contact}
                    className={styles.btnAdd}
                    onClick={() => fields.push(createContact())}/>
            </>);
    };

    public renderContent() {
        let props: any = this.props;
        let {handleSubmit, invalid, $values, i18n} = props;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <FieldArray
                    name="contacts"
                    component={this.renderContacts}/>
                <StyledDialogActions>
                    <Flex className={styles.buttons}>
                            <Button large={false}
                                    className={styles.btnCancel}
                                    small={true}
                                    fontSize={16}
                                    color={"secondary"}
                                    onClick={this.close}
                                    variant={"outlined"}>
                                {i18n.common.cancel}
                            </Button>

                            <Button large={false}
                                    small={true}
                                    fontSize={16}
                                    className={styles.btnSave}
                                    disabled={invalid || isContactUnique($values.contacts)}
                                    color={"alter"}
                                    onClick={() => this.save($values.contacts)}
                                    variant={"text"}>
                                {i18n.common.save}
                            </Button>
                    </Flex>
                </StyledDialogActions>
            </form>
        )
    }
}


const form = BasePopup.generateKey();
const mapStateToProps = (state: any) => {
    let {companyTypes, myServiceCenterInfo, i18n} = state;
    let {values = {}} = state.form[form] || {};
    return {companyTypes, myServiceCenterInfo, $values: values, i18n}
};

export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
    getServiceInfoById: getServiceCenterById(dispatch)
});

const Popup: any = ContactsPopup;
const Form: any = reduxForm({
    form,
    initialValues: {
        contacts: [
            createContact()
        ]
    }
})(Popup);

export default connect(mapStateToProps, mapDispatchToProps)(
    Form
);

