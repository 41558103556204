import { Dispatch } from "redux";

import ServiceOwnersService from "../../services/ServiceOwnersService";
import {
    LOGIN_SERVICE_OWNER_FAIL,
    LOGIN_SERVICE_OWNER_REQUEST,
    LOGIN_SERVICE_OWNER_SUCCESS,
    SIGN_UP_CLEAR_DATA,
    SIGN_UP_FAIL,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    RESET_LOGIN_STATE
} from "../constants";
import { ServiceOwnerRegister } from "../../types/ServiceOwnerRegister";
import StoreService from "../../services/StorageService";
import { IS_COMPANY, KEY_ROLE, KEY_SESSION, KEY_SIGN_UP_STEPS, KEY_USER_ID, PRIVACY, KEY_USER_INFO } from "../../const/local-storage";
import { ILoginModel } from "../../types/models";
import CarOwnersService from "../../services/CarOwnersService";
import { ROLE } from "../../const/users";
import updateDataLayer from "../../utils/data-layer";

export const signUpRequest = (dispatch: Dispatch, role: string) => (model: ServiceOwnerRegister) => {
    let service = role === ROLE.BUSINESS ? new ServiceOwnersService() : new CarOwnersService();

    dispatch({
        type: SIGN_UP_REQUEST,
    });

    service.register(model)
        .then(({ data }: any) => data)
        .then((payload: any) => {
            updateDataLayer({
                'eventCategory': 'form',
                'eventAction': 'registration',
                'eventLabel': 'success',
                'event': 'autoEvent'
            });
            dispatch({ type: SIGN_UP_SUCCESS, payload })
        })
        .catch((error: Error) => {
            updateDataLayer({
                'eventCategory': 'form',
                'eventAction': 'registration',
                'eventLabel': 'fail',
                'event': 'autoEvent'
            });
            dispatch({ type: SIGN_UP_FAIL, error })
        })
};

export const signUpClearState = (dispatch: Dispatch) => () => {
    const storeService = new StoreService();

    storeService.remove(KEY_SIGN_UP_STEPS);
    storeService.remove(PRIVACY);

    dispatch({ type: SIGN_UP_CLEAR_DATA });
};


export const login = (dispatch: Dispatch) => (model: ILoginModel, role: string) => {
    const storeService = new StoreService();

    dispatch({
        type: LOGIN_SERVICE_OWNER_REQUEST,
    });

    new ServiceOwnersService()
        .login(model, role)
        .then(({ data }: any) => data)
        .then((payload: any) => {
            updateDataLayer({
                'eventCategory': 'form',
                'eventAction': 'authorization',
                'eventLabel': 'success',
                'event': 'autoEvent'
            });
            storeService.set(KEY_USER_ID, payload.id);
            storeService.set(KEY_SESSION, payload.token);
            storeService.set(KEY_ROLE, payload.role);
            storeService.set(KEY_USER_INFO, payload.user);

            payload.isCompany && storeService.set(IS_COMPANY, payload.isCompany)
            dispatch({ type: LOGIN_SERVICE_OWNER_SUCCESS, payload })
        })
        .catch((error: Error) => {
            updateDataLayer({
                'eventCategory': 'form',
                'eventAction': 'authorization',
                'eventLabel': 'fail',
                'event': 'autoEvent'
            })
            dispatch({ type: LOGIN_SERVICE_OWNER_FAIL, error })
        })
};

export const resetLoginState = (dispatch: Dispatch) => () => dispatch({ type: RESET_LOGIN_STATE })