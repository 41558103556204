import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormControl, { FORM_CONTROL_ELEMENT } from '../FormControl';
import Flex from '../Flex';
import Label from '../Label';
import Input from '../Input';
import ErrorMessage from '../ErrorMessage';
import TimeSlider, { MS_IN_DAY } from '../TimeSlider';
import { msToTime, timeToMs } from '../../utils/time-utils';
import { joinClassNames } from "../../helpers/className";
import { IPropsClassName } from '../../types/props';
import formInternalization from '../../utils/form-internalization';
import formConfig from './form-config';

import styles from './index.module.scss';

interface iProps extends IPropsClassName {
    field: any,
    values: any,
    change: any,
    $values: any
    classNameControl?: any,
    i18n?: any
}

const HoursRange = ({ field, values, change, $values, i18n }: iProps) => {
    const time = timeToMs($values[field.name]);
    const initialTime = time.length > 1 ? time : [0, MS_IN_DAY]
    const [times, setTimes] = useState<any[]>(initialTime);

    formInternalization(formConfig)

    return (
        <Flex className={styles.row}>
            <TimeSlider
                className={styles.slider}
                value={times}
                onChange={(event: any, values: number[]) => {
                    setTimes(values);
                    change(field.name, msToTime(+values[0]) + ' - ' + msToTime(+values[1]));
                }}
            />

            <FormControl {...formConfig.time} {...field} className={styles.input}>
                <Label is={FORM_CONTROL_ELEMENT.LABEL} className={styles.font} />
                <Input is={FORM_CONTROL_ELEMENT.INPUT} placeholder={i18n.forms.time.placeholder} withBorder={true} className={joinClassNames(styles.font, styles.dateInput)} />
                <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
            </FormControl>
        </Flex>);
};

const mapStateToProps = (state: any) => {
    const { i18n } = state;
    return { i18n };
}

export default connect(mapStateToProps, null)(HoursRange);
