import React from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";

import Button from "../../../../components/Button";
import StyledDialogActions from "../../../../components/Dialog/DialogActions";
import { TABLET_BP } from "../../../../const/users";
import styles from './index.module.scss';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%"
    },
    rootMobile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%"
    },
    breaker: {
        marginBottom: "12px",
        marginLeft: "16px"
    }
}));

const CalendarActionButton = ({ i18n, onCancel, onOk, readOnly, invalid }) => {
    const matches = useMediaQuery(TABLET_BP);
    const classes = useStyles();
    return (
        <StyledDialogActions>
            <div className={!matches ? classes.root : classes.rootMobile}>
                <Button onClick={onCancel}
                    fontSize={16}
                    small={false}
                    large={false}
                    variant={"outlined"}>
                    {i18n.common.cancel}
                </Button>
                {!readOnly && <div className={classes.breaker} />}
                {!readOnly && (
                    <Button fontSize={16}
                        className={styles.finishButton}
                        small={false}
                        large={false}
                        color={"alter"}
                        variant={"text"}
                        disabled={invalid}
                        onClick={onOk}>
                        {i18n.common.submit}
                    </Button>
                )}
            </div>
        </StyledDialogActions>
    );
};
const mapStateToProps = ({ i18n }) => {
    return { i18n };
};

export default connect(mapStateToProps)(CalendarActionButton);
