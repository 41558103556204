import React from 'react';

import Flex from '../Flex';
import Icon from '../Icon';

import styles from './index.module.scss';

const ETARow = ({ eta }: any) => (
    <Flex className={styles.eta}>
        <Icon icon='clockGray' className={styles.icon}/>
        ETA {eta.slice(0, 5)}
    </Flex>
)

export default ETARow;