import React from 'react';
import { Feature, Layer } from 'react-mapbox-gl';

const MapUserMarker = ({ position: { lng, lat }, onDragEnd, handleZoom }: any) => (
    <Layer type={'circle'}
        paint={{
            'circle-color': '#ffdc37',
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#2a2728'
        }}
    >
        <Feature
            coordinates={[lng, lat]}
            draggable={!!onDragEnd}
            onDragEnd={(e: any) => {
                handleZoom(e.target.getZoom());
                onDragEnd(e.lngLat)
            }}
        />
    </Layer>
)

export default MapUserMarker;