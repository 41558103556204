import React from 'react';
import { connect } from 'react-redux';

import BasePopup from '../BasePopup';
import { closePopup } from '../../redux/actions';
import StyledDialogContent from '../../components/Dialog/DialogContent';
import Button from '../../components/Button';
import Flex from '../../components/Flex';

import styles from './index.module.scss';

class CancelSearchPopup extends BasePopup {
    callback: any;
    action: string;

    constructor(props: any) {
        super(props);
        const { i18n, initialData: { callback, action } } = props;
        this.i18n = i18n;
        this.callback = callback;
        this.action = action
        this.withDivider = false;

        this.title = undefined;
    }

    renderContent() {
        const { i18n, action, close, callback } = this;
        return (
            <StyledDialogContent>
                <Flex className={styles.content}>

                    <div className={styles.title}>
                        {i18n.find_service.are_you_sure_you_want_to_cancel_search}
                    </div>

                    <Flex className={styles.buttons}>

                        <Button
                            variant='outlined'
                            type='submit'
                            className={styles.button}
                            onClick={() => {
                                callback();
                                close();
                            }}>
                            {action}
                        </Button>

                        <Button
                            variant='text'
                            color='alter'
                            onClick={
                                () => {
                                    close();
                                }
                            }
                        >
                            {i18n.find_service.no_keep_search}
                        </Button>
                    </Flex>
                </Flex>
            </StyledDialogContent>
        )
    }
}


export const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch)
});

const mapStateToProps = (state: any) => {
    return { i18n: state.i18n }
};


export default connect(mapStateToProps, mapDispatchToProps)(CancelSearchPopup);
