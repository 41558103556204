import React from 'react';
import { DialogActions } from '@material-ui/core';

import styles from './index.module.scss';

const StyledDialogActions = ({ children }: any) => (
    <DialogActions className={styles.DialogActions}>
        {children}
    </DialogActions>
);


export default StyledDialogActions;

