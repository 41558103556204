import ContactsPopup from './ContactsPopup';
import LocationPopup from './LocationPopup';
import AddressPopup from './AddressPopup';
import AddBranch from './AddBranchPopup';
import ServiceSummaryEdit from './ServiceSummaryEditPopup';
import AddNewService from './AddNewServicePopup';
import WorkingHoursPopup from './WorkingHoursPopup';
import OfferTimePopup from './OfferTimePopup';
import AddVehicle from './AddVehicle';
import DeclineRequestsPopup from './DeclineRequestsPopup';
import DeleteVehicle from './DeleteVehicle';
import BasePopup from './BasePopup';
import SuccessPopup from './SuccessPopup';
import OfferDetailsPopup from './OfferDetailsPopup';
import ConfirmPopup from './ConfirmPopup';
import ClientInformationPopup from './ClientInformationPopup';
import CancelBookingPopup from './CancelBookingPopup';
import CarInformationPopup from './CarInformationPopup';
import CropImagePopup from './CropImagePopup';
import EditBranchServicePopup from './EditBranchServicePopup';
import SchedulePopup from './SchedulePopup';
import SaveReviewPopup from './SaveReviewPopup';
import ReportPopup from './ReportPopup';
import UserLocationPopup from './UserLocationPopup';
import ETAPopup from './ETAPopup';
import TowRangePopup from './TowRangePopup';
import CancelSearchPopup from './CancelSearchPopup';
import ConfirmPopupFullScreen from './ConfirmFullScreenPopup';
import ServiceInfoContentMobile from './ServiceInfoContentPopup';
import OfferOnMapPopup from './OfferOnMapPopup';


const uuid = () => BasePopup.generateKey();

export const CONTACTS_POPUP = {
    Component: ContactsPopup,
    key: uuid(),
    fullScreen: true
};

export const LOCATION_POPUP = {
    Component: LocationPopup,
    key: uuid()
};

export const ADDRESS_POPUP = {
    Component: AddressPopup,
    key: uuid()
};

export const USER_LOCATION_POPUP = {
    Component: UserLocationPopup,
    key: uuid()
};

export const CLIENT_INFORMATION_POPUP = {
    Component: ClientInformationPopup,
    key: uuid()
};

export const OFFER_TIME_POPUP = {
    Component: OfferTimePopup,
    key: uuid()
};

export const ETA_POPUP = {
    Component: ETAPopup,
    key: uuid()
};

export const CROP_IMAGE_POPUP = {
    Component: CropImagePopup,
    key: uuid()
};

export const DECLINE_REQUESTS_POPUP = {
    Component: DeclineRequestsPopup,
    key: uuid()
};

export const CANCEL_BOOKING_POPUP = {
    Component: CancelBookingPopup,
    key: uuid(),
    fullScreen: true
};

export const ADD_BRANCH_POPUP = {
    Component: AddBranch,
    key: uuid()
};

export const OFFER_DETAILS_POPUP = {
    Component: OfferDetailsPopup,
    key: uuid(),
    alwaysFullScreen: true
};

export const CAR_INFORMATION_POPUP = {
    Component: CarInformationPopup,
    key: uuid(),
    fullScreen: true
};

export const CONFIRM_POPUP = {
    Component: ConfirmPopup,
    key: uuid()
};

export const SERVICE_SUMMARY_EDIT_POPUP = {
    Component: ServiceSummaryEdit,
    key: uuid(),
    fullScreen: true
};

export const SUCCESS_POPUP = {
    Component: SuccessPopup,
    key: uuid()
};

export const ADD_NEW_SERVICE_POPUP = {
    Component: AddNewService,
    key: uuid(),
    fullScreen: true
};

export const WORKING_HOURS_POPUP = {
    Component: WorkingHoursPopup,
    key: uuid(),
    fullScreen: true
};

export const TOW_RANGE_POPUP = {
    Component: TowRangePopup,
    key: uuid(),
    fullScreen: true
};

export const ADD_VEHICLE_POPUP = {
    Component: AddVehicle,
    key: uuid(),
    fullScreen: true
};

export const DELETE_VEHICLE_POPUP = {
    Component: DeleteVehicle,
    key: uuid(),
    fullScreen: true
};

export const EDIT_BRANCH_SERVICE_POPUP = {
    Component: EditBranchServicePopup,
    key: uuid()
};

export const SCHEDULE_POPUP = {
    Component: SchedulePopup,
    key: uuid(),
    fullScreen: true
};

export const SAVE_REVIEW_POPUP = {
    Component: SaveReviewPopup,
    key: uuid(),
    fullScreen: true
};

export const REPORT_POPUP = {
    Component: ReportPopup,
    key: uuid()
};

export const CANCEL_SEARCH_POPUP = {
    Component: CancelSearchPopup,
    key: uuid(),
    fullScreen: true
};

export const CONFIRM_POPUP_FULL_SCREEN = {
    Component: ConfirmPopupFullScreen,
    key: uuid(),
    fullScreen: true
}

export const LANDING_SERVICE_DETAILS = {
    Component: ServiceInfoContentMobile,
    key: uuid(),
    fullScreen: true,
}

export const OFFER_ON_MAP_POPUP = {
    Component: OfferOnMapPopup,
    key: uuid()
}
