import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import { closePopup } from '../../redux/actions';
import { joinClassNames } from '../../helpers/className';

import styles from './index.module.scss';

class ServiceInfoContent extends React.Component {
    props: any;

    constructor(props: any) {
        super(props);
        this.props = props;
    }

    render() {
        const { uuid, closePopup } = this.props;
        const { service, onSubmit } = this.props.initialData;
        const onClick = () => {
            onSubmit();
            closePopup(uuid);
        }

        const wrapperClasses = joinClassNames(
            styles.open,
            styles.wrapper,
            styles.links,
            styles.padding,
        );

        return (
            <div className={wrapperClasses}>
                <ActionButton icon={'closeBlack'} className={styles.burger}
                    onClick={() => closePopup(uuid)} />
                <div className={styles.links}>
                    <div className={styles.contentHeader}>{service.name}</div>
                    <div className={styles.contentText}>{service.text}</div>
                    <Button color={'alter'} type={'submit'} onClick={() => onClick()} className={styles.btn}>
                        {this.props.landingCOR.book_car_service}
                    </Button>
                    <div className={styles.contentPicture}>
                        <img className={styles.contentPicture} src={service.picture} alt='' />
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { i18n: { landingCOR } } = state;
    return { landingCOR }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closePopup: closePopup(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceInfoContent);
