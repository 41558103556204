import React, { useState, useMemo, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Flex from "../Flex";
import Icon from "../Icon";
import { IPropsClassName } from "../../types/props";
import { joinClassNames } from "../../helpers/className";
import ServiceCentersService from '../../services/ServiceCentersService';
import { Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import Label from "../Label";
import StoreService from "../../services/StorageService";
import { KEY_LANGUAGE } from "../../const/local-storage";
import { DEFAULT_LANGUAGE } from "../../const/languages";
import { capitalizeFirstLetter } from "../../popups/MuiDatePicker/components/Datepicker/utils";

import styles from './index.module.scss'

const MIN_LENGTH_OF_SEARCH_TEXT = 2;

const serviceCentersService = new ServiceCentersService();
const storage = new StoreService();

interface IProps extends IPropsClassName {
    onSelect: (val: any) => any;
    i18n?: any;
    clearValues?: any;
    serviceObject?: any;
    isDirect?: boolean
}

interface ServiceProps {
    descriptionTranslations: {
        descriptionEn: string,
        descriptionEe: string,
        descriptionRu: string
    },
    nameTranslations: {
        nameEn: string,
        nameEe: string,
        nameRu: string
    },
    businessTypeId: number,
    businessTypeName: string,
    name: string,
    defaultDescription: string,
    id: number
}

const SearchService = ({ onSelect, i18n, clearValues, serviceObject, isDirect }: IProps) => {

    const [text, setText] = useState('');
    const [services, setServices] = useState([]);
    const [error, setError] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedService, setSelectedService] = useState<null | ServiceProps>(serviceObject)

    const isEmptyInput = useMemo(() => !!text.length, [text]);

    const getServices = useCallback(async () => {
        if (!text || text.length < MIN_LENGTH_OF_SEARCH_TEXT) return;
        try {
            const { data } = await serviceCentersService.searchService(text);
            setServices(data);
            if (text.length && !data.length) {
                setError(true);
            } else {
                setError(false);
            }
        } catch {
            //empty
        }
    }, [text])

    function toggle() {
        setIsOpen(prevState => !prevState)
    }

    function getTranslation(service: ServiceProps) {
        const locale = storage.get(KEY_LANGUAGE, DEFAULT_LANGUAGE).shortName;
        const nameToOutput = `name${capitalizeFirstLetter(locale)}`;
        return service.nameTranslations[nameToOutput as "nameEe"];
    }

    function handleSelect(service: ServiceProps) {
        setSelectedService(service);
        setText(getTranslation(service));
        onSelect(service);
        toggle();
    }

    function clearInputValues() {
        setText('');
        setSelectedService(null);
        setServices([]);
        setError(false);
        clearValues();
    }

    useEffect(() => {
        getServices()
    }, [getServices]);

    useEffect(() => {
        if (selectedService?.id) {
            setText(getTranslation(selectedService));
        }
    }, [i18n])

    return (
        <Dropdown isOpen={isOpen} toggle={toggle} >
            <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={isOpen}
            >
                <Flex
                    className={joinClassNames(styles.container)}
                >
                    <Label className={styles.label}>{i18n.find_request_page.find_service_for_you}</Label>
                    <section className={styles.inputWrapper}>
                        <Input className={joinClassNames(isEmptyInput ? styles.input : styles.inputWithIcons, error && styles.errorBorder)}
                            placeholder={i18n.find_request_page.search_service}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => { setText(e.target.value.trimLeft()); setIsOpen(true) }}
                            type='text'
                            disabled={isDirect}
                            value={text}
                        />
                        {isEmptyInput ?
                            <Icon icon='clear' className={styles.clearIcon} onClick={clearInputValues} />
                            :
                            <Icon icon='search' className={styles.searchIcon} />
                        }
                    </section>
                    {error && <span className={styles.error}>{i18n.find_request_page.no_options}</span>}


                </Flex>
            </DropdownToggle>

            {isOpen && !!services.length && text.length >= MIN_LENGTH_OF_SEARCH_TEXT &&
                <DropdownMenu className={styles.dropdownContainer}>
                    {services.map((service: ServiceProps) => {
                        const selectedServiceName = getTranslation(service);
                        const isSelected = selectedService?.id === service.id;
                        return (
                            <span
                                key={service.id}
                                className={
                                    joinClassNames(styles.dropdownElement,
                                        isSelected && styles.selectedElement
                                    )
                                }
                                onClick={() => handleSelect(service)}
                            >
                                {selectedServiceName}
                            </span>
                        )
                    })}
                </DropdownMenu>
            }
        </Dropdown>
    )
};

const mapStateToProps = ({ i18n }: any) => {
    return { i18n }
};

export default connect(mapStateToProps, null)(SearchService)
