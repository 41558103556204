import React from 'react'
import { ReactComponent as IconCalendar } from '../images/vehicle/ic-calendar.svg'
import { ReactComponent as IconPower } from '../images/vehicle/ic-engine-power.svg'
import { ReactComponent as IconVolume } from '../images/vehicle/ic-engine-volume.svg'
import { ReactComponent as IconFuel } from '../images/vehicle/ic-fuel.svg'
import { ReactComponent as IconMileage } from '../images/vehicle/ic-mileage.svg'
import { ReactComponent as IconRegNumber } from '../images/vehicle/ic-reg-number.svg'
import { ReactComponent as IconGearbox } from '../images/vehicle/ic-transmission.svg'

export const VEHICLE_FIELDS = [
    { key: "registrationNumber", title: 'Reg. Number', icon: <IconRegNumber /> },
    { key: "volume", title: 'Engine Volume', icon: <IconVolume /> },
    { key: "enginePower", title: 'Engine power', icon: <IconPower /> },
    { key: "fuelType", title: 'Fuel', icon: <IconFuel /> },
    { key: "mileage", title: 'Mileage', icon: <IconMileage /> },
    { key: "gearbox", title: 'Gearbox', icon: <IconGearbox /> },
    { key: "year", title: 'Year', icon: <IconCalendar /> },
];
