/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IPropsClassName } from '../../types/props';
import { getBranches } from '../../redux/actions/branches';
import { getMyServiceCenterInfo } from '../../redux/actions';
import Flex from '../Flex';
import Divider from '../Divider';
import BranchList from '../BranchList';
import Icon from '../Icon';

import styles from './index.module.scss';

interface IProps extends IPropsClassName {
    getBranches: any,
    getMyServiceCenterInfo: any,
    branches: any,
    myServiceCenterInfo: any,
}

const BranchListDropdown = ({ getBranches, getMyServiceCenterInfo, branches, myServiceCenterInfo, ...props }: IProps) => {
    const { collection } = branches;
    const { image, branchName } = myServiceCenterInfo;

    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        !branchName && getMyServiceCenterInfo();
    }, []);

    useEffect(() => {
        getBranches();
    }, []);

    return (
        <div className={styles.Dropdown}>
            <Flex className={styles.NavBarLink}>
                <img className={styles.Logo} src={image && image.compactPath} />
                <p>{branchName}</p>
                <Icon icon={'dropDown'} onClick={() => {
                    setIsShown(!isShown);
                }} />
            </Flex>
            {isShown && <BranchList branches={collection} className={styles.branchList} refresh={getBranches} />}
            <Divider className={styles.Divider} />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBranches: getBranches(dispatch),
    getMyServiceCenterInfo: getMyServiceCenterInfo(dispatch),
});

const mapStateToProps = (state: any) => {
    const { branches, myServiceCenterInfo } = state;
    return { branches, myServiceCenterInfo };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchListDropdown);
