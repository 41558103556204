import qs from 'qs';

const location = window.location;

export function mergeQueryParams(queryMap) {
    const params = new URLSearchParams(location.search);

    Object.keys(queryMap).forEach(queryName => {

        if (queryMap[queryName]) {
            params.set(queryName, queryMap[queryName]);
        } else {
            params.delete(queryName);
        }
    });

    return params.toString();
}

export function getQueryParams() {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
}

export function stringifyQueryParams(params) {
    return qs.stringify(params);
}

export function getCurrentPathname() {
    return location.pathname;
}

