export const formatDistance = (value: any) => {
    if (!value) {
        return '';
    }
    value = value.toFixed(2);

    if(value > 1) {
        return value + ' km';
    }

    return value * 1000 + '  m';
};
