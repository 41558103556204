import qs from 'qs';
import UsersService from "./UsersService";
import { PAGINATION_LIMIT } from "../const/pagination";
import updateDataLayer from "../utils/data-layer";
import { createRequestModel } from '../pipes/find-request';
import moment from 'moment';

const ScreensEnum = {
    TypeOfService: 0,
    Location: 1,
    DateAndTime: 2,
    CarsInformation: 3,
    Account: 4,
}

const ScreensEnumTowTruck = {
    TypeOfService: 0,
    Location: 1,
    CarsInformation: 2,
    Account: 3,
}

const TOW_TRUCK_ID = 6;
const MIN_IN_HOUR = 60;

function transformPotencialModel(model: Array<any>) {
    const isTowTruck = model[ScreensEnum.TypeOfService].selectedService.id === TOW_TRUCK_ID
    const pagesEnum = isTowTruck ? ScreensEnumTowTruck : ScreensEnum;

    const { phoneNumber, firstName, lastName, ...restRequestModel} = createRequestModel(model);

    const locationInfo = model[pagesEnum.Location];
    const accountInfo = model[pagesEnum.Account];

    if(!accountInfo?.registrationNumber){
        restRequestModel.account = model[pagesEnum.Account];
    }
    if(!isTowTruck){
        restRequestModel.radius = locationInfo?.radius ? locationInfo.radius.value : undefined;
    }

    return restRequestModel;
}

const getTimezoneOffset = () => {
    return moment().utcOffset() / MIN_IN_HOUR;
}
class CarOwnersService extends UsersService {

    public register(model: any) {
        model.timeZoneOffset = getTimezoneOffset();
        return this.post('/car-owners', model);
    }

    public getVehicles(offset: number, limit: number) {
        return this.get(`/car-owners/me/vehicles?Limit=${limit}&offset=${offset}`);
    }

    public getOffersOnMap() {
        return this.get(`/car-owners/me/offers/map`)
    }

    public getMyOffers(model: any) {
        return this.get('car-owners/me/offers', {
            params: model,
            paramsSerializer: (params: any) => {
                return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true, skipNulls: true })
            }
        })
    }

    public addVehicles(model: any) {
        return this.post('/car-owners/me/vehicles', model)
            .then((res: any) => {
                const { model, brand, year, gearbox, fuelType } = res.data;
                updateDataLayer({
                    'eventCategory': 'car',
                    'eventAction': 'create',
                    'event': 'autoEvent',
                    'booking_car_model': model,
                    'booking_car_brand': brand,
                    'booking_car_fuel_type': fuelType,
                    'booking_car_gearbox': gearbox,
                    'booking_car_year': year 
                }
                )
                return res;
            });
    }

    public editVehicles(id: number, model: any) {
        return this.put(`/car-owners/me/vehicles/${id}`, model);
    }

    public deleteVehicles(id: number) {
        return this.delete(`/car-owners/me/vehicles/${id}`);
    }

    public getFavotites(model: any) {
        return this.get('/car-owners/me/favourites', model);
    }

    public addToFavorite(id: number) {
        return this.put(`/car-owners/me/favourites/${id}`);
    }

    public deleteFromFavorite(id: number) {
        return this.delete(`/car-owners/me/favourites/${id}`);
    }

    public changeCarOwnerEmail(email: string) {
        return this.post('/car-owners/me/email', { email });
    }

    public getFindService() {
        return this.get('/car-owners/me/find-service');
    }

    public getBookings(offset: number, option: string) {
        return this.get(`/car-owners/me/bookings?Limit=${PAGINATION_LIMIT}&Offset=${offset}&Option=${option}`);
    }

    public validateBooking(id: number, serviceReceived: boolean) {
        return this.patch(`/contracts/bookings/${id}/validate`, { serviceReceived });
    }

    public getBadgeRequests() {
        return this.get('car-owners/me/badge');
    }

    public findCar = (regNumber: string) => {
        return this.get(`car-owners/car-info?RegistrationNumber=${regNumber}`)
    }

    public towRangeCheck = (model: any, id: number) => {
        const { location: { title, center } } = model;
        return this.get(`service-centers/${id}/max-distance?Title=${title}&GeoLocation.lat=${center.lat}&GeoLocation.Lng=${center.lng}`)
    }

    public preFilledRegistration(model: Array<any>) {
        const requestModel = transformPotencialModel(model);
        if(requestModel.account){
            requestModel.account.timeZoneOffset = getTimezoneOffset();
        }
        return this.post('/car-owners/pre-filled', requestModel);
    }

    public getPotencialCarServices(model: Array<any>) {
        const requestModel = transformPotencialModel(model)
        return this.post('/contracts/requests/find-service/count', requestModel);
    }
}


export default CarOwnersService;
