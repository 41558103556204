import React from "react";
import styles from './index.module.scss'
import { joinClassNames } from "../../helpers/className";

interface Props {
    children?: any;
    className?: string;
    disabled?: boolean;
    large?: boolean;
    small?: boolean;
    onClick?: (v?: any) => any
    fontSize: 14 | 16;
    type?: 'button' | 'submit';
    color: 'primary' | 'danger' | 'default' | 'secondary' | 'alter',
    variant: 'outlined' | 'text'
}

const BUTTON_PALETTE = {
    text: {
        primary: styles.primary,
        danger: styles.danger,
        secondary: styles.lightBlue,
        default: '',
        alter: styles.alter
    },
    outlined: {
        primary: styles.primaryOutlined,
        danger: styles.dangerOutlined,
        secondary: styles.lightBlueOutlined,
        default: styles.defaultOutlined,
        alter: styles.alterOutline
    }
};

const Button = ({ children, type, className, fontSize, small, large, color, variant, onClick, disabled }: Props) => {
    let classNames = joinClassNames(
        large && styles.large,
        small ? styles.small : styles.normal,
        `f-size-${fontSize}`,
        BUTTON_PALETTE[variant][color],
        className,
    );

    return (
        <button className={classNames}
            disabled={disabled}
            onClick={onClick}
            type={type}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    className: '',
    type: 'button',
    variant: 'text',
    color: 'default',
    fontSize: 16,
    large: true,
    small: true
};


export default Button;
