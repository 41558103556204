export const MY_VEHICLES_INFO_REQUEST = 'SERVICE_CENTER_INFO_REQUEST';
export const MY_VEHICLES_INFO_SUCCESS = 'SERVICE_CENTER_INFO_SUCCESS';
export const MY_VEHICLES_INFO_FAIL = 'SERVICE_CENTER_INFO_FAIL';

export const NEW_OFFER = 'NEW_OFFER';
export const NEW_DIRECT_OFFER = 'NEW_DIRECT_OFFER';
export const MY_OFFERS_INFO_REQUEST = 'MY_OFFERS_INFO_REQUEST';
export const MY_OFFERS_INFO_SUCCESS = 'MY_OFFERS_INFO_SUCCESS';
export const MY_OFFERS_INFO_FAIL = 'MY_OFFERS_INFO_FAIL';
export const MY_OFFERS_CREATE_FIND_SERVICE_FAIL = 'MY_OFFERS_CREATE_FIND_SERVICE_FAIL';

export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL';

export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_REQUEST_SUCCESS = 'GET_FAVORITES_REQUEST_SUCCESS';
export const GET_FAVORITES_REQUEST_FAIL = 'GET_FAVORITES_REQUEST_FAIL';

export const SET_SELECTED_FAVORITES = 'SET_SELECTED_FAVORITES';
export const GET_SELECTED_FAVORITES = 'GET_SELECTED_FAVORITES';

export const BADGE_CO_NEW_REQUESTS_WS = 'BADGE_CO_NEW_REQUESTS_WS';

export const GET_FIND_SERVICE_REQUEST_SUCCESS = "GET_FIND_SERVICE_REQUEST_SUCCESS";
export const GET_FIND_SERVICE_REQUEST_FAIL = "GET_FIND_SERVICE_REQUEST_FAIL";
export const CLEAR_ERROR = "CLEAR_ERROR";
