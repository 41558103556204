import {AnyAction} from "redux";
import {SET_LANGUAGE} from "../constants";

export const i18n = (state = {}, action: AnyAction) => {

    switch (action.type) {
        case SET_LANGUAGE:
            return {...state, ...action.payload};
        default:
            return state
    }
};
