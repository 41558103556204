import Http from "./HttpService";
import englishLang from '../locale/Bookmap_frontend_English.json';
import estonianLang from '../locale/Bookmap_frontend_Estonian.json';
import russianLang from '../locale/Bookmap_frontend_Russian.json';
class LanguageService extends Http {

    public setLanguage(lang: string) {
        let langFile = null;
        switch (lang) {
            case 'en':
                langFile = englishLang;
                break;
            case 'ee':
                langFile = estonianLang;
                break;
            case 'ru':
                langFile = russianLang;
                break;
            default:
                langFile = estonianLang;
        }
        return langFile
    }
}

export default new LanguageService();
