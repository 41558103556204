import BasePopup from "../BasePopup";
import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { closePopup } from "../../redux/actions";
import Button from "../../components/Button";
import TextareaField from "../../components/Textarea";
import Flex from "../../components/Flex";
import { FORM_CONTROL_ELEMENT, default as FormControl } from "../../components/FormControl";
import ErrorMessage from "../../components/ErrorMessage";
import StyledDialogContent from "../../components/Dialog/DialogContent";
import StyledDialogActions from "../../components/Dialog/DialogActions";
import Label from "../../components/Label";
import Dropdown from "../../components/Dropdown";
import { joinClassNames } from "../../helpers/className";
import ContractsService from "../../services/ContractsService";
import ServiceCentersService from "../../services/ServiceCentersService";
import { formatPhone } from "../../pipes/phone";
import formInternalization from "../../utils/form-internalization";
import updateDataLayer from "../../utils/data-layer";
import formConfig from "./form-config";

import styles from './index.module.scss';

class CancelBookingPopup extends BasePopup {
    props: any;
    id: number;
    callback: any;
    reasons: any[];

    constructor(props: any) {
        super(props);
        const { initialData = {}, i18n } = props;
        const { id, callback, serviceCenterId } = initialData;
        this.withDivider = false;
        this.id = id;
        this.callback = callback;
        this.className = styles.Card;

        this.reasons = i18n.decline_reasons_COR
            .map((item: string, index: number) => ({ name: item, id: index }));

        new ServiceCentersService()
            .getContacts(serviceCenterId)
            .then((data: any) =>
                this.setState({ phones: data }));

        this.state = {
            phones: [],
            isTemplate: true,
            dropDownValue: this.reasons[0],
            isButtonBlocked: false
        };

        this.title =
            <div className={styles.title}>
                {i18n.popups.why_do_you_want_to_cancel_the_booking}
            </div>
    }

    send = (model: any) => {
        this.setState({ isButtonBlocked: true });
        new ContractsService().cancelBooking(this.id, model)
            .then(() => {
                updateDataLayer({
                    'eventCategory': 'booking',
                    'eventAction': 'final decline',
                    'eventLabel': '9  step',
                    'event': 'autoEvent',
                })

                this.setState({ isButtonBlocked: false });
                this.callback();
                this.close();
            })
    }

    renderContent() {
        const props: any = this.props;
        const state: any = this.state;
        const { i18n, $values } = props;
        const { send, close } = this;
        formInternalization(formConfig);

        const handleSubmit = () => {
            if(props.invalid || state.isButtonBlocked){
                props.touch("reason")
                props.blur("reason")
                return
            }
            send($values)
        }
        return (
            <>
                <StyledDialogContent>
                    <form >
                        <Label className={styles.label}>{i18n.popups.choose_a_reason}</Label>
                        <Dropdown
                            className={styles.dropDown}
                            options={this.reasons}
                            value={state.dropDownValue}
                            placeholder={i18n.popups.template_reason}
                            onChange={(option: any) => {
                                this.setState({ isTemplate: false, dropDownValue: option });
                                this.props.change('reason', option.name);

                                if (option.id === 0) {
                                    this.setState({ isTemplate: true, dropDownValue: option });
                                    this.props.change('reason', '');
                                }
                            }} />

                        {state.isTemplate && <FormControl {...formConfig.reason}
                            className={joinClassNames(styles.descControl, styles.offsetTop)}>
                            <Label is={FORM_CONTROL_ELEMENT.LABEL} />
                            <TextareaField
                                className={styles.desc}
                                is={FORM_CONTROL_ELEMENT.TEXTAREA}
                            />
                            <ErrorMessage is={FORM_CONTROL_ELEMENT.ERROR_MESSAGE} />
                        </FormControl>}

                        {state.phones && !!state.phones.length && (<div className={styles.contacts}>{i18n.common.contacts}</div>)}
                        {state.phones.map((contact: any, index: number) =>
                            <div key={index} className={styles.contact}>
                                <div>{contact.name}</div>
                                <div className={styles.spacer}></div>
                                <a href={`tell:${formatPhone(contact.phone)}`}>{formatPhone(contact.phone)}</a>
                            </div>
                        )}
                    </form>
                </StyledDialogContent>

                <StyledDialogActions>
                    <Flex className={styles.buttons}>
                        <Button large={true}
                            onClick={handleSubmit}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            color='danger'
                            type='submit'
                            variant='text'>
                            {i18n.popups.yes_cancel}
                        </Button>

                        <Button large={true}
                            small={true}
                            fontSize={16}
                            className={styles.button}
                            onClick={close}
                            variant='outlined'>
                            {i18n.popups.keep_booking}
                        </Button>
                    </Flex>
                </StyledDialogActions>
            </>
        );
    }
}

const form = BasePopup.generateKey();

const mapDispatchToProps = (dispatch: any) => ({
    closePopup: closePopup(dispatch),
});

const mapStateToProps = (state: any) => {
    let { values = {} } = state.form[form] || {};
    return { $values: values, i18n: state.i18n }
};

const Form: any = reduxForm({
    form,
    initialValues: {
        reason: '',
    },
})(CancelBookingPopup);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
