import React from "react";
import { Field, FieldArray } from "redux-form";

import { joinClassNames } from "../../helpers/className";
import { CompanyType } from "../../types/CompanyType";
import Checkbox from "../Checkbox";

import styles from "../../pages/business/sign-up/index.module.scss";


export const CheckboxControl = ({ name, validate, label }: any) => {
    return (
        <Field
            type="checkbox"
            name={name}
            validate={validate}
            component={({ input }: any) => (
                <Checkbox

                    label={label}
                    className={styles.checkbox}
                    {...input}
                />
            )}
        />
    )
};

const CheckboxesControl = ({ name, validate, collection, className }: any) => {
    const checkboxClasses = joinClassNames(styles.checkbox, className);

    return (
        <FieldArray
            name={name}
            validate={validate}
            component={({ fields }: any) => (
                collection.map(({ name, id }: CompanyType) => {
                    let isChecked = fields.getAll().includes(id);

                    return (
                        <Checkbox
                            key={id}
                            label={name}
                            checked={isChecked}
                            className={checkboxClasses}
                            onChange={() => {
                                if (isChecked) {
                                    return fields.remove(
                                        fields.getAll().indexOf(id)
                                    )
                                }

                                fields.push(id);
                            }}
                        />
                    )
                })
            )}
        />
    )
};

export default CheckboxesControl
